import React from "react";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
// import Button from "antd/lib/button";
import dayjs from "dayjs";
import Skeleton from "antd/lib/skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import eventImg from "../../../../../ui/image/empty-event.png";
import Space from "antd/lib/space";

const { Title, Text } = Typography;
const DetailsCard = ({ orderDetailsData, loader, eventDetails }) => {
  return (
    <>
      <Skeleton loading={loader} active avatar>
        <Card bordered={false}>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: 350,
              padding: "20px"
            }}
          > */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={10} lg={10}>
              <Row gutter={[8, 16]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div style={{ height: 250, margin: '0px -15px' }}>
                    <img
                      width={250}
                      height={200}
                      style={{ border: '1px solid #ccc', borderRadius: '5px' }}
                      src={eventDetails?.images && eventDetails?.images?.[0]?.public_url ? eventDetails?.images?.[0]?.public_url : eventImg}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div style={{ marginBottom: 35 }}>
                    <Title level={5}>{eventDetails?.name ? eventDetails?.name : ''}</Title>
                    <a style={{ color: "#1677FF" }}>Manage Event</a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14}>
              <Title level={4} style={{ marginBottom: 35 }}>
                Order Details
              </Title>
              <Row gutter={[16, 32]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row gutter={[16, 32]}>
                    <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                      <Space direction="vertical">
                        <Text strong>Buyer Name</Text>
                        <Text type="secondary">
                          {`${orderDetailsData?.billing_first_name} ${orderDetailsData?.billing_last_name}`}
                        </Text>
                      </Space>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                      <Space direction="vertical">
                        <Text strong>Order Total</Text>
                        <Text type="secondary">{`£ ${orderDetailsData?.total_amount}`}</Text>
                      </Space>


                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                      <Space direction="vertical">
                        <Text strong>Purchase Date</Text>
                        <Text type="secondary">
                          {orderDetailsData?.booking_date
                            ? dayjs(
                              orderDetailsData?.booking_date,
                              "YYYY-MM-DD"
                            ).format("MMMM D, YYYY")
                            : ""}
                        </Text>
                      </Space>


                      {/* <div style={{ fontWeight: "bold" }}>Payment Details</div>
                      <span style={{ color: "grey" }}>
                        {orderDetailsData?.payment_method
                          ? `${orderDetailsData?.payment_method}`
                          : `NA`}
                      </span> */}
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Space direction="vertical">
                        <Text strong>Ticket Total</Text>
                        <Text type="secondary"> {orderDetailsData?.total_quantity}</Text>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row gutter={[16, 32]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <span style={{ marginRight: 15 }}>
                        <FontAwesomeIcon icon={faLocationDot} />
                      </span>
                      {eventDetails?.city}
                      <div style={{ fontSize: '12px', marginLeft: 16 }}>{eventDetails?.address_one}</div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <span style={{ marginRight: 15 }}>
                        <CalendarOutlined />
                      </span>
                      <span>
                        {eventDetails?.starts_date && eventDetails?.ends_date ? (
                          `${dayjs(eventDetails?.starts_date, "YYYY-MM-DD").format("ddd, MMMM D")}, ${dayjs(eventDetails?.starts_time, "HH:mm:ss").format("h:mm A")}
                          ${' (' + dayjs(eventDetails?.starts_date, "YYYY-MM-DD").format("YYYY") + ')'}
                          ${' - ' + dayjs(eventDetails?.ends_date, "YYYY-MM-DD").format("ddd, MMMM D")}, ${dayjs(eventDetails?.ends_time, "HH:mm:ss").format("h:mm A")}
                          ${' (' + dayjs(eventDetails?.ends_date, "YYYY-MM-DD").format("YYYY") + ')'}`
                        ) : ''}

                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* </div> */}
        </Card>
      </Skeleton>
    </>
  );
};

export default DetailsCard;
