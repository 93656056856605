import React, { useEffect, useState } from 'react';
import "./style.css";
// import logo from "../../image/logo.png"
import logImg from "../../../image/log.png"
// import registerImg from "../../../image/register.png"
import logo from "../../../image/logo.png"

import { Link, Outlet, useLocation } from 'react-router-dom';
import { Alert, Button, message } from 'antd/lib';
import { Api_base_url } from '../../../../config';

const ForgotPassword = () => {
    // const [isShown, setIsSHown] = useState(false);
    const [lo_responseMessage, setResponseMessage] = useState({})
    const [ls_email, setEmail] = useState('')
    const [lb_loading, setLoading] = useState(false)
    const [ls_successMsg, setSuccessMsg] = useState('')

    const handleSubmitForgotPassword = (e) => {
        e.preventDefault();
        setLoading(true)
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
          };
          
        fetch(Api_base_url+"send-link-forget-password?email="+ls_email, requestOptions)
        .then(response => response.text())
        .then(result => {
            const lo_response = JSON.parse(result)
            console.log('lo_response',lo_response);
            setLoading(false)
            if (lo_response.status == 'success') {
                setEmail('')
                setSuccessMsg('success')
                // message.success("Successfully Logged in!!");
                setResponseMessage({})
                // navigate('/dashboard')
              }else {
                setSuccessMsg('')
                if (typeof lo_response?.message == "string") {
                    message.error(lo_response?.message || "Validation Error!")
                    setResponseMessage({'email': lo_response?.message ? [lo_response?.message+'!'] : ['Something wrong!']})
                }else{
                    message.error("Validation Error!")
                    setResponseMessage(lo_response.message)
                }
              }
        })
        .catch(error => {
            setLoading(false)
            console.log('error', error)
            message.error("Somethin wrong!")
        });
    }

    useEffect(() => {
      console.log('ls_email',ls_email);
    }, [ls_email])
    

    return (

        <div className="login-container forgot-passpart">
        <div className="forms-container">
            <div className="signin-signup">
                
                <form onSubmit={handleSubmitForgotPassword} action="#" className="sign-in-form">
                    {ls_successMsg ? <div className="login-header-block">
                    <Alert
                        message="Successfully Submitted"
                        description="A forgot password email has been sent to you. Please open it and change your password."
                        type="success"
                        showIcon
                        closable
                        onClose={()=> setSuccessMsg('')}
                    />
                    </div> : <></>}
                    <div className="login-header-block">
                   
                    <img src={logo} />
                    <h2 className="title">Forgot Password</h2>

                    
                    </div>
                    
                    <div className="input-field">
                        <i className="fa-solid fa-user"></i>
                        <input 
                            type="text" 
                            value={ls_email} 
                            onChange={(e) => setEmail(e.target.value)}
                            name="email" placeholder="Enter your registered email" 
                        />
                        {lo_responseMessage['email'] ? <p className="comn-input-err">{lo_responseMessage['email'][0]}</p> : <></>}
                    </div>

                    {/* <div className="input-field">
                    <i className="fas fa-lock"></i>
                    <input type={isShown ? "text" : "password"} name="password" placeholder="Password" />
                    <button type="button" className="button login-show-hide-button" onClick={() => setIsSHown((isShown) => !isShown)}>
                    {isShown ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
                    </button>
                    {lo_responseMessage['password'] ? <p style={{color:"red"}}>{lo_responseMessage['password'][0]}</p> : <></>}
                    </div> */}

                    {/* <input type="submit" value="Login" className="btn solid" /> */}
                    <Button loading={lb_loading} htmlType='submit' className="btn solid">Submit</Button>
                </form>
            </div>
        </div>

        <div className="panels-container">
            <div className="panel left-panel">
            <div className="content">
                <h3>Don’t have an account? Set one up.</h3>
                <p>Join over 80,000 event creators using us!</p>
                <Link to={"/signup"}>
                    <button className="btn transparent">
                        Back to Sign up
                    </button>
                </Link>
            </div>
            <img src={logImg} className="image" alt="image" />
            </div>
            
        </div>
        </div>
    );
}

export default ForgotPassword;