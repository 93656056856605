import { UserOutlined, LoginOutlined, RetweetOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Api_base_url } from '../../../../config';
import { useNavigate } from 'react-router-dom';
import  message  from 'antd/lib/message';
import { actionForUserLogout, getUserInformationData, storeOrganisationData } from '../../../utility';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType } from '../../../store/common';
import { useEffect, useState } from 'react';
const items = [
  {
    label: 'Account Settings',
    icon: <UserOutlined />,
    key: 'organiser/account-settings',
  },
  {
    label: 'Switch to Attending',
    icon: <RetweetOutlined />,
    key: 'switch-type',
  },
  {
    label: 'Log Out',
    icon: <LoginOutlined  />,
    key: 'logout',
  },
];

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const AccountDropDown = ({la_orgList}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const ls_userType = useSelector( (state) => state.common.userType );
  const ls_token = useSelector( (state) => state.common.token );
  const ls_organisationId = useSelector( (state) => state.common.organisation_id );
  
  useEffect(() => {
      setDropDownItems()
  }, [ls_userType, la_orgList])
  
  const [la_items, setMenuItems] = useState([])
  const setDropDownItems = () => {
    if (ls_userType == 'organizer') {
      setMenuItems([
        {
          label: 'Account Settings',
          icon: <UserOutlined />,
          key: 'organiser/account-settings',
        },
        {
          label: 'Switch to Attendee',
          icon: <RetweetOutlined />,
          key: 'switch-type',
        },
        {
          type: 'divider',
        },
        {
          label: 'Organization Overview',
          key: 'overview',
        },
        {
          type: 'divider',
        },
        {
          label: 'Switch Organization',
          key: 'switch-org',
          children: [
            ...la_orgList
          ],
        },
        {
          type: 'divider',
        },
        {
          label: 'Log Out',
          icon: <LoginOutlined  />,
          key: 'logout',
        },
      ])
    } else {
      setMenuItems([
        {
          label: 'Account Settings',
          icon: <UserOutlined />,
          key: 'organiser/account-settings',
        },
        {
          label: 'Switch to Organizer',
          icon: <RetweetOutlined />,
          key: 'switch-type',
        },
        {
          label: 'Log Out',
          icon: <LoginOutlined  />,
          key: 'logout',
        },
      ])
    }
  }

  const onClickDropDownMenu = async (value) => {
    let ls_key = value?.key;
    if(ls_key) {
      if(ls_key == 'logout'){
        logOutUser()
      }else if (ls_key == 'switch-type') {
        if (ls_userType == 'organizer') {
          dispatch(setUserType('user'));
          // navigate('/home');
          navigate('/dashboard');
        }else if (ls_userType == 'user') {
          dispatch(setUserType('organizer'));
          navigate('/dashboard');
        }
      }  else {
        navigate('/'+value.key);
      }

    }
  };

  const logOutUser = () => {
    startLoadTheMessage()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(Api_base_url+"logout", requestOptions)
      .then(response => response.text())
      .then(result => {
        const lo_response = JSON.parse(result)
        if(lo_response.status == "success"){
          console.log('result',lo_response);
          actionForUserLogout();
          navigate('/signup');
          // message.error('Logged out!!')
          stopLoadingMsh('Logged out!!')
        }else{
          message.error('Something Wrong!!')
        }
      })
      .catch(error => console.log('error',error));

      actionForUserLogout();
  }

  
  
  return  (
      <Dropdown
        menu={{
          items: la_items,
          onClick : onClickDropDownMenu
        }}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space align='center'>
            <DownOutlined style={{marginBottom:10}} />
          </Space>
        </a>
      </Dropdown>
    );
}
export default AccountDropDown;