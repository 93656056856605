import React, { Component } from 'react';
import './style.css';

const buyerFaq = [
    {
      category: 'buyer',
      question: 'How can I create an account?',
      answer: 'To create an account, click on the "Sign Up" button and provide your details.'
    },
    {
      category: 'buyer',
      question: 'Is there a refund policy?',
      answer: 'Yes, we offer a refund policy within 30 days of purchase. Please contact our support for assistance.'
    },
    {
      category: 'buyer',
      question: 'Can I change my shipping address?',
      answer: 'Yes, you can change your shipping address in your account settings before the order is shipped.'
    },
    // ... Add more buyer FAQs
  ];
  
  const organiserFaq = [
    {
      category: 'organiser',
      question: 'How do I create an event?',
      answer: 'To create an event, log in to your account and navigate to the "Create Event" section.'
    },
    {
      category: 'organiser',
      question: 'Can I customize the event registration form?',
      answer: 'Yes, you can customize the registration form fields according to your needs.'
    },
    {
      category: 'organiser',
      question: 'Is there a limit on the number of attendees?',
      answer: 'The attendee limit depends on your event plan. Contact our team for more information.'
    },
    // ... Add more organiser FAQs
  ];

class FAQPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: 'buyer',
      faqs: buyerFaq,
      displayedFaqs: 10,
      searchQuery: '',
    };
  }

  renderFilteredFAQs = () => {
    const { faqs, displayedFaqs, activeCategory, searchQuery } = this.state;

    const filteredFaqs = faqs
      .filter(faq => activeCategory === 'buyer' ? faq.category === 'buyer' : faq.category === 'organiser')
      .filter(faq => faq.question.toLowerCase().includes(searchQuery.toLowerCase()))
      .slice(0, displayedFaqs);

    return filteredFaqs.map((faq, index) => (
      <div key={index} className="faq-item">
        <div className="faq-question" onClick={() => this.toggleAnswer(index)}>
          {faq.question} <i className="fas fa-chevron-down"></i>
        </div>
        <div className={`faq-answer ${faq.showAnswer ? 'show' : ''}`}>{faq.answer}</div>
      </div>
    ));
  };

  toggleAnswer = (index) => {
    const { faqs } = this.state;
    faqs[index].showAnswer = !faqs[index].showAnswer;
    this.setState({ faqs });
  };

  displayBuyerFAQ = () => {
    this.setState({
      activeCategory: 'buyer',
      faqs: buyerFaq,
      displayedFaqs: 10,
    });
  };

  displayOrganiserFAQ = () => {
    this.setState({
      activeCategory: 'organiser',
      faqs: organiserFaq,
      displayedFaqs: 10,
    });
  };

  loadMoreFAQs = () => {
    this.setState((prevState) => ({
      displayedFaqs: prevState.displayedFaqs + 10,
    }));
  };

  handleSearchInputChange = event => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  };

  render() {
    const { activeCategory, displayedFaqs, faqs, searchQuery } = this.state;

    return (
      <div>
        <div className="search-container">
          <input
            type="text"
            id="search-input"
            placeholder="Search FAQs..."
            value={searchQuery}
            onChange={this.handleSearchInputChange}
          />
        </div>
        <div className="container-s98cus">
          <div className="row-btn-faq">
            <div className="row-btn-faq-inside">
              <div
                className={`faq-buyer faq-bO ${
                  activeCategory === 'buyer' ? 'faq-active' : ''
                }`}
                onClick={this.displayBuyerFAQ}
              >
                Buyer
              </div>
              <div
                className={`faq-organiser faq-bO ${
                  activeCategory === 'organiser' ? 'faq-active' : ''
                }`}
                onClick={this.displayOrganiserFAQ}
              >
                Organiser
              </div>
            </div>
          </div>
          <hr style={{ marginTop: '4px' }} />
          <div className="d-flex-s98cus justify-content-center-s98cus">
            <div id="faq-container" className="col-12-s98cus d-flex container-s98cus">
              {this.renderFilteredFAQs()}
            </div>
          </div>
          <div className="d-flex-s98cus justify-content-center-s98cus m-3-s98cus">
            <button
              className="btn-s98cus btn-primary-s98cus search-faq-btn"
              id="read-more-button"
              style={{ display: faqs.length > displayedFaqs ? 'block' : 'none' }}
              onClick={this.loadMoreFAQs}
            >
              Read More
            </button>
            <span id="ellipsis"></span>
          </div>
        </div>
      </div>
    );
  }
}
export default FAQPage;
