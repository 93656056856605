import { Button, InputNumber } from 'antd';
import { Space } from 'antd/lib';
import React, { useState } from 'react'

const NumberCounter = ({ value = 0, onChange, minValue=0, maxValue=10 }) => {
    const [li_value, setValue] = useState(value);
    const handleSetNewValue = (countValue) => {
        const intValue = Math.floor(countValue);  // Ensure the value is an integer
        setValue(intValue);
        onChange(intValue);
    }
    return (
        <div>
            <Space>
                <Button disabled={li_value <= minValue} onClick={() => handleSetNewValue(li_value - 1)}>-</Button>
                <InputNumber
                    min={minValue}
                    max={maxValue}
                    value={li_value}
                    onChange={(val) => handleSetNewValue(val)}
                    controls={false}

                    step={1}  // Allows increment by 1
                    precision={0}  // Disables floating points
                />
                <Button disabled={li_value >= maxValue} onClick={() => handleSetNewValue(li_value + 1)}>+</Button>
            </Space>
        </div>
    )
}

export default NumberCounter