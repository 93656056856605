
import React from 'react';
import Spin from 'antd/lib/spin';

import './style.less';
const FullLoader = () => {
    return (
         <div className="full-loader-container">
              <Spin size="large" />
         </div>
      );
}
 
export default FullLoader;