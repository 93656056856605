import React, {useEffect, useState} from 'react'
import Result from 'antd/lib/result'
import Layout, { Content } from 'antd/lib/layout/layout'
import Button from 'antd/lib/button'
import { Link } from 'react-router-dom'
import { Print_base_url } from "../../../../config";
import { getRequestApi } from '../../../../api/commonRequest'
import Typography from 'antd/lib/typography/Typography'

const BookingSuccessPage = () => {
    const [lo_paymentDetails, setPaymentDetails] = useState(null)
    const [lo_eventDetails, setEventDetails] = useState({});
    
    
    const getEventDetailsById = async () => {
        if (lo_paymentDetails?.event_id) {
            const lo_eventDetails = await getRequestApi(`event-details-against-id/${lo_paymentDetails.event_id}`);
            console.log("lo_eventDetails", lo_eventDetails);
            if (lo_eventDetails?.status === "success") {
                setEventDetails(lo_eventDetails?.event);
            }else{
                setEventDetails({});
            }
        }else{
            setEventDetails({});
        }
    };

    useEffect(() => {
        getEventDetailsById();
    }, [JSON.stringify(lo_paymentDetails)]);

    useEffect(() => {
        let lo_paymentDetail;
        const paymentDetail = localStorage.getItem('paymentDetail');
        if(paymentDetail) lo_paymentDetail = JSON.parse(paymentDetail);
        setPaymentDetails(lo_paymentDetail)
        console.log('lo_paymentDetail',lo_paymentDetail);
    }, [])
    
    const handlePrintTicket = () => {
        const ls_orderUniqueId = lo_paymentDetails?.unique_code;
        const ls_printUrl = `${Print_base_url}generate-ticket-pdf/${ls_orderUniqueId}`;
        window.open(ls_printUrl, "_blank");
    }
    
  return (
    <div style={{ minHeight: '100vh'}} className='mainContainer'>
        <Result
            status="success"
            title="Your Ticket Booking is Confirmed!"
            subTitle={<div>Thank you for booking <Typography.Text style={{color:"#814c1899", fontWeight:700}}>{lo_eventDetails?.name}</Typography.Text> 🎉 
                Your tickets have been successfully processed. Check your email (including spam) for confirmation. 
                <br></br>
                You can also log in to TicketKart anytime to download your tickets instantly. 
                Click below to download your ticket now! 🎟✨</div>}
            extra={lo_paymentDetails && [
                <Button type="primary" key="console" onClick={handlePrintTicket}>
                        Download Ticket
                </Button>,
            ]}
        />
    </div>
  )
}

export default BookingSuccessPage