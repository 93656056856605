import "./style.css";
import { PageHeader } from "@ant-design/pro-layout";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "antd/lib/button";
import PrinterOutlined from "@ant-design/icons/PrinterOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
// import CloseOutlined from "@ant-design/icons/CloseOutlined";
import Divider from "antd/lib/divider";
import { Content } from "antd/es/layout/layout";
import Typography from "antd/lib/typography";
import Table from "antd/lib/table";
import DetailsCard from "./DetailsCard";
import { getRequestApi } from "../../../../api/commonRequest";
import { Admin_base_url, Print_base_url } from "../../../../config";

const OrganiserOrderDetails = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const { state } = useLocation();
  const [la_attendeeList, setAttendeeList] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState({})
  const [lb_loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false)
  const [eventDetails, setEventDetails] = useState({});
  const la_columns = [
    {
      title: "Ticket Type",
      dataIndex: "ticket_type",
      render: (text, record) => <div style={{ textTransform: 'capitalize'}}>{record?.ticket?.ticket_type}</div>,
      key: "ticket_type",
      width: "35%",
    },
    {
      title: "Price",
      dataIndex: "cost_to_buyer",
      render: (text) => `£ ${text}`,
      key: "cost_to_buyer",
      width: "20%",
    },
    {
      title: "Total Price",
      dataIndex: "sub_total",
      render: (text) => `£ ${text}`,
      key: "sub_total",
      width: "25%",
    },
    {
      title: "Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
      width: "20%",
    },
  ];

  useEffect(() => {
    getOrderDetailsByUniqueCode(id);
  }, [id]);

  useEffect(() => {
    getEventDetailsById();
  }, [state]);

  const getEventDetailsById = async () => {
    if (state) {
      const lo_eventDetails = await getRequestApi(
        `event-details-against-id/${state}`
      );
      // console.log("lo_eventDetails", lo_eventDetails);
      if (lo_eventDetails?.status === "success") {
        setEventDetails(lo_eventDetails?.event);
      }
    }
  };
  const getOrderDetailsByUniqueCode = async (id) => {
    setLoading(true)
    setLoader(true)
    const lo_orderDetails = await getRequestApi(`org-booking-details/${id}`);
    // console.log('lo_orderDetails', lo_orderDetails);
    setLoading(false)
    setLoader(false)
    if (lo_orderDetails?.status === "success") {
      setAttendeeList(lo_orderDetails?.bookings[0]?.booking_details)
      setOrderDetailsData(lo_orderDetails?.bookings[0])
    }
  };
  const handlePrintTicket = () => {
    const ls_orderUniqueId = id;
    const ls_printUrl = `${Print_base_url}generate-ticket-pdf/${ls_orderUniqueId}`;
    console.log('ls_printUrl',ls_printUrl);
    const newTab = window.open(ls_printUrl, "_blank");
    // newTab.close();
  }
  return (
    <>
      <PageHeader
        title={`Order ID ${id}`}
        className="content-layout content-text thumbnail page-header-content"
        extra={[
          <Link onClick={handlePrintTicket} key="1">
            <Button
              // className="add-btn"
              type="link"
              size="small"
              icon={<PrinterOutlined />}
              style={{ float: "right" }}
            >
              Print tickets
            </Button>
          </Link>,
          <Divider type="vertical" style={{ margin: 0 }} key="4" />,
          <Link to="add" key="2">
            <Button
              // className="add-btn"
              type="link"
              size="small"
              icon={<MailOutlined />}
              style={{ float: "right" }}
            >
              Resend confirmation email
            </Button>
          </Link>,
          // <Divider type="vertical" style={{ margin: 0 }} key={"5"} />,
          // <Link to="add" key="3">
          //   <Button
          //     className="add-btn"
          //     type="link"
          //     icon={<CloseOutlined />}
          //     style={{ float: "right" }}
          //     size="small"
          //     danger
          //   >
          //     Cancel order
          //   </Button>
          // </Link>,
        ]}
      />
      <Content className="content-layout thumbnail">
        <DetailsCard orderDetailsData={orderDetailsData} loader={loader} eventDetails={eventDetails}/>
        <div className="details-table-container">
          <Title level={5}>Attendees</Title>
          <Link to="/attendee-report" key="report">
            {/* //className="add-btn" */}
            <Button type="link">View Attendee Report</Button>
          </Link>
        </div>
        <Table
          rowKey="id"
          columns={la_columns}
          className="table-inner-content"
          pagination={true}
          scroll={{ x: 500 }}
          loading={lb_loading}
          dataSource={la_attendeeList}
          rowClassName="row-table"
          size="middle"
        />
      </Content>
    </>
  );
};

export default OrganiserOrderDetails;
