import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './style.css'
import { useSelector } from 'react-redux';
const ListEvent = () => {

  const ls_userId = useSelector( (state) => state.common.user_id );
  return (
    <div className="container-s98cus d-flex-s98cus justify-content-center-s98cus mt-5-s98cus mb-3-s98cus" style={{ height: '300px', alignItems: 'center' }}>
      <div className="container-s98cus" style={{ backgroundColor: '#36f' }}>
        <div className="list-event-div p-1-s98cus">
          <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
          <h1 className="text-center-s98cus">List Your Event with us</h1>
          <Link to={ls_userId ? "/dashboard" : "/dashboard"} className="btn-s98cus btn-primary-s98cus">
          Get started for free
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ListEvent;
