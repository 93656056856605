import React, { useEffect, useState } from "react";
import "./style.css";
import Tabs from "antd/lib/tabs";
import Table from "antd/lib/table";
import MailOutlined from "@ant-design/icons/MailOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import FileAddOutlined from "@ant-design/icons/FileAddOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";
import { Content } from "antd/lib/layout/layout";
import { Typography, Input, Button, Modal, message, Col, Row, Layout } from "antd";
// import debounce from "lodash/debounce";
import { Link, useNavigate } from "react-router-dom";
import TeamAccessForm from "./TeamAccessForm";
import { Api_base_url } from "../../../config";
// import { capitalizeFirstLetter } from "../../utility";
// import { useSelector } from "react-redux";
import dayjs from 'dayjs';

const { Search } = Input;
const { Title } = Typography;

const { TabPane } = Tabs;

const TeamAccess = () => {
    const ls_token = localStorage.getItem('token')
    const ls_organisationId = localStorage.getItem('organisation_id')

    // const dataSource = [
    //     {
    //       key: '1',
    //       name: 'Mike',
    //       role: 32,
    //       email: 'test@gmail.com',
    //       last_seen: '13 May 2023 3:35 PM',
    //     },
    //     {
    //       key: '2',
    //       name: 'New User',
    //       role: 32,
    //       email: 'test@gmail.com',
    //       last_seen: '13 May 2023 3:35 PM',
    //     },
    //   ];

    const [lb_loading, setLoading] = useState(false);
    const [la_teamAccessList, setteamAccessList] = useState([]);
    const [ls_searchText, setSearchText] = useState("");
    const [li_page, setPage] = useState(1);
    const [total, setToatal] = useState(0);
    const [li_pageSize, setPageSize] = useState(10);
    const [li_totalPage, setTotalPage] = useState(0);
    const [ls_tabIndex, setTabIndex] = useState("All");
    const navigate = useNavigate();


    //common function to crud
    const [ls_actionType, setActionType] = useState(null);
    const [la_organisationList, setOrganisationList] = useState([]);
    const [lo_actionRecord, setActionRecord] = useState({});
    const handleSetDataAction = (ls_action=null, record={}) => {
        setActionType(ls_action)
        setActionRecord(record)
    }

    useEffect(() => {
        getteamAccesssList()
    }, [ls_organisationId, ls_token])

    // useEffect(() => {
    //     getteamAccesssList(ls_tabIndex);
    // }, [li_page, ls_searchText]);
    // useEffect(() => {
    //     let totalPage = Math.ceil(total / 10);
    //     setTotalPage(totalPage);
    // }, [total]);
    const onStatusChange = (value) => {
        setTabIndex(value);
        getteamAccesssList(value);
    };
    const manageButton = (record, inx) => {
        return (
            <div style={{ display: "flex" }}>
                {ls_tabIndex == "All" ? (
                    <>
                        {" "}
                        <Button
                            // onClick={(e) => {
                            //     handleArchiveAndRestoreUser(
                            //         record,
                            //         "Invites"
                            //     );
                            // }}
                            className="delete-button"
                            type="text"
                            icon={<DeleteFilled />}
                        />
                        <Button
                            onClick={(e) => {
                                handleSetDataAction('edit_teamaccess', record);
                            }}
                            className="edit-button mr4-xs"
                            type="text"
                            icon={<EditOutlined />}
                        />
                    </>
                ) : (
                    <Button
                        
                        className="edit-button mr4-xs"
                        type="text"
                        icon={<HistoryOutlined />}
                    />
                )}
            </div>
        );
    };
    const getSubjectAndClassComponent = (record) => {
        const la_classSubjectDetails = record.class_subject_details;
        const subjectAndClasses = la_classSubjectDetails.map((rowValue) => <Typography.Text key={rowValue.class_id} code>{rowValue.subject_name} ({rowValue.class_name})</Typography.Text>)
        return subjectAndClasses;
    }
    const la_columns = [
        {
            title: "Sl No",
            // dataIndex: 'name',
            // render: (record) => (
            //     <div>{record.name.first + " " + record.name.last}</div>
            // ),
            render: (text, record, inx) => inx+1,
        },
        {
            title: "Email",
            dataIndex: "email",
            // group: 'primary_email'
        },
        {
            title: "Job Role",
            dataIndex: "role",
            render: (text, record) => (
                <div>{record.role_id ==2 ? "Admin" : record.role_id ==3 ? "Event Manager" : ""}</div>
            ),
        },
        {
            title: "Requseted On",
            dataIndex: "created_at",
            // render: (text, record, inx) =>record.created_at,
            render: (value) => (
                <div>{value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ''}</div>
            ),
        },
        // {
        //     title: "Manage",
        //     dataIndex: "manage",
        //     render: (text, record, inx) => manageButton(record),
        // },
    ];
    const handleArchiveAndRestoreUser = (record, value) => {
        // Modal.confirm({
        //     title: "Confirm",
        //     icon: <ExclamationCircleOutlined />,
        //     content: `Do you want to ${
        //         value == "Ar" ? "archive" : "restore"
        //     } this teamAccess?`,
        //     async onOk() {
        //         try {
        //             await meteorAsyncCall(
        //                 "student.archiveAndRestorUser",
        //                 organization_id,
        //                 record._id,
        //                 record.email,
        //                 'user',
        //                 value
        //             );
        //             getteamAccesssList(ls_tabIndex);
        //             message.success(
        //                 `Succesfully ${
        //                     value == "Ar" ? "archived" : "restored"
        //                 }!`
        //             );
        //         } catch (error) {
        //             message.error(error.error);
        //         }
        //     },
        // });
    };

    const getteamAccesssList = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ls_token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
        //   https://tk.hostmenow.in/tkapp/public/api/all-request-member/%7Borganisation_id%7D
        fetch(Api_base_url+"all-request-member/"+ls_organisationId, requestOptions)
        .then(response => response.text())
        .then(result => {
            const lo_response = JSON.parse(result)
            console.log('lo_response',lo_response);
            if (lo_response?.members) {
                setteamAccessList(lo_response.members)
            }else{
                setteamAccessList([])
            }
        })
        .catch(error => console.log('error', error));

        setLoading(false)
    };

    
    

    const lo_formProps = { "getDataList": getteamAccesssList, ls_actionType, lo_actionRecord, handleSetDataAction };

    return (
        <>
            <Content className="content-layout thumbnail">
            <Col xs={24} sm={24} md={24} lg={24}>
                <Row>
                <Col  xs={24} sm={24} md={24} lg={16}>
                    <Typography.Title  
                    className="title"
                    level={3}
                    >Manage Team Access</Typography.Title>
                   </Col>
                   <Col  xs={0} sm={0} md={0} lg={3}></Col>
                   <Col  xs={24} sm={24} md={24} lg={5} className="mb-5">
                   <Button
                        style={{float:"right"}}
                        onClick={() => { handleSetDataAction('add_teamaccess') }}
                        className="add-btn"
                        type="primary"
                        icon={<FileAddOutlined />}
                    >
                        Add Team Access
                    </Button>
                    </Col>
                </Row>
                </Col>
               

                {/* <Tabs
                    // className="thumbnail mt10-lg"
                    className="header-thambnail"
                    onChange={(index) => onStatusChange(index)}
                    // tabBarExtraContent={
                    //     <Search
                    //         placeholder="Name & Email"
                    //         onChange={debounce((e) => {
                    //             setSearchText(e.target.value);
                    //         }, 500)}
                    //         allowClear
                    //     ></Search>
                    // }
                >
                    {/* <TabPane
                        className="content-text"
                        key="All"
                        tab={
                            <span>
                                <MailOutlined /> All
                            </span>
                        }
                    ></TabPane> */}
                    {/* <TabPane
                        className="content-text"
                        key="Teammates"
                        tab={
                            <span>
                                <MailOutlined /> Teammates
                            </span>
                        }
                    ></TabPane> */}

                    {/* <TabPane
                        className="content-text"
                        key="Invites"
                        tab={
                            <span>
                                <DeleteOutlined />
                                Invites
                            </span>
                        }
                    />
                </Tabs> */} 
            </Content>
            {/* <Layout> */}
            <Content className="content-layout"  >
            {/* <div className="table-container"> */}
                    <Table
                        loading={lb_loading}
                        rowKey="id"
                        scroll={{ x: 600,y: 300 }}
                        columns={la_columns}
                        className="tant-table-body"
                        dataSource={la_teamAccessList}
                        rowClassName="row-table"
                        size="middle"
                    />
                    {/* </div> */}
                </Content>
            {/* </Layout>    */}
            <TeamAccessForm {...lo_formProps}/>
        </>
    );
};

export default TeamAccess;
