import { Api_base_url } from "../../config";
const ls_token = localStorage.getItem('token');

export const getCountryListCommonApi = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

    try {
        const response = await fetch(Api_base_url+"all-countries", requestOptions)
        const jsonResponse = await response.json();
        if (jsonResponse?.countries) {
            return jsonResponse.countries;
        }else{
            return [];
        }
    } catch (error) {
        console.log('error', error);
        return error;
    }
}