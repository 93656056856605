import React, { useEffect, useState } from "react";
import './style.css'
import { Modal } from 'antd';
import { Button, Space } from "antd/lib";
import Input from "antd/lib/input/Input";
import { postRequestApi } from "../../../api/commonRequest";
import { Live_base_url } from "../../../config";
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined, WhatsAppOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import growth from "../../../assets/img/refer/growth-r.png"
import money from "../../../assets/img/refer/money-r.png"
import www from "../../../assets/img/refer/www-r.png"
import wwwBg from "../../../assets/img/refer/www-bg.jpg"
import moneyBg from "../../../assets/img/refer/money-bg.jpg"
import growthBg from "../../../assets/img/refer/growth-bg.jpg"
const ReferAndEarn = ({ modalVisible = false, onCancel = () => { }, children, title = 'Buy Ticket', lo_exttraModalStyle={} }) => {
    const [lb_loading, setLoading] = useState(false)
    const [lo_referDetails, setReferDetails] = useState({})
    const [isCopied, setIsCopied] = useState(false);
    const [isReferralLinkVisible, setReferralLinkVisible] = useState(false)
    const [lo_modalStyle, setModalStyle] = useState({
        // maxHeight: '92%',
        overflow: 'hidden',
        marginTop: -70, 
        // marginBottom: 20,
    })

    const emailSubject = 'Check out our website TicketKart!';

    useEffect(() => {
        if (modalVisible == true) {
           getReferData()
        }
    }, [modalVisible, isReferralLinkVisible])
    const getReferData = async () => {
        setLoading(true)
        const lo_response = await postRequestApi("refer-and-earn", {});
        setLoading(false)
        console.log('refer lo_response',lo_response);
        if (lo_response.status == 'success') {
            if (lo_response.referandearn) {
                setReferDetails(lo_response.referandearn)
                if (lo_response.referandearn?.status == 1) {
                    setReferralLinkVisible(false)
                }
            } else {
                setReferDetails({})
            }
        }
    }

    // Copy text to clipboard
    const handleCopy = async () => {
        try {
        await navigator.clipboard.writeText(lo_referDetails?.referral_link);
        setIsCopied(true);
        } catch (err) {
        console.error(err);
        }
    };
        
    // Reset copied state after 3 seconds
    const handleReset = () => {
        setTimeout(() => {
        setIsCopied(false);
        }, 3000);
    };
    
    useEffect(() => {
        setModalStyle({...lo_modalStyle, ...lo_exttraModalStyle})
    }, [])
    
    return (
        <Modal
            footer={false}
            // title={title}
            open={modalVisible}
            // onOk={onCancel}
            onCancel={onCancel}
            // confirmLoading={lb_loading}
            className='modal referearn-mdl-parent'
            width={600}
            style={lo_modalStyle}

        >
        {Object.keys(lo_referDetails).length > 0 ? (
            <div className="modal__content">
                <h3 className="modal__title">Refer and earn</h3>
                <p className="modal__subtitle">Earn lifetime 10% commision from Ticketkart fees generated from your referral, forever.</p>
                <hr className="modal__sep"/>
                <h4 className="modal__slider-title">How it works…</h4>
                <h3 className="text-center-s98cus">
                Refer a friend to TicketKart and earn rewards together!

🌟 Both you and your friend will receive benefits: 🔸 You earn 10% commission on their TicketKart fees. 🔸 Your friend enjoys a 15% discount on their first event ticketing fees.

It's a win-win! Share your referral link now.
                </h3>
                <div className="modal__slider">
                    <div className="glide glide--swipeable glide--ltr glide--slider">
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides ulStyle" >
                                <li className="glide__slide glide__slide--active liStyle" >
                                    <div className="modal__card">
                                       
                                        {/* <img className="modal__card-bg" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/card-01.svg?r=1689782936" alt="Background"/> */}
                                        <img className="modal__card-bg" src={wwwBg}  alt="Background"/>
                                        <div className="modal__card-content">
                                            {/* <img className="modal__card-icon" alt="WWW" width="107" height="66" srcSet="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/www.png?r=1689782936 1x, https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/www@2x.png?r=1689782936 2x" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/www.png?r=1689782936"/> */}
                                            <img className="modal__card-icon" alt="WWW" width="107" height="66" src={www}/>
                                            {/* <p className="modal__card-text">Get your personalised referral link and share your link far and wide.</p> */}
                                        </div>
                                        {/* <img className="modal__arrow modal__arrow--1" alt="Arrow" width="86" height="51" srcSet="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-1.png?r=1689782936 1x, https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-1@2x.png?r=1689782936 2x" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-1.png?r=1689782936"/> */}
                                    </div>
                                </li>

                                <li className="glide__slide liStyle2" >
                                    <div className="modal__card">
                                         
                                        {/* <img className="modal__card-bg" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/card-02.svg?r=1689782936" alt="Background"/> */}
                                        <img className="modal__card-bg" src={moneyBg}  alt="Background"/>
                                        <div className="modal__card-content">
                                            {/* <img className="modal__card-icon" alt="Coins" width="80" height="78" srcSet="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/coins.png?r=1689782936 1x, https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/coins@2x.png?r=1689782936 2x" src="https://cdn.tickettailor.com/assets/dashboards/img/refer-and-earn-/coins.png?r=1689782936"/> */}
                                            <img className="modal__card-icon" alt="Coins" width="80" height="78" src={money}/>
                                        </div>
                                        {/* <img className="modal__arrow modal__arrow--2" alt="Arrow" width="101" height="88" srcSet="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-2.png?r=1689782936 1x, https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-2@2x.png?r=1689782936 2x" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/arrow-2.png?r=1689782936"/> */}
                                    </div>
                                </li>

                                <li className="glide__slide liStyle" >
                                    <div className="modal__card">
                                      
                                        {/* <img className="modal__card-bg" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/card-03.svg?r=1689782936" alt="Background"/> */}
                                        <img className="modal__card-bg" alt="Background" src={growthBg}/>
                                        <div className="modal__card-content">
                                            {/* <img className="modal__card-icon" alt="Graph" width="96" height="76" srcSet="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/graph.png?r=1689782936 1x, https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/graph@2x.png?r=1689782936 2x" src="https://cdn.tickettailor.com/assets/dashboard/img/refer-and-earn/graph.png?r=1689782936"/> */}
                                            <img className="modal__card-icon" alt="Graph"  src={growth}/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="referral-form">
                 <h2  className="social__shareText">Social Share</h2>      
                </div> */}
                <div className="referral-form" >
                 <h3 style={{
                    textAlign:'center',
                    flexDirection:'row',
                    display:'flex',
                    // color:'#648ae1'
                    fontWeight:'bold',
                    fontSize: 20
                 }} className="share__link__text">Share this link Via</h3>      
                 <div className="share__link__text"
                 style={{display:'flex',flexDirection:'row',marginTop: 5
                 }} >
                 {/* <div style={{borderWidth:1,borderColor:'grey',padding:10}}> */}
                <Link to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(lo_referDetails.referral_link)}`} target="_blank">
                    <FacebookOutlined style={{height:60,width:60,fontSize:35,color:'#3b5998', marginRight: 7}}/>
                </Link>
                {/* </div> */}
                <Link to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(lo_referDetails.referral_link)}`} target="_blank">
                    <TwitterOutlined style={{height:60,width:60,fontSize:35,color:"#00acee", marginRight: 7}}/>
                </Link>

                {/* <Link to={`https://www.instagram.com/share?url=${encodeURIComponent(lo_referDetails.referral_link)}`} target="_blank">
                <InstagramOutlined style={{height:60,width:60,fontSize:35,color:'#fa7e1e', marginRight: 7 }}/>
                </Link> */}

                <Link to={`mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(`Events Begin Here, Simplify Event Management:  ${lo_referDetails.referral_link}`)}`} target="_blank">
                <MailOutlined style={{height:60,width:60,fontSize:35,color:'#c42921', marginRight: 7 }}/>
                </Link>

                <Link to={`https://api.whatsapp.com/send?text=${encodeURIComponent(lo_referDetails.referral_link)}`} target="_blank">
                <WhatsAppOutlined style={{height:60,width:60,fontSize:35,color:' #128C7E', marginRight: 7}}/>
                </Link>

                <Link to={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(lo_referDetails.referral_link)}`} target="_blank">
                <LinkedinOutlined style={{height:60,width:60,fontSize:35,color:'#0A66C2', marginRight: 7}}/>
                </Link>
                 </div>
                </div>
                <div className="referral-form">
                    {
                        lo_referDetails?.status || isReferralLinkVisible ? 
                        <>
                            <h2>Your unique referral link</h2>
                            {/* <input type="text" className="referral-link" value="https://get.tickettailor.com/hvj1zt59gsf0" readonly="" /> */}
                            <Space.Compact
                                style={{ width: '100%', }}
                            >
                                <Input className="referral-link"
                                    readOnly  value={lo_referDetails.referral_link} 
                                />
                                <Button onClick={handleCopy} onMouseLeave={handleReset} type="link" className="copy-rffrl-btn">{isCopied ? "Copied!" : "Copy"}</Button>
                            </Space.Compact>
                        </>
                        :
                        <Button type="primary" size="large" 
                        // onClick={getReferData} 
                        onClick={() => setReferralLinkVisible(true)}
                        loading={lb_loading}>
                            Generate Link
                        </Button>
                    }
                    
                    
                </div>
                <p className="modal__footnote">
                    <a href={`${Live_base_url}referralpolicy`}   target="_blank">Read the referral partner program agreement</a>
                </p>

            </div>
        ): (
            <div style={{ height: 600, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin indicator={<LoadingOutlined style={{fontSize: 48}} spin />} />
            </div>
            
        )}

        </Modal>
    )
}

export default ReferAndEarn

