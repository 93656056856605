
import React, { useEffect, useState } from 'react';
import './style.less';
import { Button, Col, Form, Row, Typography } from 'antd';
import Card from 'antd/lib/card/Card';
import Avatar from 'antd/lib/avatar/avatar';
import Meta from 'antd/lib/card/Meta';
import { Divider, Statistic, Steps } from 'antd/lib';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import './style.css';
import { useSelector } from 'react-redux';
import { getRequestApi } from '../../../api/commonRequest';

const { Title, Text } = Typography;

const Home = () => {
  const lo_userData = useSelector( (state) => state.common.user );
  const ls_organisationId = localStorage.getItem('organisation_id')
  const [current, setCurrent] = useState(0);
  // const onChange = (value) => {
  //   console.log('onChange:', value);
  //   setCurrent(value);
  // };
  
  useEffect(() => {
    const ls_token = localStorage.getItem('token')
    console.log('ls_token',ls_token);
    if (ls_token) {
      checkToken(ls_token)
    }
  }, [])
  
  const checkToken = (ls_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://tk.hostmenow.in/tkapp/public/api/loggedin-user", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }


  const getEventssList = async () => {
    const lo_response = await getRequestApi("all-events/"+ls_organisationId+"/"+"all");
    const li_EventsListCount = (lo_response?.events && lo_response.events.length > 0) ? lo_response.events.length : 0;
    if (li_EventsListCount > 0) {
      setCurrent(2)
    }
  };

  useEffect(() => {
    getEventssList()
  }, [ls_organisationId])
  
  return (
    <>
      <Row gutter={16} style={{padding:5}} >
        {/* <Col className="content-layout" xs={24} sm={8}>
          <Title level={3}>Welcome to TicketKart</Title>
        </Col> */}

        <Col xs={24} sm={24} md={24} lg={24}>
          <Card 
          // style={{marginInlineStart:15,marginInlineEnd:15}}
          headStyle={{backgroundColor:'#04142c',alignItems:'center'}}
          title={<Title level={3} 
          style={{padding:10,color:'white'}}
          >Welcome to TicketKart</Title>}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Card headStyle={{backgroundColor:"#96c2ff"}}
               style={{marginInlineStart:10,marginInlineEnd:10}}
                type="inner" title={`Low fees, no fuss for ${lo_userData?.name}!`} 
                // extra={<a href="#">More</a>}
                >
                <p style={{    backgroundColor: '#fff'}}>
                I'm sure you can't wait to get your ticket sales off to a flying start so we've prepared an easy checklist. If you don't like lists just ignore this and feel free to click around the dashboard to find your own way.

                If you can't find something or need help just click the chat icon in the bottom right corner and ask us anything.

                P.S. Your free trial allows you to sell up to 5 tickets. After this you will automatically move on to Pay as you go. Find out about cheaper ways to pay, like buying Pre pay credits, here.
                </p>
                <Divider/>
                <Meta
                  avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                  title="TicketKart team"
                  description="Wishing you success,"
                />
                </Card>
              </Col>
              <Col  xs={24} sm={24} md={24} lg={12}>
                <Card
                 headStyle={{backgroundColor:"#96c2ff"}}
                  type="inner"
                  title="Progress"
                  extra={<a href="#">More</a>}
                  style={{marginInlineStart:10,marginInlineEnd:10}}
                  className='statistic__card'
                >
                  <Steps
                    current={current}
                    // onChange={onChange}
                    direction="vertical"
                    items={[
                      {
                        title: 'Sign up',
                        // description: 'This is a description.',
                      },
                      {
                        title: 'Add a new event',
                      },
                      {
                        title: 'Enable online payments',
                      },
                      {
                        title: 'Customise your event pages',
                      },
                      {
                        title: 'Make your event active',
                      },
                    ]}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} style={{marginTop:16}}>
          <Card
           headStyle={{backgroundColor:'#04142c',alignItems:'center',color:'white'}}
            title="Analytics">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={6} className='statistic__card'>
                <Card bordered={false} className='statistic__card'>
                  <Statistic
                    title="NEXT EVENT IN"
                    value={0}
                    precision={"n/a"}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    // suffix="%"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6}>
                <Card bordered={false}  className='statistic__card'>
                  <Statistic
                    title="ORDERS RECEIVED"
                    value={11.28}
                    precision={2}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6}>
                <Card bordered={false} className='statistic__card'>
                  <Statistic
                    title="TICKETS ISSUED"
                    value={0}
                    precision={2}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6}>
                <Card bordered={false} className='statistic__card'>
                  <Statistic
                    title="REVENUE"
                    value={0.00}
                    precision={2}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        
      </Row>
      </>
  );
};

export default Home;