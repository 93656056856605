import React, { useState, useEffect } from "react";
import "./style.css";
import img from '../../../../assets/img/addbanner/maximumprofit.jpg'
import img2 from '../../../../assets/img/addbanner/commision.jpg'
import img3 from '../../../../assets/img/addbanner/instant-payout.jpg'


const BannerCarousel = () => {
  const sliderData = [
    {
      id: 1,
      image: img,
    },
    {
      id: 2,
      image: img2,
    },
    {
      id: 3,
      image: img3,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + sliderData.length) % sliderData.length);
  };

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % sliderData.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // Auto-slide every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  return (
    <div className="container-s98cus p-1-s98cus mt-5-s98cus banner-custom" >
      <div className="slick-slider" style={{margin:10}}>
        <div
          className="slick-list"
          style={{
            width: `${sliderData.length * 33.3333}%`,
            transform: `translateX(-${(currentSlide / sliderData.length) * 300}%)`,
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {sliderData?.map(item => (
            <div key={item.id} className="slick-banner-custom">
              <img src={item.image} alt={`Slide ${item.id}`} />
              {/* <div className="banner-content-custom">
                <div className="banner-details">
                  <h2>{item.content}</h2>
                  <button type="button" className="read-more-btn">Read More</button>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="custom-arrow-banner custom-prev-arrow-banner" onClick={prevSlide}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="custom-arrow-banner custom-next-arrow-banner" onClick={nextSlide}>
        <i className="fa fa-angle-right"></i>
      </div>
    </div>
  );
};

export default BannerCarousel;
