import React, { useState , useEffect } from "react";
import { useParams } from "react-router";
// import './style.css'
import Content from "antd/lib/layout/layout";
import Tabs from "antd/lib/tabs";
import { Outlet, useNavigate } from "react-router";
import { Button, Typography } from "antd/lib";
import AppstoreAddOutlined from "@ant-design/icons/AppstoreAddOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import SolutionOutlined from "@ant-design/icons/SolutionOutlined";
import { getPublicRequestApi } from "../../../../../api/commonRequest";

const ReturnStripeAccount = () => {
    let navigate = useNavigate();
    const lo_params = useParams()
    const { stripeToken } = lo_params
    const [lb_spin, setSping] = useState(true)

    const updateStripeAccountData = async () => {
        if(!stripeToken) return;
        setSping(true)
        const accountRes = await getPublicRequestApi("connect/"+stripeToken);
        console.log('accountRes',accountRes);
        if (accountRes.status == "success") {
            navigate('/stripe-account-success');
        }
        console.log('accountRes',accountRes);
        setSping(false)
    }

    useEffect(() => {
        if(stripeToken) updateStripeAccountData()
    }, [stripeToken])

  return (
    <div>
            <div className="form-header">
                <Typography.Title level={4}>Return</Typography.Title>
                {/* <Link to="/dashboard" key="1">
                    <Button key="2" className='back-button' icon={<ArrowLeftOutlined />}>
                        Back
                    </Button>
                </Link> */}
            </div>
            <Content className="acc-stripe-sec">
            </Content>
        </div >
  )
}

export default ReturnStripeAccount