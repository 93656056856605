import React from 'react'
import './style.css'

const VendorTermsCondition = () => {
  return (
    <>
     <div className="terms-containers">
        <h1 style={{fontSize:36}}>Terms and Conditions for Vendor Collaboration</h1>
      </div>
    <div class="description containers">
        <div class="aboutUs mt-1">
          <h1 style={{fontSize:17}}>Welcome to TicketKart, an online ticket booking platform. These terms and conditions ("Terms") outline the agreement between TicketKart ("we," "us," or "our") and you ("Vendor" or "you"). By collaborating with us as a Vendor, you agree to comply with these Terms. Please read them carefully.
         <span> Eligibility: </span> Vendors who provide services related to events, such as event planning, catering, audio-visual equipment, decorations, etc., are eligible to affiliate with TicketKart and advertise their services on our platform.<br/><br/>
<span>Commission:</span> Vendors will be charged a commission on each sale they generate through our platform. The commission percentage will be determined and agreed upon in a separate agreement between TicketKart and the vendor.<br/><br/>
<span>Service Listings:</span> Vendors must accurately and comprehensively list their services on TicketKart's platform. This includes providing detailed descriptions, pricing information, availability, and any other relevant details required for event organizers to make informed decisions.<br/><br/>
<span>Advertising Placement:</span> TicketKart reserves the right to determine the placement and positioning of vendor advertisements on our platform. We will strive to maximize visibility and exposure for vendors, while ensuring a fair and balanced representation across different categories.<br/><br/>
<span>Referral Program:</span> Vendors who affiliate with TicketKart and successfully refer event organizers to our platform will be eligible for our referral program. They will earn a lifetime commission of [X]% on the event profits generated by the referred organizers.<br/><br/>
<span>Referral Commission Calculation:</span> The referral commission for vendors will be calculated based on the profit earned by TicketKart from each completed event of the referred organizers, after deducting any applicable fees and charges.<br/><br/>
<span>Compliance with Policies:</span> Vendors must adhere to TicketKart's policies, including but not limited to, quality standards, ethical practices, and any guidelines outlined by the platform. Failure to comply may result in the termination of the vendor's affiliation.<br/><br/>
<span>Termination:</span> TicketKart reserves the right to terminate the affiliation of vendors at any time, with or without cause, upon providing reasonable notice. Termination may occur due to violations of our policies, improper conduct, or any other reason deemed necessary by TicketKart.<br/><br/>
<span>Modification of Terms:</span> TicketKart reserves the right to modify these Terms & Conditions for vendors at any time, with reasonable notice. Vendors will be informed of any changes and expected to comply with the updated terms.
Legal Compliance: Vendors are responsible for ensuring compliance with all applicable laws, regulations, licenses, permits, and other legal requirements related to their services.<br/><br/>
By collaborating with us as a Vendor, you acknowledge that you have read, understood, and agree to be bound by these Terms.</h1>
         
        </div>
      </div>
    </>
  )
}

export default VendorTermsCondition