import React, { useEffect, useState } from "react";
import Layout from "antd/lib/layout";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import BookedTicketCard from "../BookedTicketCard";
import "./style.css";
const { Content } = Layout;

const BookingHistory = ({ lo_details }) => {
  const [la_ticketList, setTicketList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lo_details?.booking_details) {
      setLoading(true);
      setTicketList(lo_details?.booking_details);
      setLoading(false);
    }
  }, [lo_details]);
  return (
    <>
      <div className="divider"></div>
      <Layout>
        <Content className="ticket-card-container">
          <Spin spinning={loading}>
            {la_ticketList.length > 0 ? (
              la_ticketList.map((item, inx) => (
                <BookedTicketCard item={item} key={item?.id} />
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Spin>
        </Content>
      </Layout>
    </>
  );
};

export default BookingHistory;
