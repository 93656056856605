import React, { useEffect, useState } from "react";
import "./style.css";
import MoreOutlined from "@ant-design/icons/MoreOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";
import { Content } from "antd/lib/layout/layout";
import { Typography, Input, Button, Modal, message, Table, Space } from "antd";
import List from "antd/lib/list";
import CreateTicketForm from "./CreateTicketForm";
import { Dropdown, Image, Menu, Spin } from "antd/lib";
// import { Api_base_url } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import { getRequestApi } from "../../../../../../api/commonRequest";
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from "../../../../../utility";
import { CopyOutlined } from "@ant-design/icons";
import { ProcessingFee } from "../../../../../../config";

const CreateTicket = ({ls_organisationId, ls_eventId, lo_eventDetails}) => {
    let navigate = useNavigate();
    //common function to crud 
    const [ls_actionType, setActionType] = useState(null);
    const [la_ticketList, setTicketList] = useState([]);
    const [lb_listLoading, setListLoading] = useState(true);
    const [lo_actionRecord, setActionRecord] = useState({});
    const handleSetDataAction = (ls_action=null, record={}) => {
        setActionType(ls_action)
        setActionRecord(record)
    }

    const getTicketList = async () => {
        const lo_response = await getRequestApi("ticket-list/"+ls_eventId);
        console.log('lo_response',lo_response);
        setTicketList(lo_response.tickets)
        setListLoading(false)
    }

    const getMenu = (record) => {
        return [
            {
                key: '1',
                label: (
                <Link className="underLineNone"
                    onClick={() => { handleSetDataAction('edit_ticket',record) }}
                >
                <EditOutlined style={{marginInlineEnd:10}} />
                    Edit
                </Link>
                ),
            },
            {
                key: '2',
                label: (
                <Link className="underLineNone"
                    onClick={() => { handleSetDataAction('copy_ticket',record) }}
                >
                <CopyOutlined style={{marginInlineEnd:10}} />
                    Copy
                </Link>
                ),
            },
            {
                key: '3',
                label: (
                <Link  className="underLineNone"
                    onClick={() => { handleSetDataAction('remove_ticket',record)}}
                >
                <DeleteOutlined style={{marginInlineEnd:10}}/>
                    Delete
                </Link>
                ),
            }
        ]
    }

    const getTicketPriceWithoutServiceFee = (li_costValue, lb_absorbFees) => {
        return (Math.round((parseFloat(li_costValue) + (lb_absorbFees ? 0 : ProcessingFee)) * 100) / 100);
    }

    useEffect(() => {
        console.log('ls_eventId, lo_eventDetails',ls_eventId, lo_eventDetails);
        if (ls_eventId && lo_eventDetails?.id) {
            getTicketList()
        }
    }, [ls_eventId, lo_eventDetails])
    
    const lo_formProps = { "getDataList": getTicketList, ls_actionType, lo_actionRecord, handleSetDataAction, ls_eventId, lo_eventDetails };
    const la_columns = [
        {
            title: "Ticket Type",
            dataIndex: "ticket_type",
            key: "ticket_type",
            render: (text, record) => {
                return <Space direction="vertical"> 
                <Typography.Text>{record.ticket_name}</Typography.Text>
                <Typography.Text type="secondary">{`Ends ${dayjs(record.sales_ends_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(record.sales_ends_time, "HH:mm:ss").format("h:mm A")}`}</Typography.Text>
                </Space>
            }
        },
        {
            title: "Sold/Qty",
            dataIndex: "quantity",
            key: "quantity",
            render: (text) => `0/${text}`
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (text, record) => {
                return (
                    record.ticket_type == "paid" ? "£ "+ getTicketPriceWithoutServiceFee(record.cost_to_buyer, record.absorb_fee) : capitalizeFirstLetter(record.ticket_type) 
                )
            }
            // render: (text, record) => {
            //     return (
            //         record.ticket_type == "paid" ? "£ "+record.cost_to_buyer : capitalizeFirstLetter(record.ticket_type) 
            //     )
            // }
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => {
                return (<>
                    <Dropdown 
                        menu={{
                            items:getMenu(record),
                            // onClick : onClickDropDownMenu
                        }}
                        trigger={['click']}> 
                        <Button size="middle" type="primary" shape="circle" ghost icon={<MoreOutlined />} className='button menu-dropdown-btn' />
                    </Dropdown>
                </>)
            }
        },
    ]
    
    return (
        <>
            <Content className="evnt-content-layout">
                <div className="header-component">
                    {/* <Typography.Title level={4}>Add Tickets</Typography.Title> */}
                    <Button
                        onClick={() => { handleSetDataAction('add_ticket') }}
                        // className="add-btn"
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{float:"right"}}
                    >
                        Add Ticket
                    </Button>
                </div>
            </Content>
            <Content className="content-layout ticket-list-section">
            <div className="table-container">
                    <Table
                        rowKey="id"
                        columns={la_columns}
                        className="table-inner-content"
                        scroll={{ x: 900, y:300 }}
                        pagination={true}
                        
                        loading={lb_listLoading}
                        dataSource={la_ticketList}
                        rowClassName="row-table"
                        size="middle"
                    />
                </div>
            {/* <List
                loading={lb_listLoading}
                itemLayout="horizontal"
                dataSource={la_ticketList}
                renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        title={item.ticket_name}
                        description={`Ends ${dayjs(item.sales_ends_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(item.sales_ends_time, "HH:mm:ss").format("h:mm A")}`}
                    />
                    <div className="ticket-list-row-contnt">
                        <Typography.Text>0/{item.quantity}</Typography.Text>
                        <Typography.Text>{item.ticket_type == "paid" ? "£ "+item.cost_to_buyer : capitalizeFirstLetter(item.ticket_type)}</Typography.Text>
                        <Dropdown 
                            menu={{
                                items:getMenu(item),
                                // onClick : onClickDropDownMenu
                            }}
                            trigger={['click']}> 
                            <Button size="middle" type="primary" shape="circle" ghost icon={<MoreOutlined />} className='button menu-dropdown-btn' />
                        </Dropdown>
                    </div>
                </List.Item>
                )}
            /> */}
            </Content>

            <CreateTicketForm {...lo_formProps}/>
        </>
    );
};

export default CreateTicket;
