import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
// import Modal from "antd/lib/modal";
import { Modal } from 'antd';
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
// import "./style.less";
import { Space } from "antd/lib";

const BuyTicketModal = ({ modalVisible = false, onCancel = () => { }, children, title = 'Buy Ticket' }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            footer={false}
            // title={title}
            open={modalVisible}
            // onOk={onCancel}
            onCancel={onCancel}
            // confirmLoading={lb_loading}
            className='modal'
            width={1080}
            style={{
                maxHeight: '92%',
                overflow: 'hidden',
                marginTop: -70, 
                marginBottom: 20
            }}

        >
            {children}
        </Modal>
    )
}

export default BuyTicketModal