import React, { useState } from 'react'

const GoogleMaps = ({ ls_address }) => {
    // if (ls_address == '') {
    //     ls_address = 'kolkata';
    // }
    return (
        <iframe
            width="100%"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC0sgYOOhN8f7TJEMR4jcTLkWJ1CujOVec&q=${ls_address}`}
        >
        </iframe>
    )
}

export default GoogleMaps;
