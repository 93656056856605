import React from "react";
import Typography from "antd/lib/typography";
import Space from "antd/lib/space";
const { Text } = Typography;

const BookedTicketCard = ({ item }) => {
  return (
    <>
      <div className="card booked-ticket-card">
        <div className="card-wrapper">
          <Space direction="vertical">
            <Text>{item?.ticket?.ticket_name}</Text>
            <Text type="secondary">{`Sub Total: £ ${item?.sub_total}`}</Text>
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <Text>{"$" + item?.cost_to_buyer}</Text>
            <Text type="danger">{`Qty: ${item?.total_quantity}`}</Text>
          </Space>
        </div>
      </div>
      <div className="card-divider"></div>
    </>
  );
};

export default BookedTicketCard;
