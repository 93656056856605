import React from 'react'
import HeroSection from './HeroSection'
import EventFeatures from './EventFeatures'
import Slides from './Slides'
import TicketKart from './TicketKart'
import BannerCarousel from './BannerCarousel'
import ListEvent from './ListEvent'
import Faqs from './Faqs'
import EventsPage from './EventsPage'
import FAQPage from './FAQPage'

const HomePublic = () => {
  return (
    <>
    <HeroSection/>
    <EventFeatures/>
    <Slides/>
    <TicketKart/>
    <BannerCarousel/>
    <ListEvent/>
    <Faqs/>
    {/* <EventsPage/> */}
    {/* <FAQPage/> */}
    </>
  )
}

export default HomePublic
