import React, { useEffect, useState } from "react";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined"
import DeleteOutlined from "@ant-design/icons/DeleteOutlined"
import EyeOutlined from "@ant-design/icons/EyeOutlined"
import { Content } from "antd/lib/layout/layout";
import { Typography,Button, Col, Row } from "antd";
import List from "antd/lib/list";
import OverviewForm from "./OverviewForm";
import { Api_base_url } from "../../../config";
import { storeOrganisationData } from "../../utility";
import { useNavigate } from "react-router-dom";
import './style.css';

const Overview = () => {
    let navigate = useNavigate();
    const ls_token = localStorage.getItem('token')
    //common function to crud
    const [ls_actionType, setActionType] = useState(null);
    const [la_organisationList, setOrganisationList] = useState([]);
    const [lo_actionRecord, setActionRecord] = useState({});
    const [lb_loading, setLoading] = useState(false)
    const handleSetDataAction = (ls_action=null, record={}) => {
        setActionType(ls_action)
        setActionRecord(record)
    }

    const getOrganizationList = async () => {
        // if (!ls_token) return
        // const lo_response = await getRequestApi("all-organisations");
        // console.log('---lo_response.organisations',lo_response.organisations);
        // if (lo_response?.organisations) {
        //     setOrganisationList(lo_response.organisations)
        // }else{
        //     setOrganisationList([])
        // }
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ls_token);
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

        fetch(Api_base_url+"all-organisations", requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false)
            const lo_response = JSON.parse(result)
            console.log('lo_response',lo_response);
            if (lo_response?.organisations) {
                setOrganisationList(lo_response.organisations)
            }else{
                setOrganisationList([])
            }
        })
        .catch(error => console.log('error', error));
    }

    const addToSpecificOrganisation = async (item) => {
        console.log('item',item);
        const respone = await storeOrganisationData(item)
        console.log('respone',respone);
        if(respone) navigate('/dashboard');
    }

    useEffect(() => {
      if(ls_token) getOrganizationList()
    }, [ls_token])
    
    const lo_formProps = { "getDataList": getOrganizationList, ls_actionType, lo_actionRecord, handleSetDataAction };
    return (
        <>
            <Content className="content-layout thumbnail">
            <Col xs={24} sm={24} md={24} lg={24}>
                <Row>
                <Col  xs={24} sm={24} md={24} lg={16}>
                    <Typography.Title  
                    className="title"
                    level={3}
                    >Organization overview</Typography.Title>
                   </Col>
                   <Col  xs={0} sm={0} md={0} lg={3}></Col>
                   <Col  xs={24} sm={24} md={24} lg={5}>

                    <Button
                        style={{float:"right"}}
                        onClick={() => { handleSetDataAction('add_organization') }}
                        // className="add-btn"
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Add New Organization
                    </Button>
                    </Col>
                </Row>
                </Col>
            </Content>
            <Content className="content-layout">
            <List
                itemLayout="horizontal"
                dataSource={la_organisationList}
                loading={lb_loading}
                renderItem={(item, index) => (
                <List.Item
                    actions={
                        item.pivot.role_id == 1 ? 
                        [   <a onClick={() => addToSpecificOrganisation(item)} key="list-loadmore-edit"><EyeOutlined />{'Mange'}</a>, 
                            <a key="list-loadmore-more" onClick={() => { handleSetDataAction('edit_organization',item) }} ><EditOutlined />{`Edit`}</a>,
                            <a key="list-loadmore-more" onClick={() => { handleSetDataAction('remove_organization',item) }} ><DeleteOutlined />{'Delete'}</a>
                        ]
                        : 
                        [<a key="list-loadmore-edit"><EyeOutlined />{'Mange'}</a>]
                    }
                >
                    <List.Item.Meta
                        onClick={() => addToSpecificOrganisation(item)}
                        // title={<a href="#">{item.name} ({item.roles[0].name})</a>}
                        title={<a href="#">{item.name}</a>}
                        // description="Upcoming Events: 0"
                        description={item?.roles[0]?.name}
                    />
                    <div onClick={() => addToSpecificOrganisation(item)}>
                        {/* BILLING
                        5 credits
                        Auto-renew: No
                        PAYG rate: £0.50 */}
                    </div>
                </List.Item>
                )}
            />
            </Content>

            <OverviewForm {...lo_formProps}/>
        </>
    );
};

export default Overview;
