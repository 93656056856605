import { Divider } from 'antd'
import React from 'react'

const RefferalPolicy = () => {
  return (
    <>
    <div style={{height:65}}></div>
      <div class="description containers">
        <h1 style={{fontSize:34}}>Referral Policy</h1>
     <Divider style={{height:10,width:10,color:'black',marginTop:30}}/>
        <div class="aboutUs mt-1">
          <h2 class="terms-h2-style" style={{color
          :'black'}}>Effective date: July 01, 2023</h2>
          <h1 style={{fontSize:17}}>Ticketkart.com is a trading name of TICKETKART LIMITED ("us", "we", or "our") is a company registered in England and Wales (Company registration number: 14867948) operates the <a href='https://www.ticketkart.com'>https://www.ticketkart.com</a> & <a href='https://www.ticketkart.co.uk'>https://www.ticketkart.co.uk</a> website (the "Service").<br/><br/>
          This Referral Policy describes how does TicketKart referral program works if any referral is made and event is hosted by referred organiser via TicketKart platform on www.ticketkart.com or www.ticketkart.co.uk (the “Site”).<br/><br/>
         <span> Eligibility: </span> The referral program is open to existing event organisers and individual who are registered on TicketKart.<br/><br/>
<span>Referral Commission: </span> Referrers are eligible to earn a lifetime commission of 10% on the profit earned from each completed event listed on TicketKart by organisers referred by them.<br/><br/>
<span>Referral Criteria: </span> To qualify for the lifetime referral commission pay-out, the referred organisers must list their events regularly, without a gap of more than 3 months between any two events.<br/><br/>
<span>Termination of Referral Commission: </span>  If the gap between any two events listed by a referred organiser exceeds 3 months, TicketKart reserves the right to terminate the referral commission pay-out for that organiser indefinitely.<br/><br/>
<span>Commission Calculation: </span> The referral commission will be calculated based on the profit earned by TicketKart from each completed event, after deducting any applicable fees and charges.<br/><br/>
<span>Pay-out Schedule: </span>Referral commission pay-outs will be made on a regular basis, such as monthly or quarterly, depending on the specific pay-out schedule determined by TicketKart.<br/><br/>
<span>Referral Tracking:</span>Referrals must be made using the unique referral link or code provided by TicketKart to ensure proper tracking and attribution. Referrals made through other channels may not be eligible for the referral commission.<br/><br/>
<span>Disputes: </span>  In the event of any dispute or disagreement regarding referral commission eligibility or pay-outs, TicketKart's decision will be final and binding.<br/><br/>
<span>Modification or Termination: </span>TicketKart reserves the right to modify or terminate the referral program at any time, without prior notice.<br/><br/>
<span>Compliance with Terms:</span> By participating in the referral program, referrers and referred organisers agree to comply with these terms and conditions, as well as TicketKart's overall terms of service.</h1>
        </div>
    
      </div>
      </>
  )
}

export default RefferalPolicy