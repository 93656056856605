import { Button, Col, Divider, Modal, Row, Space, TimePicker, message } from 'antd/lib';
import React, { useEffect, useState } from 'react'
import Form from 'antd/lib/form/Form';
import Spin from "antd/lib/spin";
import { TimeFormat } from '../../../../../../config';
import Input from 'antd/lib/input/Input';
import { MinusCircleOutlined, PlusOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { getRequestApi, postRequestApi } from '../../../../../../api/commonRequest';
import dayjs from 'dayjs';
import Typography from 'antd/lib/typography/Typography';
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import Card from 'antd/lib/card/Card';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const CreateFaq = ({ls_eventId}) => {
    const [multiform] = Form.useForm();
    const formdata = Form.useWatch('faq', multiform);
    const [lb_spin, setSping] = useState(true)
    const handleSubmitFaq = (add, key, updateId=null) => {
        if (updateId) {
            if(formdata[key]) submitFormDataToServer(formdata[key], updateId)
        }else{
            multiform.validateFields().then((values) => {
                // form.resetFields();
                if(formdata.length == key+1) add();
                console.log('Received values of form:', key, formdata[key]);
                if(formdata[key]) submitFormDataToServer(formdata[key], updateId)
            })
            .catch((info) => {
                message.error("Validation Error!")
                // console.log('Validate Failed:', info);
            });
        }
    }

    const getDataList = async (isBlankList=false) => {
        setSping(true)
        const lo_faqDetails = await getRequestApi("all-event-faq/"+ls_eventId);
        console.log('++++++++++++++++lo_faqDetails',lo_faqDetails);
        let la_formData = []
        if (lo_faqDetails?.event_faqs && lo_faqDetails.event_faqs.length > 0) {
            const la_faq = [...lo_faqDetails.event_faqs]
            la_formData = [...la_faq];
        }

        if (isBlankList || la_formData.length > 0) {
            const lo_formData = {};
            lo_formData['question'] = '';
            lo_formData['answer'] = '';
            la_formData.push(lo_formData)
        }
        
        console.log('++++++++++++++++lo_faqDetails la_formData',la_formData);
        multiform.setFieldsValue({"faq":la_formData})
        setSping(false)
    }

    const submitFormDataToServer = async (formData, updateId=null) => {
        
        startLoadTheMessage()
        const lo_formData = {...formData};
        lo_formData['event_id'] = ls_eventId;

        let ls_requestUrl = "add-faq";
        if (updateId) {
            ls_requestUrl = "update-faq/"+updateId;
        }
        console.log('formData----',lo_formData);
        const lo_response = await postRequestApi(ls_requestUrl, lo_formData)
        
        console.log('lo_response',lo_response);
        
        if (lo_response.status == 'success') {
            // form.resetFields()
            stopLoadingSuccessMsh(`Faq ${updateId ? "Updated" : "Created"} Succesfully!`)
            getDataList()
            // navigate("/events/update/"+lo_response.event.slug+"/details")
        }else {
            // message.error('Validation Error!!')
            stopLoadingMsh(lo_response.message[0])
        }
    }

    const handleRemoveSpecificFaqById = (key,remove,name) => {
        
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Typography.Text>Do you want to delete?</Typography.Text>
            </>,
            onOk() {
                deleteFaq(formdata[key]);
                remove(name)
            },
            //// cancelText: (setDelete(false)),
            // onCancel() {
            //     handleSetDataAction()
            // },
        });
    }

    const deleteFaq = async (record) => {
        console.log('record',record);
        const lo_response = await postRequestApi("delete-event-faq/"+record.id, {});
        // handleSetDataAction()
        if (lo_response.status == 'success') {
            getDataList()
            message.success(`Succesfully Deleted!`)
        }else {
        // setResponseMessage(lo_response.message)
            message.error(lo_response.message[0])
        }
    }

    useEffect(() => {
        if(ls_eventId) getDataList()
    }, [ls_eventId])

    return (
        <div>
            
            <Card
                title={<span><QuestionCircleOutlined /> FAQ</span>}
                extra={(formdata && formdata.length == 0) ? <Button onClick={() => getDataList(true)}>Add</Button> : <></>}
                style={{
                    width: "100%",
                }}
                headStyle={{backgroundColor:"#e97e154a"}}
                >
                <Spin spinning={lb_spin}>
                    <Form
                        form={multiform}
                        name="faq_form_details"
                        // onFinish={handleSubmitFaq}
                        // style={{
                        // maxWidth: 600,
                        // }}
                        autoComplete="off"
                        labelCol={{
                            span: 7
                        }}
                    >
                        <Form.List name="faq" 
                            // initialValue={[{}]}
                        >
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Card style={{backgroundColor:"rgb(139 78 20 / 10%)", border:"1.5px solid #fe8c1d63",marginBottom:15}}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={19}>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Question"
                                                    {...restField}
                                                    name={[name, 'question']}
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Question',
                                                    },
                                                    ]}
                                                >
                                                    <Input placeholder="Question" />
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Answer"
                                                    {...restField}
                                                    name={[name, 'answer']}
                                                    rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing Answer',
                                                    },
                                                    ]}
                                                >
                                                    <Input.TextArea placeholder="Answer" showCount maxLength={300}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col xs={24} sm={24} md={24} lg={5} style={{paddingLeft:20}}>
                                        <div style={{ display:"flex",justifyContent:'center'}}>
                                            {formdata && formdata[key]?.id ? 
                                            <>
                                            <Button onClick={() => handleSubmitFaq(add, key, formdata[key].id)}
                                             type='dashed'  style={{padding:0,paddingRight:7,
                                                            paddingLeft:7,marginInlineEnd:9}}>
                                            <EditOutlined  /> Update
                                            </Button>
                                            <Button onClick={() =>{ handleRemoveSpecificFaqById(key,remove,name)}}
                                             type='dashed'  style={{padding:0,paddingRight:7,
                                                            paddingLeft:7}}>
                                            <MinusCircleOutlined /> 
                                            Remove
                                            </Button>
                                            </>
                                            : <Button onClick={() => handleSubmitFaq(add, key)} type='dashed'>
                                            <PlusOutlined/>
                                            Save & Add More
                                            </Button>}
                                        </div>
                                    </Col>
                                </Row>
                                </Card>
                            ))}
                            {/* <Form.Item>
                                <Button type="dashed" onClick={() => handleSubmitFaq(add)} block icon={<PlusOutlined />}>
                                Add field
                                </Button>
                            </Form.Item> */}
                            </>
                        )}
                        </Form.List>
                        {/* <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        </Form.Item> */}
                    </Form>
                </Spin>
            </Card>

            

            
        </div>
    )
}

export default CreateFaq