import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import { getRequestApi } from "../../../../api/commonRequest";
import { useNavigate } from "react-router-dom";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import Row from "antd/lib/row";
import { CSVLink } from "react-csv";
import message from "antd/lib/message";
import { PageHeader } from "@ant-design/pro-layout";

const SalesSummaryList = () => {
  const ls_organisationId = localStorage.getItem("organisation_id");
  const navigate = useNavigate();
  const [la_SummaryList, setSummaryList] = useState([]);
  const [lb_loading, setLoading] = useState(false);

  useEffect(() => {
    getSalesSummaryByOrganisation();
  }, [ls_organisationId]);

  const getSalesSummaryByOrganisation = async () => {
    setLoading(true);
    let la_summary = await getRequestApi(
      `org-sales-report/${ls_organisationId}`
    );
    setLoading(false);
    console.log("la_summary", la_summary);
    if (la_summary?.status === "success") {
      setSummaryList(la_summary?.reports);
    }
  };
  const la_columns = [
    {
      title: "Event Name",
      dataIndex: "event_name",
    },
    {
      title: "Event Start Date",
      dataIndex: "event_start_date",
      render: (value) => (
        <div>
          {value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ""}
        </div>
      ),
    },
    // {
    //   title: "Sales Channel",
    //   dataIndex: "channel",
    //   render: (text) => (text ? text : "-"),
    // },

    {
      title: "Quantity",
      dataIndex: "sold_qty",
    },
    // {
    //   title: "Total Face Value",
    //   dataIndex: "face_value",
    //   render: (text) => (text ? `£ ${text}` : "-"),
    // },
    {
      title: "Gross Sales",
      dataIndex: "total_amount",
      render: (text) => `£ ${text}`,
    },
    {
      title: "Net Sales",
      dataIndex: "total_ticket_price",
      render: (text) => `£ ${text}`,
    },
  ];
  return (
    <>
      {/* <Content className="content-layout thumbnail">
        <Row>
          <Col xs={14} sm={12} md={16} lg={16}>
            <Typography.Title className="title" level={3}>
              Sales Summary
            </Typography.Title>
          </Col>
          <Col xs={10} sm={12} md={8} lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              icon={<ArrowLeftOutlined />}
              className="add-btn"
              onClick={() => navigate("/report")}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Content> */}
      <Content className="content-layout thumbnail">
        <PageHeader
          title="Sales Summary"
          className="content-layout content-text thumbnail page-header-content"
          extra={[
            <Row justify={"space-between"} gutter={[14]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="add-btn"
                  onClick={() => navigate("/report")}
                >
                  Back
                </Button>
              </Col>
            </Row>
          ]}
        ></PageHeader>
      </Content>
      <Content className="content-layout">
        <Space wrap className="select-event">
          <Button icon={<UploadOutlined />} type="primary">
            <CSVLink
              filename={"Sales_Summary.csv"}
              data={la_SummaryList}
              // className="btn btn-primary"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to CSV
            </CSVLink>
          </Button>
        </Space>

        <Table
          rowKey="event_name"
          columns={la_columns}
          className="table-inner-content"
          pagination={true}
          // scroll={{ x: 1100 }}
          scroll={{ x: 900, y:400 }}
          loading={lb_loading}
          dataSource={la_SummaryList}
          rowClassName="row-table"
          size="middle"
        />
      </Content>
    </>
  );
};
export default SalesSummaryList;
