import { Divider } from 'antd'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div style={{ height: 65 }}></div>
      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Privacy Policy</h1>
        <Divider style={{ height: 10, width: 10, color: 'black', marginTop: 30 }} />
        <div class="aboutUs mt-1">
          <h2 class="terms-h2-style">Effective date: July 01, 2023</h2>
          <p>Ticketkart.com is a trading name of TICKETKART LIMITED ("us", "we", or "our") is a company registered in England and Wales (Company registration number: 14867948) operates the <a href='https://www.ticketkart.com'>https://www.ticketkart.com</a> & <a href='https://www.ticketkart.co.uk'>https://www.ticketkart.co.uk</a> website (the "Service").
            <br /><br />
            This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.ticketkart.com or www.ticketkart.co.uk (the “Site”).<br /><br />
            This policy will not be applicable for you if you have consumed a service from an event organiser directly who used TicketKart platform to avail its services. In this scenario, event organiser does have the legal responsibilities to inform their customers about privacy policies and explain how customer data is used.
            You are free to choose your data processing by TicketKart. We provide convenient ways to our customer to control their information and can change your preference whenever you want to.<br /><br />
            We respect your right to privacy. This privacy statement explains our policy regarding the collection, use, disclosure and protection of Personal Data. This privacy statement applies to the collection and use of Personal Data about you by TICKETKART LIMITED and their affiliates (“TICKETKART”, “we” or “us”). In certain circumstances, we may provide you with additional information about our use of your Personal Data in specific circumstances, for example as part of a special offer or specific services.  Since we regularly review our policies and practices, your comments are always welcome. We reserve the right to change this Privacy Policy at any time.</p>
          <p style={{ color: 'red', fontSize: 20 }}>Children:  We do not intend to collect information for children who are under 18 years of age. If you are under 18, please do not provide information to us.</p>
        </div>

      </div>
      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>What is Personal Data?</h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>Personal Data is information relating to an identified or identifiable individual, such as a name, address, telephone number, e-mail address, debit/credit card number or any photograph, Internet (IP) address and information about an individual’s purchases and services used through our website.
            <br />
            Personal Data does not include "aggregate" information. Aggregate information is data we collect about a group or category of products, services or customers, from which individual customers cannot be identified. In other words, information about how you use a service may be collected and combined with information about how others use the same service, but no Personal Data will be included in the resulting data. Likewise, information about the service used by you on our website may be collected and combined with information about the service used by others. Aggregate data helps us understand trends and customer needs so that we can better consider new products and services, and tailor existing products and services to customer desires.</p>
        </div>
      </div>
      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Collection Of Personal Information </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.
            We collect Device Information using the following technologies:
            - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, please visit www.allaboutcookies.org.
            - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, referring/exit pages, Internet service provider and date/time stamps.
            - “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.
            - Other tracking technologies may be tested from time to time, and will be detailed here if they are implemented as a permanent fixture to the site.
            Additionally, when you use our service through the Site, we collect certain information from you, including your name, address, payment information (including debit/credit card numbers, Paypal, Apple Pay, Google pay), email address, and phone number.
            When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>How do we use your Personal Information?</h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>We use the Information that we collect generally to fulfil any service through the Site (including processing your payment information). Additionally, we use this Information to:
            <br />
            - Communicate with you;  <br />
            - Send notifications (via email or SMS/text message) related to your service <br />
            - Alert you about service recalls or safety issues; <br />
            - Manage invoicing, accounting and information security services related to our transactions with you; <br />

            (a) administer the website; <br />
            (b) improve your browsing experience by personalising the website; <br />
            (c) enable your use of the services available on the website; <br />
            (d) send statements and invoices to you, and collect payments from you; <br />
            (e) send you general (non-marketing) commercial communications; <br />
            (f) send you email notifications which you have specifically requested; <br />
            (g) send to you our newsletter and other marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you by post or, where you have specifically agreed to this, by email or similar technology, (you can inform us at any time if you no longer require marketing communications); <br />
            (h) provide third parties with statistical information about our users – but this information will not be used to identify any individual user; <br />
            (i) deal with enquiries and complaints made by or about you relating to the website. <br />
            Where you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the license you grant to us. <br /> <br />
            We will not without your express consent provide your personal information to any third parties for the purpose of direct marketing. <br /> <br />
            All our website financial transactions are handled through our payment services providers. You should only provide your personal information after reviewing the providers privacy policy (available at payment service provider’s website). We will share information with payment service provider only to the extent necessary for the purposes of processing payments you make via our website.
            We also use this data for retargeting advertising with Facebook, Twitter and Google Add words. You can opt-out of our direct marketing at any time by: <br /> <br />
            • unsubscribing from our marketing messages by following the opt-out links on any marketing email sent to you or by emailing to TicketKart support team. <br />
            • Informing us that you do not wish to receive any targeted advertising by emailing to TicketKart support team..</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Sharing Your Personal Data</h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>We only share necessary personal data with our partners only to the extent required to provide best Services and for our internal administration purposes. We may share your personal information to selected third parties who we use or partnered to help manage our business. Please email to our support team if you would like more information about these service providers. <br /> <br />
            Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Behavioural Advertising </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a href='http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.'>http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</a> <br />
            You can opt out of targeted advertising by using the links below:  <br />
            - Facebook: <a href='https://www.facebook.com/settings/?tab=ads'>https://www.facebook.com/settings/?tab=ads</a> <br />
            - Google: <a href='https://www.google.com/settings/ads/anonymous'>https://www.google.com/settings/ads/anonymous</a>  <br />
            - Bing: <a href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads'>https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a> <br /> <br />
            Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: <a href='http://optout.aboutads.info/'>http://optout.aboutads.info/.</a></p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Customer’s Account</h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>All data collected upon registration in the account section will be processed in line with this Privacy Policy.<br /><br />
            We understand the importance of taking extra precautions to protect the privacy and safety of children. Accordingly, children under 18 will not be permitted to open an account. We will delete any account created by a child under 18, as soon as we are made aware of it.<br /><br />
            You will have the option to stay signed-in into your account by checking the “keep me logged in” box. This option will only apply to the computer / device and the browser that you're using when you select the box. If you do not wish to stay signed on a particular browser, simply sign out of account on that browser.
            When our use of your personal data is based on your consent, you have the option to withdraw your consent to our processing and delete your personal data at any time. You can do this by submitting your request through our contact us page.
            We keep your personal information contained in your account for as long as you hold the account. You can change the personal data in your account directly in the account. Any changes made by you will only show in orders made after these changes have been made and not in existing orders.<br /><br />
            Please note that general retention periods apply to any personal data we collect to enter into a contract with you or to perform that contract or because we have a legal obligation to process it.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Do Not Track </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Your Data Protection Rights </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.<br /><br />
            Additionally, if you are a European resident we note that we are processing your information in order to fulfil contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Data Retention  </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
        </div>
      </div>
      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Changes To Privacy Policy</h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
        </div>
      </div>

      <div class="description containers">
        <h1 style={{ fontSize: 34 }}>Contact Us </h1>
        <Divider />
        <div class="aboutUs mt-1">
          <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href='support@ticketkart.com'>support@ticketkart.com</a> or by mail using the details provided below:<br /><br />
            TICKETKART LIMITED<br />
            [Re: TicketKart Limited - Privacy Compliance Officer] <br />
            Office 105, Boundary House <br />
            Cricket field Road<br />
            Uxbridge - UB8 1QG</p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
