import React, { useState, useEffect } from "react";
import "./style.css";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Typography from "antd/lib/typography"
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Tag from "antd/lib/tag";

import Divider from "antd/lib/divider";
import QuestionOutlined from "@ant-design/icons/QuestionOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import message from "antd/lib/message";
import Search from 'antd/lib/input/Search';
// import debounce from 'lodash/debounce';
import Avatar from "antd/lib/avatar";
import InputNumber from "antd/lib/input-number";
import { Descriptions, Popover, Radio, Space, TimePicker } from "antd/lib";
import { Api_base_url, DateFormat, ProcessingFee, TimeFormat } from "../../../../../../../config";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { objectToFormData } from "../../../../../../utility";
import { getPublicRequestApi, getRequestApi, postRequestApi, postRequestApiByOrganisationId } from "../../../../../../../api/commonRequest";
import dayjs from 'dayjs';
import { QuestionCircleOutlined } from "@ant-design/icons";

const CreateTicketForm = ({ls_actionFromPage="main", ls_organizationId=null, getDataList, ls_actionType, lo_actionRecord, handleSetDataAction, ls_eventId, lo_eventDetails}) => {
    const ls_token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const dateFormat = "DD/MM/YYYY";
    const [lb_loading, setLoading] = useState(false)
    const [lb_visibleModal, setVisibleModal] = useState(false)
    const [ls_updateId, setUpdateId] = useState('')
    const [lo_costDetails, setCostDetails] = useState({})
    // const [ls_ticketType, setTicketType] = useState('paid');
    const ls_ticketType = Form.useWatch('ticket_type', form);
    const li_givenCost = Form.useWatch('given_cost', form);
    const lb_absorbFees = Form.useWatch('absorb_fee', form);
    
    const handleShowModal = ()=>{
        setUpdateId('')
        form.resetFields();
        form.setFieldsValue({'ticket_type':'paid'})
        setVisibleModal(true)
    }

    const setFormData = async (record, index)=>{
        const lo_response = await getRequestApi("ticket-details/"+record.id);
        const lo_record = lo_response?.ticket ? lo_response.ticket : {};
        setVisibleModal(true)
        const lo_updateDate = {}
        lo_updateDate['ticket_type'] = lo_record.ticket_type;
        lo_updateDate['ticket_name'] = lo_record.ticket_name;
        lo_updateDate['absorb_fee'] = lo_record.absorb_fee ? lo_record.absorb_fee : 0;
        lo_updateDate['quantity'] = lo_record.quantity;
        lo_updateDate['given_cost'] = lo_record.given_cost;
        lo_updateDate['sales_starts_date'] = dayjs(lo_record.sales_starts_date);
        lo_updateDate['sales_starts_time'] = dayjs(lo_record.sales_starts_time, "HH:mm:ss");
        lo_updateDate['sales_ends_date'] = dayjs(lo_record.sales_ends_date);
        lo_updateDate['sales_ends_time'] = dayjs(lo_record.sales_ends_time, "HH:mm:ss");
        
        const costDetail = {
            "cost_to_buyer":lo_record.cost_to_buyer,
            "processing_fee":lo_record.processing_fee,
            "processing_fee_per":lo_record.processing_fee_per,
            "service_fee":lo_record.service_fee,
            "service_fee_amount_val":lo_record.service_fee_amount_val,
            "service_fee_per":lo_record.service_fee_per,
            "ticket_price":lo_record.ticket_price,
        }
        setCostDetails({...costDetail})
        // setEventCostingDetails()
        
        // setUpdateId(lo_record.id)
        form.resetFields()
        form.setFieldsValue(lo_updateDate)

        return lo_record;
    }

    const handleShowUpdateModal = async (record, index)=>{
        const lo_record = await setFormData(record, index);
        setUpdateId(lo_record.id)
    }

    const handleShowCopyModal = async (record, index)=>{
        const lo_record = await setFormData(record, index);
    }

    const handleCancel = () => {
        form.resetFields()
        setUpdateId('')
        setVisibleModal(false)
        handleSetDataAction()
    };

    const handleSubmitFormDetails = async (lo_formDetails) => {
        setLoading(true)

        if (ls_updateId) {
            const lo_response = await getPublicRequestApi("ticket-availability/"+lo_actionRecord?.event_id+"/"+ls_updateId);

            if (parseInt(lo_response?.sold_out) > lo_formDetails?.quantity) {
                message.error(`This ticket quantity can not be less than ${lo_response?.sold_out} !`);
                setLoading(false);
                return;
            }
        }
        
        const lo_formData = { ...lo_formDetails };
        lo_formData['event_id'] = ls_eventId;

        lo_formData['service_fee_per'] = ls_ticketType == "paid" ? 
            (lo_costDetails.service_fee_per ? lo_costDetails.service_fee_per : 0) : 0;
        lo_formData['service_fee_per_cost'] = ls_ticketType == "paid" ? 
            (lo_costDetails.service_fee ? lo_costDetails.service_fee : 0) : 0;
        lo_formData['service_fee_amount_val'] = ls_ticketType == "paid" ? 
            (lo_costDetails.service_fee_amount_val ? lo_costDetails.service_fee_amount_val : 0) : 0;
        lo_formData['service_fee_amount_val_cost'] = ls_ticketType == "paid" ? 
            (lo_costDetails.service_fee_amount_val ? lo_costDetails.service_fee_amount_val : 0) : 0;
        lo_formData['processing_fee_per'] = ls_ticketType == "paid" ? 
            (lo_costDetails.processing_fee_per ? lo_costDetails.processing_fee_per : 0) : 0;
        lo_formData['processing_fee_per_cost'] = ls_ticketType == "paid" ? 
            (lo_costDetails.processing_fee ? lo_costDetails.processing_fee : 0) : 0;
        lo_formData['cost_to_buyer'] = ls_ticketType == "paid" ? 
            (lo_costDetails.cost_to_buyer ? lo_costDetails.cost_to_buyer : 0) : 0;
        
        // lo_formData['processing_fee'] = ls_ticketType == "paid" ? 
        //     (lo_costDetails.processing_fee ? lo_costDetails.processing_fee : 0) : 0;
        lo_formData['your_payout'] = ls_ticketType == "paid" ? 
            (lo_costDetails.your_payout ? lo_costDetails.your_payout : 0) : 0;
        // lo_formData['service_fee'] = ls_ticketType == "paid" ? 
        //     (lo_costDetails.service_fee ? lo_costDetails.service_fee : 0) : 0;
        lo_formData['ticket_price'] = ls_ticketType == "paid" ? 
            (lo_costDetails.ticket_price ? lo_costDetails.ticket_price : 0) : 0;

        if (ls_ticketType != "paid") {
            lo_formData['given_cost'] = 0;
        }

        lo_formData['absorb_fee'] = lo_formDetails?.absorb_fee ? 1 : 0;

        // lo_formData['sales_starts_date'] = lo_formData.sales_starts_date.toISOString().slice(0, 10);
        // lo_formData['sales_ends_date'] = lo_formData.sales_ends_date.toISOString().slice(0, 10);
        // lo_formData['sales_starts_time'] = new Date(lo_formData.sales_starts_time).toTimeString().split(' ')[0];
        // lo_formData['sales_ends_time'] = new Date(lo_formData.sales_ends_time).toTimeString().split(' ')[0];

        lo_formData['sales_starts_date'] = dayjs(lo_formData.sales_starts_date).format('YYYY-MM-DD');
        lo_formData['sales_ends_date'] = dayjs(lo_formData.sales_ends_date).format('YYYY-MM-DD');
        
        lo_formData['sales_starts_time'] = dayjs(lo_formData.sales_starts_time).format('HH:mm:ss');
        lo_formData['sales_ends_time'] = dayjs(lo_formData.sales_ends_time).format('HH:mm:ss');

        if (ls_updateId) {
            const lo_updateData = {...lo_formData};
            const lo_response = await postRequestApi("update-ticket/"+ls_updateId, lo_updateData)
            setLoading(false)
            if (lo_response.status == 'success') {
                form.resetFields()
                setLoading(false)
                setVisibleModal(false)
                setUpdateId(null)
                setCostDetails({})
                message.success("Ticket Updated Succesfully!")
                getDataList()
                // navigate("/events/update/"+lo_response.event.slug+"/details")
                handleSetDataAction()
            }else {
                message.error('Validation Error!!')
            }
        } else {
            const lo_response = await postRequestApi("save-ticket", lo_formData)
            setLoading(false)
            if (lo_response.status == 'success') {
                form.resetFields()
                setLoading(false)
                setVisibleModal(false)
                setUpdateId(null)
                setCostDetails({})
                message.success("Ticket Created Succesfully!")
                getDataList()
                handleSetDataAction()
                // navigate("/events/update/"+lo_response.event.slug+"/details")
            }else {
                if (lo_response?.message?.sales_ends_date?.length > 0) {
                    message.error(`${lo_response?.message?.sales_ends_date[0]}`)
                } else {
                    message.error('Validation Error!!')
                }
                
            }
        }
    }

    const deleteTicket = async (record) => {
        const lo_response = await getRequestApi("ticket-status-update/"+record.id);
        handleSetDataAction()
        if (lo_response.status == 'success') {
            getDataList()
            message.success(`Succesfully Deleted!`)
        }else {
        // setResponseMessage(lo_response.message)
            message.error(lo_response.message[0])
        }
    }
    const handleRemoveSpecificTicketById = async (record) => {
        const lo_response = await getPublicRequestApi("ticket-availability/"+record?.event_id+"/"+record?.id);
        if (lo_response?.available_quantity < lo_response?.total_quantity) {
            message.error(`You can not delete. Ticket already sold!`);
            handleSetDataAction();
            return;
        }
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Typography.Text>Do you want to delete the ticket ({record.ticket_name})?</Typography.Text>
                {/* <Typography.Text>Do you want to delete ${record.name}?</Typography.Text> */}
            </>,
            onOk() {
                deleteTicket(record);
            },
            //// cancelText: (setDelete(false)),
            onCancel() {
                handleSetDataAction()
            },
        });
    }

    
    const handleOnBlurCost = (e) => {
        setEventCostingDetails()
        // const li_cost = e?.target?.value ? e.target.value : 0;
        // if(li_cost <= 0) return;
        // var lo_formData = {"given_cost": li_cost}
        // setCostDetails({})
        // const ls_postUrl = lb_absorbFees ? "calculate-absorve-fee" : "calculate-buyer-total";
        // const lo_response = await postRequestApiByOrganisationId(ls_postUrl, lo_formData)
        // if (lo_response.status == 'success' && lo_response.data) {
        //     setCostDetails(lo_response.data)
        // }
    }

    const setEventCostingDetails = async () => {
        const li_cost = li_givenCost ? li_givenCost : 0;
        if(li_cost <= 0) return;
        var lo_formData = {"given_cost": li_cost}
        setCostDetails({})
        const ls_postUrl = lb_absorbFees ? "calculate-absorve-fee" : "calculate-buyer-total";
        const lo_response = await postRequestApiByOrganisationId(ls_postUrl, lo_formData)
        if (lo_response.status == 'success' && lo_response.data) {
            setCostDetails(lo_response.data)
        }
    }

    const getCostDetailsContent = () => {
        return (<div>
                <Divider orientation="center" plain style={{marginTop:0, marginBottom:14}} />
                <Descriptions title="" layout="horizontal" style={{maxWidth:"600px"}} size="middle" bordered>
                    <Descriptions.Item span={24} label="Cost to buyer">£ {(Math.round((parseFloat(lo_costDetails.cost_to_buyer)+(lb_absorbFees ? 0 : ProcessingFee)) * 100) / 100)}</Descriptions.Item>
                    <Descriptions.Item span={24} label="Ticket price">£ {lo_costDetails.ticket_price}</Descriptions.Item>
                    <Descriptions.Item span={24} 
                        // label="Service fee"
                        label={<div>
                            <p level={5}>Service fee</p>
                            <p style={{fontSize:12,marginTop:-8}}>£ {lo_costDetails.service_fee_per}% + {lo_costDetails.service_fee_amount_val} per ticket</p>
                        </div>}
                    >
                        £ {lo_costDetails.service_fee}</Descriptions.Item>
                    <Descriptions.Item span={24} 
                        // label="Processing fee"
                        label={<div>
                            <p level={5}>Processing fee</p>
                            <p style={{fontSize:12,marginTop:-8}}>{lo_costDetails.processing_fee_per}% + 20p per order</p>
                        </div>}
                    >£ {Math.round((parseFloat(lo_costDetails.processing_fee)+ProcessingFee)*100)/100}</Descriptions.Item>
                    <Descriptions.Item span={24} label="Your payout">£ {(lo_costDetails.your_payout-(lb_absorbFees ? ProcessingFee : 0))?.toFixed(2)}</Descriptions.Item>
                </Descriptions>
                </div>)
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && (current > dayjs(new Date(lo_eventDetails?.starts_date)).startOf('day') || current < dayjs().startOf('day'));
    };

    const disabledEndDate = (current) => {
        return current && (current >= dayjs(new Date(lo_eventDetails?.ends_date)).endOf('date') || current < dayjs().startOf('day'));
    };

    useEffect(() => {
      setEventCostingDetails();
    }, [lb_absorbFees])
    

    useEffect(() => {
        if (ls_actionType == "add_ticket") {
            handleShowModal()
        } else if (ls_actionType == "edit_ticket") {
            handleShowUpdateModal(lo_actionRecord)
        } else if (ls_actionType == "copy_ticket") {
            handleShowCopyModal(lo_actionRecord)
        } else if (ls_actionType == 'remove_ticket') {
            handleRemoveSpecificTicketById(lo_actionRecord)
        }
    }, [ls_actionType])

    useEffect(() => {
      console.log('lo_costDetails====',lo_costDetails);
    }, [lo_costDetails])
    

    return (
        <Modal
            title={ls_updateId ? 'Update Ticket' : (ls_actionType == "copy_ticket" ? "Copy Ticket" : 'Add Ticket')}
            open={lb_visibleModal}
            onOk={() => {
                form.validateFields().then((values) => {
                    // form.resetFields();
                    handleSubmitFormDetails(values);
                })
                    .catch((info) => {
                        // console.log('Validate Failed:', info);
                    });
            }}
            okText={ls_updateId ? "Update" : (ls_actionType == "copy_ticket" ? "Copy" : "Add")}
            onCancel={handleCancel}
            confirmLoading={lb_loading}
            className='modal'
            width={700}
        >
            <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
                style={{
                    maxWidth: "600px",
                  }}
            >
                <Row>
                    <Col span={24}> <Divider orientation="center" plain /> </Col>

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Type"
                            name='ticket_type'
                            rules={[{ required: true }]}
                        >
                            <Radio.Group autoFocus={true}  buttonStyle="solid"
                            // onChange={(e) => setTicketType(e?.target?.value)}
                            >
                                <Radio.Button key={"paid"} value="paid">Paid</Radio.Button>
                                <Radio.Button key={"free"} value="free">Free</Radio.Button>
                                <Radio.Button key={"donation"} value="donation">Donation</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Ticket Name"
                            name='ticket_name'
                            rules={[{ required: true }]}
                        >
                            <Input maxLength={50} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Available Quantity"
                            name='quantity'
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    {ls_ticketType == 'paid' ? 
                    <>
                        <Col span={24}> <Form.Item
                                labelAlign="right"
                                label="Price"
                                name='given_cost'
                                rules={[{ required: true }]}
                            >
                                <InputNumber onBlur={handleOnBlurCost} min={0} style={{width:"100%"}}/>
                            </Form.Item>
                        
                            {li_givenCost > 0 ? 
                               <div className="buyer">Buyer total :{lo_costDetails?.cost_to_buyer ? `£ ${(Math.round((parseFloat(lo_costDetails.cost_to_buyer)+(lb_absorbFees ? 0 : ProcessingFee)) * 100) / 100)}` : "Calculating..."}
                               </div>
                           : <></>}
                             
                            {li_givenCost > 0 ? <Popover placement="right" content={getCostDetailsContent()} title="Cost Details" trigger="click">
                            <Button className="howworkbttn" type="text" >
                           <QuestionCircleOutlined /> How fees work </Button>
                            </Popover> 
                            : <></>}
                        </Col>
                    </>
                    : <></>}

                    {(ls_ticketType == 'paid' && li_givenCost >= 1) ? 
                    <Col xs={24} sm={24} md={24} lg={24}> 
                        <Form.Item
                            labelAlign="left"
                            name="absorb_fee"
                            valuePropName="checked"
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Checkbox className="checkBoxStyle">Absorb fees: Ticketing fees are deducted from your ticket revenue</Checkbox>
                        </Form.Item>
                    </Col>
                    : <></>}
                    
                    <Col span={24}>
                        <Row className="dateViewRowStyle">
                        <Col xs={0} sm={0} md={3} lg={3}></Col>
                            <Col xs={18} sm={18} md={15} lg={15} className="">
                                <Form.Item
                                    labelAlign="right"
                                    // labelCol={{ span: 12 }}
                                    name="sales_starts_date"
                                    label="Sale Start Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your date!",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        // disabled={true}
                                        placeholder="Select Start Date"
                                        format={DateFormat}
                                        style={{ width: "-webkit-fill-available" }}
                                        // onChange={handleOnChangeStartDate}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}/>
                            <Col span={5}>
                                <Form.Item
                                    name={['sales_starts_time']}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required Start Time",
                                        },
                                    ]}
                                    className="dateViewStyle"
                                >
                                    <TimePicker placeholder='From' format={TimeFormat} minuteStep={5} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="dateViewRowStyle">
                        <Col xs={0} sm={0} md={3} lg={3}></Col>
                            <Col  xs={18} sm={18} md={15} lg={15} className="col-left-form-gap">
                                <Form.Item
                                    labelAlign="right"
                                    // labelCol={{ span: 12 }}
                                    name="sales_ends_date"
                                    label="Sale End Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your date!",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        // disabled={lo_RepetitionDetailRecord.repetition_details && !lo_RepetitionDetailRecord.repetition_details.is_expandable}
                                        placeholder="Select End Date"
                                        format={dateFormat}
                                        style={{ width: "-webkit-fill-available" }}
                                        // onChange={handleOnChangeEndDate}
                                        // suffixIcon={false}
                                        // prefix={<CalendarOutlined />}
                                        disabledDate={disabledEndDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}/>
                            <Col span={5} >
                                <Form.Item
                                    name={['sales_ends_time']}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required End Time",
                                        },
                                    ]}
                                    className="dateViewStyle"

                                >
                                    <TimePicker placeholder='From' format={TimeFormat} minuteStep={5} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col span={24}> <Divider orientation="center" plain /> </Col>

                </Row>
            </Form>
        </Modal>
    )
}

export default CreateTicketForm