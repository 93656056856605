import React, { useEffect, useState } from "react";
import "./style.css";
import eventImg from "../../../../../ui/image/empty-event.png"
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import TeamOutlined from "@ant-design/icons/TeamOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import Radio from "antd/lib/radio";
import AccountBookOutlined from '@ant-design/icons/AccountBookOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import Space from "antd/lib/space";
import InputNumber from "antd/lib/input-number"; 
import message from "antd/lib/message";
import { useParams } from "react-router";
import { objectToFormData } from "../../../../utility";
import { Api_base_url } from "../../../../../config";
import { Typography } from "antd";
import { getPublicRequestApi, getRequestApi } from "../../../../../api/commonRequest";
import CkEditors from "../../../../components/CkEditor";
import UploadAndCropImage from "../../../../components/UploadAndCropImage";
import { Content } from "antd/lib/layout/layout";
import Card from "antd/lib/card/Card";
import ForwardFloatButton from "antd/lib/float-button/FloatButton";
import { Spin, Tag } from "antd/lib";
import Meta from "antd/lib/card/Meta";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Alert from "antd/lib/alert/Alert";

const Publish = () => {
    let navigate = useNavigate();
    const [form] = Form.useForm();
    // const addressOne = Form.useWatch('address_one', form);
    const ls_token = localStorage.getItem('token')
    // const ls_organisationId = localStorage.getItem('organisation_id')
    const ls_organisationId = useSelector( (state) => state.common.organisation_id );
    const lo_params = useParams()
    const { slug } = lo_params
    const [lb_loading, setLoading] = useState(true)
    // const [ls_updateId, setUpdateId] = useState('')
    const [ls_slug, setSlug] = useState(slug)
    const [lo_eventDetails, setEventDetails] = useState({});
    const [ls_paymentAccountStatus, setPaymentAccountStatus] = useState()
    const [li_totalTicketAmount, setTotalTicketAmount] = useState(0)

    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    

    const onSubmitFormToPublish = async (lo_formValue) => {
        if (ls_paymentAccountStatus && ls_paymentAccountStatus == 'unverified' && li_totalTicketAmount > 0) {
            // message.error('First You have to create stripe account before publish!!');
            navigate('/create-stripe-account');
            return;
        }
        if (!lo_eventDetails?.tickets || lo_eventDetails?.tickets?.length == 0) {
            message.error('First you have to create ticket to publish the event!!');
            return;
        }
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...',});
        setLoading(true)
        // const lo_formData = { ...lo_formValue };

        if (ls_slug) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer "+ls_token);
            // const formdata = objectToFormData(lo_formData);
            // formdata.append("organisation_id", ls_organisationId);
            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                // body: formdata,
                redirect: 'follow'
              };
            
            fetch(Api_base_url+"publish-event/"+ls_slug, requestOptions)
            .then(response => response.text())
            .then(result => {
                messageApi.destroy()
                setLoading(false)
                console.log('result',JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    getSpecificEventDetails();
                    message.success("Event Published Succesfully!")
                }else {
                    message.error('Validation Error!!')
                }
            })
            .catch(error => {
                messageApi.destroy()
                setLoading(false)
                console.log('error', error)
                message.error('Something Wrong!!')
            });
        } else {
            messageApi.destroy()
            message.error('Something Wrong!!')
        }
    }

    const getSpecificEventDetails = async () => {
        setLoading(true)
        const lo_event = await getUpdateData();
        if (lo_event) {
            setEventDetails(lo_event)
        }else{
            setEventDetails({})
        }
        setLoading(false)
        // const lo_formData = {};
        // lo_formData['video_url'] = lo_event.video_url;
        // lo_formData['summery'] = lo_event.summery;
        // lo_formData['decription'] = lo_event.decription;
        // form.setFieldsValue(lo_formData)
    }
    const getUpdateData = async () => {
        if(!ls_slug) return;
        // const lo_event = await getRequestApi("public-event/"+ls_slug);

        // const lo_event = await getRequestApi("events/"+ls_slug);
        const lo_event = await getRequestApi("preview-event/"+ls_slug);
        console.log('lo_event',lo_event);
        return lo_event.event;
    }


    const getPaymentAccountStatus = async () => {
        const lo_res = await getPublicRequestApi("connect-account/"+ls_organisationId);
        setPaymentAccountStatus(lo_res?.account?.status == 1 ? 'verified' : 'unverified')
    }

    const setTotalTicketAmountForEvent = () => {
        const la_alltickets = lo_eventDetails?.tickets ? lo_eventDetails.tickets : [];
        let totalAmount = 0;
        for (let i = 0; i < la_alltickets.length; i++) {
            const lo_element = la_alltickets[i];
            totalAmount += lo_element?.given_cost ? lo_element.given_cost : 0;
        }
        setTotalTicketAmount(totalAmount)
    }
    useEffect(() => {
        if(ls_organisationId) getPaymentAccountStatus();
    }, [ls_organisationId])

    useEffect(() => {
        getSpecificEventDetails()
    }, [ls_slug])

    useEffect(() => {
        setTotalTicketAmountForEvent()
    }, [JSON.stringify(lo_eventDetails)])
    

    return (
        <Spin spinning={lb_loading}>
            <Content style={{paddingTop:10}}>
            <Alert
                style={{marginBottom:8}}
                // message="Tips"
                description={<Typography.Text><span style={{fontSize:16,fontWeight:700, color:'#c09403'}}>Tips:</span> You must add <Link to={'/create-stripe-account'}>payment method</Link> before publishing your paid events to receive automated payout. Once you are done adding tickets, please click on 'Stripe Account' and link to stripe. Click on Events on the left hand navigation and go to your drafts - edit and publish your event from publish tab. You can only publish paid events once your stripe is connected.</Typography.Text>}
                type="warning" closable
            />
                <Row>
                <Col span={24} >
                        {   lo_eventDetails?.is_publish === 0 ? 
                            <ForwardFloatButton
                                onClick={onSubmitFormToPublish}
                                shape="square"
                                type="primary"
                                description={(ls_paymentAccountStatus && ls_paymentAccountStatus == 'unverified' && li_totalTicketAmount > 0) ? "Connect stripe & publish": "Publish"}
                                // className="publish-btn"
                                style={{
                                right: '45%',
                                left: '45%',
                                bottom:70,
                                width:(ls_paymentAccountStatus && ls_paymentAccountStatus == 'unverified' && li_totalTicketAmount > 0) ? 160: 78,
                                height: 58,
                                textAlign:"center",
                                }}
                                icon={<CheckOutlined />}
                                load
                            />
                            :   <Tag icon={<CheckCircleOutlined />} color="success"
                                    style={{ padding: "10px 20px 10px 20px", marginBottom:6, width: "100%"}}
                                >
                                    Published
                                </Tag>
                        }
                    </Col>
                    <Col span={24}>
                        <Card
                            // style={{
                            // maxWidth: "70%",
                            // }}
                            cover={
                            <img style={{maxHeight:320}}
                                alt="example"
                                src={lo_eventDetails?.images && lo_eventDetails.images[0]?.public_url ? lo_eventDetails.images[0].public_url : eventImg}
                            />
                            }
                            actions={[
                            <Typography.Text><AccountBookOutlined key="setting" />{` £ ${lo_eventDetails?.minimumTicketPrice ? lo_eventDetails?.minimumTicketPrice : 0.00}`}</Typography.Text>
                            ,
                            <Typography.Text><TeamOutlined key="edit" />{` ${lo_eventDetails?.totalTickets ? lo_eventDetails?.totalTickets : 0}`}</Typography.Text>,
                            <Link to={lo_eventDetails?.is_publish === 0 ? "/preview-event/"+lo_eventDetails.slug : "/event-details/"+lo_eventDetails.slug} target="_blank" rel="noopener noreferrer">
                                <Typography.Text><EyeOutlined key="ellipsis" />{lo_eventDetails?.is_publish === 0 ? 'Preview' : "View"} </Typography.Text>
                            </Link>,
                            ]}
                        >
                            <Meta
                            // avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                            title={lo_eventDetails.name}
                            description={
                                <div>
                                    <Typography.Text>
                                        {dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at {dayjs(lo_eventDetails.starts_time, "HH:mm:ss").format("h:mm A")}
                                        {lo_eventDetails.venu_type == 'online' ? ' through online. ' : ""}
                                        {lo_eventDetails.venu_type == 'vanue' ? " "+lo_eventDetails.address_one+". " : ""}
                                        {lo_eventDetails.venu_type == 'to-be-announced' ? '. ' : ""}
                                    </Typography.Text>
                                    <Typography.Text>Come join us at {lo_eventDetails.name} on {dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")}.</Typography.Text>
                                </div>
                            }
                            />
                        </Card>
                    </Col>
                    
                </Row>
                <Divider style={{marginTop:58}} />

                
                
            </Content>
        </Spin>
    )
}
export default Publish