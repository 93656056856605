import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Divider from "antd/lib/divider";
import TextArea from "antd/lib/input/TextArea";
import Typography from "antd/lib/typography";
import Radio from "antd/lib/radio";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import { objectToFormData } from "../../../../utility";
import { Api_base_url } from "../../../../../config";

const { Paragraph, Title, Text } = Typography;
const ReportModal = (lo_props) => {
  const { lb_reportModalVisible, setReportModalVisible, lo_eventDetails, lo_userDetails } = lo_props;
  const [form] = Form.useForm();
  const [lb_loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (lo_userDetails?.user) {
      form.setFieldsValue({
        name: lo_userDetails?.user?.name,
        email: lo_userDetails?.user?.email
      })
    }
  }, [lo_userDetails])
  const handleCancel = () => {
    setReportModalVisible(false);
    form.resetFields();
  };

  const la_reasons = [
    {
      value: "Fradulent or Unauthorized Event",
      label: "Fradulent or Unauthorized Event",
    },
    { value: "Harmful Content", label: "Harmful Content" },
    {
      value: "Illegal or Regulated Content",
      label: "Illegal or Regulated Content",
    },
    { value: "Spam", label: "Spam" },
    { value: "Sexually Explicit Content", label: "Sexually Explicit Content" },
    { value: "Hateful Content", label: "Hateful Content" },
    { value: "Violence or Extremism", label: "Violence or Extremism" },
    { value: "Canceled Event", label: "Canceled Event" },
    { value: "Request a Refund", label: "Request a Refund" },
    {
      value: "Copyright or Trademark infringement",
      label: "Copyright or Trademark infringement",
    },
  ];

  const handleSubmit = (lo_formData) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    formdata.append("event_id", lo_eventDetails?.id);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Api_base_url + "add-report-event", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        messageApi.destroy();
        setLoading(false);
        // console.log("result", JSON.parse(result));
        const lo_response = JSON.parse(result);
        if (lo_response.status == "success") {
          setReportModalVisible(false);
          message.success("Event is reported successfully");
          form.resetFields();
        } else {
          message.error("Validation Error!!");
        }
      })
      .catch((error) => {
        messageApi.destroy();
        setLoading(false);
        console.log("error", error);
        message.error("Something Wrong!!");
      });
  };
  return (
    <>
      <Modal
        title={
          <>
            <div>Report This Event</div>
            <Divider />
          </>
        }
        open={lb_reportModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        confirmLoading={lb_loading}
        className="modal"
        width={550}
        okText="Sumbit"
        // cancelButtonProps={{ type: "primary", danger: true }}
        // bodyStyle={{
        //   maxHeight: "90%",
        //   //   marginTop: 40,
        //   //   marginBottom: 20,
        //   padding: "3px 15px 3px 15px",
        // }}
        bodyStyle={{ 
          overflowY: 'auto', maxHeight: 'calc(100vh - 200px)', padding: "3px 15px 3px 15px",
          // maxHeight: 700,
          // minHeight: 700,
          overflowX: 'hidden',
          scrollbarWidth: 'thin !important',
          scrollbarColor: 'var(--leftNavTxt) var(--pageHeaderBackground) !important'
        }}
      >
        <>
          <Paragraph style={{ fontSize: "15px" }}>
            Please help Ticketkart investigate this event by providing
            information about why you're reporting it.
          </Paragraph>
          <Form
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            name="basic"
          >
            <Row>
              <Col lg={16} md={16} sm={24} xs={24}>
                <Form.Item
                  label={<Text strong>Reason For Report</Text>}
                  name="contact_reason"
                  rules={[
                    {
                      required: true,
                      message: "Choose one reason",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      {la_reasons.map((reason) => {
                        return (
                          <Radio value={reason.value} key={reason.label}>
                            {reason.label}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  label="Your Name"
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input placeholder="username" />
                </Form.Item>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  label="Your Email"
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input placeholder="example@gmail.com" />
                </Form.Item>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  label="Description"
                  name={"message"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Why do you believe this event listing is fradulent or unauthorized? Please describe your relationship to this event and/or provide a link to the authorized ticket sales site."
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ margin: 0 }} />
        </>
      </Modal>
    </>
  );
};

export default ReportModal;
