import React, { useEffect, useState } from "react";
import Typography from "antd/lib/typography";
import BookingHistory from "./BookingHistory";
import "./style.css";
import Divider from "antd/lib/divider";
import { useLocation, useParams } from "react-router-dom";
import { getRequestApi } from "../../../../api/commonRequest";
import dayjs from "dayjs";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Image from "antd/lib/image";
import ContactDetails from "./ContactDetails";
import { Admin_base_url, Print_base_url } from "../../../../config";
import ContactModal from "../../EventFrontend/EventDetails/ContactModal";

const BookingDetails = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const { state } = useLocation();
  const [lo_details, setDetails] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [lb_contactModalVisible, setContactModalVisible] = useState(false)

  useEffect(() => {
    getBookingDetailsByUniqueCode(id);
  }, [id]);

  useEffect(() => {
    getEventDetailsById();
  }, [state]);

  const getEventDetailsById = async () => {
    if (state) {
      const lo_eventDetails = await getRequestApi(
        `event-details-against-id/${state}`
      );
      // console.log("lo_eventDetails", lo_eventDetails);
      if (lo_eventDetails?.status === "success") {
        setEventDetails(lo_eventDetails?.event);
      }
    }
  };
  const getBookingDetailsByUniqueCode = async (id) => {
    const lo_bookingDetails = await getRequestApi(`booking-details/${id}`);

    // console.log("lo_bookingDetails", lo_bookingDetails);
    if (lo_bookingDetails?.status === "success") {
      setDetails(lo_bookingDetails?.bookings[0]);
    }
  };

  const handlePrintTicket = () => {
    const ls_orderUniqueId = id;
    const ls_printUrl = `${Print_base_url}generate-ticket-pdf/${ls_orderUniqueId}`;
    window.open(ls_printUrl, "_blank");
  }

  useEffect(() => {
    console.log('----eventDetails',eventDetails);
  }, [eventDetails])
  const lo_contactProps = {lb_contactModalVisible, setContactModalVisible, 'organisation_id':eventDetails?.organisation_id}
  return (
    <>
      {/* <div className="content-layout thumbnail "> */}
        {/* <div className="event-details-section"> */}
          {/* <div className="user-order-details-container"> */}
            <Row>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Title level={2}>
                  <div className="details-header">
                    {eventDetails?.name ? eventDetails?.name : ""}
                  </div>
                </Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="order-image">
                <Image
                  width={200}
                  height={200}
                  src={eventDetails?.images?.[0]?.public_url}
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col xs={12} sm={12} md={12} lg={12} className="details-header-text">
                {`Order ID: ${id}`}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="details-header-text">
                {`Booking Date: ${
                  lo_details?.booking_date
                    ? dayjs(lo_details?.booking_date, "YYYY-MM-DD").format(
                        "MMMM D, YYYY"
                      )
                    : ""
                }`}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="details-header-text">
                {`Total Quantity: ${
                lo_details?.total_quantity ? lo_details?.total_quantity : ""
                }`}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="details-header-text">
                {`Total Price: £ ${
                lo_details?.total_amount ? lo_details?.total_amount : ""
                }`}
              </Col>
            </Row>
            </Row>
            <Divider />
            <Space wrap className="btn-container">
              {/* <Button danger type="primary">
                Cancel Order
              </Button> */}
              <Button onClick={() => setContactModalVisible(true)}>Contact the organiser</Button>
              <Button onClick={handlePrintTicket}>Print Tickets</Button>
            </Space>
          {/* </div> */}
        {/* </div> */}
      {/* </div> */}

     
      {/* <div className="details-body"> */}
      <Row gutter={[16, 0]} style={{ marginTop: '35px'}}>
      <Col xs={24} sm={24} md={12} lg={12}>
      <div className="booking-history-container">
          <BookingHistory lo_details={lo_details} />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
      <div className="contact-details-container">
          <ContactDetails lo_details={lo_details} />
        </div>
      </Col>
      
        
      </Row>
        
      {/* </div> */}

      <ContactModal {...lo_contactProps}/>
    </>
  );
};

export default BookingDetails;
