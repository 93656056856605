import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import ReferAndEarn from "../ReferAndEarn";
import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Row } from "antd/lib";

const Referrals = () => {
  const [la_referralList, setReferralList] = useState([]);
  const [lb_loading, setLoading] = useState(false);
  const [lb_referModal, setReferModal] = useState(false)

  const la_columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Joined On",
      dataIndex: "joined_on",
      key: "joined_on",
      render: (text) => (
        <div>
          {text ? `${dayjs(text, "YYYY-MM-DD").format("MMMM D, YYYY")} ` : ""}
        </div>
      ),
    },
]
    return (
        <>
            <Content className="content-layout thumbnail">
                {/* <Col xs={24} sm={24} md={24} lg={24}>
                    <Typography.Title className="title" level={3}> Referrals</Typography.Title>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24}>
                <Row>
                <Col  xs={24} sm={24} md={24} lg={16}>
                    <Typography.Title  
                    className="title"
                    level={3}
                    >Referrals</Typography.Title>
                   </Col>
                   <Col  xs={0} sm={0} md={0} lg={3}></Col>
                   <Col  xs={24} sm={24} md={24} lg={5}>

                    <Button
                        style={{float:"right"}}
                        onClick={()=>setReferModal(true)}
                        // className="add-btn"
                        type="primary"
                        icon={<ShareAltOutlined />}
                    >
                        Refer and earn
                    </Button>
                    
                    </Col>
                </Row>
                </Col>
            </Content>
            <Content className="content-layout">
                <div className="table-container">
                    <Table
                        rowKey="id"
                        columns={la_columns}
                        className="table-inner-content"
                        // className="tant-table-body"
                        pagination={true}
                        // scroll={{ x: 1900 }}
                        loading={lb_loading}
                        dataSource={la_referralList}
                        rowClassName="row-table"
                        size="middle"
                    />
                </div>
            </Content>
            <ReferAndEarn lo_exttraModalStyle={{marginTop:0}} modalVisible={lb_referModal} onCancel={() => setReferModal(false)} />
        </>
    )
}

export default Referrals;