import React from 'react';
import "../../../../assets/css/custom-style.css";
import './style.css'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="container-fluid-s98cus footer-wid" style={{ backgroundColor: '#313131' , paddingBottom:'10px',paddingTop:32}}>
        <div className="main-footer section-padding pt-2">
          <div className="footer-top-section">
            <div className="container-s98cus">
              <div className="row-s98cus" style={{ 
                                                  justifyContent:'space-between'
                                                }}>
                <div className="col-md-3-s98cus footer__width" 
                >
                  <div className="footer-widget">
                    <div className="footer_title">
                      <h1 style={{textDecoration:'none'}}>Use Ticketkart</h1>
                    </div>
                    <ul className="footer-link-list">
                      <li><Link to={'/aboutus'}>About Us</Link></li>
                      <li><Link to={'/pricing'}>Pricing</Link></li>
                      <li><Link className='text-decoration' to={'/events/add'}>Create an Event</Link></li>
                      <li><Link to={'/user-orders'}>Download My Tickets</Link></li>
                      <li><Link target='_blank' to={'https://checkin.ticketkart.com'}>Ticketkart Check-In App</Link></li>
                      {/* <li><a href="#!">Content Standards</a></li> */}
                      {/* <li><Link to={'/faqs'}>FAQs</Link></li> */}
                      {/* <li><a href="#!">Sitemap</a></li> */}
                      {/* <li><Link to={''}>Blogs</Link></li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-3-s98cus footer__width" 
                >
                  <div className="footer-widget">
                    <div className="footer_title">
                      <h1 style={{textDecoration:'none'}}>Policies</h1>
                    </div>
                    <ul className="footer-link-list" >
                      <li><Link to={'/termsandcondition'}>Terms & Conditions</Link></li>
                      <li><Link to={'/vendor-terms-conditions'}>Organisers Terms & Conditions</Link></li>
                      {/* <li><Link to={'/vendor-terms-conditions'}>Vendor Terms & Conditions</Link></li> */}
                      <li><Link to={'/privacypolicy'}>Privacy Policy</Link></li>
                      <li><Link to={'/refferalpolicy'}>Referral Policy</Link></li>
                      <li><Link to={'/coockiespolicy'}>Cookie Policy</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3-s98cus footer__width" 
                >
                  <div className="footer-widget">
                    <div className="footer_title">
                      <h1 style={{textDecoration:'none'}}>Find Events</h1>
                    </div>
                    <ul className="footer-link-list">
                      <li><Link to={'/event-list'}>Search Events</Link></li>
                      <li><Link to={'/event-list'}>Events Near Me</Link></li>
                      <li><Link to={'/event-list'}>Events This Weekend</Link></li>
                     
                      {/* <li><Link to={'/event-list'}>Events on Ticketkart</Link></li>
                      <li><Link to={'/event-list'}>Events Happening Tomorrow</Link></li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-3-s98cus footer__width" 
                >
                  <div className="footer-widget">
                    <div className="footer_title">
                      <h1 style={{textDecoration:'none'}}>Get in Touch</h1>
                    </div>
                    <ul className="footer-link-list">
                      <li style={{color:'whitesmoke'}}><Link to={'mailto:hi@ticketkart.com'}>hi@ticketkart.com</Link></li>
                      {/* <li><Link to={'/contactform'}>Contact Support</Link></li> */}
                      <li><Link target='_blank' to={'https://help.ticketkart.com'}>Contact Support</Link></li>
                      <li><Link to={'/faqs'}>FAQs</Link></li>
                      <li><Link target='_blank' to={'https://twitter.com/ticketkart'}>X (Formerly Twitter)</Link></li>
                      <li><Link target='_blank' to={'https://www.facebook.com/uk.TicketKart'}>Facebook</Link></li>
                      <li><Link target='_blank' to={'https://www.instagram.com/uk.TicketKart/'}>Instagram</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid-s98cus footer-wid" 
      // style={{ backgroundColor: 'var(--black)' }}
      >
        <footer className="main-footer section-padding p-0-s98cus">
          <div className="footer-bottom-section">
            <div className="container-s98cus">
              <div className="footer-bottom">
                <div className="row-s98cus" style={{ justifyContent:'start' }}>
                  <div className="col-lg-10-s98cus d-flex-s98cus align-items-center-s98cus">
                    <div className="footer-bottom-menu">
                      <ul>
                        <li><Link to={'/aboutus'}>About</Link></li>
                        {/* <li><a href="#!">Blog</a></li> */}
                        <li><Link target='_blank' to={'https://help.ticketkart.com/helpdesk/helpdesk-1/knowledgebase'}>Help</Link></li>
                        {/* <li><a href="#!">Careers</a></li>
                        <li><a href="#!">Press</a></li>
                        <li><a href="#!">Investors</a></li>
                        <li><a href="#!">Security</a></li>
                        <li><a href="#!">Terms</a></li>
                        <li><a href="#!">Privacy</a></li>
                        <li><a href="#!">CA Privacy Notice</a></li>
                        <li><a href="#!">Accessibility</a></li>
                        <li><a href="#!">Cookies</a></li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2-s98cus d-flex-s98cus "  style={{ justifyContent:'end' ,width: '100%'}}>
                    <div className="copyright-area">
                      <p>© {new Date().getFullYear()} Ticketkart</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
