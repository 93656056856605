import React from 'react';
import './style.css'; // Import custom styles for ContactForm
import { Form, Input, Button, Row, Col } from 'antd';
import contactBanner from '../../../../assets/img/Contact_page_banner.jpg';
import { PhoneOutlined } from '@ant-design/icons';

const ContactForm = () => {
  const onFinish = (values) => {
    console.log('Form values:', values);
  };

  return (
    <>
      <div className="container_price_main" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <div className="container-s98cus pricing-conatiner">
          <h2 className="heading_price">
            <span style={{ color: "#ff8d1d" }}>Contact Us</span>
          </h2>
          <div className="s98cus-img">
            <img src={contactBanner} alt="Contact Banner" className="contact-banner" />
          </div>
        </div>
      </div>
      <div className='mainContainer'>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 17 }} lg={{ span: 18 }} xl={{ span: 19 }}>
            <div className="contact-form-container">
              <Form
                name="contact-form"
                onFinish={onFinish}
                layout="vertical"
                className='p-0-s98cus'
                style={{ maxWidth: '80%', margin: '0 auto' }}
              >
                <h3 className="form-title">Get in Touch</h3>
                <Row gutter={[4, 4]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                required: true,
                                message: 'Please enter your name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                required: true,
                                type: 'email',
                                message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[
                                {
                                required: true,
                                message: 'Please enter a message',
                                },
                            ]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <div className="submit-button-container">
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="submit-button">
                            Submit
                            </Button>
                        </Form.Item>
                        </div>
                    </Col>
                </Row>
                

                

                
                
              </Form>
            </div>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 7 }} lg={{ span: 6 }} xl={{ span: 5 }}>
            <div className="contact-info-container">
              <h3 className="info-title">Contact Information</h3>
              <div className="phone-numbers">
                <p>
                  <PhoneOutlined className="phone-icon" />
                  <a href="tel:03330148620" className="phone-link">03330148620</a>
                </p>
                <p>
                  <PhoneOutlined className="phone-icon" />
                  <a href="tel:02038330000" className="phone-link">02038330000</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactForm;
