import React, { useEffect, useState } from 'react';
// import "../assets/css/mystyle.css";

import './style.css'; // Make sure to import your CSS file
import { Card } from 'antd';
import { getPublicRequestApi } from '../../../../api/commonRequest';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getDeviceType } from '../../../utility';

// const slides = [
//   {
//     imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//     content: 'Event 1',
//     date: 'Sun, May 28, 11:00 AM',
//     location: 'Nalta recreation club, Kolkata, West Bengal',
//     price: '40',
//     production: 'Unreal Production',
//     followers: 170,
//   },
//   // {
//   //   imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//   //   location: 'Nalta recreation club, Kolkata, West Bengal',address_one,
//   //   price: '40',
//   //   production: 'Unreal Production',categoryname,
//   //   followers: 170,
//   // },
//   {
//     imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//     content: 'Event 1',
//     date: 'Sun, May 28, 11:00 AM',
//     location: 'Nalta recreation club, Kolkata, West Bengal',
//     price: '40',
//     production: 'Unreal Production',
//     followers: 170,
//   },
//   {
//     imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//     content: 'Event 1',
//     date: 'Sun, May 28, 11:00 AM',
//     location: 'Nalta recreation club, Kolkata, West Bengal',
//     price: '40',
//     production: 'Unreal Production',
//     followers: 170,
//   },
//   {
//     imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//     content: 'Event 1',
//     date: 'Sun, May 28, 11:00 AM',
//     location: 'Nalta recreation club, Kolkata, West Bengal',
//     price: '40',
//     production: 'Unreal Production',
//     followers: 170,
//   },
//   {
//     imageUrl: 'https://source.unsplash.com/user/wsanter/800x400',
//     content: 'Event 1',
//     date: 'Sun, May 28, 11:00 AM',
//     location: 'Nalta recreation club, Kolkata, West Bengal',
//     price: '40',
//     production: 'Unreal Production',
//     followers: 170,
//   },
//   // Add more slide objects as needed
// ];

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    // slidesToSlide: 1,
    // innerWidth: 100
  }
};

const ls_deviceType = getDeviceType();

const Slides = () => {
  const [la_eventList, setEventList] = useState([])
  useEffect(() => {
    getEventList()
  }, [])

  const getEventList = async () => {
    const lo_res = await getPublicRequestApi("home-featured-event");
    // console.log('lo_res.... ',lo_res.events.map((i, index) => i.images[0].public_url));
    console.log('lo_res.... ', lo_res.events);
    setEventList(lo_res?.events ? lo_res.events : [])
  }
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + la_eventList.length) % la_eventList.length);
  };

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % la_eventList.length);
  };

  const onChangeCarousel = (currentSlide) => {
    console.log(currentSlide);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // Auto-slide every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  return (
    <>
    {/* // <div className="container-s98cus p-1-s98cus mt-5-s98cus banner-custom">
    //   <div className="slick-slider" style={{display:'flex',flexDirection:'row'}}>
    //     <div
    //       className="slick-list"
    //       style={{
    //         width: `${la_eventList.length * 100}%`,
    //         transform: `translateX(-${(currentSlide / la_eventList.length) * 100}%)`,
    //         transition: "transform 0.5s ease-in-out",
    //       }}
    //     >
    //       {la_eventList.map((slide, inx) => (
    //         <div key={inx} className="slick-banner-custom">

    //         <div className="my-event-carousel-card">
    //           <div className="carousel-event-card-full-content">
    //             <div className="event-information">
    //               <img src={'https://source.unsplash.com/user/wsanter/800x400'} alt="" />
    //               <span className="eventName">{slide?.name}</span>
    //               <span className="eventAddress">
    //                 <i className="fa fa-calendar" aria-hidden="true"></i>
    //                 {`  ${moment(slide?.starts_date).format('DD-MM-YYYY ')}`}
    //               </span>
    //               <span className="eventLocation mb-2" style={{ display: 'flex', flexDirection: 'row' }}>
    //                 <i className="fa fa-location-dot" style={{ marginTop: 7 }}></i>
    //                 <p style={{ marginInlineStart: 5 }} >{` ${slide?.address_one}`}</p>
    //               </span>
    //               <span className="eventPrice mb-2-s98cus">
    //                 <i className="fa fa-gbp"></i> 99
    //               </span>
    //               <span className="eventProduction mb-3-s98cus">{slide.category.name}</span>
    //             </div>
    //           </div>
    //         </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    //   <div className="custom-arrow-banner custom-prev-arrow-banner" onClick={prevSlide}>
    //     <i className="fa fa-angle-left"></i>
    //   </div>
    //   <div className="custom-arrow-banner custom-next-arrow-banner" onClick={nextSlide}>
    //     <i className="fa fa-angle-right"></i>
    //   </div>
    // </div>*/}

    <div>
      <Carousel 
        responsive={responsive}
        swipeable={true}
        draggable={false}
        showDots={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        // customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container container-s98cus d-flex-s98cus eventFeatures-main"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={ls_deviceType == 'xs' ? 'mobile' : ''}
        dotListClass="custom-dot-list-style"
        // itemClass="carousel-item-padding-40-px carouslitm-sec"
        itemClass="carousel-item-padding-40-px"
        partialVisible={ls_deviceType == 'xs' ? true : false}
        centerMode={ls_deviceType == 'xs' ? false : true}
      >
        {la_eventList.map((slide, index) => (
          <div key={index} className="my-event-carousel-card">
            <Link to={'/event-details/'+slide.slug}>
              <div className="carousel-event-card-full-content" style={ls_deviceType == 'xs' ? {width:"auto"} : {}}>
              <img src={slide?.images[0]?.public_url} alt="" />
                {/* <img src={'https://source.unsplash.com/user/wsanter/800x400'} alt="" /> */}
                <div className="event-information" style={ls_deviceType == 'xs' ? {width:"auto"} : {}}>
                  <span className="eventName">{slide?.name}</span>
                  <span className="eventAddress">
                    <i className="fa fa-calendar" aria-hidden="true"></i> &nbsp;
                {/* {  `  ${moment(slide?.starts_date).format('DD-MM-YYYY ')}`} */}
                {dayjs(slide?.starts_date, "YYYY-MM-DD").format("MMMM D, YYYY")}
                  </span>
                  {slide?.address_one ? <span className="eventLocation mb-2" style={{display:'flex',flexDirection:'row'}}>
                    <i className="fa fa-location-dot" style={{marginTop:7}}></i>
                    <p style={{marginInlineStart:5}} >{` ${slide?.address_one}`}</p>
                  </span> : <></>}
                  {/* <span className="eventPrice mb-2-s98cus">
                    <i className="fa fa-gbp"></i> 99
                  </span> */}
                  <span className="eventProduction mb-3-s98cus">{slide?.organisation?.name || slide?.organisation?.contact_person}</span>
                  {/* <span className="eventFollowers">
                    <i className="fa fa-user"></i>
                    <span className="follower-no mx-1-s98cus">{slide.followers}</span>Followers
                  </span> */}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
      </div>
     </>
  );
};

export default Slides;
