import React from "react";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";
import Col from "antd/lib/col";
import Card from "antd/lib/card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const OrganiserReportCard = ({ cardDetails }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(`/${path}`);
  };
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={8}>
        <Card
          title={""}
          bordered
          hoverable
          onClick={() => handleClick(cardDetails?.url)}
        >
          <Row gutter={[16, 16]}>
            <Col xs={16} sm={16} md={16} lg={16}>
              <Title level={5}>{cardDetails?.header}</Title>
              <Text type="secondary">{cardDetails?.description}</Text>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: 40,
                  width: 40,
                  backgroundColor: "#ddeafd",
                  borderRadius: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={cardDetails?.icon}
                  style={{ color: "#145fe1", height: 20, width: 20 }}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default OrganiserReportCard;
