import Row from 'antd/lib/row'
import Col from 'antd/lib/grid/col'
import { Header } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import './style.css'
import TopDropDown from '../TopDropDown'
import AccountDropDown from '../AccountDropDown'
import { Link, useNavigate } from 'react-router-dom';
import { getUserInformationData, storeOrganisationData, stringAvatar } from '../../../utility'
import { Button } from 'antd'
import { AppstoreOutlined, MenuUnfoldOutlined, ShareAltOutlined } from '@ant-design/icons'
import ReferAndEarn from '../../../pages/ReferAndEarn'
import { useDispatch, useSelector } from 'react-redux'
import { Tag, Tooltip, message } from 'antd/lib'
import Avatar from 'antd/lib/avatar/avatar'
import { setOrganizationId, setSelectOrganization, setUserType } from '../../../store/common'

const HeaderSection = ({ colorBgContainer, logo,openAndCloseSideMenu=()=>{}}) => {
  const ls_userType = useSelector( (state) => state.common.userType );
  const ls_organisationId = useSelector( (state) => state.common.organisation_id );
  const [lb_referModal, setReferModal] = useState(false)
  const userData = getUserInformationData() 

  const la_urlPath = window.location.pathname.split('/');
  const ls_currentPath = la_urlPath.pop();
  const [la_orgList, setOrgList] = useState([])
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const la_orgListNew = userData.organisation_list.map((org) =>  {
      return {key: org.id, label: <div style={{backgroundColor: (org.id == ls_organisationId) ? '#eff0f1d1' : ''}}><Link className='underLineNone' onClick={()=> onClickDropDownMenuOrg(org)}>{org.name}</Link></div>, disabled: (org.id == ls_organisationId), danger: (org.id == ls_organisationId)}
    })
    setOrgList(la_orgListNew)
    }, [])

    const onClickDropDownMenuOrg = async (org) => {
      dispatch(setUserType('organizer'));
      dispatch(setOrganizationId(org.id));
      dispatch(setSelectOrganization(org));

      await storeOrganisationData(org)

      navigate('/dashboard');
      message.success(`Switched to organisation ${org.name}`, 10)
    }

    const getHeaderText = (userData) => {
      let ls_hdTxtFull = userData?.user?.name ? userData?.user?.name : "";
      let ls_hdTxt = userData?.user?.name ? userData?.user?.name : "";
      if (ls_hdTxt.length > 14) {
        ls_hdTxt = ls_hdTxt.substring(0, 14)+'..';
      }
      return { ls_hdTxtFull, ls_hdTxt};
    }

  return (
    <>
    <Header
        style={{
        padding: 0,
        background: colorBgContainer,
        }}
    >
        <Col xs={24} sm={24} md={24} lg={24}  >
          <Row className='top-header-sec' >
              <Col xs={5} sm={5} md={3} lg={3} >   
                <Link to={'/home'}><img  className='logoHeader' src={logo} /></Link>
              </Col>
              <Col xs={5} sm={5} md={3} lg={3}></Col>
              {/* {ls_currentPath != "overview" ?
               <Col xs={5} sm={5} md={3}  lg={3}>
                  {ls_userType == "organizer" ? <TopDropDown/> : <></>}
                </Col> : 
               <Col xs={5} sm={5} md={3} lg={3}></Col>} */}
              <Col  xs={2} sm={2} md={13} lg={13}></Col>
              <Col xs={3} sm={3} md={1} lg={1}>
                {/* {ls_userType == "organizer" ?  */}
                  <Tooltip placement="top" title={'Refer and earn'}>
                    <Button style={{
                      backgroundColor:'#001529',borderColor:'#001529',
                      color:'#6d8399f1'}} className='header__font' onClick={()=>setReferModal(true)}>
                      <ShareAltOutlined />
                    </Button> 
                  </Tooltip>
                {/* : <></>} */}
              </Col>
                <Col  xs={2} sm={2} md={0} lg={0}> 
                <AppstoreOutlined height={20} width={40} onClick={openAndCloseSideMenu}/>
               {/* <MenuUnfoldOutlined height={20} width={40} onClick={openAndCloseSideMenu} style={{marginLeft:20}}/> */}
              </Col>
          
              <Col xs={7} sm={7} md={4} lg={4} style={{display:'flex',flexDirection:'row'}}>
                <Tag color="#6d8399f1" className='accountdropdown-card' style={{
                  display:'flex',justifyContent:'center',alignItems:'center'
                }}>
                  <Tooltip placement="top" title={getHeaderText(userData)?.ls_hdTxtFull}>
                  <h4 style={{paddingInlineEnd:5,display:'flex',flexDirection:'row',alignItems:'center',}} className='header__font'>
                    <Avatar
                      {...stringAvatar(userData?.user?.name)}
                      style={{ backgroundColor: "rgb(50 101 254)", verticalAlign: "middle" }}
                      size="default"
                    ></Avatar> &nbsp;
                    <div className='username__style'>
                    {getHeaderText(userData)?.ls_hdTxt}
                    </div>
                  </h4>
                  </Tooltip>
                  &nbsp;<AccountDropDown la_orgList={la_orgList}/>
                </Tag>
              </Col>
          </Row>
        </Col>
        {/* {ls_organisationId ? <Col span={24}>
          <Menu onClick={onClickTopMenu} theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={la_headerItems} />
        </Col> : <></>} */}
    {/* </Row> */}
      
    </Header>
      <ReferAndEarn modalVisible={lb_referModal} onCancel={() => setReferModal(false)} />
    </>
  )
}

export default HeaderSection