import React, { useEffect, useState, useRef } from "react";
import logo from "../../../../../src/assets/img/logo.png";
import nav1 from "../../../../../src/assets/img/nav/nav1.png";
import nav2 from "../../../../../src/assets/img/nav/nav2.png";
import nav3 from "../../../../../src/assets/img/nav/nav3.png";
import nav5 from "../../../../../src/assets/img/nav/nav5.png";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "antd/lib/input";
const { Search } = Input;

export default function Navbar() {
  const ls_token = useSelector((state) => state.common.token);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuHeaderContainer = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenuMobile);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenuMobile);
    };
  }, []);

  const handleClickOutsideMenuMobile = (event) => {
    if (menuHeaderContainer.current && !menuHeaderContainer.current.contains(event.target)) {
      setMenuOpen(false);
      document.body.classList.remove("show-menu");
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("show-menu");
  };

  const handleDropdownToggle = (e) => {
    const parentDiv = e.currentTarget.closest("li");
    if (parentDiv) {
      const dropdownMenu = parentDiv.querySelector(".menu");
      if (dropdownMenu) {
        dropdownMenu.classList.toggle("menu-show");
      }
      const arrowIcon = parentDiv.querySelector(".arrow-iconn");
      if (arrowIcon) {
        arrowIcon.classList.toggle("icon-rotated");
      }
    }
  };
  const handleSubDropdownToggle = (e) => {
    const parentLi = e.currentTarget.closest("li");
    if (!parentLi) return; // Return early if parentLi is null

    // Toggle the sub-dropdown menu show class
    const menu = parentLi.nextElementSibling;
    if (menu) {
      menu.classList.toggle("sub-menu-show");
      parentLi.lastElementChild.classList.toggle("icon-rotated");
    }
  };

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    navigate(`/event-list/${value}`)
  }
  return (
    <>
      <nav ref={menuRef} className="container-fluid fixed-tops nav-container eventFeatures-main centerItem">
        <div ref={menuHeaderContainer} className="container-s98cus">
          <div className="row-s98cus nav-row">
            <div className="col-2-s98cus d-flex-s98cus logo-div">
              <h2 className="home__logo d-flex-s98cus align-items-center-s98cus">
                <Link to={"/home"}>
                  <img src={logo} alt="" />
                </Link>
              </h2>
            </div>
            <div
              className={`menu-header-container col-6-s98cus ${menuOpen ? "show" : ""}`}>
              <ul className={`mega-header-menu ${menuOpen ? "mega-menu-show" : ""}`}>
                <li className="header-dropdown" onClick={handleDropdownToggle}>
                  <div>
                    <span style={{ fontSize: 16, fontWeight: 'bold', color: '#2c2a2a' }}>Attendees</span>
                    <i className=" notshow fas fa-chevron-down arrow-icon"
                      style={{ fontSize: "13px" }}>
                    </i>
                    <span className="material-symbols-outlined">
                      <i
                        className=" fas fa-chevron-down arrow-iconn"
                        style={{ fontSize: "13px" }}
                      ></i>
                    </span>
                  </div>
                  <ul className="menu">
                    <li
                      className="header-sub-dropdown-menu"
                      onClick={handleSubDropdownToggle}
                    >
                      <ul className="sub-menu d-flex-s98cus gap-3-s98cus">
                        <li>
                          <Link to={'user-orders'} className="text__decoration">
                            <div className="my-ticket">
                              <img src={nav1} alt="my-ticket" />
                              <h3>My Ticket</h3>
                              <p className="text-center-s98cus">
                                Seamlessly access and manage your upcoming event tickets.
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to={'/event-list'} className="text__decoration" onClick={handleMenuToggle}>
                            <div className="my-ticket">
                              <img src={nav2} alt="my-ticket" />
                              <h3>Event Search</h3>
                              <p className="text-center-s98cus" >
                                Discovering events? Your search ends here.
                              </p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="header-dropdown" onClick={handleDropdownToggle}>
                  <div>
                    <span style={{ fontSize: 16, fontWeight: 'bold', color: '#2c2a2a' }}>Organisers</span>
                    <i
                      className="notshow fas fa-chevron-down arrow-icon"
                      style={{ fontSize: "13px" }}
                    ></i>
                    <span className="material-symbols-outlined">
                      {" "}
                      <i
                        className="fas fa-chevron-down arrow-iconn"
                        style={{ fontSize: "13px" }}
                      ></i>{" "}
                    </span>
                  </div>
                  <ul className="menu">
                    <li
                      className="header-sub-dropdown-menu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ul className="sub-menu d-flex-s98cus gap-3-s98cus">
                        <li>
                          <Link to={'/events/add'} className="text__decoration">
                            <div className="my-ticket">
                              <img src={nav3} alt="my-ticket" />
                              <h3>Host an Event</h3>
                              <p className="text-center-s98cus" >
                                Unlock event hosting: Create, Publish, and Get Instant Payment.
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/pricing"} className="text__decoration" onClick={handleMenuToggle}>
                            <div className="my-ticket">
                              <img src={nav5} alt="my-ticket" />
                              <h3>Pricing</h3>
                              <p className="text-center-s98cus" >
                                A perfect fit for every event, pricing plans tailored for your event success.
                              </p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <Link to={"/referrals"}>
                  <li className="header-dropdown">
                    <div className="nav-hover">
                      <span style={{ fontSize: 16, fontWeight: 'bold', color: '#2c2a2a' }}>Referral</span>
                      <span className="material-symbols-outlined"> </span>
                    </div>
                  </li>
                </Link>
                <button className="host-btn btn-in-toggle">
                  <Link to={"/events/add"} className="color">
                    Create an Event
                  </Link>
                </button>
              </ul>
            </div>
            <div id="btn-id" className="buttons res-btn col-4-s98cus">
              {/* <div className="search-nav-btn"> */}
                {/* <input
                  type="text"
                  id="search-input-nav"
                  placeholder="Search Events.."
                />
                <i className="fa fa-search" aria-hidden="true"></i> */}
                 
              {/* </div> */}

              <div className="search-nav-btn">
                <Search placeholder="Search Events" onSearch={onSearch} allowclear/>
              </div>
              
              
              {/* className="host-btn btn-out-toggle" */}
                <Link to={"/events/add"} className="color">
                <button className="host-btn">
                  Create an Event
                  </button>
                </Link>
              
              {ls_token ?
                <Link to={'/dashboard'}>
                  <button className="login-btn">
                    <i
                      className="fa-solid fa-user"
                      style={{ marginRight: "4px" }}
                    ></i>
                    My Account
                  </button>
                </Link>
                :
                <Link to={'/signup'}>
                  <button className="login-btn">
                    <i
                      className="fa-solid fa-user"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Log in/Signup
                  </button>
                </Link>
              }
            </div>
            <div id="bt-class" className="buttons res-btn col-7-s98cus">
              {/* <div className="search-nav-btn">
                <input
                  type="text"
                  id="search-input-nav"
                  placeholder="Search Event..."
                />
                <i className="fa fa-search" aria-hidden="true"></i>
              </div> */}

              <div className="search-nav-btn" 
              // style={{paddingLeft:30}}
              >
                <Search placeholder="Search Events" onSearch={onSearch} allowclear/>
              </div>

              <button className="host-btn btn-out-toggle">
                <Link to={"/events/add"}>
                  Create an Event
                </Link>
              </button>
              {ls_token ?
                <Link to={'/dashboard'}>
                  <button className="login-btn">
                    <i
                      className="fa-solid fa-user"
                      // style={{ marginRight: "4px" }}
                    ></i>
                  </button>
                </Link>
                :
                <Link to={'/signup'}>
                  <button className="login-btn">
                    <i
                      className="fa-solid fa-user"
                      style={{ marginRight: "4px" }}
                    ></i>
                    Log in/Signup
                  </button>
                </Link>
              }
            </div>
            <div className="buttons col-1-s98cus" style={{ padding: "0px" }}>
              <button className="menu-btn" onClick={handleMenuToggle}>
                <span className="material-symbols-outlined">
                  {menuOpen ? (
                    <i
                      className="fa fa-window-close fa-sharp"
                      style={{ fontSize: "25px" }}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa-sharp fa-solid fa-bars"
                      style={{ fontSize: "25px" }}
                    ></i>
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

