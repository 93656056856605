import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Divider from "antd/lib/divider";
import TextArea from "antd/lib/input/TextArea";
import message from "antd/lib/message";
import { objectToFormData } from "../../../../utility";
import { Api_base_url } from "../../../../../config";

const ContactModal = (lo_props) => {
  const { lb_contactModalVisible, setContactModalVisible, lo_userDetails, organisation_id } = lo_props;
  const [form] = Form.useForm();
  const [lb_loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (lo_userDetails?.user) {
      form.setFieldsValue({
        name: lo_userDetails?.user?.name,
        email: lo_userDetails?.user?.email
      })
    }
  }, [lo_userDetails])
  const ls_organisationId = localStorage.getItem("organisation_id");
  const handleSubmitContactData = (lo_formData) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    const formdata = objectToFormData(lo_formData);
    formdata.append("organisation_id", organisation_id);
    // console.log('lo_formData',lo_formData);
    let requestOptions = {
      // mode: 'no-cors',
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(Api_base_url + "add-orgcontact", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        messageApi.destroy();
        setLoading(false);
        console.log("result", JSON.parse(result));
        const lo_response = JSON.parse(result);
        if (lo_response.status == "success") {
          setContactModalVisible(false);
          message.success("An organiser is contacted");
          form.resetFields();
        } else {
          message.error("Validation Error!!");
        }
      })
      .catch((error) => {
        messageApi.destroy();
        setLoading(false);
        console.log("error", error);
        message.error("Something Wrong!!");
      });
  };

  const handleCancel = () => {
    setContactModalVisible(false);
    form.resetFields();
  };
  return (
    <>
      <Modal
        title={
          <>
            <div>Contact the organiser</div>
            <Divider />
          </>
        }
        open={lb_contactModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        confirmLoading={lb_loading}
        className="modal"
        width={550}
        okText="Sumbit"
        // cancelButtonProps={{ type: "primary", danger: true }}
        bodyStyle={{
          maxHeight: "90%",
          marginTop: 40,
          marginBottom: 20,
        }}
      >
        <Form
          autoComplete="off"
          layout="horizontal"
          form={form}
          onFinish={handleSubmitContactData}
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Form.Item
            label="Your Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input placeholder="Please Enter Your Name" />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name={"email"}
            rules={[
              {
                required: true,
                type: "email",
                message: "Email is required",
              },
            ]}
          >
            <Input placeholder="Please Enter Your Email" />
          </Form.Item>
          <Form.Item
            label="Contact Reason"
            name="contact_reason"
            rules={[
              {
                required: true,
                message: "Please select one reason",
              },
            ]}
          >
            <Select placeholder="Select one reason">
              <Select.Option value="Question about the event">
                Question about the event
              </Select.Option>
              <Select.Option value="Question about my ticket">
                Question about my ticket
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Message"
            name={"message"}
            rules={[
              {
                required: true,
                message: "Please enter the message",
              },
            ]}
          >
            <TextArea placeholder="Message" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ContactModal;
