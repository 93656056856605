import React from 'react';
import Purchesimg from '../../../../assets/img//faq/imgpsh_fullsize_anim.png';
import Accessimg from '../../../../assets/img/faq/imgpsh_fullsize_anim (2).png';
import ReadInjImg from '../../../../assets/img/faq/imgpsh_fullsize_anim (3).png';
// import "../assets/css/mystyle.css";
import "../../../../assets/css/custom-style.css";

const TicketKart = () => {
  return (
    <div className="container-s98cus">
      <div className="howItworks-heading">
        <h2 className="text-center-s98cus">How it works</h2>
        <h3 className="text-center-s98cus">
        Create your event using TicketKart and enjoy Effortless Ticket Management.
        </h3>
      </div>
      <div className="row-s98cus px-3" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus responsive-phone">
          <div className="howItWorks-div">
            <div className="inside-content-div">
              <img src={Purchesimg} alt="" />
              <h2 className="mt-1-s98cus mb-2-s98cus text-center-s98cus">
              Create Your Event Listing - Free</h2>
            </div>
            <svg
              className="svgg svg1"
              xmlns="http://www.w3.org/2000/svg"
              width="134.5"
              height="61"
              viewBox="0 0 134.5 61"
            >
              <path
                className="cls-1"
                d="M445.692,2599.63c15.923-1.1,35.269,1.63,49.808,10.87-0.333.67,0.833,2.83-1,3.39-19.667-5.87-39.333-8.89-59.8-3.92-22.319,6.39-30.934,13.01-44.2,21.53-0.667-.33-2.833.83-2.459-2.25C401.725,2614.27,414.348,2605.42,445.692,2599.63Zm67.639,16.11A121.78,121.78,0,0,1,477.5,2570.5a35.764,35.764,0,0,1,3.826.17c11.414,12.26,20.062,27.28,32.3,39.7,4.295,0.71,4.449,5.55,8.872,7.13-0.333.67,0.833,2.83-2,2.94-17,3.29-34,9.38-51,9.06,0.333-.67-0.833-2.83,1.566-2.94,12.986-4.4,28.587-6.29,42.328-8.87C513.394,2617.69,513.5,2615.5,513.331,2615.74Z"
                transform="translate(-388 -2570.5)"
              />
            </svg>
            <div className="howItWorks-serial">1</div>
          </div>
        </div>
        <div className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus responsive-phone">
          <div className="howItWorks-div howItworks-mid-div">
            <div className="inside-content-div">
              <img src={Accessimg} alt="" />
              <h2 className="mt-1-s98cus mb-2-s98cus text-center">
              Manage Ticket Sales</h2>
            </div>
            <svg
              className="svgg svg2"
              xmlns="http://www.w3.org/2000/svg"
              width="134.5"
              height="61"
              viewBox="0 0 134.5 61"
            >
              <path
                className="cls-1"
                d="M445.692,2599.63c15.923-1.1,35.269,1.63,49.808,10.87-0.333.67,0.833,2.83-1,3.39-19.667-5.87-39.333-8.89-59.8-3.92-22.319,6.39-30.934,13.01-44.2,21.53-0.667-.33-2.833.83-2.459-2.25C401.725,2614.27,414.348,2605.42,445.692,2599.63Zm67.639,16.11A121.78,121.78,0,0,1,477.5,2570.5a35.764,35.764,0,0,1,3.826.17c11.414,12.26,20.062,27.28,32.3,39.7,4.295,0.71,4.449,5.55,8.872,7.13-0.333.67,0.833,2.83-2,2.94-17,3.29-34,9.38-51,9.06,0.333-.67-0.833-2.83,1.566-2.94,12.986-4.4,28.587-6.29,42.328-8.87C513.394,2617.69,513.5,2615.5,513.331,2615.74Z"
                transform="translate(-388 -2570.5)"
              />
            </svg>
            <div className="howItWorks-serial">2</div>
          </div>
        </div>
        <div className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus responsive-phone">
          <div className="howItWorks-div">
            <div className="inside-content-div">
              <img src={ReadInjImg} alt="" />
              <h2 className="mt-1-s98cus mb-2-s98cus text-center-s98cus">
              Receive Instant Payments</h2>
            </div>
            <div className="howItWorks-serial">3</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketKart;
