import React, { useEffect, useState } from "react";
import "./style.css";
import Tabs from "antd/lib/tabs";
import Table from "antd/lib/table";
import MailOutlined from "@ant-design/icons/MailOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import FileAddOutlined from "@ant-design/icons/FileAddOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";
import { Content } from "antd/lib/layout/layout";
import { Typography, Input, Button, Modal, message, Col, Row, Layout } from "antd";
// import debounce from "lodash/debounce";
import { Link, useNavigate } from "react-router-dom";
import CheckInUserForm from "./CheckInUserForm";
// import { capitalizeFirstLetter } from "../../utility";
// import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { getRequestApi } from "../../../api/commonRequest";

const { Search } = Input;
const { Title } = Typography;

const { TabPane } = Tabs;

const CheckInUser = () => {
    const ls_token = localStorage.getItem('token');
    const ls_organisationId = localStorage.getItem('organisation_id');

    const [lb_loading, setLoading] = useState(false);
    const [la_checkInUserList, setcheckInUserList] = useState([]);

    //common function to crud
    const [ls_actionType, setActionType] = useState(null);
    const [lo_actionRecord, setActionRecord] = useState({});
    const handleSetDataAction = (ls_action=null, record={}) => {
        setActionType(ls_action)
        setActionRecord(record)
    }

    useEffect(() => {
        getcheckInUserList()
    }, [ls_organisationId, ls_token])

    const manageButton = (record, inx) => {
        return (
            <div style={{ display: "flex" }}>
                <Button
                    className="delete-button"
                    type="text"
                    icon={<DeleteFilled />}
                />
                <Button
                    onClick={(e) => {
                        handleSetDataAction('edit_checkinuser', record);
                    }}
                    className="edit-button mr4-xs"
                    type="text"
                    icon={<EditOutlined />}
                />
            </div>
        );
    };

    const la_columns = [
        {
            title: "Sl No",
            render: (text, record, inx) => inx+1,
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        // {
        //     title: "Job Role",
        //     dataIndex: "role",
        //     render: (text, record) => (
        //         <div>{record.role_id ==2 ? "Admin" : record.role_id ==3 ? "Event Manager" : "Checkin User"}</div>
        //     ),
        // },
        {
            title: "Created On",
            dataIndex: "created_at",
            // render: (text, record, inx) =>record.created_at,
            render: (value) => (
                <div>{value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ''}</div>
            ),
        },
        // {
        //     title: "Manage",
        //     dataIndex: "manage",
        //     render: (text, record, inx) => manageButton(record),
        // },
    ];

    const getcheckInUserList = async () => {
        setLoading(true)
        const lo_response = await getRequestApi("checkin-user-list/"+ls_organisationId);
        console.log('---lo_response',lo_response);
        setLoading(false)
        if (lo_response?.user) {
            setcheckInUserList(lo_response.user)
        }else{
            setcheckInUserList([])
        }
    };

    const lo_formProps = { "getDataList": getcheckInUserList, ls_actionType, lo_actionRecord, handleSetDataAction };

    return (
        <>
            <Content className="content-layout thumbnail">
                <Col xs={24} sm={24} md={24} lg={24}>
                <Row>
                <Col  xs={24} sm={24} md={24} lg={16}>
                    <Typography.Title  
                    className="title"
                    level={3}
                    >Manage Checkin User</Typography.Title>
                   </Col>
                   <Col  xs={0} sm={0} md={0} lg={3}></Col>
                   <Col  xs={24} sm={24} md={24} lg={5} className="mb-5">
                   <Button
                        style={{float:"right"}}
                        onClick={() => { handleSetDataAction('add_checkinuser') }}
                        className="add-btn"
                        type="primary"
                        icon={<FileAddOutlined />}
                    >
                        Add Checkin User
                    </Button>
                    </Col>
                </Row>
                </Col>
            </Content>
            <Content className="content-layout"  >
                    <Table
                        loading={lb_loading}
                        rowKey="id"
                        scroll={{ x: 600,y: 300 }}
                        columns={la_columns}
                        className="tant-table-body"
                        dataSource={la_checkInUserList}
                        rowClassName="row-table"
                        size="middle"
                    />
                </Content>
            <CheckInUserForm {...lo_formProps}/>
        </>
    );
};

export default CheckInUser;
