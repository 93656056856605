import React from 'react'

const CoockiesPolicy = () => {
  return (
    <>
      <div className="terms-containers">
        <h1 style={{fontSize:36}}>Cookie Policy for TicketKart.com</h1>
      </div>
      <div className="description containers">
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Last Updated: September 23, 2023</h2>
          <p>This Cookie Policy explains how TicketKart.com ("we," "us," or "our") uses cookies and similar tracking technologies on our website, <a href='https://www.ticketkart.com'>www.ticketkart.com</a> (the "Website"). By using our Website, you consent to the use of cookies as described in this policy.</p>

          <h2 className="terms-h2-style" style={{color:'black'}}>1. What Are Cookies?</h2>
          <p>Cookies are small text files that are stored on your device (computer, smartphone, tablet, etc.) when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owner.</p>
          <h2 className="terms-h2-style" style={{color:'black'}}>2. Types of Cookies We Use</h2>
          <h3 className="terms-h2-style"><span>a. Essential Cookies: </span>You accept full liability for the contractual relationship between you and the customer. Any legal and financial responsibilities arising from the contractual agreement with the customer rest solely on you.</h3>

          <h3 className="terms-h2-style"><span>b. Performance Cookies: </span>These cookies collect information about how visitors use our Website, such as which pages are visited most often. This helps us improve the performance and usability of our Website.</h3>

          <h3 className="terms-h2-style"><span>c. Functional Cookies: </span>These cookies allow our Website to remember choices you make (such as your language preference) and provide enhanced, more personalized features.</h3>
          <h3 className="terms-h2-style"><span>d. Advertising Cookies:</span>We may work with advertising partners who use cookies to deliver personalized ads that are relevant to your interests. These cookies collect data about your online activities and may be used to show you targeted advertisements on our Website or other websites.</h3>
        </div>
        <div className="aboutUs mt-1" style={{marginTop:40}}>
          <h2 className="terms-h2-style" style={{color:'black'}}> 3. How We Use Cookies</h2>
          <p>We use cookies for various purposes, including but not limited to:<br/><br/>
- Improving Website Functionality: Cookies help us enhance the functionality and performance of our Website, making it easier for you to use.<br/>
- Analyzing Website Traffic: Cookies provide us with insights into how visitors use our Website, allowing us to optimize our content and user experience.<br/>
- Personalizing Your Experience: Cookies enable us to remember your preferences and provide you with a more personalized experience.<br/>
- Advertising: We use cookies to display relevant ads to you and to measure the effectiveness of our advertising campaigns.</p>
         </div>
         <div className="aboutUs mt-1" style={{marginTop:40}}>
          <h2 className="terms-h2-style" style={{color:'black'}}> 4. Third-Party Cookies</h2>
          <p>We may also allow third-party service providers, such as analytics or advertising partners, to place cookies on your device. These third-party cookies are subject to the privacy policies of the respective third parties, and we encourage you to review their policies.
        </p>
         </div>

         <div className="aboutUs mt-1" style={{marginTop:40}}>
          <h2 className="terms-h2-style" style={{color:'black'}}>5. Managing Cookies</h2>
          <p>You can control and manage cookies through your browser settings. Most browsers allow you to block or delete cookies, and you can set your preferences for how cookies are handled. Please note that if you disable cookies, some features of our Website may not function properly.
        </p>
         </div>
         <div className="aboutUs mt-1" style={{marginTop:40}}>
          <h2 className="terms-h2-style" style={{color:'black'}}>6. Changes to This Policy</h2>
          <p>We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with the "Last Updated" date.
        </p>
         </div>
         <div className="aboutUs mt-1" style={{marginTop:40}}>
          <h2 className="terms-h2-style" style={{color:'black'}}>7. Contact Us</h2>
          <p>If you have any questions or concerns about our use of cookies or this Cookie Policy, please write to us at:<br/>
Email: hi@ticketkart.com<br/>
By using our Website, you agree to our use of cookies as described in this Cookie Policy. If you do not agree with the use of cookies, you should adjust your browser settings or refrain from using our Website.
        </p>
         </div>
        </div>
    </>
  )
}

export default CoockiesPolicy