import React, { useState, useEffect } from 'react';
import "./style.css";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getPublicRequestApi, postRequestByTokenParamApi } from '../../../../../../api/commonRequest';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, message } from 'antd/lib';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

function PaymentForm({amount, order_id, lo_userDetails}) {
  const ls_token = useSelector( (state) => state.common.token );
  const stripe = useStripe();
  const elements = useElements();
  let navigate = useNavigate();

  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  // Pre-set the cart amount and application fee
  //   const amount = 1000; // Replace with your cart amount in cents
  //   const applicationFee = 100; // Replace with your application fee in cents

  const handleSubmit = async (event) => {
    startLoadTheMessage()
    event.preventDefault();
    console.log(stripe, elements);
    if (!stripe || !elements) {
      return;
    }

    const { token, error } = await stripe.createToken(elements.getElement(CardElement), {
      name: name,
      email: email,
    });

    if (error) {
      setError(error.message);
      stopLoadingMsh(error?.message);
    } else {
      // Send the payment details to your Laravel endpoint

      console.log('token.id',token.id);
      //   const lo_res = await getPublicRequestApi("get_stripe");
      //   console.log('-- create request lo_response',lo_res);


      const lo_formData = {};
        lo_formData['order_id'] = order_id;
        lo_formData['payment_method'] = token.id;
        // paymentMethod: token.id
        const lo_response = await postRequestByTokenParamApi("make-payment-stripe", lo_formData, ls_token);
        console.log('....lo_response',lo_response);
        if(lo_response?.message) stopLoadingSuccessMsh(lo_response.message)
        if (lo_response.status == 'success') {
          navigate("/booking-details");
        }

      
          
      
        // const lo_data = {
        //     paymentMethod: token.id,
        //     amount : amount,
        //     application_fee_amount: applicationFee,
        // }
        // const lo_response = await postRequestPublicApi("post_stripe", lo_data)
        // console.log('-- create request lo_response',lo_response);

      /*
    const apiUrl =Api_base_url+"post_stripe";
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethod: token.id,
          amount,
          application_fee_amount: applicationFee,
        }),
      });

      const data = await response.json();
      console.log('data-----------------------------',data);
      if (response.ok) {
        // Payment successful, handle accordingly
        console.log(data.message);
      } else {
        // Payment failed, handle error
        setError(data.error);
      }
      */
    }
  };

  useEffect(() => {
    const ls_userName = lo_userDetails?.user?.name;
    const ls_userEmail = lo_userDetails?.user?.email;
    setName(ls_userName);
    setEmail(ls_userEmail);
  }, [JSON.stringify(lo_userDetails)])
  

  return (
    <form onSubmit={handleSubmit} className="payment-form-pmnt">
      {/* Display pre-set values for cart amount and application fee */}
      <div className="amount-pmnt">
        Cart Amount: £ {amount} {/* Convert amount to dollars */}
        <Divider className="mt-0" orientation="left" plain></Divider>
      </div>

      <div className="form-group-pmnt">
        <label>Name</label>
        <input
          type="text"
          className="input-field-pmnt"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group-pmnt">
        <label>Email</label>
        <input
          type="email"
          className="input-field-pmnt"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group-pmnt">
        <label>Card details</label>
        <CardElement options={{ hidePostalCode: true }} className="card-element-pmnt" />
      </div>

      <button type="submit" className="submit-button-pmnt">Pay</button>
      {error && <div className="error-pmnt">{error}</div>}
    </form>
  );
}

export default PaymentForm;