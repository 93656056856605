import logo from "../../../ui/image/logo.png"
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomSider from './Slider';
import ConfigProvider from 'antd/lib/config-provider';
import './style.css'
import HeaderSection from './Header';
import FooterSection from './Footer';
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OverviewForm from "../../pages/Overview/OverviewForm";
const { Header, Content, Sider } = Layout;

const MainLayout = ({ setUserDetailsOfUser }) => {
  const ls_userType = useSelector( (state) => state.common.userType );
  const ls_token = useSelector( (state) => state.common.token );
  const ls_organisation_id = useSelector( (state) => state.common.organisation_id );
  const lo_orgDetails = useSelector((state) => state.common.organisation_details);
  let lo_params = useParams();

  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true)
  const [lb_isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed)
  };

  const openAndCloseSideMenu = () => {
    setMobileMenuActive(!lb_isMobileMenuActive);
  }

  useEffect(() => {
    setUserDetailsOfUser()
  }, [location])

  const { token: { colorBgContainer } } = theme.useToken();

  useEffect(() => {
    console.log('lb_isMobileMenuActive',lb_isMobileMenuActive);
  
    return () => {
    }
  }, [lb_isMobileMenuActive])
  
  const [lo_updateOrgProps, setUpdateOrgProps] = useState();
  useEffect(() => {
    console.log('----window?.location?.pathname',lo_orgDetails);
    if (ls_userType == 'organizer' && ls_organisation_id && !lo_orgDetails?.contact_phone && window?.location?.pathname != '/dashboard' && window?.location?.pathname != '/overview') {
      // alert('add phn')
      setUpdateOrgProps({ "getDataList": ()=>{}, "ls_actionType":'edit_organization', "lo_actionRecord":lo_orgDetails, "handleSetDataAction":()=>{ setUpdateOrgProps({})}, 'updateFrom':'other' });
    }else{
      setUpdateOrgProps(null)
    }
  }, [ls_userType, ls_organisation_id, JSON.stringify(lo_orgDetails), window?.location?.pathname])
  

  return (
    <>
    {
    (windowSize.width > 950) ?
        <Layout style={{ minHeight: '100vh', }} >
          <Layout className="mainLayout">
            <HeaderSection logo={logo} colorBgContainer={colorBgContainer} openAndCloseSideMenu={openAndCloseSideMenu}/>
            <div className="logo resMenuBtn">
              <button onClick={openAndCloseSideMenu} className="menuBtn">
                <MenuUnfoldOutlined />
              </button>
            </div>
            <Layout style={{minHeight:'90vh'}}>
              <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} trigger={null} className={`mainNavigationMenu ${lb_isMobileMenuActive ? 'open' : ''}`}>
                <CustomSider openAndCloseSideMenu={openAndCloseSideMenu} />
                <div className={`logo ${collapsed ? "collapsed" : ""}`} 
                style={{backgroundColor:'#04142c',
                position:'absolute',
                bottom:-61,
                width:'100%'}}>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: toggle,
                    }
                  )}
                </div>
              </Sider>

              <Content style={{ margin: '30px' }} >
                <div className="container"
                  style={{ padding: 24, 
                  minHeight: 450, 
                  // height:500,
                  background: colorBgContainer, }}>
                  <Outlet />
                </div>
              </Content>
            </Layout>
            <FooterSection/>
          </Layout>
        </Layout> 
      :
      <ConfigProvider>
        <Layout className={`mainLayout`}  style={{ minHeight: '100vh', }}>
          {/* <div className="logo resMenuBtn" style={{marginInlineStart:30,marginTop:0}}>
            <button onClick={openAndCloseSideMenu} className="menuBtn">
              <MenuUnfoldOutlined />
            </button>
          </div> */}
          <HeaderSection logo={logo} colorBgContainer={colorBgContainer} openAndCloseSideMenu={openAndCloseSideMenu} />
          <Sider trigger={null} collapsible collapsed={collapsed} className={`mainNavigationMenu ${lb_isMobileMenuActive ? 'open' : ''}`}>
            <div className={`logo ${collapsed ? "collapsed" : ""}`}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
            </div>
            <CustomSider setMobileMenuActive={setMobileMenuActive} />
          </Sider>
          <Layout className="site-layout" style={{ position: 'absolute', top: 95 }}>
            <Content className="site-layout-background"
              // style={{ minHeight: 280 }}
              >
              <Outlet />
            </Content>
            <FooterSection />
          </Layout>
        </Layout>
      </ConfigProvider>
      }
      {lo_updateOrgProps ? <OverviewForm {...lo_updateOrgProps}/> : <></>}
      </>);
};
export default MainLayout;