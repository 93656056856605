import React, { useEffect, useState } from 'react';
import whybg from '../../../../assets/img/why-box-bg.png';
import whybg2 from '../../../../assets/img/why-box-bg-2.png';
import why01 from '../../../../assets/img/why01.png';
import why02 from '../../../../assets/img/why02.png';
import why03 from '../../../../assets/img/why03.png';
import "../../../../assets/css/custom-style.css";
import { getPublicRequestApi } from '../../../../api/commonRequest';
import { useSelector } from 'react-redux';


const EventFeatures = () => {
  const ls_userId = useSelector( (state) => state.common.user_id );
  const getEventFeaturesList = async () => {
    console.log('calll');
  // const lo_eventList = await getPublicRequestApi("home-featured-event");
  const lo_res = await getPublicRequestApi("home-featured-event");
  // return lo_event.event;
}
  useEffect(() => {
    console.log('calll111');
    getEventFeaturesList()
  }, [])


    return (
        <div className="container-s98cus d-flex-s98cus eventFeatures-main">
        <div className="mb-5-s98cus  container-s98cus eventFeatures-se-main" style={{ backgroundColor: '#fbfdff' }}>
          <div className="row-s98cus">
            <div className="col-12-s98cus mb-5-s98cus">
              <h1 className="text-center-s98cus ">Unleash the Potential of Your Events</h1>
              <h2 className="text-center-s98cus">with Our Ticketing Solution</h2>
            </div>
          </div>
          <div className="row-s98cus content-parent">
            <div className="col-lg-4-s98cus col-md-12-s98cus event-fe col-sm-12-s98cus content-up">
              <div
                className="why-choose-content-area-ed"
                style={{ backgroundImage: `url('${whybg}')`}}
              >
                <div className="why-choose-left-img-ed">
                  <img src={why01} alt="" />
                </div>
                <div className="why-choose-content-ed">
                  <h2>Direct payment to you</h2>
                  <p>
                  Enjoy the freedom of direct payment to your account, eliminating any intermediaries and ensuring quick access to your event revenue.
                  </p>
                  <a href="/pricing" className="choose-btn">View our prices</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4-s98cus col-md-12-s98cus event-fe col-sm-12-s98cus content-up wrap">
              <div
                className="why-choose-content-area-ed"
                style={{ backgroundImage: `url('${whybg2}')` }}
              >
                <div className="why-choose-left-img-ed">
                  <img src={why02} alt="" />
                </div>
                <div className="why-choose-content-ed">
                  <h2>Expand Your Reach</h2>
                  <p>
                  Reach a wider audience and attract more attendees with our extensive marketing and promotional tools. Seamlessly integrate social media sharing, email campaigns, and targeted advertising to maximize event exposure.
                  </p>
                  <a href={ls_userId ? '/dashboard' : '/signup'} className="choose-btn">Explore our features</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4-s98cus col-md-12-s98cus event-fe col-sm-12-s98cus content-up">
              <div
                className="why-choose-content-area-ed"
                style={{ backgroundImage: `url('${whybg}')` }}
              >
                <div className="why-choose-left-img-ed">
                  <img src={why03} alt="" />
                </div>
                <div className="why-choose-content-ed">
                  <h2>Earn 10% for each referral</h2>
                  <p>
                  Earn ongoing passive income with our referral program. For every event organizer you refer to our platform, you'll receive a generous 10% share in their profits for a lifetime.
                  </p>
                  <a href={ls_userId ? '/dashboard' : '/signup'} className="choose-btn">See our impact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default EventFeatures;