import { CalendarOutlined } from '@ant-design/icons'
import { DatePicker, message } from 'antd/lib'
import Layout, { Content } from 'antd/lib/layout/layout'
import React from 'react'
import TicketCard from '../TicketCard'
import dayjs from 'dayjs';
import { Button, Typography } from 'antd'
import './style.css'

const ChooseTicket = (lo_props) => {
    const { la_ticketList, setCheckOut, ld_bookingDate, setBookingDate, lo_eventDetails, setStep, la_bookedTickets, setBookedTickets } = lo_props;

    // const handleGoToCheckout = () => {
    //     if (!ld_bookingDate) {
    //         message.error('Choose Date!');
    //         return
    //     }
    //     if (!la_bookedTickets || la_bookedTickets.length == 0) {
    //         message.error('Choose a ticket!');
    //         return
    //     }
    //     setStep(2)
    // }
    return (
        <>
            <div>
                <Typography.Title level={4} style={{ justifyContent: 'center', textAlign: 'center' }}>Choose Tickets</Typography.Title>
                <Layout>
                    <Content style={{ overflow: 'auto'}} className='mainContent'>
                      
                        {la_ticketList.map((item, inx) =>
                            {
                                if (dayjs().isAfter(dayjs(item.sales_starts_date+ " " + item.sales_starts_time)) && item.status !== 0) {
                                    return <TicketCard key={inx} item={item} lo_eventDetails={lo_eventDetails}
                                                // setCheckOut={setCheckOut}
                                                ld_bookingDate={ld_bookingDate}
                                                setBookingDate={setBookingDate}
                                                la_bookedTickets={la_bookedTickets}
                                                setBookedTickets={setBookedTickets}
                                            />
                                }
                            }
                        )}
                    </Content>
                </Layout>
                {/* <Button
                    className="add-btn button"
                    type="default"
                    style={{ float: "right",width: 109,height: 38,marginTop:20 }}
                    onClick={() => handleGoToCheckout()}
                >
                    Checkout
                </Button> */}
            </div>
        </>
    )
}

export default ChooseTicket