import { Button, Col, Divider, Modal, Row, Space, TimePicker, message } from 'antd/lib';
import Form from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { TimeFormat } from '../../../../../../config';
import Input from 'antd/lib/input/Input';
import Spin from "antd/lib/spin";
import { MinusCircleOutlined, PlusOutlined, EditOutlined, CalendarOutlined } from '@ant-design/icons';
import { getRequestApi, postRequestApi } from '../../../../../../api/commonRequest';
import dayjs from 'dayjs';
import Typography from 'antd/lib/typography/Typography';
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import Card from 'antd/lib/card/Card';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh = (msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh = (msg) => message.success({ content: msg, key: 'load', duration: 2, })

const CreateAgenda = ({ ls_eventId }) => {
    const [agndaform] = Form.useForm();
    const formdata = Form.useWatch('agenda', agndaform);
    const [lb_spin, setSping] = useState(true)
    const handleSubmitAgenda = (add, key, updateId = null) => {
        if (updateId) {
            if (formdata[key]) submitFormDataToServer(formdata[key], updateId)
        } else {
            agndaform.validateFields().then((values) => {
                // form.resetFields();
                if (formdata.length == key + 1) add();
                console.log('Received values of form:', key, formdata[key]);
                if (formdata[key]) submitFormDataToServer(formdata[key], updateId)
            })
                .catch((info) => {
                    message.error("Validation Error!")
                    // console.log('Validate Failed:', info);
                });
        }
    }

    const getDataList = async (isBlankList = false) => {
        setSping(true)
        const lo_agendaDetails = await getRequestApi("all-event-agenda/" + ls_eventId);
        console.log('++++++++++++++++lo_agendaDetails', lo_agendaDetails);
        const la_formData = []
        if (lo_agendaDetails?.event_agendas && lo_agendaDetails.event_agendas.length > 0) {
            const la_agenda = [...lo_agendaDetails.event_agendas]
            for (let i = 0; i < la_agenda.length; i++) {
                const lo_formData = {};
                const lo_agenda = la_agenda[i];
                const la_hosts = lo_agenda?.hosts ? lo_agenda?.hosts?.split(',') : [];
                const la_newhostDetails = la_hosts.map((rowValue) => {
                    return { "host_name": rowValue };
                })
                lo_formData['id'] = lo_agenda.id;
                lo_formData['title'] = lo_agenda.title;
                lo_formData['starts_time'] = dayjs(lo_agenda.starts_time, "HH:mm:ss");
                lo_formData['ends_time'] = dayjs(lo_agenda.ends_time, "HH:mm:ss");
                lo_formData['decription'] = lo_agenda.decription;
                lo_formData['hosts'] = la_newhostDetails;
                la_formData.push(lo_formData)
                console.log('++++++++++++++++lo_agendaDetails lo_formData', lo_formData);
            }
        }

        if (isBlankList || la_formData.length > 0) {
            const lo_formData = {};
            lo_formData['title'] = '';
            lo_formData['starts_time'] = '';
            lo_formData['ends_time'] = '';
            lo_formData['decription'] = '';
            lo_formData['hosts'] = [];
            la_formData.push(lo_formData)
        }

        console.log('++++++++++++++++lo_agendaDetails la_formData', la_formData);
        agndaform.setFieldsValue({ "agenda": la_formData })
        setSping(false)
    }

    const submitFormDataToServer = async (formData, updateId = null) => {
        console.log('formData----', formData);
        startLoadTheMessage()
        const lo_formData = { ...formData };
        lo_formData['event_id'] = ls_eventId;
        lo_formData['starts_time'] = new Date(formData.starts_time).toTimeString().split(' ')[0];
        lo_formData['ends_time'] = new Date(formData.ends_time).toTimeString().split(' ')[0];
        let ls_hosts = '';
        if (formData?.hosts && formData.hosts.length > 0) {
            for (let k = 0; k < formData.hosts.length; k++) {
                const element = formData.hosts[k];
                if (k == 0) {
                    ls_hosts += element.host_name;
                } else {
                    ls_hosts += ',' + element.host_name;
                }
            }
        }
        lo_formData['hosts'] = ls_hosts;

        let ls_requestUrl = "add-agenda";
        if (updateId) {
            ls_requestUrl = "update-agenda/" + updateId;
        }

        const lo_response = await postRequestApi(ls_requestUrl, lo_formData)

        console.log('lo_response', lo_response);

        if (lo_response.status == 'success') {
            // form.resetFields()
            stopLoadingSuccessMsh(`Agenda ${updateId ? "Updated" : "Created"} Succesfully!`)
            getDataList()
            // navigate("/events/update/"+lo_response.event.slug+"/details")
        } else {
            // message.error('Validation Error!!')
            stopLoadingMsh(lo_response.message[0])
        }
    }

    const handleRemoveSpecificAgendaById = (key, remove, name) => {

        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Typography.Text>Do you want to delete?</Typography.Text>
                {/* <Typography.Text>Do you want to delete ${record.name}?</Typography.Text> */}
            </>,
            onOk() {
                deleteAgenda(formdata[key]);
                remove(name)
            },
            //// cancelText: (setDelete(false)),
            // onCancel() {
            //     handleSetDataAction()
            // },
        });
    }

    const deleteAgenda = async (record) => {
        console.log('record', record);
        const lo_response = await postRequestApi("delete-event-agenda/" + record.id, {});
        // handleSetDataAction()
        if (lo_response.status == 'success') {
            getDataList()
            message.success(`Succesfully Deleted!`)
        } else {
            // setResponseMessage(lo_response.message)
            message.error(lo_response.message[0])
        }
    }

    useEffect(() => {
        if (ls_eventId) getDataList()
    }, [ls_eventId])

    useEffect(() => {
        console.log('formdata-', formdata);
    }, [formdata])

    return (
        <div>


            <Card
                title={<span><CalendarOutlined /> Agenda</span>}
                extra={(formdata && formdata.length == 0) ? <Button className='add-btn' onClick={() => getDataList(true)}>Add</Button> : <></>}
                style={{
                    width: "100%",
                }}
                headStyle={{backgroundColor:"#e97e154a"}}
            >
                <Spin spinning={lb_spin}>
                    <Form
                        form={agndaform}
                        name="agenda_form_details"
                        // onFinish={handleSubmitAgenda}
                        // style={{
                        // maxWidth: 600,
                        // }}
                        autoComplete="off"
                        labelCol={{
                            span: 5
                        }}
                        // labelAlign='right'
                    // layout="vertical"
                    >
                    
                        <Form.List name="agenda" initialValue={[{}]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        // <Space
                                        // key={key}
                                        // style={{
                                        //     display: 'flex',
                                        //     marginBottom: 8,
                                        // }}
                                        // align="baseline"
                                        // >
                                        <Card style={{backgroundColor:"rgb(139 78 20 / 10%)", border:"1.5px solid #fe8c1d63",marginBottom:15}}>
                                        <Row>
                                            <Col xs={24} sm={24} md={24} lg={19}>
                                                <Row>
                                                    <Col span={24}>
                                                    <Form.Item
                                                            {...restField}
                                                            label="Title"
                                                            name={[name, 'title']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing Title',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Title" />
                                                        </Form.Item>  
                                                    </Col>
                                                    
                                                    <Col span={24}>
                                                    <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Start Time"
                                                            name={[name, 'starts_time']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing Start Time',
                                                                },
                                                            ]}
                                                        >
                                                            <TimePicker
                                                                style={{ width: "-webkit-fill-available" }}
                                                                placeholder='Start Time'
                                                                format={TimeFormat} minuteStep={5}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            label="End Time"
                                                            name={[name, 'ends_time']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing End Time',
                                                                },
                                                            ]}
                                                        >
                                                            <TimePicker
                                                                style={{ width: "-webkit-fill-available" }}
                                                                placeholder='End Time'
                                                                format={TimeFormat} minuteStep={5}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    </Row>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Decription"
                                                            name={[name, 'decription']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Missing Decription',
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea placeholder="Decription" showCount maxLength={1000} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                    <Row>
                                                <Col xs={0} sm={0} md={5} lg={5}/>
                                                    <Col xs={24} sm={24} md={19} lg={19}>
                                                        <Form.Item {...restField} name={[name, "hosts"]}>
                                                            <Form.List name={[name, "hosts"]} >
                                                                {(fields, { add, remove }) => (
                                                                    <Row>
                                                                        {fields.map(({ key, name, ...restField }) => (

                                                                            <Col span={6}>
                                                                                <Space
                                                                                    key={key}
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        marginBottom: 8,
                                                                                        marginRight: 8,
                                                                                        gap: "0px 4px",
                                                                                    }}
                                                                                    align="baseline"
                                                                                    size={"small"}
                                                                                >
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, "host_name"]}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Missing Host name',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input placeholder="Host Name" />
                                                                                    </Form.Item>
                                                                                    <MinusCircleOutlined onClick={() => remove(name)}/>
                                                                                </Space>
                                                                            </Col>

                                                                        ))}
                                                                        <Form.Item>
                                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} 
                                                                                // style={{marginInlineStart: name == '' && 20}}
                                                                            >Add Host</Button>
                                                                        </Form.Item>
                                                                    </Row>
                                                                )}
                                                            </Form.List>
                                                        </Form.Item>
                                                        </Col>
                                                        </Row>
                                                    </Col>
                                                    
                                                    {/* <Col span={24} style={{display:'flex',justifyContent:'center'}}>
                                                        {formdata && formdata[key]?.id ?
                                                            <>
                                                            <Button onClick={() => handleSubmitAgenda(add, key, formdata[key].id)} type='text'>
                                                        <EditOutlined  />
                                                                </Button>
                                                                <Button onClick={() => {
                                                                    handleRemoveSpecificAgendaById(key, remove, name)
                                                                }} type='text'>
                                                                <MinusCircleOutlined  />
                                                            </Button>
                                                            </>
                                                            :
                                                            <Button className='add-btn' onClick={() => handleSubmitAgenda(add, key)}>Add Agenda</Button>
                                                        }
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                    
                                            <Col xs={24} sm={24} md={24} lg={5} style={{paddingLeft:20}}>
                                           
                                                <div style={{display:"flex",justifyContent:'center'}}>
                                                    {formdata && formdata[key]?.id ?
                                                        <>
                                                       
                                                            <Button onClick={() => handleSubmitAgenda(add, key, formdata[key].id)} 
                                                            type='dashed' style={{padding:0,paddingRight:7,
                                                            paddingLeft:7,marginInlineEnd:9}}>
                                                                <EditOutlined  />
                                                                Update
                                                            </Button>
                                                          <Button onClick={() => {
                                                                    handleRemoveSpecificAgendaById(key, remove, name)
                                                            }} type='dashed' style={{padding:0,paddingRight:7,
                                                            paddingLeft:7}}>
                                                                <MinusCircleOutlined  />
                                                                Remove
                                                            </Button>
                                                           
                                                        </>
                                                        :
                                                        <Button type="dashed" onClick={() => handleSubmitAgenda(add, key)}>
                                                        <PlusOutlined/> 
                                                        Save & Add More
                                                        </Button>

                                                    }
                                            </div>
                                         
                                            </Col>
                                            {/* <Col span={5}>
                                                <Form.Item
                                                    {...restField}
                                                    label="Start Time"
                                                    name={[name, 'starts_time']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing Start Time',
                                                        },
                                                    ]}
                                                >
                                                    <TimePicker
                                                        style={{ width: "-webkit-fill-available" }}
                                                        placeholder='Start Time'
                                                        format={TimeFormat} minuteStep={5}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    {...restField}
                                                    label="End Time"
                                                    name={[name, 'ends_time']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Missing End Time',
                                                        },
                                                    ]}
                                                >
                                                    <TimePicker
                                                        style={{ width: "-webkit-fill-available" }}
                                                        placeholder='End Time'
                                                        format={TimeFormat} minuteStep={5}
                                                    />
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                        </Card>
                                        // </Space>
                                    ))}
                                    {/* <Form.Item>
                                    <Button type="dashed" onClick={() => handleSubmitAgenda(add)} block icon={<PlusOutlined />}>
                                    Add field
                                    </Button>
                                </Form.Item> */}
                                </>
                            )}
                        </Form.List>

                        {/* <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item> */}
                    </Form>
                </Spin>
            </Card>

        </div>
    )
}

export default CreateAgenda