import React, { useState,useEffect } from 'react';
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faLocationDot, faSackDollar} from '@fortawesome/free-solid-svg-icons';
import { getPublicRequestApi } from '../../../../../api/commonRequest';
import dayjs from 'dayjs';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Button, List } from 'antd/lib';
import { Link } from 'react-router-dom';



const EventCards = ({initLoading, la_data, la_events, li_count, setEvents, getEventsList}) => {
 
 
  const [loading, setLoading] = useState(false);
  
 
  // const getEventsList = async () => {
  //   const lo_res = await getPublicRequestApi("events");
  //   setData(lo_res.events)
  //   var newArr = lo_res.events.slice(0, li_count);
  //   setEvents(newArr)
  //   setInitLoading(false);
  //   console.log('lo_event',lo_res);
  // }

  const onLoadMore = () => {
    setLoading(true);
    // setEvents(
    //   data.concat(
    //     [...new Array(count)].map(() => ({
    //       loading: true,
    //       name: {},
    //       picture: {},
    //     })),
    //   ),
    // );
    var newArr = la_data.slice(la_events.length, li_count);
    const newData = la_data.concat(newArr);
    setEvents(newData);
    setLoading(false);
    // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
    // In real scene, you can using public method of react-virtualized:
    // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
    window.dispatchEvent(new Event('resize'));
  };
  useEffect(() => {
    getEventsList()
  }, [])

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore} className='add-btn'>Loading more</Button>
      </div>
    ) : null;

    const responsiveColumnCount = {
      xs: 1, // On extra-small screens, show 1 column
      sm: 1, // On small screens, show 2 columns
      md: 2, // On medium screens, show 3 columns
      lg: 3, // On large screens, show 4 columns
      xl: 4, // On extra-large screens, show 5 columns
    };

  return (
    <div className="all-event-card" >
    <List
      className="demo-loadmore-list"
      grid={{
        gutter: 16,
        ...responsiveColumnCount,
      }}
      dataSource={la_events}
      loading={initLoading}
      loadMore={la_data?.length > la_events?.length ? loadMore : <></>}
      renderItem={(event) => (
        <List.Item style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Link to={'/event-details/'+event.slug} style={{color:'black'}}>
         <div className="event-card" key={event.id}>
          <div className="image-section">
          {/* <Link to={'/event-details/'+event.slug}> */}
            <img src={event?.images[0]?.public_url} alt={`Event ${event.id} Image`} />
            <div className="buy-text">View</div>
            {/* </Link> */}
          </div>
          <div className="content-section">
            <div className="card-img-overlayy">
              <div className="event-basic-details">
                <h5 className="card-title">{event.name}</h5>
                <ul className="for-date-time">
                  <li>
                    <FontAwesomeIcon icon={faCalendarDays} /> {event.display_start_time ? ` ${dayjs(event.starts_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(event.starts_time, "HH:mm:ss").format("h:mm A")}` : ""}
                  </li>
                </ul>
                <ul className="for-date-time">
                  <li>
                  <FontAwesomeIcon icon={faSackDollar} style={{color: "#707070",}} />  {event.minimumTicketPrice === "0.00" ? 'Free' : `£ ${event.minimumTicketPrice}`}
                  </li>
                </ul>
                <ul className="for-location">
                  <li>
                  {event?.venu_type == "vanue" ?
                  <div>
                    
                    <p className='truncate-text'><FontAwesomeIcon icon={faLocationDot} /> {event?.address_one}</p>
                  </div>
                  : <></>}

                  {event?.venu_type == "online" ?
                  <div>
                    <p><PlayCircleOutlined /> Online</p>
                  </div>
                  : <></>}
                  </li>
                </ul>
                {/* <p className="for-price">
                  <FontAwesomeIcon icon={faSterlingSign} /> {event.price}
                </p> */}
                {/* <p className="organizer-title">{event.summery}</p> */}
                {/* <ul className="organizer-rating-status">
                  <li>
                    <FontAwesomeIcon icon={faUser} /> {event.followers} Followers
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
        </Link>
        </List.Item>
      )}
    />

    {/* <div className='button-add d-flex-s98cus justify-content-center-s98cus event-card-btn'>
      <button className="bubbly-button" onClick={addEventCard} ref={addButtonRef}>
        Add New Event
      </button>
    </div> */}
  </div>
  );
};

export default EventCards;
