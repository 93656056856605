import React from 'react'
import './style.css'

const TermsAndCondition = () => {
  return (
    <>
      <div className="terms-containers">
        <h1 style={{ fontSize: 36 }}>Terms and conditions of TicketKart</h1>
      </div>
      <div className="description containers">
        <p>Along with our Privacy Policy and the Website Terms of Use explains information about the legal terms and conditions on which TicketKart provides online solution for selling event tickets and associated products and services. Please read these Terms carefully and make sure that you understand them before signing up to the TicketKart Services.
        </p>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Who are we:</h2>
          <p>Ticketkart.com is a trading name of TICKETKART LIMITED ("us", "we", or "our") is a company registered in England and Wales (Company registration number: 14867948) operates the <a href='https://www.ticketkart.com'>https://www.ticketkart.com</a> & <a href='https://www.ticketkart.co.uk'>https://www.ticketkart.co.uk</a> website (the "Service").</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Our contract with you:</h2>
          <p>By clicking the 'Sign Up' or similar buttons on our website, or by downloading any of our apps, you acknowledge your agreement to these Terms and enter into a legally binding contract with TicketKart. If you do not agree with any part of these Terms of Service, please refrain from using or accessing the Service.<br />

            Please note that these Terms may be updated periodically. It is recommended that you visit our website regularly to review the most up-to-date version of the Terms, as they are enforceable upon you. Certain provisions within these Terms may be overridden by specific legal notices or terms, which may be located on specific pages of our website or communicated to you on occasion.</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>TicketKart Services:</h2>
          <p>Our web-based Service offers event organizers and authorized ticket sellers a platform to register, sell, and manage event tickets online. To register for an account, you must be either the event organizer or an authorized ticket seller with explicit written permission from the event organizer for all the events you sell tickets for. If you are using the Service on behalf of an entity, you agree to these Terms on behalf of that entity and confirm that you have the necessary authority to do so.
            <br />
            Please note that we solely provide the Service to you and do not have any direct relationship with your customers, referred to as 'Consumers'. When you sell tickets or other products and services to Consumers through our website, the contractual relationship is established between you and the Consumer and you agree with below customer support policies:</p>
          <div style={{ marginInlineStart: 80 }}>
            <h3 className="terms-h2-style" >1. <span>Customer Support Responsibility:</span> You are solely responsible for handling all customer communications and should not redirect customers to us for support. All customer support inquiries should be addressed by you directly, and we will not handle customer support on your behalf:</h3>

          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h3 className="terms-h2-style" >2.<span>Timely Customer Response: </span>It is expected that all customer inquiries will be addressed promptly within a 5-day timeframe.</h3>

          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h3 className="terms-h2-style">3. <span>Liability for Contractual Relationship: </span>You accept full liability for the contractual relationship between you and the customer. Any legal and financial responsibilities arising from the contractual agreement with the customer rest solely on you.</h3>

          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h3 className="terms-h2-style">4. <span>Event Updates and Notifications:</span>  Timely communication and updates are required in the case of event cancellations, postponements, or significant modifications, and your customers must be promptly informed while the TicketKart event page reflects these changes.</h3>

          </div>
          <p>We maintain the right to suspend or terminate your access to the Service in the event of non-compliance with the contractual relationship requirements between you and your Consumers. As a user of the Service, you are expected to:</p>
          <div style={{ marginInlineStart: 80 }}>
            <ul>
              <li><p>Comply with all applicable laws, regulations, and these Terms of Service.</p></li>
              <li><p>Provide accurate and up-to-date information while using the Service.</p></li>
              <li><p>Ensure that you have obtained all necessary permissions, licenses, or consents required for selling tickets or other products and services to Consumers.</p></li>
              <li><p>Maintain a professional and respectful demeanour when interacting with Consumers through the Service.</p></li>
              <li><p>Safeguard the confidentiality and security of any sensitive information provided by Consumers.</p></li>
            </ul>
            <p>Failure to adhere to these requirements may result in the suspension or termination of your access to the Service.
              <br />
              Temporary suspension of access to the Service may occur without prior notice in the event of system failure, maintenance, repair, or circumstances beyond our control. Additionally, your access to the Service may be suspended or your account may be cancelled at our discretion, including but not limited to the following situations: suspicion of fraudulent or criminal activity on your part, a directive from law enforcement agencies, or failure to pay the service fee (in such cases, we typically provide notification of non-payment before suspension or cancellation). It is important to note that we will not be held liable for any loss or damage resulting from the misuse of our service.</p>
          </div>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Your Account:</h2>
          <p>Upon signing up, you gain immediate access to set up your event, create event tickets, and, provided you have established an account with a Payment Processor, sell tickets for your event. Please note the following important points:</p>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >1.<span> Minimum age requirement:</span> You must be at least 18 years old to create an account.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >2.<span> Account management:</span> You have the option to make changes to your account using the available functionality in your account dashboard.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >3.<span> Account closure:</span> You may choose to close your account at any time. 5 days’ notice period is required for the account closure to take effect. Prior to closure, it is advised that you make a copy of any content you wish to retain by utilizing the export functionality in your account dashboard. Following the closure of your account, Your Content will be deleted.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >4.<span> Account retention:</span>For your convenience, we will keep your account open for 36 months after your last sign-in, in case you decide to use our Service again. Prior to closing your account and deleting Your Content, we will attempt to send warning emails to the email address associated with your account. This will provide you with an opportunity to keep your account open or export any desired data.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >5.<span> Closing your account:</span>  To close your account, please email our customer support team at hi@TicketKart.com from the email address registered to the account.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >6.<span> Non-refundable fees:</span>  Please be aware that any fees already paid for the Service or any remaining Credits at the time of account closure will not be refunded.</h2>
          </div>
          <p>Should you have any further inquiries or require assistance, please feel free to contact our customer support team.
            <br /><br />
            We are pleased to offer the TicketKart 14-days money back guarantee on Membership subscription, providing you with the opportunity to try our Service for a period of one month starting from the moment you create your account. If, during this time, you find that the product does not meet your expectations, we offer a full refund. To initiate the refund process, kindly send an email to hi@TicketKart.com with the subject line '14-days money back guarantee' within 14 days of creating your account. In your email, please provide a brief explanation of why you were not satisfied. Our dedicated customer support team will carefully review your request and promptly process the refund.</p>
        </div>

        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Account Security: </h2>
          <p>Account Security is of utmost importance to us. Please take note of the following provisions:</p>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >1.<span> Account Confidentiality:</span> It is your responsibility to maintain the confidentiality of your account and access credentials. You are fully accountable for all activities that occur as a result of accessing your account using your access credentials.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >2.<span> Password and API Key:</span> If we have provided you with a password or API key for accessing your account, specific parts of our website or apps, or if you have invited team members to access your account or parts of our site or apps, it is your duty to keep this password or API key confidential. Sharing your password or API key with anyone is strictly prohibited.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >3.<span> Unauthorized Use or Breach:</span>In the event of any unauthorized use of your password, API key, or account, or any security breach, please notify us immediately by sending an email to SOS@TicketKart.com. Prompt notification is essential. We shall not be held liable for any loss or damage arising from your failure to comply with this provision.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >4.<span> Internet Transmission: </span> Please be aware that transmitting information over the internet is not completely secure. While we strive to protect your data, we cannot guarantee the security of data transmitted to our site. Any transmission of data is done at your own risk.</h2>
          </div>
          <div style={{ marginInlineStart: 80 }}>
            <h2 className="terms-h2-style" >5.<span> Security Measures:</span>To close your account, please email our customer support team at hi@TicketKart.com from the email address registered to the account.</h2>
          </div>
          <p>Your account security is of paramount importance to us, and we are dedicated to implementing necessary measures to safeguard your information.</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Payments:</h2>
          <p>Our Service enables you to accept online payments from Consumers for your events and products/services through our partnered payment processors, namely Stripe Payments and PayPal.
            <br /><br />
            To utilize the payment service, you must create an account with the Payment Processor of your choice, subject to their own terms and conditions and privacy policy. You will establish a direct relationship with the Payment Processor and will be required to review and accept their terms and conditions before accepting payments from Consumers through our Service.
            <br /><br />
            The Payment Processor acts as a data processor to both you and us in certain functions, while in other respects, it acts as a controller. The Payment Processor's activities as a controller adhere to their own privacy policy. By using our payment service, you acknowledge and consent to us sharing personal and transactional information with the Payment Processor.
            <br /><br />
            If we receive notice from the Payment Processor indicating a breach of their terms or any agreement between you and them, fraudulent activity, non-compliance with money laundering regulations, or any other harmful behaviour related to your use of our payment service, we may take appropriate actions. These actions may include rectifying the breach, disabling your access to our payment service, or suspending/terminating your account. The Payment Processor reserves the right to refuse their payment services to anyone, for any reason, and at any time.
            <br /><br />
            For more information, please refer to the terms and privacy policies of Stripe and PayPal:
            <br /><br />
            Stripe: You can find the Stripe Services Agreement and Stripe Global Privacy Policy on their respective websites.
            PayPal: The terms and privacy policy for PayPal Services can be accessed through their Legal Agreements.
            Please note that we do not assume responsibility for the service provided by the Payment Processor, and we hereby disclaim any liability, including but not limited to losses, costs, fees, and penalties arising from their services, including the acts and omissions of the Payment Processor.</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Fees & Charges:</h2>
          <p>Our fees for using our Service are outlined on our website and are charged based on usage. A chargeable activity includes the sale of tickets, seat reservations, the sale of associated products or services, or other chargeable activities provided through our Service. Additionally, we may offer add-on services that are charged as specified on our website or as agreed upon with you.
            <br /><br />
            Fees will be charged at the point of sale through the Payment Processor you use for ticket sales. When a Consumer makes a payment to you, the fees will be deducted and paid to us by the Payment Processor. Periodically, and at least monthly, the fees will be charged to your account by the Payment Processor or the credit/debit card you have provided. We will send you a monthly statement and invoice via email, which will detail all the fees and applicable taxes you have paid to us.
            <br /><br />
            Please note that our fees are non-refundable, even if an event is cancelled.</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Use of Personal Information: </h2>
          <p>We respect your privacy and handle your personal information in accordance with our Privacy Policy. We encourage you to carefully read our Privacy Policy, as it contains important information and terms that apply to you regarding the use and protection of your personal information.
            <br />
            In relation to the personal information provided by your consumers and event attendees ("Customer Data"), the following provisions apply:
            <br />
            Data Protection Laws: Both you and we agree to comply with all applicable Data Protection Laws. You are the controller of the Customer Data, and we act as a processor of personal data in relation to the Customer Data.
            <br />
            Your Responsibilities:
            a. You are responsible for obtaining necessary consents and providing appropriate notices to enable the lawful transfer and processing of Customer Data by us in connection with the Service. This includes informing consumers and event attendees about how their data will be handled by you and us.
            b. You are solely responsible for establishing and maintaining the lawful basis for our processing of Customer Data, including obtaining necessary consents from customers and event attendees.
            c. When you export Customer Data from the Service, you are responsible for ensuring compliance with Data Protection Laws and the terms between you and consumers/event attendees.</p>

          <p>Our Obligations:</p>
          <div style={{ marginInlineStart: 80 }}>
            <ul>
              <li><p>We will only process Customer Data to provide the Service, as instructed by you, or as required by law. We will not sell or use Customer Data for any other purpose.</p></li>
              <li><p>We will notify you if we believe any of your instructions infringe Data Protection Laws. However, you remain responsible for ensuring the legality of your instructions.</p></li>
              <li><p>We will maintain confidentiality obligations for our personnel handling Customer Data.</p></li>
              <li><p>We will implement appropriate technical and organizational measures to protect Customer Data against unauthorized or unlawful processing, loss, destruction, or damage.</p></li>
              <li><p>We will assist you in meeting your obligations under Data Protection Laws, such as security, breach notifications, impact assessments, and consultations.</p></li>

              <li><p>We will promptly inform you of any loss, destruction, damage, or unauthorized processing of Customer Data.</p></li>
              <li><p>We will assist you in responding to data subject requests related to Customer Data.</p></li>
              <li><p>Upon termination of the Service, we will return or delete Customer Data unless legal requirements demand continued storage.</p></li>
              <li><p>We will provide you with information necessary to demonstrate compliance and allow audits by you or an independent auditor.</p></li>
            </ul>

          </div>
          <p>Sub-processors: As a data processor, we may engage sub-processors to process Customer Data. We will ensure they adhere to obligations equivalent to those outlined in this section. Some sub-processors may process Customer Data outside the UK or EEA, and appropriate safeguards will be in place for such transfers. You can find a list of sub-processors on our website, and we will notify you of any new appointments in advance.
            <br /><br />
            Please note that this summary provides an overview, and the complete details can be found in our Terms and Conditions or Privacy Policy. It is important to review the full text for a comprehensive understanding of your rights and obligations regarding personal information and data protection.

          </p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Content Standards:</h2>
          <p>As the event organizer, you are responsible for the content you upload to our website ("Your Content"). You own all rights to Your Content and are responsible for ensuring its legality, reliability, integrity, accuracy, and quality. By uploading Your Content, you grant us an unconditional, non-exclusive, transferrable, royalty-free, worldwide license to use Your Content for the provision of the Service.

            You have the option to manage the visibility of your events on search engines or keep them private. If an event is not private, you grant us an unconditional, non-exclusive, royalty-free, worldwide license to use Your Content for promotional purposes.</p>

          <p>When submitting Your Content, you must comply with the following Acceptable Content Standards:</p>
          <div style={{ marginInlineStart: 80 }}>
            <ul>
              <li><p>Ensure Your Content is accurate and complies with applicable laws in any country from which it is posted.</p></li>
              <li><p>Do not use our website to collect excessive or unnecessary personal data from consumers or event attendees, such as card details (which should be collected by the Payment Processor), government or social security numbers, passwords, or special category data unless necessary for your event (e.g., allocating seating for people with disabilities).</p></li>
            </ul>
          </div>
          <p>Your Content must not:</p>
          <div style={{ marginInlineStart: 80 }}>
            <ul>
              <li><p>Be unlawful, harmful, threatening, defamatory, obscene, deceitful, fraudulent, infringing, harassing, morally repugnant, or racially or ethnically offensive.</p></li>
              <li><p>Facilitate illegal activity.</p></li>
              <li><p>Depict sexually explicit images.</p></li>
              <li><p>Promote violence.</p></li>
              <li><p>Discriminate based on race, sex, religion, nationality, disability, sexual orientation, or age.</p></li>
              <li><p>Infringe upon any copyright, database right, trademark, or other intellectual property rights of any other person.</p></li>
              <li><p>Conceal identity or deceive any person.</p></li>
            </ul>
          </div>
          <p>If we determine that Your Content violates our Acceptable Content Standards, we may remove it from our website and service. In case of a material breach of the Acceptable Content Standards, we reserve the right to suspend or terminate your access to the Service, seek reimbursement for costs resulting from the breach, and disclose information to law enforcement authorities as required or reasonably necessary.
            <br />
            Please note that this summary provides an overview, and the complete details can be found in our Terms and Conditions. It is important to review the full text for a comprehensive understanding of your rights and obligations regarding Your Content and acceptable content standards.
          </p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Intellectual Property:</h2>
          <p>We, as the owner or licensee, hold all intellectual property rights in the Service, the website, and the material published on it, excluding Your Content. This includes any necessary software used in connection with the Service ("Software"). These intellectual property rights are protected by copyright laws and treaties worldwide, and all rights are reserved. Unless expressly authorized by us, you agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Service, the website, or the Software, either in whole or in part.
            <br /><br />
            You acknowledge that we collect data on your use of our website and Service for performance and service analysis purposes. You grant us a worldwide, royalty-free, non-exclusive, perpetual license to use non-personally identifiable information from such data for the purposes of our business.
            <br /><br />
            It's important to review the full terms and conditions for a comprehensive understanding of the intellectual property rights and data collection practices outlined in our agreement.</p>
        </div>
        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Disclaimer:</h2>
          <p>While we strive to ensure that the Service is available 24 hours a day, we cannot always guarantee its availability. We will not be held liable if the Service becomes unavailable for any reason or during any period. Temporary suspension of access to the Service may occur without prior notice in the case of system failure, maintenance or repair, or for reasons beyond our control.
            <br /><br />
            We do not provide a warranty regarding the accuracy and completeness of the material on the website. We reserve the right to make changes to the material on the website and the functionality of the Service at any time and without notice. The material on the website may be outdated, and we do not commit to updating it.
            <br /><br />
            We will provide the Service with reasonable care and skill. However, except for the warranties explicitly stated in these Terms, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, and other terms, including the conditions of satisfactory quality and fitness for a particular purpose, regarding the Service and the material on the website.
            <br /><br />
            It's essential to review the full terms and conditions for a comprehensive understanding of our liability limitations and disclaimers related to the availability, accuracy, and warranties of the Service.</p>
        </div>

        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Liabilities:</h2>
          <p>We are not involved in any transactions, other relationships, or disputes between you and consumers or between you and event attendees. Additionally, we do not pre-screen consumers and cannot be held responsible for any fraudulent transactions carried out by them. We disclaim all liability arising from our actions taken in response to breaches of our Acceptable Content Standards and these Terms. By using the Service, you agree to indemnify us, as well as our officers, directors, and partners, against any costs, claims, or demands, including reasonable legal fees, related to disputes with your consumers, your relationship with consumers and event attendees, and any claims made by third parties due to or arising from your content and use of the Service. You are solely responsible for your actions while using the Service.
            <br /><br />
            These terms do not limit or exclude our liability in cases of<br /> (a) death or personal injury caused by our negligence, or the negligence of our employees, agents, or subcontractors;<br /> (b) fraud or fraudulent misrepresentation; or<br /> (c) any other matter for which it would be unlawful to exclude or restrict liability. Subject to this, our liability to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, loss of income, or any indirect or consequential loss arising from the provision of our services, shall be limited to £100. Furthermore, nothing in these terms aims to limit or exclude consumers' legal rights.
            <br /><br />
            Jurisdiction & Law:
            <br />
            The interpretation and enforcement of these Terms shall be governed by the laws of England. Any disputes arising in connection with these Terms shall be subject to the non-exclusive jurisdiction of the courts in England.</p>
            <br />
        </div>

        <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{ color: 'black' }}>Ticket Buyer Refund Policy:</h2>
          <h3 className="terms-h2-style">As a ticket buyer, please review our refund policy below:</h3>

          <p>1. Requesting a Refund: If you wish to request a refund for your tickets, please contact the event organiser directly.
            <br/>
            2. Finding Contact Information: You can locate the event organiser's contact details at the  event's official website or social media pages.
            <br/>
            3. Refund Discretion: Refunds are entirely at the discretion of the event organiser and will be processed in accordance with their terms and conditions.
            <br/>
            4. Payment Management: Please be aware that we do not manage ticket payments or refunds. All financial transactions are handled by the event organiser.
            <br/>
            If you have any questions about the refund process, please reach out to the event organiser for further assistance.</p>
        </div>
      </div>
    </>
  )
}

export default TermsAndCondition
