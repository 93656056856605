import React, { useState } from "react";
import "./style.css";
import img from "../../../../assets/img/about/img.png";
import img1 from "../../../../assets/img/about/tk-about-banner.jpg";
import PricingFooter from "../../../components/PricingFooter";

const initialCards = [
  {
    icon: "fa fa-user-friends",
    heading: "For attendees",
    details:
      "Register for events, sell or transfer your tickets bought through us at face value and get extra security with a unique digital ID.",
  },
  {
    icon: "fa fa-chart-bar",
    heading: "For organisers",
    details:
      "Create unlimited online, live or hybrid events for free, track your progress and pay zero set-up or monthly fees.",
  },
  {
    icon: "fa fa-globe-americas",
    heading: "For the world",
    details:
      "We’re a business that gives back: 50% of our booking fees go to charities working to make a positive impact on the planet.",
  },
];
const AboutUs = () => {
  const [cards, setCards] = useState(initialCards);
  const valuesData = [
    {
      title: 'People-centered',
      icon: 'fa fa-user-friends',
      description: ` Ticketpass is for people and that’s why we always put them
      first. From helping people create great events and experiences,
      to allowing our customers resell or transfer tickets at face
      value, our process is seamless and fair.`,
      additionalInfo: `We also give back to communities through Give Back 50, our
      pledge to donate 50% of our booking fees to charity. We are
      driven by the idea of putting people and the planet at the heart
      of what we do and that’s something on which we will never
      compromise.`,
    },
    {
      title: 'Fairness',
      icon: 'fa fa-balance-scale',
      description: ` To be a truly ethical ticketing company we must be fair in everything we do, and ensure that we remain accountable to you and our stakeholders. `,
      additionalInfo: `We promise to treat our community with the utmost respect, to not profit unfairly off the back of others and to be a leading example of fairness in the ticketing industry. We work to honour this promise in how we act and operate as a business for good.`,
    },
    {
      title: 'Leadership',
      icon: 'fa fa-hiking',
      description: ` Being ethical starts with us. We lead by example, putting our money to work for good causes and not exaggerating claims of what we do as a business.  `,
      additionalInfo: `As an innovative tech start-up, we believe that any technology we develop should benefit everyone, not just those who are willing to pay the most for it. So, when we make a promise that we’ll lead the case for a fairer, ethical ticketing platform that benefits everyone, we mean it.`,
    },
    {
      title: 'Impactful',
      icon: 'fa fa-hands-helping',
      description: ` Yes, we intend to make an impact on the ticketing industry by challenging it to be more fair for fans. But we don’t just challenge others, we challenge ourselves.   `,
      additionalInfo: `By donating a significant portion of our revenue to charity, we intend to make our business a force for good. And it’s something our customers can get involved with too, by choosing to support the causes that are closest to their heart. `,
    },
    {
      title: 'Transparency',
      icon: 'fa fa-glass-water-droplet',
      description: ` We believe that the ticketing industry needs to be more transparent about how it operates. As an ethical business, we are committed to being transparent and open about what we charge and how the fees are used.    `,
      additionalInfo: `We are also transparent about what we pay our team, because we believe that openness is essential to building trust. Your trust is what we strive for. `,
    },
    // Add more value objects as needed
  ];
  return (
    <>
      {/* <div className="aboutUs_conatiner">
        <center>
          <h1>
            We are building the biggest <br />
            <span style={{ color: "#ff8d1d" }}>ethical ticketing</span> platform
          </h1>
          <p>Create events that make great experiences.</p>
          <img src={img}></img>
        </center>
      </div>
      <div className="about_content_container">
        <center>
          <div className="about_content">
            <div className="about_header_all">
              <h2>About TicketKart: Your Event Success Partner</h2>
              <p>
              At TicketKart, we're not just a ticketing platform; we're your trusted partner in event excellence. Our mission is to empower event organizers of all sizes and types to create, manage, and sell tickets for events that exceed expectations.
              </p>
              <div className="about_card-container">
                {cards.map((card, index) => (
                  <div className="about_card" key={index}>
                    <i className={card.icon}></i>
                    <h3>{card.heading}</h3>
                    <p>{card.details}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </center>
      </div>
      <div className="about_content_us_container">
        <div className="about_us_main_content">
          <div className="img_about1">
            <img
              src="https://source.unsplash.com/user/wsanter/800x400"
              alt=""
            />
          </div>
          <div className="img_about2">
            <img
              src="https://source.unsplash.com/user/wsanter/800x400"
              alt=""
            />
          </div>

          <div className="img_about_container">
            <div className="img_about">
              <img
                src="https://source.unsplash.com/user/wsanter/800x400"
                alt=""
              />
            </div>
            <div className="img_about display_none_img2">
              <img
                src="https://source.unsplash.com/user/wsanter/800x400"
                alt=""
              />
            </div>
          </div>

          <center>
            <div className="about_details">
              <h2>About Us</h2>
              <p>
                Ticketpass is built for those wanting to create and take part in
                memorable events without bad experiences.
                <br />
                <br />
                Whether you are an events organiser or an attendee, at
                Ticketpass our mission is to provide an ethical alternative that
                is fair, friendly and social; and that truly makes for great
                experiences, from planning through to participation.
                <br />
                <br />
                Our journey started with the acceptance that the ticketing
                industry was broken; often swallowed up by greed, profiting from
                high fees, lack of transparency, and rip-off practices.
                Ticketpass founder and CEO Rodrigo Bautista knew that the
                industry could do better. With a second-hand bicycle, he set out
                to prove that he could create a fairer ticketing solution that
                would benefit everyone. That includes you, us, our charity
                partners and even the planet. It’s a big vision. We work hard
                every day to make it a reality.
              </p>
            </div>
          </center>

          <div className="img_about_container2">
            <div className="img_about">
              <img
                src="https://source.unsplash.com/user/wsanter/800x400"
                alt=""
              />
            </div>
            <div className="img_about">
              <img
                src="https://source.unsplash.com/user/wsanter/800x400"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="values_container">
      <div className="values_content">
        <center>
          <h2>Our Values</h2>
          <p>Our values reflect those of a business started by caring people.</p>
        </center>
        <div className="row-s98cus gap-3-s98cus">
          {valuesData.map((value, index) => (
            <div key={index} className="col-lg-5-s98cus values_card">
              <i className={value.icon}></i>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
              <p>{value.additionalInfo}</p>
            </div>
          ))}
        </div>
      </div>
    </div> */}
    {/* <img src={img1} className="aboutus__banner"/> */}
    {/* <div className="terms-containers">
        <h1 style={{fontSize:36}}>About TicketKart: Your Event Success Partner</h1>
      </div> */}
       <div className="container_price_main">
       <div className="container-s98cus pricing-conatiner">
      <h2 className="heading_price">
            <span style={{ color: "#ff8d1d" }}>About TicketKart </span>
            <span style={{ color: "#3265fe" }}>Your Event Success Partner</span>
      </h2>
      <div className="s98cus-img">
        <img src={img1} alt="" />
      </div>
      </div>
       </div>
      
      
      <div className="description containers">
      <div className="aboutUs mt-1" style={{marginTop:40}}>
          <p>At TicketKart, we're not just a ticketing platform; we're your trusted partner in event excellence. Our mission is to empower event organizers of all sizes and types to create, manage, and sell tickets for events that exceed expectations.
        </p>
         </div>

         <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Our Commitment to Event Excellence:</h2>
          <p>Our commitment to the world of events is unwavering. We believe in the power of bringing people together to celebrate, connect, and share experiences. That's why we've dedicated ourselves to providing you with the tools, support, and insights you need to make your events a resounding success.
          </p>
          </div>
          <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Why Choose TicketKart?</h2>
          <p>TicketKart is the result of years of industry expertise and a deep understanding of the challenges you face as an event organizer. Here's why organizers around the world choose TicketKart:
          </p>
          <div style={{ marginInlineStart: 80 }}>
            <ul>
              <li><p><span style={{color:'black'}}>Instant Payouts:</span> We understand the importance of cash flow in event planning. With TicketKart, you receive your funds directly and instantly, so you can invest in your event's success right away.</p></li>
              <li><p><span style={{color:'black'}}>User-Friendly Platform:</span> Our platform is designed with you in mind. From creating event listings to tracking ticket sales, our intuitive tools streamline every aspect of event management.</p></li>

              <li><p><span style={{color:'black'}}>Data-Driven Insights: </span> Harness the power of real-time analytics to make informed decisions and fine-tune your events for maximum engagement and profitability.</p></li>

              <li><p><span style={{color:'black'}}>Referral Rewards:</span> Our commitment to partnership extends to our referral program. Spread the word about TicketKart, and we'll reward you with lifelong commissions for every organizer you refer.</p></li>
            </ul>
          </div>
          </div>

          <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Our Story: From Vision to Reality</h2>
          <p>TicketKart's journey began with a vision to transform the event industry. We saw the opportunity to simplify the ticketing process, providing organizers with immediate access to their funds and valuable insights to optimize their events.
          </p>
          </div>

          <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>A Team of Event Enthusiasts</h2>
          <p>Our founding team comprises dedicated event enthusiasts who understand the heartbeat of events. We've been in your shoes, managing events and striving for excellence. This firsthand experience fuels our passion for making event management easier and more rewarding.
          </p>
          </div>

          <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Driven by Innovation</h2>
          <p>Innovation is at the core of our identity. We're constantly exploring new technologies and strategies to enhance your event planning experience. We take pride in staying ahead of industry trends to provide you with cutting-edge solutions.
          </p>
          </div>
          <div className="aboutUs mt-1">
          <h2 className="terms-h2-style" style={{color:'black'}}>Join Us in Shaping Event Success</h2>
          <p>We invite you to join us on this journey of innovation, partnership, and event success. Together, we'll create events that inspire, entertain, and leave lasting memories. TicketKart is more than a platform; it's a community of event professionals committed to elevating the industry.<br/><br/>
Empower Your Events with TicketKart
          </p>
          </div>
         </div>
   <PricingFooter/>
    </>
    
  );
};

export default AboutUs;
