import React, { useState, useEffect } from 'react';
import { Api_base_url } from "../../../../../config"
import Select from 'antd/lib/select';
import Space from "antd/lib/space"
import dayjs from 'dayjs';

function FilterFeatures({ setEvents, allEvents }) {
  const [filterCount, setFilterCount] = useState(0);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [la_categoryList, setCategoryList] = useState([])
  

  // Function to handle the click event
  function handleLiClick(event) {
    const clickedLi = event.target;
    const allLiElements = document.querySelectorAll('.day-box ul li');
    allLiElements.forEach(li => li.classList.remove('selected'));
    clickedLi.classList.add('selected');
  }

  // Function to handle the "ALL" checkbox toggle
  function toggleAllCheckboxesmb() {
    const checkboxes = document.getElementsByName('eventMbVer');
    const allCheckbox = checkboxes[0];

    if (allCheckbox.checked) {
      for (let i = 1; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    } else {
      for (let i = 1; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }
  }

  // Function to clear all checkboxes
  function clearAllCheckboxesmb() {
    const checkboxes = document.getElementsByName('eventMbVer');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  }

  // Function to apply the selected filters and close the sidebar
  function applyFilters() {
    const filterSidebar = document.getElementById('sidebar');
    filterSidebar.classList.remove('active');
    setIsFilterSidebarOpen(false);

    // Count the number of checked checkboxes and update the count in the filter button label
    const checkboxes = document.querySelectorAll('input[name="eventMbVer"]');
    let checkedCount = 0;
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkedCount++;
      }
    }
    setFilterCount(checkedCount);
  }

  // Function to update the filter count when checkbox clicked
  function updateFilterCount() {
    const checkboxes = document.querySelectorAll('input[name="eventMbVer"]');
    let checkedCount = 0;
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkedCount++;
      }
    }
    setFilterCount(checkedCount);
  }

  
  useEffect(() => {
    getAllCategoryList()
  }, [])

  const getAllCategoryList = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
  };
    
  fetch(Api_base_url+"all-categories", requestOptions)
      .then(response => response.text())
      .then(result => {
          const lo_response = JSON.parse(result)
          // console.log('lo_response',lo_response);
          if (lo_response?.categories) {
              setCategoryList(lo_response.categories)
          }else{
              setCategoryList([])
          }
      })
      .catch(error => console.log('error', error));
  }

  const filterHandler = (e, field) => {
    if (field == "category") {
      if (e.length > 0) {
        // if (e.includes(0)) {
        //   setEvents(allEvents)
        // } else {
          // console.log('la_events', la_events);
          let la_result = allEvents.filter((cat) => e.includes(cat.category_id))
          setEvents(la_result)
        // }
      } else {
        setEvents(allEvents)
      }
      
    } else {
      let value = e.target.id
      let now = dayjs() 
      var isBetween = require('dayjs/plugin/isBetween')
      let la_events
      dayjs.extend(isBetween)
      if (value == "all") {
        setEvents(allEvents)
      } else {
        if (value == "today") {  
          la_events = allEvents.filter((date) => dayjs(now).isBetween(date.starts_date, dayjs(date.ends_date), 'day', '[]'))
          // console.log('diff', la_events);
         } else if (value == "tomorrow") {
         let tomorrow = now.add(1, 'day');
         la_events = allEvents.filter((date) => dayjs(tomorrow).isBetween(date.starts_date, dayjs(date.ends_date), 'day', '[]'))
        //  console.log('diff1', la_events);
        } else if (value == "past") {
          la_events = allEvents.filter(rowData => {
            if (rowData?.ends_date && dayjs(rowData.ends_date).isBefore(dayjs())) {
              return true;
            } else if (rowData?.starts_date && dayjs(rowData.starts_date).isBefore(dayjs())) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          let isSunday = now.day(0)
          let isSaturday = dayjs().day(6)
    
          la_events = allEvents.filter((date) => {
            if ( isSaturday.isBetween(date.starts_date, dayjs(date.ends_date), 'day', '[]') 
              || isSunday.isBetween(date.starts_date, dayjs(date.ends_date), 'day', '[]')) {
            return date 
          }
              
          })
         }
         setEvents(la_events)
      } 
      
    }
  }
  return (
    <div className="short-filter-features border-s98cus border-primary-s98cus">
      <div className="day-select side-select" style={{marginTop:15}}>
        {/* day */}
        <div className="day-box">
          <ul style={{listStyle:'none'}}>
            <span className="day-sort">Day :</span>
            <li id="all" value={'all'} onClick={(e) => filterHandler(e, "date")}>All</li>
            <li id="today" value={'today'} onClick={(e) => filterHandler(e, "date")}>Today</li>
            <li id="tomorrow" value={'tommorow'} onClick={(e) => filterHandler(e, "date")}>Tomorrow</li>
            <li id="weekends" value={'weekends'} onClick={(e) => filterHandler(e, "date")}>Weekend</li>
            <li id="past" value={'past'} onClick={(e) => filterHandler(e, "date")}>Past</li>
            {/* <li id="yesterday" onClick={handleLiClick}>Yesterday</li> */}
          </ul>
        </div>
        {/* day */}
      </div>

      <div className="short-select side-select-filter">
        {/* short by button */}
        {/* <button className="filter-button" id="filterBtn" onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}>
          <i className="fa-solid fa-filter mx-1"></i>
          Filter (<span id="filterCount">{filterCount}</span>)
        </button> */}
        <div className="select-box ">
          {/* <span className="lebel-sort"></span> */}
          {/* <select name="" id="">
            <option value="nowshowing">Now Showing</option>
            <option value="exclusive">Exclusive</option>
            <option value="trending">Trending</option>
            <option value="mostview">Most view</option>
          </select> */}
          <Space direction='horizontal'>
          <span className="lebel-sort">Filter By:</span>
          <Select allowClear mode='multiple' 
              placeholder='Please Select Categories' 
              style={{ width: '250px'}} 
              maxTagCount={'responsive'}
            onChange={(e) => filterHandler(e, "category")}
            >
            {/* <Select.Option value={0}><span style={{ fontSize: 12}}>All</span></Select.Option> */}
            {la_categoryList.map((cat) => {
              return (
                <Select.Option key={cat.id} value={cat.id}><span style={{ fontSize: 12}}>{cat.name}</span></Select.Option>
              )
            })}
          </Select>
          </Space>
         
        </div>
        {/* short by button */}
      </div>

 {/* Filter sidebar */}
      {/* <div className="button">
       
        <div className={`filter-sidebar ${isFilterSidebarOpen ? 'active' : ''}`} id="sidebar">
          <button className="close-button" onClick={() => setIsFilterSidebarOpen(false)}>
            <i className="fa fa-window-close" style={{ fontSize: '26px', marginRight: '10px' }} aria-hidden="true"></i>
          </button>
          <div className="filter-sec">
            <div className="filter-head">
              <div className="left-fil-cont">Filter Section</div>
              <div className="right-fil-cont">
                <button onClick={clearAllCheckboxesmb}>Clear Filters</button>
              </div>
            </div>
            <div className="filter-checkbox">
              <form>
                <label>
                  <input type="checkbox" name="eventMbVer" value="ALL" onClick={toggleAllCheckboxesmb} /> ALL
                </label><br />
                <label>
                  <input type="checkbox" name="eventMbVer" value="SCREENING" /> SCREENING
                </label><br />
                <label>
                  <input type="checkbox" name="eventMbVer" value="SCREENING" /> SCREENING
                </label><br />
                <label>
                  <input type="checkbox" name="eventMbVer" value="SCREENING" /> SCREENING
                </label><br />
                <label>
                  <input type="checkbox" name="eventMbVer" value="SCREENING" /> SCREENING
                </label><br />
               
              </form>
            </div>
            <button className="apply-button" onClick={applyFilters}>Apply</button>
          </div>
        </div>
        
      </div> */}
    </div>
  );
}

export default FilterFeatures;
