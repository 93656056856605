import React, { useState } from "react";
import "./style.css";
import img1 from "../../../../assets/img/pricing/ticket0.png";
import img2 from "../../../../assets/img/pricing/ticket1.png";
import img3 from "../../../../assets/img/pricing/ticket2.png";
import imgn from "../../../../assets/img/pricing/ticketn.png";
import img from "../../../../assets/img/pricing/tk-pricing-bg.jpg";
import PricingFooter from "../../../components/PricingFooter";

const Pricing = () => {
  const [pricingOptions, setPricingOptions] = useState([
    {
      type: "Free Event",
      imageSrc:img1,
      title: "Free events",
      price: "Free",
      featurehead: "",
      features: [
        "A world-class platform for free.",
        "Everything you need to manage your free events.",
        "Use a socially responsible events platform.",
      ],
      buttonName:'Sign Up'
    },
    {
      type: "Paid Event",
      imageSrc:  imgn,
      title: "Paid events",
      // price: "Essentials 1.9% + £0.50",
      price : 'Service Fee 1% + £0.20',
      featurehead:
        "Advanced features for managing your events and boosting sales",
      features: [
        "Minimal Fees on your ticket sales",
        "Payment processing charges 1.5% + 0.20 of each ticket",
        "No hidden cost",
      ],
      buttonName:'Sign Up'

    },
    // {
    //   type: "Paid Event",
    //   imageSrc: img2,
    //   title: "Paid events",
    //   price: "Pro\n 4.8% + £0.50\n(50% kickback for NonProfits)",
    //   featurehead:
    //     "Advanced features for managing your events and boosting sales",
    //   features: [
    //     "Booking fees capped at £14.99",
    //     "50% donated to charities",
    //     "Get all Pro features",
    //   ],
    // },
    {
      type: "Paid Event",
      // imageSrc: img3,
      imageSrc: img3,
      title: "Paid events",
      price: "Enterprise\nCustom pricing",
      featurehead:'',
        // "Advanced features for managing your events and boosting sales",
      features: [
        "Bespoke pricing",
        "Extra discount on fees",
        "Get all Pro features",
      ],
      buttonName:'Contact Sales'

    },
    // Add more pricing options here
  ]);

  return (
    <div>
      <div className="container_price_main">
        <div className="container-s98cus pricing-conatiner">
          <h2 className="heading_price">
            <span style={{ color: "#ff8d1d" }}>Pricing that </span>
            <span style={{ color: "#3265fe" }}>works for you</span>
          </h2>
          <div className="s98cus-img">
            <img src={img} alt="" />
          </div>
          {/* <div className="pricing-pass-content">
            <center>
              <h2>Absorb or pass</h2>
              <p>
                Need to take 100% of your ticket revenue? Ticketpass lets you do
                just that, by passing the fees on to the customer. If you’d
                prefer to absorb these fees so your customer makes a saving
                instead, that’s great too. And if your event is free to attend?
                There’s no charge whatsoever to list it here on Ticketpass.
              </p>
            </center>
          </div> */}
        </div>
      </div>
      <center>
        <h2 className="free_event_heading">
          Free for free events <br />
          Paid events have impact
        </h2>
      </center>
      {/* ... rest of the code */}
      <div className="container_price_main">
        <div className="price_card_container">
          <div className="free_container">
            {pricingOptions.map((option, index) => (
              <div key={index} className="col_price_free2">
                <div className="free_content">
                  <div className="free_event" style={{
                    backgroundColor:option.type == "Free Event" ? 'green':
                    option.type == "Paid Event" && option?.imageSrc==img3 ? 'blue' :'#ff7e00' ,color:'white'
                  }}>{option.type}</div>
                  <div className="free_content1">
                    <div className="free_img2">
                      <img src={option.imageSrc} alt="" />
                    </div>
                    <div className="free_event_content1">
                      <div className="pricing-title">{option.title}</div>
                      <p>{option.price}</p>
                    </div>
                  </div>
                  <div className="free_event_col_6">
                    <ul>
                      {/* <li class="li_head">{option.featurehead}</li> */}
                      {option.features.map((feature, index) => (
                        <li key={index}>
                          <i className="fa fa-circle-check"></i> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="free_event_col_3 free_content3">
                    <a
                      data-v-79921ce0=""
                      href="/signup"
                      className="btn_price"
                    >
                      {/* Get started */}
                    {option.buttonName}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PricingFooter/>
    </div>
  );
};

export default Pricing;
