import React from 'react'
import dayjs from 'dayjs';
import NumberCounter from '../../../../../components/NumberCounter';
import { Tag } from 'antd/lib';
import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const TicketCard = (props) => {
    const {item = {} ,setCheckOut=false, lo_eventDetails={}, la_bookedTickets, setBookedTickets} = props;
    
    const handleSelectTicket = (qtyValue, li_ticketId, ls_ticketName, price, lo_tkItem) => {
        const la_tickets = [...la_bookedTickets];
        let li_index = la_tickets?.findIndex(x => x.ticket_id == li_ticketId);
        if (li_index >= 0) {
            if(qtyValue > 0){
                la_tickets[li_index] = {...la_tickets[li_index], "qty":qtyValue}
            }else{
                la_tickets.splice(li_index, 1);
            }
        }else{
            if(qtyValue > 0) la_tickets.push({"ticket_id":li_ticketId, "ticket_name":ls_ticketName, "qty":qtyValue, "price":price, "tk_price":lo_tkItem.given_cost, "processing_fee":lo_tkItem.processing_fee_per_cost, "is_absorb_fee":lo_tkItem?.absorb_fee || false})
        }
        setBookedTickets(la_tickets)
    }
    // const getTicketDetailsById = (li_ticketId) => {
    //     const la_tickets = [...la_bookedTickets];
    //     const lo_obj = la_tickets.find((element) => element.ticket_id == li_ticketId);
    //     return lo_obj;
    // }

    const lo_ticketObj = la_bookedTickets?.find((element) => element.ticket_id == item.id);

    return (
     
        <div>
            <div className="card" style={{
                flexDirection: 'row', display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div style={{
                    display: 'flex', flexDirection: 'row', width: '100%',

                }}>
                    {/* <div className="card-content">
                        <p style={{ marginBottom: 0, marginTop: 5, color: 'red', textTransform: "capitalize" }}>
                        {dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("MMM")}
                        </p>
                        <h3 style={{ marginBottom: 0 }}>{dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("D")}</h3>
                    </div> */}
                    <div 
                        // className="card-content" 
                        // style={{ paddingInlineStart: 15 }}
                    >
                        <h2 style={{ marginBottom: 0 }}>
                            {item?.ticket_name}
                        </h2>
                        <p style={{ marginBottom: 0, fontSize: 13 }}>
                            {/* {dayjs(item.sales_starts_date, "YYYY-MM-DD").format("ddd, MMMM D")}, {dayjs(item.sales_starts_time, "HH:mm:ss").format("h:mm A")}
                            {' ('+dayjs(item.sales_starts_date, "YYYY-MM-DD").format("YYYY")+')'} */}
                            {'Sale ends on '+dayjs(item.sales_ends_date, "YYYY-MM-DD").format("ddd, MMMM D")}, {dayjs(item.sales_ends_time, "HH:mm:ss").format("h:mm A")}
                            {/* {' ('+dayjs(item.sales_ends_date, "YYYY-MM-DD").format("YYYY")+')'} */}
                        </p>
                        {/* <p style={{ fontSize: 11 }}>{lo_eventDetails?.address_one}</p> */}
                    </div>
                </div>
                {item.sales_starts_date &&  
                dayjs().isAfter(dayjs(item.sales_starts_date+ " " + item.sales_starts_time)) &&
                dayjs().isBefore(dayjs(item.sales_ends_date+ " " + item.sales_ends_time))
                && item.available_quantity > 0 ? 
                <div>
                    <NumberCounter 
                        value={lo_ticketObj ? lo_ticketObj.qty : 0} 
                        onChange={(value) => handleSelectTicket(value, item.id, item.ticket_name, item.cost_to_buyer, item)}
                        // maxValue={item.available_quantity}
                        maxValue={10}
                    />
                    <p style={{ justifyContent: 'center', textAlign: 'center' }}>
                       {/* {item?.ticket_type == "paid" ? "£"+item?.cost_to_buyer : "("+item.ticket_type+")" } */}
                       {/* {item?.ticket_type == "paid" ? <>£ {item?.given_cost}</> : "("+item.ticket_type+")" } */}
                       {item?.ticket_type == "paid" ? <>£ {item?.given_cost} { lo_ticketObj?.qty ? 'x '+lo_ticketObj.qty+' = '+Math.round(((item?.given_cost || 0)*(lo_ticketObj?.qty)) * 100) / 100 : ''}</> : "("+item.ticket_type+")" }
                    </p>
                </div>
                :
                <div>
                    <Tag icon={<ExclamationCircleOutlined />} color="gold">  Sold Out </Tag>
                </div>}
            </div>
            <div style={{ backgroundColor: '#ccc', height: 1, marginTop: 25, marginBottom: 25 }}></div>
        </div>
    )
}

export default TicketCard