import Sider from 'antd/lib/layout/Sider'
import Menu from 'antd/lib/menu'
import React, { Fragment, useEffect } from 'react'
import { getMenuList, getMenuListForUser } from '../SideMenu';
import { Link, useNavigate } from 'react-router-dom';
import { getUserInformationData } from '../../../utility';
import { useState } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType } from '../../../store/common';
import  message  from 'antd/lib/message';


const CustomSider = ({setMobileMenuActive=()=>{}}) => {
  const ls_userType = useSelector( (state) => state.common.userType );
  const ls_token = useSelector( (state) => state.common.token );
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getUserInformationData() 
  const ls_organisationId =  userData?.organisation_id

  const [la_menuList, setMenuList] = useState([]);
  let [ls_openSub, setOpenSub] = useState();
  const [ls_navIndex, setNavndex] = useState(0);
  // const [collapsed, setCollapsed] = useState(true)

  const onClickTopMenu = (e) => {
    // console.log('e.key ', e.key);
    setMobileMenuActive(false)
    if (e.key == "switch-to-attendee") {
      dispatch(setUserType('user'));
      navigate('/dashboard');
      message.success("Switched to Attendee")
    } else if (e.key == "switch-to-organizer") {
      dispatch(setUserType('organizer'));
      navigate('/dashboard');
      message.success("Switched to Organizer")
    } else {
      navigate('/'+e.key);
    }
    // if(e.key)
  };

  const onOpenChange = (item) => {
    const la_temMenuInx = []
    item.map(item => {
      la_temMenuInx.push(item)
    })
    setOpenSub(la_temMenuInx)
  }

  useEffect(() => {
    let la_tempMenuList = [];
    if (ls_userType == 'organizer') {
      la_tempMenuList = getMenuList();
    }else if (ls_userType == 'user') {
      la_tempMenuList = getMenuListForUser();
    }
    setMenuList(la_tempMenuList);
    
    // const la_tempTopMenuList = getTopMenu();
    // setTopMenuList(la_tempTopMenuList);
  }, [ls_userType])
  useEffect(() => {
    const la_urlPath = window.location.pathname;
    const la_tempMenuInx = [];
    let index1 = la_menuList.findIndex(e => e.subMenu && e.subMenu.findIndex(ee => ee.path === la_urlPath) != -1)
    setOpenSub(index1);
    if (index1 == -1) {
      const ls_navIndex = la_menuList.findIndex(x => x.path == la_urlPath);
      setNavndex(ls_navIndex);
      setOpenSub([])
    }
    else {
      la_tempMenuInx.push('' + index1)
      const li_index2 = la_menuList[index1].subMenu.findIndex(e => e.path === la_urlPath)
      setNavndex(index1 + "_" + li_index2)
      setOpenSub(la_tempMenuInx)
    }

  }, [la_menuList]);

  return (
    <>
      {ls_token ? 
        // <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        //   {/* <div className="demo-logo-vertical" /> */}
        //   <Menu onClick={onClickTopMenu} theme="dark" defaultSelectedKeys={['dashboard']} mode="inline" items={la_menuList} />
        // </Sider> 
        <Menu onClick={onClickTopMenu} theme="dark" defaultSelectedKeys={['1']} mode="inline"  
        items={la_menuList} 
        onOpenChange={onOpenChange} openKeys={ls_openSub} selectedKeys={'' + ls_navIndex}/>
        : <></>}
    </>
  )
}

export default CustomSider