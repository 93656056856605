import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Upload from "antd/lib/upload"
import TextArea from "antd/lib/input/TextArea";
import "./style.less";
import DatePicker from "antd/lib/date-picker";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import Radio from "antd/lib/radio";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import Space from "antd/lib/space";
import InputNumber from "antd/lib/input-number";
import message from "antd/lib/message";
import { useParams } from "react-router";
import { TimePicker } from "antd/lib";
import AutoCompleteLocation from "../../../../components/AutoCompleteLocation";
import { getCountryIdByCountryName, isExistProfanity, objectToFormData } from "../../../../utility";
import { Api_base_url, MaxTagLength, MaxTags } from "../../../../../config";
import GoogleMaps from "../../../../components/GoogleMap";
import { Typography } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Spin from "antd/lib/spin";
// import moment from "moment";
import dayjs from 'dayjs';
import { getPublicRequestApi, getRequestApi } from "../../../../../api/commonRequest";

const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

const BasicInfo = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const addressOne = Form.useWatch('address_one', form);
    const startTime = Form.useWatch('starts_time', form);
    const endTime = Form.useWatch('ends_time', form);
    const ls_vanueType = Form.useWatch('venu_type', form);

    const ls_token = localStorage.getItem('token')
    const ls_organisationId = localStorage.getItem('organisation_id')
    const dateFormat = "DD/MM/YYYY";
    const timeFormat = 'HH:mm';
    const lo_params = useParams()
    const key = 'update';
    const [showMobileFiled, setShowMobileFiled] = useState(false)
    const [lb_loading, setLoading] = useState(false)
    // const lo_currentCompanyDetails = useSelector(state => state.common.selected_company);
    // const ls_companyId = lo_currentCompanyDetails.company_id;
    const { slug } = lo_params
    const { copySlug } = lo_params
    // const [ls_updateId, setUpdateId] = useState('')
    const [ls_slug, setSlug] = useState('')

    const [la_eventType, setEventType] = useState([])
    const [la_categoryList, setCategoryList] = useState([])
    const [la_subCategoryList, setSubCategoryList] = useState([])
    const [la_tagList, setTagList] = useState([])
    const [la_countryList, setCountryList] = useState([]);
    const [la_timezoneList, setTimezoneList] = useState([])
    // const [ls_vanueType, setVanueType] = useState('vanue');

    const [ls_address, setAddress] = useState('');


    const [ls_eventTitle, setEventTitle] = useState('');
    const [ls_category, setCategoryId] = useState('');
    const [ls_subCategory, setSubCategoryId] = useState('');
    const [ls_eventType, setEventTypeId] = useState('');
    const [ls_tagValueName, setTagValueName] = useState('');

    const [ls_categoryName, setCategoryIdName] = useState('');
    const [ls_subCategoryName, setSubCategoryIdName] = useState('');
    const [ls_eventTypeName, setEventTypeIdName] = useState('');
    const [lb_spin, setSping] = useState(true)

    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    const getCountryList = async () => {
        const lo_response = await getPublicRequestApi("all-countries");
        if (lo_response?.countries) {
            setCountryList(lo_response.countries)
        } else {
            setCountryList([])
        }
    }

    const getAndSetEventType = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        fetch(Api_base_url+"all-event-types", requestOptions)
            .then(response => response.text())
            .then(result => {
                const lo_response = JSON.parse(result)
                console.log('lo_response',lo_response);
                if (lo_response?.event_types) {
                    setEventType(lo_response.event_types)
                }else{
                    setEventType([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const getAndSetCategoryList = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        fetch(Api_base_url+"all-categories", requestOptions)
            .then(response => response.text())
            .then(result => {
                const lo_response = JSON.parse(result)
                console.log('lo_response',lo_response);
                if (lo_response?.categories) {
                    setCategoryList(lo_response.categories)
                }else{
                    setCategoryList([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const getAndSetSubCategoryList = () => {
        if (!ls_category) {
            setSubCategoryList([]); return;
        }
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(Api_base_url+"all-subcategory/"+ls_category, requestOptions)
            .then(response => response.text())
            .then(result => {
                const lo_response = JSON.parse(result)
                console.log('lo_response',lo_response);
                if (lo_response?.sub_categories) {
                    setSubCategoryList(lo_response.sub_categories)
                }else{
                    setSubCategoryList([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const getAndSetTagsList = (serachText) => {
        const options = [];
        if (!serachText) {
            setTagList(options)
            return
        }
        for (let i = 10; i < 36; i++) {
            let ls_optionValue = i.toString(36) + i;
            if (ls_optionValue.includes(serachText)) {
                options.push({
                    value: ls_optionValue,
                    label: i.toString(36) + i,
                });
            }
        }

        setTagList(options)
    }
    // handleTagSearch
    const handleTagSearch = (newValue) => {
        getAndSetTagsList(newValue);
    };

    
    const getAndSetTimezoneList = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        fetch(Api_base_url+"all-timezones", requestOptions)
            .then(response => response.text())
            .then(result => {
                const lo_response = JSON.parse(result)
                console.log('lo_response',lo_response);
                if (lo_response?.timezones) {
                    setTimezoneList(lo_response.timezones)
                }else{
                    setTimezoneList([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const [ld_selectedEndDate, setSelectedEndDate] = useState(null)
    const [ld_selectedStartDate, setSelectedStartDate] = useState(null)
    const handleOnChangeEndDate = (date, dateString) => {
        // console.log('date',date);
        let ld_endDt = null
        if (date) {
            ld_endDt = new Date(date.toDate())    
        }
        setSelectedEndDate(ld_endDt)
    }
    const handleOnChangeStartDate = (date, dateString) => {
        let ld_startDt = null
        if (date) {
            ld_startDt = new Date(date.toDate())    
        }
        setSelectedStartDate(ld_startDt)
    }
    
    const onSubmitForm = async (lo_formValue) => {
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...',});
        setLoading(true)
        // console.log('lo_formValue.tags',lo_formValue);

        const lo_formData = { ...lo_formValue };
        // lo_formData['starts_date'] = lo_formData.starts_date.toISOString().slice(0, 10);
        // lo_formData['ends_date'] = lo_formData.ends_date.toISOString().slice(0, 10);

        lo_formData['starts_date'] = dayjs(lo_formData.starts_date).format("YYYY-MM-DD");
        lo_formData['ends_date'] = dayjs(lo_formData.ends_date).format("YYYY-MM-DD");

        lo_formData['starts_time'] = new Date(lo_formData.starts_time).toTimeString().split(' ')[0];
        lo_formData['ends_time'] = new Date(lo_formData.ends_time).toTimeString().split(' ')[0];

        if (!lo_formData.display_end_time) lo_formData['display_end_time'] = 0;
        if (!lo_formData.display_start_time) lo_formData['display_start_time'] = 0;
        if (!lo_formData?.subcategory_id) { lo_formData['subcategory_id'] = '';};
        if (!lo_formData?.address_two) { lo_formData['address_two'] = '';};
        
        // console.log('---lo_formData',lo_formData);

        if (isErrorOnTimeValidation(lo_formData.starts_date, lo_formData.ends_date, lo_formData.starts_time, lo_formData.ends_time)) {
            messageApi.destroy()
            message.error('Start Date should be before End Date!');
            setLoading(false)
            return;
        }

        const la_allInputText = Object.values(lo_formValue)
        const li_profinityCount =  await isExistProfanity(la_allInputText)
        if (li_profinityCount && li_profinityCount > 0) {
        messageApi.destroy()
        message.error('You can not use any slang / bad language!');
        setLoading(false)
        return;
        }

        if (ls_slug && !copySlug) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer "+ls_token);
            const formdata = objectToFormData(lo_formData);
            formdata.append("organisation_id", ls_organisationId);
            console.log('lo_formData',lo_formData);
            let requestOptions = {
                // mode: 'no-cors',
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
            
            fetch(Api_base_url+"event-general-info-update/"+ls_slug, requestOptions)
            .then(response => response.text())
            .then(result => {
                messageApi.destroy()
                setLoading(false)
                console.log('result1',JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    getSpecificEventDetails();
                    // form.resetFields()
                    message.success("Event Updated Succesfully!")
                    navigate("/events/update/"+ls_slug+"/details")
                    // setVanueType(null)
                    // setResponseMessage({})
                }else {
                    // setResponseMessage(lo_response.message)
                    message.error('Validation Error!!')
                }
            })
            .catch(error => {
                messageApi.destroy()
                setLoading(false)
                console.log('error', error)
                message.error('Something Wrong!!')
            });
        } else {

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+ls_token);
            const formdata = objectToFormData(lo_formData);
            formdata.append("organisation_id", ls_organisationId);
            // setResponseMessage({})
            console.log('lo_formData',lo_formData);
            let requestOptions = {
                // mode: 'no-cors',
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
            
            fetch(Api_base_url+"event-create", requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false)
                console.log('result',JSON.parse(result))
                
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    form.resetFields()
                    message.success("Event Created Succesfully!")
                    // setVanueType(null)
                    navigate("/events/update/"+lo_response.event.slug+"/details")
                // setResponseMessage({})
                }else {
                // setResponseMessage(lo_response.message)
                message.error('Validation Error!!')
                }
            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)
                message.error('Something Wrong!!')
            });
        }
    }

    const getSpecificEventDetails = async () => {
        setSping(true)
        if (!ls_slug) {
            const defaultDate = dayjs().add(2, "month").endOf('day');
            form.setFieldsValue({ 'starts_date': defaultDate });
            form.setFieldsValue({ 'ends_date': defaultDate });
        }else{
            const lo_eventDetails = await getUpdateData();
            const lo_formData = {};
            lo_formData['name'] = lo_eventDetails.name;
            lo_formData['type_id'] = lo_eventDetails.type_id;
            lo_formData['category_id'] = lo_eventDetails.category_id;
            lo_formData['subcategory_id'] = lo_eventDetails.subcategory_id;
            if(lo_eventDetails?.tags) lo_formData['tags'] = lo_eventDetails.tags.split(",");
            lo_formData['venu_type'] = lo_eventDetails.venu_type;
            lo_formData['address_one'] = lo_eventDetails.address_one;
            lo_formData['address_two'] = lo_eventDetails.address_two;
            lo_formData['city'] = lo_eventDetails.city;
            lo_formData['post_code'] = lo_eventDetails.post_code;
            lo_formData['country_id'] = lo_eventDetails.country_id;
            lo_formData['online_link'] = lo_eventDetails.online_link;
            lo_formData['starts_date'] = dayjs(lo_eventDetails.starts_date);
            lo_formData['starts_time'] = dayjs(lo_eventDetails.starts_time, "HH:mm:ss");
            lo_formData['ends_date'] = dayjs(lo_eventDetails.ends_date);
            lo_formData['ends_time'] = dayjs(lo_eventDetails.ends_time, "HH:mm:ss");
            lo_formData['display_start_time'] = lo_eventDetails.display_start_time;
            lo_formData['display_end_time'] = lo_eventDetails.display_end_time;
            lo_formData['timezone_id'] = lo_eventDetails.timezone_id;
            form.setFieldsValue(lo_formData);

            setCategoryId(lo_eventDetails.category_id);
        }
        
        setSping(false);
    }

    const setmapRes = async (lo_value) => {
        let ls_country = null;
        if (lo_value?.country) {
            ls_country = await getCountryIdByCountryName(lo_value.country, la_countryList)
        }
        form.setFieldsValue({ 'city': lo_value.city });
        form.setFieldsValue({ 'post_code': lo_value.postalCode });
        form.setFieldsValue({ 'country_id': ls_country });
    }
    const setFullAddress = (value) => {
        console.log('setFullAddress',value);
        form.setFieldsValue({ 'address_one': value });
    }

    const eventTagCreate = () => {
        console.log('---la_subCategoryList',la_subCategoryList);
        // get the event title from an API or props
        const title = ls_eventTitle+' '+ls_eventTypeName+' '+ls_categoryName+' '+ls_subCategoryName;
        // split the title into words and filter out common words or symbols
        const commonWords = ["a", "an", "the", "to", "using", "how", "and", "&", "or", "", " ", "  "];
        const initialTags = title
        .split(" ")
        .filter((word) => (!commonWords.includes(word.toLowerCase()) && isNaN(word)))
        .map((word) => {
            let ls_tag = word.toLowerCase();
            ls_tag = ls_tag.replace(",","");
            if (ls_tag.length > 1 && ls_tag.length <= MaxTagLength) {
                return ls_tag;
            }
        });

        let la_allTags = [...initialTags]
        if (initialTags.length > MaxTags) {
            la_allTags = initialTags.slice(0, MaxTags);
        }

        let set = new Set(la_allTags); // a set with unique values
        la_allTags = [...set];

        setTagValueName(la_allTags)

        console.log('++++initialTags',la_allTags);
        form.setFieldsValue({ 'tags': la_allTags });
        // update the state with the title and initial tags
        // this.setState({
        // title,
        // initialTags
        // });
        // }
    }

    // const handleSetTagValues = (text) => {
    //     setTagValueName((old) => old+" "+text)
    // }

    // const range = (start, end) => {
    //     const result = [];
    //     for (let i = start; i < end; i++) {
    //       result.push(i);
    //     }
    //     return result;
    // };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    // const disabledDateTime = () => ({
    //     disabledHours: () => range(0, 24).splice(4, 20),
    //     disabledMinutes: () => range(30, 60),
    //     // disabledSeconds: () => [55, 56],
    // });

    const handleChangeTags = (tagValues) => {
        console.log('tagValues tags', tagValues);
        let la_tagValues = [...tagValues]
        let newTag = la_tagValues[la_tagValues.length-1]
        if (newTag.length > MaxTagLength) {
            la_tagValues.pop()
            form.setFieldsValue({ 'tags': la_tagValues });
            message.error(`Tag length can not be more than ${MaxTagLength} !`)
        }
        if (la_tagValues.length > MaxTags) {
            la_tagValues = la_tagValues.slice(0, MaxTags);
            form.setFieldsValue({ 'tags': la_tagValues });
            message.error(`You can not add more than ${MaxTags} tag !`)
        }
    }

    const getUpdateData = async () => {
        if(!ls_slug) return;
        const lo_eventDetails = await getRequestApi("events/"+ls_slug);
        console.log('-----|| lo_eventDetails',lo_eventDetails);
        return lo_eventDetails.event;
    }

    const isErrorOnTimeValidation = (starts_date, ends_date, starts_time, ends_time) => {
        const start1 = dayjs(starts_date + " " + starts_time);
        const end1 = dayjs(ends_date + " " + ends_time);
        return !start1.isBefore(end1);
    };

    useEffect(() => {
        if (ls_eventTitle && ls_categoryName && ls_eventTypeName && !ls_tagValueName) {
            eventTagCreate()
        }
    }, [ls_eventTitle, ls_categoryName, ls_eventTypeName])

    useEffect(() => {
        // getSpecificEventDetails()
        getAndSetEventType()
        getAndSetCategoryList()
        // getAndSetSubCategoryList()
        // getAndSetTagsList()
        getCountryList()
        getAndSetTimezoneList()
    }, [])

    useEffect(() => {
        if(slug) setSlug(slug)
        if(copySlug) setSlug(copySlug)
    }, [slug, copySlug])

    useEffect(() => {
        getSpecificEventDetails()
    }, [ls_slug])

    useEffect(() => {
        setAddress(addressOne);
    }, [addressOne])
    useEffect(() => {
        getAndSetSubCategoryList()
    }, [ls_category])

    useEffect(() => {
        if(startTime) {
            form.setFieldsValue({ 'display_start_time': 1 });
        }else{
            form.setFieldsValue({ 'display_start_time': 0 });
        }
        if(endTime) {
            form.setFieldsValue({ 'display_end_time': 1 });
        }else{
            form.setFieldsValue({ 'display_end_time': 0 });
        }
    }, [JSON.stringify(startTime), JSON.stringify(endTime)])

    return (
        <div className="basicinfo-parent">
            {(ls_slug && !copySlug) ? <></> :
            <Col xs={24} sm={24} md={24} lg={24} className="header">
                <Row className="form-header">
                <Col  xs={17} sm={18} md={16} lg={16} >
                    <Typography.Title  
                    className="title"
                    level={4}
                    >{copySlug ? "Copy" : "Add"} Event</Typography.Title>
                   </Col>
                   <Col  xs={1} sm={2} md={6} lg={6}></Col>
                   <Col  xs={6} sm={4} md={2} lg={2}>
                   <Link to="/events" key="1">
                    <Button key="2" className='back-button' icon={<ArrowLeftOutlined />}>
                        Back
                    </Button>
                </Link>
                    </Col>
                </Row>
                </Col>
             
            }
            {/* <div className="form-header" 
            style={{marginTop:-26,marginInlineStart:-23,width:1138}}>
                <Typography.Title level={4}>Add Events</Typography.Title>
                <Link to="/events" key="1">
                    <Button key="2" className='back-button' icon={<ArrowLeftOutlined />}>
                        Back
                    </Button>
                </Link>
            </div> */}
            <Spin spinning={lb_spin}>
                <Form
                    className="content-form"
                    form={form}
                    labelCol={{
                        span: 24
                    }}
                    onFinish={onSubmitForm}
                    // {...formItemLayout}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            {/* <Divider orientation="left" plain > */}
                            
                            <Typography.Title type="secondary" style={{marginTop:15}} level={3}>Basic Info</Typography.Title>
                            {/* </Divider> */}
                        </Col>
                       
                        
                        {/* <Col span={24} className="form-input-radio-group">
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                label="Image"
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                // maxCount={1}
                                >
                                    <Button className="text-button" type="text" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>

                        </Col> */}
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                label="Event Title"
                                placeholder="Event Title"
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                        // message: "Please input!",
                                    },
                                ]}
                            >
                                <Input maxLength={75} showCount onChange={(value, event) => setEventTitle(value.target.value)} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                //labelCol={{ span: 8 }}
                                label="Event Type"
                                name="type_id"
                                rules={[ { required: true },]}
                                
                            >
                                <Select placeholder="Select Event Type"
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={(value, event) => {
                                        setEventTypeId(value, event); setEventTypeIdName(event?.children)
                                    }}
                                >
                                    {
                                        la_eventType && la_eventType.map(rowType => <Select.Option value={rowType.id} key={rowType._id}>
                                            {rowType.name}
                                        </Select.Option>)

                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* la_subCategoryList, la_categoryList */}

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Item
                                // labelCol={{ span: 8 }}
                                label="Category"
                                name="category_id"
                                rules={[ { required: true }, ]}
                                style={{marginInlineEnd:20}}
                            >
                                <Select placeholder="Select Category"
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={(value, event) => {setCategoryId(value); setCategoryIdName(event?.children)}}
                                >
                                    {
                                        la_categoryList && la_categoryList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>
                                            {rowValue.name}
                                        </Select.Option>)

                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Item
                                //labelCol={{ span: 8 }}
                                label="Sub-Category"
                                name="subcategory_id"
                                // rules={[ { required: true }, ]}
                                style={{marginInlineStart:20}}
                                
                            >
                                <Select placeholder="Select Category"
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={(value, event) => {setSubCategoryId(value); setSubCategoryIdName(event?.children)}}
                                >
                                    {
                                        la_subCategoryList && la_subCategoryList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>
                                            {rowValue.name}
                                        </Select.Option>)

                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} className="tagselection">
                            <Form.Item
                                //labelCol={{ span: 8 }}
                                label="Tags"
                                name="tags"
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                                tooltip={{
                                    title: 'You can add maximum 15 tags, each tags can contain maximum upto 25 character, duplicate tags will be removed automatically',
                                    icon: <InfoCircleOutlined />,
                                }}
                            >
                                

                                <Select
                                    onSearch={handleTagSearch}
                                    placeholder="Select Tags"
                                    mode="tags"
                                    style={{
                                    width: '100%',
                                    }}
                                    // onChange={handleChange}
                                    tokenSeparators={[',']}
                                    options={la_tagList}
                                    maxTagTextLength={25}
                                    maxTagCount={15}
                                    onChange={handleChangeTags}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            {/* <Divider orientation="left" plain > */}
                            
                            <Typography.Title type="secondary" style={{marginTop:15}} level={3}>Location</Typography.Title>
                            {/* </Divider> */}
                        </Col>
                            
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                labelAlign="right"
                                rules={[ { required: true, message: "Please input Venue Type!", }]}
                            // labelCol={{ span: 8 }}
                            // label="Address"
                            name="venu_type"
                            >
                                <Radio.Group autoFocus={true}  buttonStyle="solid" 
                                // onChange={(e) => setVanueType(e?.target?.value)}
                                >
                                    <Radio.Button value="vanue">Venue</Radio.Button>
                                    <Radio.Button value="online">Online</Radio.Button>
                                    <Radio.Button value="to-be-announced">To be announced</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        {ls_vanueType == "vanue" ? 
                            <Col span={24}>
                                <Form.Item
                                    labelAlign="right"
                                // labelCol={{ span: 8 }}
                                // label="Address"
                                >
                                    <div style={{marginBottom:8}}>
                                    <AutoCompleteLocation setmapRes={setmapRes} setFullAddress={setFullAddress} />
                                    </div>
                                    <Input.Group>
                                        <Row className="mb8-xs" style={{marginBottom:8}} justify="space-between">
                                            <Form.Item
                                                name={['address_one']}
                                                noStyle
                                                rules={[ { required: true, message: "Address Required!", }]}
                                            >
                                                <Input style={{ width: '50%', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} placeholder="Address 1" />
                                            </Form.Item>
                                            <Form.Item
                                                name={['address_two']}
                                                noStyle
                                            >
                                                <Input style={{ width: '50%', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} placeholder="Address 2" />
                                            </Form.Item>
                                        </Row>
                                        <Row className="address-second-group" style={{marginBottom:8}}>
                                            <Form.Item
                                                name={['city']}
                                                noStyle
                                                rules={[ { required: true, message: "City Required!", }]}
                                            >
                                                <Input style={{ width: '33.3%', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} placeholder="City" />
                                            </Form.Item>

                                            <Form.Item
                                                name={['post_code']}
                                                noStyle
                                                rules={[ { required: true, message: "Postcode Required!", }]}
                                            >
                                                <Input style={{ width: '33.3%', borderRadius: '0px' }} placeholder="Post Code" />
                                            </Form.Item>
                                            <Form.Item
                                                name={['country_id']}
                                                noStyle
                                                rules={[ { required: true, message: "Country Required!", }]}
                                            >
                                                <Select className="country__select" style={{ width: '33.3%' }} placeholder="Select Country"
                                                    allowClear showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        la_countryList && la_countryList.map((countryItem, countryIndex) => {
                                                            return (
                                                                <Select.Option key={countryIndex} value={countryItem.id}>{countryItem.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Row>
                                    </Input.Group>
                                </Form.Item>
                            </Col> 
                        : <></>}

                        {ls_vanueType == "online" ? 
                            <Col span={24}>
                                <Form.Item
                                    labelAlign="right"
                                    // labelCol={{ span: 8 }}
                                    label="Online Event Link"
                                    name='online_link'
                                    rules={[
                                        {
                                        required: true,
                                        },
                                        {
                                        type: 'url',
                                        warningOnly: true,
                                        },
                                        {
                                        type: 'string',
                                        min: 6,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Online Link" />
                                </Form.Item>
                            </Col>
                        : <></>}
                        {ls_address ? <Col span={24}>
                            <GoogleMaps ls_address={ls_address} />
                        </Col> : <></>}
                        
                        {/* <Col span={24}>
                            <Divider orientation="left" plain><Typography.Title type="secondary" level={3}>Date and time</Typography.Title></Divider>
                        </Col> */}
                        <Col xs={24} sm={24} md={24} lg={24}>
                            {/* <Divider orientation="left" plain > */}
                            
                            <Typography.Title type="secondary" style={{marginTop:15}} level={3}>Date and time</Typography.Title>
                            {/* </Divider> */}
                        </Col>
                        {/* <Col span={14}/> */}
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                labelAlign="right"
                                hidden={true}
                            // labelCol={{ span: 8 }}
                            // label="Address"
                            // name="schedule_type"
                            >
                                <Radio.Group autoFocus={true} defaultValue="single-event" buttonStyle="solid" 
                                // onChange={(e) => setVanueType(e?.target?.value)}
                                >
                                    <Radio.Button value="single-event">Single Event</Radio.Button>
                                    {/* <Radio.Button value="recurring-event">Recurring Event</Radio.Button> */}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                        <Row justify={"space-between"}>
                            <Col xs={12} sm={12} md={12} lg={6} 
                                    style={{marginInlineEnd:10}}
                            >
                                <Form.Item
                                    // labelAlign="right"
                                    // labelCol={{ span: 12 }}
                                    name="starts_date"
                                    label="Start Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your date!",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        // disabled={true}
                                        placeholder="Select Start Date"
                                        format={dateFormat}
                                        style={{ width: "-webkit-fill-available" }}
                                        // onChange={handleOnChangeStartDate}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={3} 
                            style={{marginTop:40,marginInlineStart:5}}
                            
                            >
                                <Form.Item
                                    // name="starts_date"
                                    name={['starts_time']}
                                    // style={{
                                    //     display: 'inline-block',
                                    //     width: 'calc(50% - 8px)',
                                    // }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required Start Time",
                                        },
                                    ]}
                                    
                                >
                                    <TimePicker placeholder='From' format={timeFormat} minuteStep={5} />
                                </Form.Item>
                            </Col>
                            

                            <Col xs={12} sm={12} md={12} lg={6} className="col-left-form-gap"
                            style={{marginInlineEnd:10}}
                            >
                                <Form.Item
                                    labelAlign="right"
                                    // labelCol={{ span: 12 }}
                                    name="ends_date"
                                    label="End Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your date!",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        // disabled={lo_RepetitionDetailRecord.repetition_details && !lo_RepetitionDetailRecord.repetition_details.is_expandable}
                                        placeholder="Select End Date"
                                        format={dateFormat}
                                        style={{ width: "-webkit-fill-available" }}
                                        // onChange={handleOnChangeEndDate}
                                        // suffixIcon={false}
                                        // prefix={<CalendarOutlined />}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3} 
                            style={{marginTop:40,marginInlineStart:5}}>
                                <Form.Item
                                    // name="starts_date"
                                    name={['ends_time']}
                                    // style={{
                                    //     display: 'inline-block',
                                    //     width: 'calc(50% - 8px)',
                                    // }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required End Time",
                                        },
                                    ]}
                                >
                                    <TimePicker placeholder='To' format={timeFormat} minuteStep={5} />
                                </Form.Item>
                            </Col>
                        </Row>
                       </Col>
                        <Col span={24}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name="display_start_time"
                                        valuePropName="checked"
                                        // wrapperCol={{
                                        //     offset: 8,
                                        //     span: 16,
                                        // }}
                                        >
                                        <Checkbox>Display start time. (The start time of your event will be displayed to attendees.)</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item
                                        name="display_end_time"
                                        valuePropName="checked"
                                        // wrapperCol={{
                                        //     offset: 8,
                                        //     span: 16,
                                        // }}
                                        >
                                        <Checkbox>Display end time. (The end time of your event will be displayed to attendees.)</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                //labelCol={{ span: 8 }}
                                label="Time Zone"
                                name="timezone_id"
                                rules={[ { required: true },]}
                                
                            >
                                <Select placeholder="Select Time Zone"
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {
                                        la_timezoneList && la_timezoneList.map(rowType => <Select.Option value={rowType.id} key={rowType._id}>
                                            {rowType.name}
                                        </Select.Option>)

                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={24} className="form-input-radio-group">
                            <Form.Item
                                labelAlign="right"
                                label="Gender"
                                name='gender'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Gender!",
                                    },
                                ]}
                            >
                                <Radio.Group >
                                    <Space size={40} align='baseline'>
                                        <Radio value="male">Male</Radio>
                                        <Radio value="female">Female</Radio>
                                        <Radio value="other">Other</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col> */}
                    

                    </Row>
            
                    {/* <Row gutter="30">
                        <Col span={24}>
                            <Divider className="mt-0" orientation="left" plain>Status info</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                labelAlign="right"
                                label="Status"
                                name='status'
                                form={form}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Status!",
                                    },
                                ]}
                            >
                                <Select placeholder="Select">
                                    <Select.Option value="a" key='1'>Active</Select.Option>
                                    <Select.Option value="d" key='2'>Draft</Select.Option>
                                    <Select.Option value="ar" key='3'>Archived</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> */}
                
                    <Row>
                        <Col span={24}>
                            <Divider className="mt-0" orientation="left" plain></Divider>
                        </Col>
                    </Row>
                    <div style={{ textAlign:"center" }}>
                        <Button 
                            loading={lb_loading} type="primary" htmlType="submit" block
                            size="large"
                            // icon={<CheckOutlined />}
                            style={{width:"30%"}}
                            className="add-btn"
                        >
                            {ls_slug ? (copySlug ? "Copy" : "Update") : "Add"}
                        </Button>
                    </div>
                </Form>
            </Spin>
            

            <Divider style={{marginTop:58}} />
            

        </div>
    )
}
export default BasicInfo