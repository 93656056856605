import React, { useEffect, useState } from "react";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import "./style.less";
import DatePicker from "antd/lib/date-picker";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import Radio from "antd/lib/radio";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import Space from "antd/lib/space";
import InputNumber from "antd/lib/input-number";
import message from "antd/lib/message";
import { useParams } from "react-router";
import { Spin, TimePicker } from "antd/lib";
import AutoCompleteLocation from "../../../../components/AutoCompleteLocation";
import { objectToFormData } from "../../../../utility";
import { Api_base_url } from "../../../../../config";
import GoogleMaps from "../../../../components/GoogleMap";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import dayjs from 'dayjs';
import { getRequestApi } from "../../../../../api/commonRequest";
import CreateTicket from "./CreateTicket";
import Alert from "antd/lib/alert/Alert";

const TicketDetails = () => {
    const [form] = Form.useForm();
    // const addressOne = Form.useWatch('address_one', form);
    // const ls_token = localStorage.getItem('token')
    const ls_organisationId = localStorage.getItem('organisation_id')
    const lo_params = useParams()
    const { slug } = lo_params
    const [ls_eventId, setEventId] = useState(null);
    const [lo_eventDetails, setEventDetails] = useState({});
    const [lb_spin, setSping] = useState(true)

    const getUpdateData = async () => {
        if(!slug) return;
        const eventDetails = await getRequestApi("events/"+slug);
        
        console.log('lo_eventDetails',eventDetails);
        if (eventDetails?.event?.id) {
            setEventId(eventDetails.event.id)
            setEventDetails(eventDetails.event)
        }else{
            setEventId(null)
            setEventDetails({})
        }
        setSping(false)
    }

    useEffect(() => {
        console.log('slug && ls_organisationId',slug, ls_organisationId);
        if(slug && ls_organisationId) getUpdateData()
    }, [ls_organisationId, slug])
    
    const lo_props = {ls_organisationId, ls_eventId, lo_eventDetails};
    return (
        
        <div>
            <Divider orientation="center" plain><Typography.Title type="secondary" level={5}>Event Tickets</Typography.Title></Divider>

            <Alert
                // message="Tips"
                description={<Typography.Text><span style={{fontSize:16,fontWeight:700, color:'#c09403'}}>Tips:</span> You must add <Link to={'/create-stripe-account'}>payment method</Link> before publishing your paid events to receive automated payout. Once you are done adding tickets, please click on 'Stripe Account' and link to stripe. You can only publish paid events once your stripe is connected.</Typography.Text>}
                type="warning" closable
            />

            <Spin spinning={lb_spin}>
            {(ls_organisationId && slug && ls_eventId && lo_eventDetails?.id && !lb_spin) ? <CreateTicket {...lo_props} /> : <></>}
            </Spin>
        </div>
    )
}
export default TicketDetails