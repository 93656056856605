import React, { useEffect, useState } from 'react';
import "./style.css";
// import logo from "../../image/logo.png"
import logImg from "../../../image/log.png"
// import registerImg from "../../../image/register.png"
import logo from "../../../image/logo.png"

import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, message, Tooltip } from 'antd/lib';
import { Api_base_url } from '../../../../config';
import InfoCircleOutlined  from "@ant-design/icons/InfoCircleOutlined";

const ChangePassword = () => {
    const lo_params = useParams();
    const ls_encriptionId = lo_params?.id;
    let navigate = useNavigate();

    const [isShown, setIsSHown] = useState(false);
    const [lo_responseMessage, setResponseMessage] = useState({})
    const [ls_password, setPassword] = useState('')
    const [ls_passwordConfirmation, setPasswordConfirmation] = useState('')
    const [lb_loading, setLoading] = useState(false)
    const [ls_successMsg, setSuccessMsg] = useState('')
    
    const ls_passwordRules = 
    "Password format rule :  (lowercase characters (a – z) | uppercase characters (A – Z) ) | digits (0 – 9) | Non-alphanumeric (For example: !, $, #, or %)";

    const handleSubmitChangePassword = (e) => {
        e.preventDefault();
        setLoading(true)
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
          };
          
        // fetch(Api_base_url+"send-link-forget-password?email="+ls_email, requestOptions)
        fetch(Api_base_url+"change-password?encryption_key="+ls_encriptionId+"&password="+ls_password+"&password_confirmation="+ls_passwordConfirmation, requestOptions)
        .then(response => response.text())
        .then(result => {
            const lo_response = JSON.parse(result)
            console.log('lo_response',lo_response);
            setLoading(false)
            if (lo_response.status == 'success') {
                setPassword('')
                setPasswordConfirmation('')
                message.success("Your password changed successfully! Now you can login with your new password.");
                setResponseMessage({})
                navigate('/signup')
              }else {
                setResponseMessage(lo_response.message)
                message.error("Validation Error!")
              }
        })
        .catch(error => {
            setLoading(false)
            console.log('error', error)
            message.error("Somethin wrong!")
        });
    }
    

    return (

        <div className="login-container resetpssword">
        <div className="forms-container">
            <div className="signin-signup">
                
                <form onSubmit={handleSubmitChangePassword} action="#" className="sign-in-form">
                    <div className="login-header-block">
                    <img src={logo} />
                    <h2 className="title">Change Your Password</h2>
                    </div>
                    
                    <div className="input-field" 
                        style={(lo_responseMessage['password']&&lo_responseMessage['password'].length) > 0 ? { marginBottom : (12*lo_responseMessage['password'].length)+'px'} : {} }>
                        <i className="fas fa-lock"></i>
                        <input 
                            type={isShown ? "text" : "password"} 
                            name="password" placeholder="Input new password (Must be at least 8 characters)" 
                            value={ls_password} 
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type="button" className="button signup-show-hide-button" onClick={() => setIsSHown((isShown) => !isShown)}>
                        {isShown ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
                        </button>

                        <Tooltip placement="top" title={ls_passwordRules}>
                            <Button style={{position:"absolute", top:"12px", left:"379px", color:"#836666"}} type="text"><InfoCircleOutlined style={{fontSize:"18px"}} /></Button>
                        </Tooltip>
                        
                        {lo_responseMessage['password'] ? 

                        (lo_responseMessage['password'] && lo_responseMessage['password'].map((rowMsg, inx) => <p key={inx} 
                            className="comn-input-err"
                            style={{ top : (55+(15*inx))+'px'}}
                        >
                            {rowMsg}
                        </p>))
                        : <></>}
                    </div>
                    <div className="input-field">
                        <i className="fas fa-lock"></i>
                        <input 
                            type="password" name="password_confirmation"
                            placeholder="Input confirm password" 
                            value={ls_passwordConfirmation} 
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        {lo_responseMessage['password_confirmation'] ? <p className="comn-input-err">{lo_responseMessage['password_confirmation'][0]}</p> : <></>}
                    </div>

                    {/* <div className="input-field">
                    <i className="fas fa-lock"></i>
                    <input type={isShown ? "text" : "password"} name="password" placeholder="Password" />
                    <button type="button" className="button login-show-hide-button" onClick={() => setIsSHown((isShown) => !isShown)}>
                    {isShown ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
                    </button>
                    {lo_responseMessage['password'] ? <p style={{color:"red"}}>{lo_responseMessage['password'][0]}</p> : <></>}
                    </div> */}

                    {/* <input type="submit" value="Login" className="btn solid" /> */}
                    <Button loading={lb_loading} htmlType='submit' className="btn solid">Submit</Button>
                </form>
            </div>
        </div>

            <div className="panels-container">
                <div className="panel left-panel">
                <div className="content">
                    <h3>Don’t have an account? Set one up.</h3>
                    <p>Join over 80,000 event creators using us!</p>
                    <Link to={"/signup"}>
                        <button className="btn transparent">
                            Back to Sign up
                        </button>
                    </Link>
                </div>
                <img src={logImg} className="image" alt="image" />
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;