import React, { useEffect, useState } from 'react';
// import Button from 'antd/lib/button';
import './style.css'
import { Outlet, useLocation } from "react-router-dom";
import Layout, { Content } from 'antd/lib/layout/layout';

const EventFrontend = () => {
  return (
    <Layout>
        <Content>
          <div 
            // className="container"
          >
            <Outlet />
          </div>
        </Content>
    </Layout>
    
  )
}
export default EventFrontend;