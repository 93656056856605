import React, { useState, useEffect } from "react";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Typography from "antd/lib/typography"
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Tag from "antd/lib/tag";
import "./style.less";
import Divider from "antd/lib/divider";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
// import moment from "moment";
import message from "antd/lib/message";
import Search from 'antd/lib/input/Search';
// import debounce from 'lodash/debounce';
import Avatar from "antd/lib/avatar";
import InputNumber from "antd/lib/input-number";
import { postRequestByTokenParamApi } from "../../../../api/commonRequest";

const CheckInUserForm = ({ls_actionFromPage="main", getDataList, ls_actionType, lo_actionRecord, handleSetDataAction}) => {
    const ls_organisationId = localStorage.getItem('organisation_id')
    const ls_token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const dateFormat = "DD/MM/YYYY";
    const [lb_loading, setLoading] = useState(false)
    const [lb_visibleModal, setVisibleModal] = useState(false)
    const [ls_updateId, setUpdateId] = useState('')
    const [la_jobRoleList, setJobRoleList] = useState([]);
    const [lo_responseMessage, setResponseMessage] = useState({})

    useEffect(() => {
      console.log('la_jobRoleList',la_jobRoleList);
    }, [la_jobRoleList])
    
    
    const handleShowModal = ()=>{
        setUpdateId('')
        form.resetFields();
        setVisibleModal(true)
    }
    const handleShowUpdateModal = (record, index)=>{
        setVisibleModal(true)
        const lo_updateDate = {}
        // lo_updateDate['name'] = record.name;
        // lo_updateDate['mobile'] = record.mobile.primary_number;
        // lo_updateDate['email'] = record.email;
        // lo_updateDate['organization'] = {...record.organization};
        setUpdateId(record._id)
        form.resetFields()
        form.setFieldsValue(lo_updateDate)
    }
    const handleCancel = () => {
        form.resetFields()
        setUpdateId('')
        setVisibleModal(false)
        handleSetDataAction()
    };

    const handleSubmitFormDetails = async (lo_formDetails) => {
        // console.log('lo_formDetails',lo_formDetails); return
        setLoading(true)
        const lo_formData = { ...lo_formDetails };
        lo_formData['organisation_id'] = ls_organisationId;

        if (ls_updateId) {
            // const lo_updateData = {...lo_formData};
        } else {
            const lo_response = await postRequestByTokenParamApi("checkin-user-reg", lo_formData, ls_token);
            console.log('submit resutl-------lo_response',lo_response);
            setLoading(false)
            if (lo_response.status == 'success') {
                setVisibleModal(false)
                form.resetFields()
                getDataList()
                handleSetDataAction()
                setResponseMessage({})
                message.success("User Created Successfully!")
            }else{
                if (lo_response?.message) {
                    setResponseMessage(lo_response.message)
                    const lo_mssg = lo_response?.message;
                    if (lo_mssg['name'][0]) {
                        message.error(lo_mssg['name'][0])
                    }else if (lo_mssg['email'][0]) {
                        message.error(lo_mssg['email'][0])
                    }else if (lo_mssg['password'][0]) {
                        message.error(lo_mssg['password'][0])
                    }else if (lo_mssg['password_confirmation'][0]) {
                        message.error(lo_mssg['password_confirmation'][0])
                    }
                }else{
                    message.error('Something Wrong!!')
                }
            }
        }
    }

    useEffect(() => {
        if (ls_actionType == "add_checkinuser") {
            handleShowModal()
        } else if (ls_actionType == "edit_checkinuser") {
            handleShowUpdateModal(lo_actionRecord)
        } else if (ls_actionType == 'remove_checkinuser') {
            // handleRemoveSpecificCheckInUserById(lo_actionRecord)
        }
    }, [ls_actionType])

    return (
        <Modal
            title={ls_updateId ? 'Update Checkin User' : 'Checkin User'}
            open={lb_visibleModal}
            onOk={() => {
                form.validateFields().then((values) => {
                    // form.resetFields();
                    handleSubmitFormDetails(values);
                })
                    .catch((info) => {
                        // console.log('Validate Failed:', info);
                    });
            }}
            onCancel={handleCancel}
            confirmLoading={lb_loading}
            className='modal'
            width={700}
        >
            <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
            >
                <Row>
                    <Col span={24}>
                            <Divider plain></Divider>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Name"
                            name='name'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Email"
                            name='email'
                            rules={[{ type: "email", required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Password"
                            placeholder="Password"
                            name='password'
                            rules={[{ required: true }]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col> 
                    <Col span={24}>
                        {lo_responseMessage['password'] ? 

                        (lo_responseMessage['password'] && lo_responseMessage['password'].map((rowMsg, inx) => <p key={inx} 
                        className="comn-input-err2"
                        style={{ top : (55+(15*inx))+'px'}}
                        >
                        {rowMsg}
                        </p>))
                        : <></>}
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Confirm Password"
                            name='password_confirmation'
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {lo_responseMessage['password_confirmation'] ? 

                        (lo_responseMessage['password_confirmation'] && lo_responseMessage['password_confirmation'].map((rowMsg, inx) => <p key={inx} 
                        className="comn-input-err2"
                        style={{ top : (55+(15*inx))+'px'}}
                        >
                        {rowMsg}
                        </p>))
                        : <></>}
                    </Col>
                    
                </Row>


            </Form>

        </Modal>
    )
}

export default CheckInUserForm