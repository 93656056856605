import Filter from 'bad-words';
import { Api_base_url } from '../../config';
import dayjs from 'dayjs';
import store from "../store";
import { logout, setOrganizationId, setSelectOrganization } from '../store/common';

export const clearAll = () => { // it will be called when user loged out;
    store.dispatch(logout())
}

export const formatName = (ls_name = '') => {
    if (ls_name === '')
        return ls_name;

    else {
        let la_name = ls_name.split(" ");
        if (la_name[1] === null)
            return la_name[0][0].toUpperCase();

        return la_name[0][0].toUpperCase() + ' ' + la_name[1][0].toUpperCase();
    }
}

export const getDate = () => {
    return new Date();
}
export const capitalizeFirstLetter = (string) => {
    let ls_removeSpecialChar = string.replace('_', ' ');
    return ls_removeSpecialChar.split(" ").map(function (w) { return w[0].toUpperCase() + w.substr(1) }).join(" ").replace('_', ' ');
}

export const getUserInformationData = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const lo_user = localStorage.getItem('user');
    const organisation_id = localStorage.getItem('organisation_id');
    // console.log('--organisation_id',organisation_id, typeof(organisation_id));
    const organisationDetails = localStorage.getItem('organisation_details');
    let user = {}
    if(lo_user) user = JSON.parse(lo_user);

    let organisation_details = {};
    if(organisationDetails) organisation_details = JSON.parse(organisationDetails);

    let organisation_list = []
    const organisationList = localStorage.getItem('organisation_list');
    if(organisationList) organisation_list = JSON.parse(organisationList);

    return {token, user_id, user, organisation_id, organisation_details, organisation_list}
}

export const storeDataAfterLogin = (lo_response, la_organisationList) => {
    // console.log('===lo_response',lo_response);
    if (lo_response) {
        localStorage.setItem('token', lo_response.token);
        localStorage.setItem('user', JSON.stringify(lo_response.user));
        localStorage.setItem('user_id', lo_response?.user?.id);
    }
    if (la_organisationList) {
        localStorage.setItem('organisation_list', JSON.stringify(la_organisationList));
    }
    // localStorage.setItem('organisation_id', null);
    return true;
}
export const storeOrganisationData = async (lo_response) => {
    console.log('--lo_response',lo_response);
    localStorage.setItem('organisation_id', lo_response.id);
    localStorage.setItem('organisation_details', JSON.stringify(lo_response));
    store.dispatch(setOrganizationId(lo_response.id));
    store.dispatch(setSelectOrganization(lo_response));
    return true;
}
export const clearOrganisationData = async () => {
    localStorage.setItem('organisation_id', '');
    localStorage.setItem('organisation_details', null);
    return true;
}
export const actionForUserLogout = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    // localStorage.removeItem("user_id");
    localStorage.clear();
    store.dispatch(logout())
}
export const objectToFormData = (obj) => {
    const formData = new FormData();
  
    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });
  
    return formData;
}

export const checkProfanity = (text) => {
    const filter = new Filter();
    return filter.isProfane(text);
};

export const isExistProfanity = async (la_text) => {
    const la_profinity = []
    for (let k = 0; k < la_text.length; k++) {
        const value = la_text[k];
        if (checkProfanity(value)) {
            la_profinity.push(value)
        }
    }
    return la_profinity.length;
};

export const getAndsetAllOrganizationList = (ls_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

    fetch(Api_base_url+"all-organisations", requestOptions)
    .then(response => response.text())
    .then(result => {
        const lo_response = JSON.parse(result)
        if (lo_response?.organisations) {
          storeDataAfterLogin(null, lo_response.organisations)
        }
    })
    .catch(error => console.log('error', error));
  }

  const getDateTimeWithSpecificFormat = (lo_dateTime) => {
        return `${dayjs(lo_dateTime, "YYYY-MM-DD HH:mm:ss").format("dddd, MMMM D, YYYY on h:mm A")}`;
  }

export const getDeviceType = () => {
    const li_width = window.screen.width;
    if (li_width < 576)
        return 'xs';
    else
        return 'sm';
}

export const stringAvatar = (name) => {
    if (name) {
        return {
            children:
              name.split(" ")[1] == null
                ? `${name.split(" ")[0][0]}`
                : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
          };
    } else return {}
};
export const getCountryIdByCountryName=(ls_countryName, la_countryList)=>{
    const result =  la_countryList.find((element) => element.name.toLowerCase() === ls_countryName.toLowerCase());
    return result?.id ? result.id : null;
}

