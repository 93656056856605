import { Footer } from 'antd/lib/layout/layout'
import React from 'react'

const FooterSection = () => {
  return (
    <Footer
        style={{
        textAlign: 'center',
        paddingTop:13
        }}
    >
        <div style={{}}>
          Copyright @Ticketkart {new Date().getFullYear()}
        </div>
    </Footer>
  )
}

export default FooterSection