import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, KeyOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import Layout, { Content } from 'antd/lib/layout/layout';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Form from 'antd/lib/form/Form';
import Input from 'antd/lib/input/Input';
import { Button, Col, Divider, Row, Space } from 'antd/lib';
import Typography from 'antd/lib/typography/Typography';
import Link from 'antd/lib/typography/Link';
import { getRequestApi } from '../../../../../../api/commonRequest';
import LoginModal from '../../LoginModal';

const Checkout = (lo_props) => {
    const { li_step, setStep, form, lb_loading, la_bookedTickets, setBookedTickets, setUserData, lo_userData, lo_eventDetails, handleSubmitTicketDetails, lo_userDetails, lb_isGuest, lo_responseMessage={} } = lo_props;

    const la_eventdetailList = Form.useWatch("ticket_list", form);
    // const handlePlaceOrder = () => {
    //     form.validateFields().then((values) => {
    //         console.log('values', values);
    //         // form.resetFields();
    //         handleSubmitTicketDetails(values);
    //     }).catch((info) => {
    //         // console.log('Validate Failed:', info);
    //     });
    // }

    // ticket_list

    const handleShowPreviousPage = () => {
        const lo_formValue = form.getFieldsValue(['billing_first_name', 'billing_last_name', 'billing_email']);
        setUserData(lo_formValue)
        setStep(1)
        if (la_eventdetailList && la_eventdetailList?.length > 0) {
            setBookedTickets(la_eventdetailList)
        }else{
            setBookedTickets([])
        }
    }


    useEffect(() => {
        if (li_step == 2) {
            const lo_formDetails = { ...lo_userData, "ticket_list": la_bookedTickets }
            form.setFieldsValue(lo_formDetails)
        }
    }, [li_step, lo_userData, la_bookedTickets])

    const [lb_modalLoginVisible, setModalLoginVisible] = useState(false)
    const lo_loginProps = {lb_modalVisible: true, setModalVisible : () => {}, lb_modalLoginVisible, setModalLoginVisible}
    return (
        <>
            <div>
                <Col span={24}>
                    <Row>
                    <Col span={2}>
                        <ArrowLeftOutlined onClick={() => handleShowPreviousPage()}
                        size={50} style={{ marginBottom: 10 }} />
                    </Col>   
                    <Col span={20}>
                        <Typography.Title level={4} style={{ justifyContent: 'center', textAlign: 'center',marginBottom:20 }}>{lb_isGuest ? 'Guest Checkout' : 'Checkout'}</Typography.Title>
                    </Col> 
                    <Col span={2}></Col>
                    </Row> 
                </Col> 
                {/* <div style={{ backgroundColor: '#ccc', height: 1, marginTop: 25, }}></div> */}
                {/* <Layout>
                    <Content style={{ overflow: 'auto', height: '300px', paddingInlineStart: 40, paddingInlineEnd: 40 }}> */}
                <Layout>
                    <Content style={{ overflow: 'auto', marginTop:10}} className='mainContent'>
                        <div style={{ padding: 15 }}>
                            <div>
                                <h1 style={{ marginBottom: 2, fontSize:17  }}>Billing Information</h1>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 0, justifyContent: 'space-between'
                                }}>
                                    {
                                        lo_userDetails.user_id ? <h3 style={{ fontSize:12  }}>Logged in as {lo_userDetails.user.email}.</h3>
                                        : <h3 style={{ fontSize:12  }}><Link onClick={() => setModalLoginVisible(true)}>Log in</Link> for a faster experience.</h3>
                                    }
                                    
                                    <h4 style={{ fontSize:10  }}>*Required</h4>
                                </div>
                            </div>

                            <Divider style={{padding:0, margin:'0px 0px 6px 0px'}} orientation="left" plain></Divider>

                            <Form
                                className="content-form"
                                form={form} labelCol={{ span: 8 }}
                                // onFinish={onSubmitForm}
                                labelAlign='left'
                            >
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            // labelCol={{ span: 8 }}
                                            // label="First Name"
                                            name='billing_first_name'
                                            rules={[{ required: true, message: "Please enter First Name!" }]}
                                        >
                                            <Input size="small" placeholder="First Name" prefix={<UserOutlined className="site-form-item-icon" />}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            // labelCol={{ span: 8 }}
                                            // label="Last Name"
                                            name='billing_last_name'
                                            rules={[{ required: true, message: "Please enter Last Name!" }]}
                                        >
                                            <Input size="small" placeholder="Last Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>{lo_responseMessage['name'] ? <p className="comn-input-err2">{lo_responseMessage['name'][0]}</p> : <></>}</Col>
                                    <Col span={24}>
                                        <Form.Item
                                            // labelCol={{ span: 8 }}
                                            // label="Email"
                                            // placeholder="Email"
                                            name='billing_email'
                                            rules={[{ required: true, message: "Please enter Email!" }]}
                                        >
                                            <Input size="small" placeholder="Email" prefix={<MailOutlined className="site-form-item-icon" />}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                    {lo_responseMessage['email'] ? <p className="comn-input-err2">{lo_responseMessage['email'][0]}</p> : <></>}
                                    </Col>

                                    {lb_isGuest ? 
                                    <>
                                    <Col span={24}>
                                        <Form.Item
                                            // labelCol={{ span: 8 }}
                                            // label="Password"
                                            name='password'
                                            rules={[{ required: true, message: "Please enter Password!" }]}
                                        >
                                            <Input.Password size="small" placeholder="Password" prefix={<KeyOutlined  className="site-form-item-icon" />}/>
                                        </Form.Item>
                                    </Col> 
                                    <Col span={24}>
                                        {lo_responseMessage['password'] ? 

                                        (lo_responseMessage['password'] && lo_responseMessage['password'].map((rowMsg, inx) => <p key={inx} 
                                        className="comn-input-err2"
                                        style={{ top : (55+(15*inx))+'px'}}
                                        >
                                        {rowMsg}
                                        </p>))
                                        : <></>}
                                    </Col>
                                    </>
                                    : <></>}

                                    {/* <Col span={24}>
                                        <Form.List name="ticket_list">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Row
                                                            key={key}
                                                        >
                                                            <Col span={24}>
                                                                <Divider orientation="left" plain>
                                                                    <Typography.Text type="secondary">
                                                                        {la_bookedTickets && la_bookedTickets[key]?.ticket_name ?
                                                                            la_bookedTickets[key].ticket_name : ''
                                                                        }
                                                                    </Typography.Text>
                                                                </Divider>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    label="First Name"
                                                                    {...restField}
                                                                    name={[name, 'first_name']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing first name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="First Name" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    label="Last Name"
                                                                    {...restField}
                                                                    name={[name, 'last_name']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing last name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Last Name" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    label="Email"
                                                                    {...restField}
                                                                    name={[name, 'email']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Missing Email',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Email" />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </>
                                            )}
                                        </Form.List>
                                    </Col> */}
                                </Row>
                                {/* <h1 style={{ marginTop: 15 }}>  Pay with </h1> */}
                                {/* <h3 style={{ marginTop: 5 }}> Pay with </h3>
                                <p>Select 'Place Order' to pay with PayPal. You can check out as a guest or with your PayPal account. Return to this page when you're finished.</p> */}

                                <div style={{ margin:'-14px 0px 0px 6px'}}>
                                    <Typography.Text  style={{ fontSize: 12, color: '#303030' }}>
                                    By clicking "Place Order" I agree to the <Link style={{ fontSize: 12 }} target='_blank' href="/termsandcondition">Terms and Conditions of Ticketkart</Link>.
                                    </Typography.Text>
                                </div>

                                <div style={{ margin:'-12px 0px 6px 0px', visibility:"hidden" }}>
                                    <Form.Item style={{marginBottom:-12}} name="diclaration" valuePropName="checked" rules={[{ required: true, message: 'You have to check!' }]} >
                                
                                        <Checkbox size="small" onChange={(e) => form.setFieldsValue({ diclaration: e.target.checked })}
                                        // checked={true}
                                        //   onChange={handleChange}
                                        // disabled={false}
                                        // indeterminate={false}
                                        >
                                            <p style={{ marginInlineStart: 5,marginTop:10, fontSize:10 }}> I accept the Ticketkart Terms of Service.</p>
                                        </Checkbox>
                                    
                                    </Form.Item>
                                    <div>
                                        {lo_responseMessage['diclaration'] ? 

                                        (lo_responseMessage['diclaration'] && lo_responseMessage['diclaration'].map((rowMsg, inx) => 
                                        <div key={inx} class="ant-form-item-explain-error" style={{color:'red'}}>{rowMsg}</div>))
                                        : <></>}
                                    </div>

                                </div>
                            </Form>
                        </div>
                    </Content>
                </Layout>
                {/* <Button
                    loading={lb_loading}
                    className="add-btn button"
                    type="default"
                    style={{ float: "right" }}
                    onClick={() => handlePlaceOrder()}
                >
                    Place Order
                </Button> */}
            </div>

            <LoginModal {...lo_loginProps}/>
        </>
    )
}

export default Checkout