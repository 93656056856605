import React from 'react'
import Login from './Login'
import SignUp from './SignUp'

const PublicAuth = () => {
  return (
    <div className="signin-signup">
          <Login/>
          <SignUp/>
    </div>
  )
}

export default PublicAuth