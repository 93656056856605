import React from 'react'
import CookieConsent from 'react-cookie-consent';

const AcceptCookie = () => {
  return (
    <>
        <CookieConsent
            location="bottom"
            buttonText="Okay!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#fd8f1d" }}
            buttonStyle={{ color: "#fff", fontSize: "13px", backgroundColor:"rgb(7, 99, 246)" }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.{" "}
            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
        </CookieConsent>
    </>
  )
}

export default AcceptCookie