import React, { useEffect, useState } from "react";
import "./style.css";
import MailOutlined from "@ant-design/icons/MailOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import HistoryOutlined from "@ant-design/icons/HistoryOutlined";
import { Content } from "antd/lib/layout/layout";
import { Typography, Input, Button, Modal, message } from "antd";
import List from "antd/lib/list";
import { Api_base_url } from "../../../config";
import { storeOrganisationData } from "../../utility";
import { useNavigate } from "react-router-dom";
// import { getRequestApi } from "../../../api/commonRequest";
import dayjs from 'dayjs';
import Avatar from "antd/lib/avatar/avatar";

import { useDispatch } from 'react-redux';
import { setOrganizationId, setSelectOrganization, setUserType } from '../../store/common';

const SelectOrganization = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const ls_token = localStorage.getItem('token')
    //common function to crud
    const [la_organisationList, setOrganisationList] = useState([]);
    const [lb_loading, setLoading] = useState(false)

    const getOrganizationList = async () => {
        setLoading(true)
        // if (!ls_token) return
        // const lo_response = await getRequestApi("all-organisations");
        // console.log('---lo_response.organisations',lo_response.organisations);
        // if (lo_response?.organisations) {
        //     setOrganisationList(lo_response.organisations)
        // }else{
        //     setOrganisationList([])
        // }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ls_token);
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

        fetch(Api_base_url+"all-organisations", requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false)
            const lo_response = JSON.parse(result)
            console.log('lo_response',lo_response);
            if (lo_response?.organisations) {
                setOrganisationList(lo_response.organisations)
            }else{
                setOrganisationList([])
            }
        })
        .catch(error => console.log('error', error));
    }

    const addToSpecificOrganisation = async (item) => {
        console.log('item',item);
        const respone = await storeOrganisationData(item)

        dispatch(setOrganizationId(item.id));
        dispatch(setSelectOrganization(item));
        dispatch(setUserType('organizer'));
        console.log('respone',respone);
        if(respone) navigate('/dashboard');
    }

    useEffect(() => {
      if(ls_token) getOrganizationList()
    }, [ls_token])
    
    return (
        <>
            <Content className="content-layout form-header" style={{justifyContent:"space-around"}}>
                <div className="header-component" style={{justifyContent:"space-around"}}>
                <h1 className="select__ora__text">Select Organization</h1>
                   
                    {/* <Typography.Title level={4} className="select__ora__text">Select Organization</Typography.Title> */}
                </div>
            </Content>
            <Content className="content-layout">
            <List 
                style={{textAlign:"center"}}
                gutter={10}
                loading={lb_loading}
                // grid={{gutter: 20, column: 1}} 
                // bordered={true}
                itemLayout="horizontal"
                dataSource={la_organisationList}
                renderItem={(item, index) => (
                <List.Item style={{cursor: "pointer"}} 
                    onClick={() => addToSpecificOrganisation(item)}>
                    <List.Item.Meta
                        // avatar={<Avatar size="large" src={`https://cdn.pixabay.com/photo/2017/06/10/06/39/calender-2389150_1280.png`} />}
                        title={<p>{item.name} ({item.roles[0].name})</p>}
                        description={`From ${dayjs(item.created_at, "YYYY-MM-DD HH:mm:ss").format("dddd, MMMM D, YYYY")} 
                        ${item.contact_person ? ' & Contact person is : '+item.contact_person : ''}`}
                    />
                </List.Item>
                )}
            />
            </Content>
        </>
    );
};

export default SelectOrganization;
