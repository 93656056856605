import React from 'react';
import hero from '../../../../assets/img/hero-01.png';
import art02 from '../../../../assets/img/art02-1.png';
import "../../../../assets/css/custom-style.css";
import './style.css'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeroSection = () => {

  const ls_userId = useSelector( (state) => state.common.user_id );
  return (
    <div className="p-0-s98cus hero-section">
      <div className='sub__div' style={{ width: '100%' }}>
        <div className="row-s98cus align-items-center-s98cus responsive-content" style={{ justifyContent: 'space-between' }}>
          <div className="cl-5-s98cus">
            <div className="hero-content">
              <h1>
                Events Begin Here,<br />
                Simplify Event Management.
              </h1>
              <p>Your Event, Your Tickets, Your Success</p>
              <div className="btn-two mt-3-s98cus mb-3-s98cus">
                <a href={ls_userId ? "/dashboard" : "dashboard"} className="cyevent-btn" style={{ marginRight: '10px', height: '34px', width: '159px' }}>
                  Get started for free
                </a>
                <Link to={'/contactform'} className="bademo-btn" style={{ marginRight: '10px', height: '44px', width: '168px' }}>
                  Book a Demo
                </Link>
              </div>
            </div>
          </div>
          <div className="cl-7-s98cus">
            <div className="hero-image">
              <img src={hero} alt="" srcSet="" />
            </div>
          </div>
        </div>
      </div>
      <img src={art02} className="hero-left-art" />
    </div>
  );
};

export default HeroSection;