import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from '../layouts/AuthLayout';
import PublicAuth from '../pages/PublicAuth';
import FullLoader from '../components/FullLoader';
import Home from '../pages/Home';
import MainLayout from '../layouts/MainLayout';
import TeamAccess from '../pages/TeamAccess';
import Overview from '../pages/Overview';
import { getUserInformationData } from '../utility';
import Event from '../pages/Event';
import BasicInfo from '../pages/Event/CreateEvent/BasicInfo';
import CreateEvent from '../pages/Event/CreateEvent';
import EmailVerification from '../pages/PublicAuth/EmailVerification';
import SuccessPage from '../pages/PublicAuth/SuccessPage';
import ForgotPassword from '../pages/PublicAuth/ForgotPassword';
import ChangePassword from '../pages/PublicAuth/ChangePassword';
import Details from '../pages/Event/CreateEvent/Details';
import TicketDetails from '../pages/Event/CreateEvent/TicketDetails';
import Publish from '../pages/Event/CreateEvent/Publish';
import AccountSettings from '../pages/AccountSettings';
import AccountInfo from '../pages/AccountSettings/AccountInfo';
import CreatePassword from '../pages/AccountSettings/CreatePassword';
import EventDetails from '../pages/EventFrontend/EventDetails';
import SelectOrganization from '../pages/SelectOrganization';
import EventFrontend from '../pages/EventFrontend';
import User from '../pages/User';
import BookingSuccessPage from '../pages/EventFrontend/BookingSuccessPage';
import UserOrders from '../pages/UserOrders/OrderList';
import BookingDetails from '../pages/UserOrders/BookingDetails';
import OrderList from '../pages/Orders/OrderList';
import TicketSales from '../pages/Orders/TicketSales';
import AttendeeReport from '../pages/Report/AttendeesReport';
import OrganiserOrderDetails from '../pages/Orders/OrderDetails';
import PublicLayout from '../layouts/PublicLayOut';
import HomePublic from '../pages/HomePublic';
import SalesSummaryList from '../pages/Report/SalesSummary';
import EventsPage from '../pages/HomePublic/EventsPage';
import FAQPage from '../pages/HomePublic/FAQPage';
import AboutUs from '../pages/HomePublic/AboutUs';
import Pricing from '../pages/HomePublic/Pricing';
import Reports from '../pages/Report/OrganiserReport';
import Dashboard from '../pages/Dashboard';
// import PublicContent from '../pages/PublicContent';
import TermsAndCondition from '../pages/PublicContent/TermsAndCondition';
import PrivacyPolicy from '../pages/PublicContent/PrivacyPolicy';
import VendorTermsCondition from '../pages/PublicContent/VendorTermsCondition';
import RefferalPolicy from '../pages/PublicContent/RefferalPolicy';
import CoockiesPolicy from '../pages/PublicContent/CoockiesPolicy';
import CreateStripeAccount from '../pages/Payment/Stripe/CreateStripeAccount';
import ReturnStripeAccount from '../pages/Payment/Stripe/ReturnStripeAccount';
import ResetStripeAccount from '../pages/Payment/Stripe/ResetStripeAccount';
import StripeSuccessPage from '../pages/Payment/Stripe/StripeSuccessPage';
import { useSelector } from 'react-redux';
import ContactForm from '../pages/PublicContent/ContactForm';
import Referrals from '../pages/Referrals';
import ScrollToTopOnRouteChange from '../components/ScrollToTopOnRouteChange';
import Features from '../../../src/ui/pages/PublicContent/Features'
import CheckInUser from '../pages/CheckInUser';
const Routers = () => {
  const ls_userType = useSelector( (state) => state.common.userType );
  const ls_token = useSelector( (state) => state.common.token );
  const ls_organisation_id = useSelector( (state) => state.common.organisation_id );
  const lo_orgDetails = useSelector((state) => state.common.organisation_details);
  const [lb_pageLoading, setPageLoading] = useState(true);
  const [ls_userUserToken, setUserUserToken] = useState(null);
  const [ls_organisationId, setOrganisationId] = useState(null);
  // const la_urlPath = window.location.pathname
  // const location = useLocation();

  const setUserDetailsOfUser = () => {
    const userData = getUserInformationData() 
    setUserUserToken(ls_token)
    setOrganisationId(ls_organisation_id)
    console.log('userData',userData);
  }

  useEffect(() => {
    setPageLoading(false);    
    setUserDetailsOfUser()
    console.log('ls_organisation_id',ls_organisation_id);
  }, [ls_token, ls_organisation_id]);

  useEffect(() => {
    console.log('lo_orgDetails>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',ls_organisation_id, lo_orgDetails);
  }, [lo_orgDetails])
  
  

  return (
    (lb_pageLoading) ? <FullLoader /> :
    <BrowserRouter>
        <ScrollToTopOnRouteChange /> {/* This component will handle scrolling to the top on route change */}
        {
          ls_userUserToken && ls_organisationId ?
          <Routes>
            <Route path="/*" element={<Navigate replace to="/home" />} />
            <Route path="/" element={<MainLayout setUserDetailsOfUser={setUserDetailsOfUser} />} >

              
              <Route index element={<Navigate replace to="home" />} />
              {/* <Route path="overview" element={<Overview />} /> */}
              <Route path="overview" element={<Overview />} />
              <Route path="dashboard" element={<Dashboard />} />
              {/* <Route path="team-access" element={<TeamAccess />} /> */}
              <Route path="checkin-user" element={<CheckInUser />} />
              <Route path='user-orders' element={<UserOrders/>}/>
              <Route path='referrals' element={<Referrals/>}/>
              <Route path='booking-details/:id' element={<BookingDetails/>}/>

              {
                ls_userType  == "organizer" &&
                <>
              <Route path='orders' element={<OrderList/>}/>
              <Route path='ticket-sales' element={<TicketSales/>}/>
              <Route path='attendee-report' element={<AttendeeReport/>}/>
              <Route path='sales-summary' element={<SalesSummaryList/>}/>
              <Route path='report' element={<Reports/>}/>
              <Route path='order-details/:id' element={<OrganiserOrderDetails/>}/>
              <Route path="events" element={<Event />} />
              <Route path="/events/add" element={<BasicInfo />} />
              <Route path="/events/copy/:copySlug" element={<BasicInfo />} />

              <Route path="/events/update/:slug" element={<CreateEvent />} >
                  <Route path="basic-info" element={<BasicInfo />} />
                  <Route path="details" element={<Details />} />
                  <Route path="ticket" element={<TicketDetails />} />
                  <Route path="publish" element={<Publish />} />
                  <Route index element={<Navigate replace to="basic-info" />} />
              </Route>

              <Route path="/create-stripe-account" element={<CreateStripeAccount />}/>
              <Route path="/return_payment_account/:stripeToken" element={<ReturnStripeAccount />} />
              <Route path="/reset_payment_account/:stripeToken" element={<ResetStripeAccount />} />
              <Route path="/stripe-account-success" element={<StripeSuccessPage />} />
              </>
              }

              <Route path="/organiser/account-settings" element={<AccountSettings />} >
                  <Route path="account-info" element={<AccountInfo />} />
                  <Route path="create-password" element={<CreatePassword />} />
                  <Route index element={<Navigate replace to="account-info" />} />
              </Route>
              
              
            </Route>

            <Route path="/" element={<EventFrontend />}>
              <Route path="/event-details/:slug" element={<EventDetails />} />
              <Route path="/preview-event/:slug" element={<EventDetails />} />
              <Route path='/' element={<PublicLayout/>}>
                <Route path="/booking-details" element={<BookingSuccessPage />} />
              </Route>
            </Route>
            <Route path='/' element={<PublicLayout/>}>
                <Route path='/home' element={<HomePublic/>}/>
                <Route path='/event-list' element={<EventsPage/>}/>
                <Route path='/event-list/:slug' element={<EventsPage/>}/>
                <Route path='/faqs' element={<FAQPage/>}/>
                <Route path='/aboutus' element={<AboutUs/>}/>
                <Route path='/pricing' element={<Pricing/>}/>
                <Route path='/termsandcondition' element={<TermsAndCondition/>}/>
                <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                <Route path='/vendor-terms-conditions' element={<VendorTermsCondition/>}/>
                <Route path='/referralpolicy' element={<RefferalPolicy/>}/>
                <Route path='/coockiespolicy' element={<CoockiesPolicy/>}/>
                <Route path='/contactform' element={<ContactForm/>}/>
            </Route> 
              {/* <Route path='/' element={<PublicContent/>}>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path='/termsandcondition' element={<TermsAndCondition/>}/>
                <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                <Route path='/vendor-terms-conditions' element={<VendorTermsCondition/>}/>
                <Route path='/refferalpolicy' element={<RefferalPolicy/>}/>
                <Route path='/coockiespolicy' element={<CoockiesPolicy/>}/>
                <Route path='/contactform' element={<ContactForm/>}/>
              </Route> */}
          </Routes>
          : 
            ls_userUserToken && !ls_organisationId ? 
            <Routes>
              {
                ls_userType  == "organizer" ? 
                <Route path="/*" element={<Navigate replace to="/select-organization" />} />
                : 
                <Route path="/*" element={<Navigate replace to="/home" />} />
              }
            
              <Route path="/" element={<MainLayout setUserDetailsOfUser={setUserDetailsOfUser} />} >
                {
                  ls_userType  == "organizer" ? 
                  <Route path="/" element={<Navigate replace to="/select-organization" />} />
                  : 
                  <Route path="/" element={<Navigate replace to="/home" />} />
                }
                <Route path="overview" element={<Overview />} />
                <Route path="select-organization" element={<SelectOrganization />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path='user-orders' element={<UserOrders/>}/>
                <Route path='booking-details/:id' element={<BookingDetails/>}/>
                <Route path='referrals' element={<Referrals/>}/>

                <Route path="/organiser/account-settings" element={<AccountSettings />} >
                    <Route path="account-info" element={<AccountInfo />} />
                    <Route path="create-password" element={<CreatePassword />} />
                    <Route index element={<Navigate replace to="account-info" />} />
                </Route>
              </Route>

              <Route path="/" element={<EventFrontend />}>
                <Route path="/event-details/:slug" element={<EventDetails />} />
                <Route path="/preview-event/:slug" element={<EventDetails />} />
                <Route path="/booking-details" element={<BookingSuccessPage />} />
              </Route>
              <Route path='/' element={<PublicLayout/>}>
                  <Route path='/home' element={<HomePublic/>}/>
                  <Route path='/event-list' element={<EventsPage/>}/>
                  <Route path='/event-list/:slug' element={<EventsPage/>}/>
                  <Route path='/faqs' element={<FAQPage/>}/>
                  <Route path='/aboutus' element={<AboutUs/>}/>
                  <Route path='/pricing' element={<Pricing/>}/>

                  <Route path='/termsandcondition' element={<TermsAndCondition/>}/>
                  <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                  <Route path='/vendor-terms-conditions' element={<VendorTermsCondition/>}/>
                  <Route path='/referralpolicy' element={<RefferalPolicy/>}/>
                  <Route path='/coockiespolicy' element={<CoockiesPolicy/>}/>
                  <Route path='/feature' element={<Features/>}/>
              </Route> 
            </Routes>
            :
            
            <Routes>
              <Route path='/*' element={<Navigate replace to="/signup" />} />
              {/* <Route path="/*" element={<Navigate replace to="/home" />} /> */}
              <Route path="/organiser/authorization/:id" element={<EmailVerification />} />
              {/* <Route path='/' element={<PublicLayout/>}>
                <Route path="/organiser/signup-success" element={<SuccessPage />} />
              </Route> */}
              <Route path="/organiser/forgot-password" element={<ForgotPassword />} />
              <Route path="/organiser/change-password/:id" element={<ChangePassword />} />
              <Route path="/" element={<AuthLayout setUserDetailsOfUser={setUserDetailsOfUser} />}>
                <Route path="/signup" element={<PublicAuth />} />
                <Route path="/login" element={<PublicAuth />} />
                <Route path="/signup/:id" element={<PublicAuth />} />
                <Route path="/" element={<Navigate replace to="/home" />} />
              </Route>
              <Route path='/' element={<PublicLayout/>}>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path='/home' element={<HomePublic/>}/>
                <Route path='/event-list' element={<EventsPage/>}/>
                <Route path='/event-list/:slug' element={<EventsPage/>}/>
                <Route path='/faqs' element={<FAQPage/>}/>
                <Route path='/aboutus' element={<AboutUs/>}/>
                <Route path='/pricing' element={<Pricing/>}/>
                <Route path='/feature' element={<Features/>}/>
                <Route path='/termsandcondition' element={<TermsAndCondition/>}/>
                <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                <Route path='/vendor-terms-conditions' element={<VendorTermsCondition/>}/>
                <Route path='/referralpolicy' element={<RefferalPolicy/>}/>
                <Route path='/coockiespolicy' element={<CoockiesPolicy/>}/>
                <Route path='/contactform' element={<ContactForm/>}/>
                
                <Route path="/organiser/signup-success" element={<SuccessPage />} />
              </Route>
              
              <Route path="/" element={<EventFrontend />}>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/event-details/:slug" element={<EventDetails />} />
                <Route path="/preview-event/:slug" element={<EventDetails />} />
              </Route>
              <Route path="user" element={<User />} />

            </Routes>
        }
        

        
    </BrowserRouter>
  )
}

export default Routers