import Result from 'antd/lib/result'
import Layout, { Content } from 'antd/lib/layout/layout'
import React from 'react'
import Button from 'antd/lib/button'
import { Link } from 'react-router-dom'

const SuccessPage = () => {
  return (
    <>
        <Layout style={{ minHeight: '100vh',}}>
            <Content
            style={{ margin: '80px 16px' }}
            >
                <Result
                    status="success"
                    title="Successfully Registered!"
                    subTitle="To complete the verification process, please check your email and click on the link we sent you."
                    extra={[
                    <Link to="/signup">
                        <Button type="primary" key="console">
                            Go Back to Login Page
                        </Button>
                    </Link>,
                    ]}
                />
            </Content>
        </Layout>
    </>
  )
}

export default SuccessPage