import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import bg from '../../../image/event-background-image.jpg'
import logo from '../../../image/logo.png'
import { Spin,message, Button, Tag, Space, Divider, Collapse, Tooltip, FloatButton } from "antd/lib";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPublicRequestApi, getRequestApi } from '../../../../api/commonRequest'
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import BuyTicket from './BuyTicket'
import LoginModal from './LoginModal'

import { getDeviceType, getUserInformationData } from '../../../utility'
import { Col, Row, Typography } from 'antd';
import GoogleMaps from '../../../components/GoogleMap';
import { PlayCircleOutlined, UserOutlined, FlagOutlined } from '@ant-design/icons';
import Card from 'antd/lib/card/Card';
import ContactModal from './ContactModal';
import ReportModal from './ReportModal';
import Footer from '../../../layouts/PublicLayOut/Footer';
import { Live_base_url } from "../../../../config/index"
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
const EventDetails = () => {
    const loggingIn = useSelector( (state) => state.common.loggingIn );
    const lo_params = useParams()
    const { slug } = lo_params
    const [ls_slug, setSlug] = useState(slug)
    const [lb_modalVisible, setModalVisible] = useState(false)
    const [ls_url, setUrl] = useState("")
    const [lb_isGuest, setIsGuest] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()
    let event_url = `${location.pathname.split("/")[1]}/${ls_slug}`;
    // const url = `https://master.diditgml51xfa.amplifyapp.com//${location.pathname}`
    const url = `${Live_base_url}${event_url}`

    // user details
    const lo_userDetails = getUserInformationData() 

    // for event details
    const [lo_eventDetails, setEventDetails] = useState({})
    const [lb_spinning, setSpinning] = useState(true)
    const getSpecificEventDetails = async () => {
        setSpinning(true)
        const lo_event = await getEventDetailsDataBySlug();
        console.log('-----------lo_event',lo_event);
        if (lo_event) {
            if (lo_event?.tags) {
                lo_event['tag_list'] = lo_event.tags.split(',');
            }
            setEventDetails(lo_event)
            setSpinning(false)
        }else{
            setEventDetails({})
            message.error('Not Exist!')
        }
    }
    const getEventDetailsDataBySlug = async () => {
        if(!ls_slug) return;
        let lo_event;
        let event_url = location.pathname.split("/")[1];
        setUrl(event_url)
        console.log('event_url', `${event_url}/${ls_slug}`);
        if (event_url == "event-details") {
            lo_event = await getPublicRequestApi("public-event/"+ls_slug);
        } else {
            lo_event = await getRequestApi("preview-event/"+ls_slug);
        }
        
        console.log('lo_event',lo_event);
        return lo_event.event;
    }

    const [lb_modalLoginVisible, setModalLoginVisible] = useState(false)
    const [lb_modalLoading, setModalLoading] = useState(false)
    const [lb_contactModalVisible, setContactModalVisible] = useState(false)
    const [lb_reportModalVisible, setReportModalVisible] = useState(false)
    const handleCheckBeforeBuyTicket = async () => {
        setModalLoading(true)
        const lo_userDetails = await getRequestApi("loggedin-user");
        console.log('lo_userDetails',lo_userDetails);
        if (lo_userDetails.status == "success") {
            setModalVisible(true)
        }else{
            setModalLoginVisible(true)
        }
        setModalLoading(false)
    }

    useEffect(() => {
        getSpecificEventDetails()
    }, [ls_slug])
    useEffect(() => {
        setIsGuest(!loggingIn)
    }, [loggingIn])
    

    const la_faqitems = [ { key: '1', label: 'This is panel header 1', children: <p>{'A dog is a type of domesticated animal.Known for its loyalty and faithfulness,'}</p>, },
        { key: '2', label: 'This is panel header 2', children: <p>{'Known for its loyalty and faithfulness'}</p>,},
        { key: '3', label: 'This is panel header 3', children: <p>{'Known for its loyalty and faithfulness'}</p>,
        },
      ];

    const lo_ticketProps = {lb_modalVisible, setModalVisible, lo_eventDetails, lo_userDetails, lb_isGuest}
    const lo_loginProps = {lb_modalVisible, setModalVisible, lb_modalLoginVisible, setModalLoginVisible}
    const lo_contactProps = {lb_contactModalVisible, setContactModalVisible, lo_userDetails, 'organisation_id':lo_eventDetails?.organisation_id}
    const lo_reportProps = {lb_reportModalVisible, setReportModalVisible, lo_eventDetails, lo_userDetails}

    useEffect(() => {
      console.log('----lo_eventDetails',lo_eventDetails);
    }, [lo_eventDetails])

    const iframeRef = useRef(null);
    useEffect(() => {
        const iframe = iframeRef?.current?.querySelector('iframe');
        if (iframe) {
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        }
        }, [lo_eventDetails]);

        const ls_deviceType = getDeviceType();
    
    return (<>

    <Helmet>
        <title>{`TicketKart.com - ${lo_eventDetails.name}`}</title>
        <meta property="title" content={"TicketKart.com -"+lo_eventDetails.name}/>
        <meta property="description" content={"TicketKart -"+lo_eventDetails.summery}/>
        <meta property="Keywords" content={"TicketKart, Ticket Kart, Ticket Cart"+lo_eventDetails.tags}/>

        <meta property="og:title" content="TicketKart.com - Unleash the Potential of Your Events with Our Ticketing Solution"/>
        <meta property="og:description" content="TicketKart - Your one-stop destination for event tickets. Bringing you the best selection of tickets for concerts, sports events, theater shows, and more."/>
        <meta property="og:image" content={lo_eventDetails?.images && lo_eventDetails.images[0]?.public_url ? lo_eventDetails.images[0].public_url : "https://www.ticketkart.com/media/colour-transparent.png"}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="TicketKart"/>
        <meta property="og:url" content="https://www.ticketkart.com"/>
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />
    </Helmet>

        <Spin spinning={lb_spinning}>
            <div className='content-layout thumbnail' style={{ padding: 0,backgroundColor:'red',height:100}}>
                <div className="buy-ticket-details-inner" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="site_header">
                            <div className="logo-section">
                                <a href="/home">
                                    <img src={logo} alt="" />
                                </a>
                            </div>
                            <Button onClick={() => navigate(-1)}>Back</Button>
                        </div>
                    </div>
                    <div className="container">
                        <div 
                            style={{display:"flex", justifyContent:"center", backgroundColor:"#1e1d1bb3"}}
                            className="event-banner-section"
                        >
                            <img 
                                style={{width:'auto'}}
                                src={lo_eventDetails?.images && lo_eventDetails.images[0]?.public_url ? lo_eventDetails.images[0].public_url : false} alt="" 
                            />
                        </div>
                    </div>
                </div>
                <div className="event-details-section">
                    <div className="container">
                        <div className="event-details-content">
                            <div className="row">
                                <div className="cl-8">
                                    <div classNames="event-details-area">
                                        <div className="event-details-area-heading">
                                            <h1>{lo_eventDetails.name}</h1>
                                            <ul>
                                                <li><i className="fa-regular fa-calendar-days"></i> 
                                                    {lo_eventDetails.display_start_time ? ` ${dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(lo_eventDetails.starts_time, "HH:mm:ss").format("h:mm A")}` : ""}
                                                    {lo_eventDetails.display_start_time ? ` - ${dayjs(lo_eventDetails.ends_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")} at ${dayjs(lo_eventDetails.ends_time, "HH:mm:ss").format("h:mm A")}` : ""}
                                                </li>
                                                {/* {(lo_eventDetails.venu_type == "online" || lo_eventDetails.venu_type == "vanue") ? 
                                                <li><i className="fa-sharp fa-solid fa-location-dot"></i> 
                                                    {lo_eventDetails?.venu_type == "vanue" ? ` ${lo_eventDetails.address_one}, ${lo_eventDetails.city}, ${lo_eventDetails.post_code}` : ''}
                                                    {lo_eventDetails?.venu_type == "online" ? ` ${lo_eventDetails.online_link}` : ''}
                                                </li> : <></>} */}
                                            </ul>
                                        </div>
                                        <div className="event-page-description">
                                            <span><strong>{`${lo_eventDetails?.event_type?.name}${(lo_eventDetails?.event_type?.name && lo_eventDetails?.category?.name) ? ' , ' : ''}${lo_eventDetails?.category?.name}`}</strong></span>
                                            {/* <span><strong>{lo_eventDetails?.category?.name}</strong></span> */}
                                            <div><strong> <span>Organised By: {lo_eventDetails?.organisation?.name || lo_eventDetails?.organisation?.contact_person}</span></strong></div>
                                            {/* <br /> */}

                                            {/* <p><strong>Performances &nbsp;
                                             {dayjs(lo_eventDetails.starts_date, "YYYY-MM-DD").format("dddd, MMMM D")} {lo_eventDetails?.ends_date && ' - '+dayjs(lo_eventDetails.ends_date, "YYYY-MM-DD").format("dddd, MMMM D")}
                                            
                                            </strong></p> */}
                                            {/* <br /> */}

                                            {/* <p>{lo_eventDetails.summery} </p> */}
                                            {/* <br /> */}

                                            {(lo_eventDetails.venu_type == "online" || lo_eventDetails.venu_type == "vanue") ? 
                                                <div>
                                                    {lo_eventDetails?.venu_type == "vanue" ? 
                                                    <Row>
                                                        <Col span={24}>
                                                            {/* <Divider orientation="left" plain >Location</Divider> */}
                                                            <h1 style={{fontSize:20,fontWeight:600,marginTop:10}}>Location</h1>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Collapse defaultActiveKey={['panel-location']} >
                                                                <Collapse.Panel key={`panel-location`} header={<>&nbsp;&nbsp;
                                                                    <Link>
                                                                    <i className="fa-sharp fa-solid fa-location-dot"></i> {lo_eventDetails.address_one}, {lo_eventDetails.city}, {lo_eventDetails.post_code}
                                                                    </Link>
                                                                </>}>
                                                                    {lo_eventDetails?.address_one ? <GoogleMaps ls_address={lo_eventDetails?.address_one} />
                                                                    : <></>}
                                                                </Collapse.Panel>
                                                            </Collapse>
                                                            
                                                            
                                                        </Col>
                                                            
                                                    </Row> : ''}

                                                    {lo_eventDetails?.venu_type == "online" ? 
                                                     <Row>
                                                        <Col span={24}>
                                                            <Divider orientation="left" plain >Venue</Divider>
                                                        </Col>
                                                        
                                                        
                                                        {lo_eventDetails?.online_link ? 
                                                            <Col span={24}>
                                                                <a href={lo_eventDetails.online_link} target="_blank" rel="noopener noreferrer"><PlayCircleOutlined /> Online</a>
                                                            </Col> 
                                                            : <Col span={24}><PlayCircleOutlined /> Online </Col>}
                                                     </Row>
                                                    : ''}
                                                </div>
                                            : <></>}
                                            
                                            <Divider orientation="left" plain ></Divider>
                                            
                                            <Row>
                                                <Col span={24}>
                                                    <h1 style={{fontSize:20,fontWeight:600,marginTop:10}}>Description</h1>
                                                </Col>
                                                <Col span={24}>
                                                    {lo_eventDetails?.decription ? parse(lo_eventDetails.decription) : ""}
                                                </Col>
                                            </Row>
                                            
                                            
                                            {lo_eventDetails?.video_url ? <div>
                                            <Card title="Video">
                                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                                <div ref={iframeRef}>
                                                { 
                                                    (lo_eventDetails.video_url.startsWith('http://') || lo_eventDetails.video_url.startsWith('https://')) ?
                                                    <iframe
                                                        title="Embedded Video"
                                                        src={ (lo_eventDetails.video_url.startsWith('http://') || lo_eventDetails.video_url.startsWith('https://')) ? lo_eventDetails.video_url: ''}
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        frameBorder="0"
                                                        allowFullScreen
                                                        // style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                                    ></iframe>
                                                    :
                                                    parse(lo_eventDetails.video_url)
                                                }
                                                </div>
                                            </div>
                                            </Card>
                                            </div> : <></>}
                                            

                                            {lo_eventDetails && lo_eventDetails?.event_agendas?.length > 0 && (
                                                <div>
                                                {/* <Divider orientation="left" plain ><Typography.Title level={5}>Agenda</Typography.Title></Divider> */}
                                                <Col span={24}>
                                                <h1 style={{fontSize:20,fontWeight:600,marginTop:10}}>Agenda</h1>
                                                </Col>
                                                <Collapse defaultActiveKey={['1']} 
                                                style={{ backgroundColor: 'rgba(255, 221, 204,0.3 )'}}
                                                    // onChange={onChange}
                                                >
                                                    {/* {la_faqitems.map((item, index) => (
                                                        <Collapse.Panel key={`panel-${index}`} header={item.label}>
                                                        {item.children}
                                                        </Collapse.Panel>
                                                    ))} */}
                                                    {lo_eventDetails && lo_eventDetails?.event_agendas?.map((item, index) => (
                                                        <Collapse.Panel key={item.id} header={item.title}>
                                                            <div style={{borderLeft: '2px solid #ff9966', paddingLeft: 10 }}>
                                                                <div style={{fontSize: 14, fontWeight: 'bold', padding: '5px 10px 5px 10px', backgroundColor: '#e6e6e6', maxWidth: 'fit-content' }}><i className="fa-regular fa-clock" style={{color: "#494a4b"}}></i>
                                                                    {item.starts_time ? ` ${dayjs(item.starts_time, "HH:mm:ss").format("h:mm A")}` : ""}
                                                                    {item.ends_time ? ` - ${dayjs(item.ends_time, "HH:mm:ss").format("h:mm A")}` : ""}
                                                                </div>

                                                                <div style={{ fontSize: 12, padding: '10px', marginTop: '15px', color: 'rgba(0, 0, 0, 0.70)', backgroundColor: 'rgba(255, 221, 204,0.3 )' }}>{item.decription}</div>
                                                                <Divider orientation='left'>Hosts</Divider>
                                                                {item.hosts ? item.hosts.split(',').map((host, i) => {
                                                                    return ( <Space wrap size={[0, 'small']}><Tooltip title={host}><Tag icon={<UserOutlined />} key={i}>{host}</Tag></Tooltip></Space>)
                                                                }) : <Tag>NA</Tag>}
                                                            </div>
                                                        </Collapse.Panel>
                                                    ))}
                                                </Collapse>
                                            </div>
                                            )}
                                            

                                             {lo_eventDetails && lo_eventDetails?.event_faqs?.length > 0 && (
                                                
                                                <div style={{ padding: '20px 0px 20px 0px'}}> 
                                                {/* <Divider orientation="left" plain >FAQ</Divider> */}
                                                
                                                <Card title="FAQ">
                                                {lo_eventDetails && lo_eventDetails?.event_faqs?.map((item, index) => (
                                                        <div key={item.id} style={{ marginBottom: '25px'}}>
                                                            <div style={{ margin: '20px 0px 10px 0px'}}><Typography.Text strong>Q: {item.question}</Typography.Text></div> 
                                                            <div><Typography.Text>A: {item.answer}</Typography.Text></div>  
                                                        </div>
                                                    ))}
                                                </Card>
                                               
                                                
                                                {/* <Collapse defaultActiveKey={['1']} 
                                                    onChange={onChange}
                                                >
                                                    {la_faqitems.map((item, index) => (
                                                        <Collapse.Panel key={`panel-${index}`} header={item.label}>
                                                        {item.children}
                                                        </Collapse.Panel>
                                                    ))}
                                                    {lo_eventDetails && lo_eventDetails?.event_faqs?.map((item, index) => (
                                                        <Collapse.Panel key={item.id} header={item.question}>
                                                        {item.answer}
                                                        </Collapse.Panel>
                                                    ))}
                                                </Collapse> */}
                                            </div>
                                             )}   
                                           
                                           {lo_eventDetails && lo_eventDetails?.refund_policy && (
                                             <div style={{marginTop:18}}>
                                                <h1 style={{fontSize:20,fontWeight:600}}>Refund & Cancellation Policy</h1>
                                                <div>{ parse(lo_eventDetails.refund_policy)}</div>
                                             </div>
                                           )}
                                            {
                                                lo_eventDetails?.tag_list ? 
                                                <div>
                                                    {/* <Divider orientation="left" plain ><Typography.Title level={5}>Tags</Typography.Title></Divider> */}
                                                    <Col span={24}>
                                                            {/* <Divider orientation="left" plain >Location</Divider> */}
                                                            <h1 style={{fontSize:20,fontWeight:600}}>Tags</h1>
                                                        </Col>
                                                    <Space size={[0, 'small']} wrap>
                                                    {
                                                        lo_eventDetails.tag_list.map((tagName, inx) => <Tag key={inx}><Link to={''}>{tagName}</Link></Tag>)
                                                    }
                                                    </Space>
                                                </div>
                                                : <></>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="cl-4">
                                    <div className="ticket-kart-area">
                                        <div className="ticket-kart-area ticket-krt-btn-area">
                                            {
                                                ls_deviceType == 'xs' ?
                                                <div className='ticket-krt-btn-area-mb'>
                                                <FloatButton shape="square" description="Buy Ticket" loading={lb_modalLoading} className="btn btn-buy-ticket-mb add-btn" id="getTicketbtn"
                                                    onClick={() => setModalVisible(true)}
                                                    disabled={ls_url == "preview-event"}
                                                >Buy Ticket</FloatButton>
                                                </div>
                                                :
                                                <Button loading={lb_modalLoading} className="btn btn-buy-ticket add-btn" id="getTicketbtn"
                                                onClick={() => setModalVisible(true)}
                                                disabled={ls_url == "preview-event"}
                                            >Buy Ticket</Button> 
                                            }
                                            
                                        </div>
                                        <div className="share-section ticket-kart-area" style={{ display: 'block' }}>
                                            <p>Share this events</p>
                                            <ul style={{ margin: 0, padding: 0, }}>
                                                <li style={{ listStyle: 'none' }}>
                                                    <a href={ls_url == "preview-event" ? `#`: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} target={ls_url == "preview-event" ? '_self': '_blank'} rel="noopener noreferrer" style={{
                                                        cursor: 'pointer', transition: .5,
                                                        display: 'inline-block',
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        width: '40px',
                                                        height: '40px',
                                                        textAlign: 'center',
                                                        lineHeight: ' 40px',
                                                        borderRadius: '50%'
                                                    }}>
                                                        <i className="fa-brands fa-facebook-f">

                                                        </i></a></li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <a href={ls_url == "preview-event" ? `#` :`https://twitter.com/intent/tweet?text=${encodeURIComponent(url)}`} target={ls_url == "preview-event" ? '_self': '_blank'} rel="noopener noreferrer"
                                                        style={{
                                                            cursor: 'pointer', transition: .5,
                                                            display: 'inline-block',
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            width: '40px',
                                                            height: '40px',
                                                            textAlign: 'center',
                                                            lineHeight: ' 40px',
                                                            borderRadius: '50%'
                                                        }}><i className="fa-brands fa-twitter"></i></a></li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <a href={ls_url == "preview-event" ? `#` : `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`} target={ls_url == "preview-event" ? `_self` : '_blank'} rel="noopener noreferrer"
                                                        style={{
                                                            cursor: 'pointer', transition: .5,
                                                            display: 'inline-block',
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            width: '40px',
                                                            height: '40px',
                                                            textAlign: 'center',
                                                            lineHeight: ' 40px',
                                                            borderRadius: '50%'
                                                        }}
                                                        ><i className="fa-brands fa-linkedin-in">

                                                        </i></a></li>
                                            </ul>
                                        </div>
                                        <div className="need-help-section">
                                            <p>Need Help?</p>
                                            
                                            <Button type='primary'
                                                className="btn contact-organizer" onClick={() => setContactModalVisible(true)}
                                                disabled={ls_url == "preview-event"}
                                                >Contact the event organizer</Button>
                                            
                                            <Divider/>
                                            <Button size='small' type='link' icon={<FlagOutlined />} disabled={ls_url == "preview-event"} onClick={() => setReportModalVisible(true)}>
                                            Report Event
                                            </Button>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Col span={24} >
                  <Row>
                  <Col   xs={1} sm={1} md={1} lg={1} />
                    <Col xs={8} sm={8} md={8} lg={8}  style={{display:'flex',alignItems:'center'}}>
                    <a href="#!">Privacy Policy</a>
                    </Col>
                    <Col xs={14} sm={14} md={14} lg={14} style={{float:'right',alignItems:'center'}}
                    className="footer-right-section"><p>
                                    <a href="#!" style={{marginTop:15,marginInlineEnd:10}}/>{`Sell tickets online with      `}
                                    <img src={logo} alt=""
                                        style={{ maxWidth: "100%" }}/>
                                </p>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}/>
                  </Row>
                </Col> */}
                <Footer/>
            </div>
        </Spin>

        <BuyTicket {...lo_ticketProps}/>
        <LoginModal {...lo_loginProps}/>
        <ContactModal {...lo_contactProps}/>
        <ReportModal {...lo_reportProps}/>
    </>)
}
export default EventDetails