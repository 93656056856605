import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import Typography from "antd/lib/typography";
import dayjs from "dayjs";
import Table from "antd/lib/table";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import MoreOutlined from "@ant-design/icons/MoreOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import { Link, useNavigate } from "react-router-dom";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Select from "antd/lib/select";
import Space from "antd/lib/space";
import { getRequestApi } from "../../../../api/commonRequest";
import { CSVLink } from "react-csv";
import message from "antd/lib/message";
import "./style.css";
import { PageHeader } from "@ant-design/pro-layout";

const AttendeeReport = () => {
  const ls_organisationId = localStorage.getItem("organisation_id");
  const navigate = useNavigate();
  const [la_attendeeList, setAttendeeList] = useState([]);
  const [lb_loading, setLoading] = useState(false);
  const [la_events, setEventsList] = useState([]);
  const [li_selectedEventId, setSlectedEventId] = useState(null);
  const [la_filteredAttendeeList, setFilteredAttendeeList] = useState([]);
  
  const la_columns = [
    {
      title: "Event Name",
      dataIndex: "event_name",
    },
    {
      title: "Event Date",
      dataIndex: "event_start_date",
      render: (text) => (
        <div>
          {text ? `${dayjs(text, "YYYY-MM-DD").format("MMMM D, YYYY")} ` : ""}
        </div>
      ),
    },
    {
      title: "Ticket Type",
      dataIndex: "ticket_name",
      width: 100,
    },
    {
      title: "Price",
      dataIndex: "ticket_price",
      render: (text) => `£ ${text}`,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      render: (value) => (
        <div>
          {value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ""}
        </div>
      ),
      width: 120,
    },
    {
      title: "Purchaser Name",
      dataIndex: "purchaser_name",
      render: (text, record) =>
        `${record?.purchaser_first_name} ${record?.purchaser_last_name}`,
      width: 135,
    },
    {
      title: "Purchaser Email",
      dataIndex: "purchaser_email",
    },
    {
      title: "Purchaser Phone",
      dataIndex: "purchaser_phone",
    },
    {
      title: "Attended",
      dataIndex: "attend",
    },
    {
      title: "Attended Date",
      dataIndex: "attended_date",
      render: (value) => (
        <div>
          {value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : "-"}
        </div>
      ),
    },
    {
      title: "Manage",
      dataIndex: "manage",
      render: (text, record, inx) => manageButton(record),
    },
  ];

  const handlePrintTicket = (record) => {
    // console.log('called', record);
    // const ls_orderUniqueId = record.id;
    // const ls_printUrl = `${Admin_base_url}/public/generate-ticket-pdf/${ls_orderUniqueId}`;
    // window.open(ls_printUrl, "_blank");
  }
  const getMenu = (record) => {
    return [
      {
        key: "1",
        label: (
          <Button type="link" icon={<DownloadOutlined style={{ marginInlineEnd: 15 }} />} onClick={() => handlePrintTicket(record)}>Download Ticket</Button>
          // <Link
          //   // to={"/event-details/"+record.slug} target="_blank"
          //   className="underLineNone"
          //   rel="noopener noreferrer"
          // >
          //   <DownloadOutlined style={{ marginInlineEnd: 15 }} /> Download Ticket
          // </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link
            //   to={"/events/update/"+record.slug+"/basic-info"}
            className="underLineNone"
          >
            <MailOutlined style={{ marginInlineEnd: 15 }} />
            Resend Confirmation Email
          </Link>
        ),
      },
    ];
  };
  const manageButton = (record, index) => {
    return (
      <div style={{ display: "flex" }}>
        <Dropdown
          menu={{
            items: getMenu(record),
          }}
          trigger={["click"]}
        >
          <Button
            size="middle"
            type="primary"
            shape="circle"
            ghost
            icon={<MoreOutlined />}
            className="button menu-dropdown-btn"
          />
        </Dropdown>
      </div>
    );
  };

  // useEffect(() => {
  //   getUpcomingEventsList();
  //   if (ls_organisationId) {
  //     // console.log('ls_organisationId', ls_organisationId);
  //     getAttendeeList();
  //   }
  // }, [ls_organisationId]);

  const getUpcomingEventsList = async () => {
    // let evntType = "upcomming";
    let evntType = "all"
    const lo_response = await getRequestApi(
      "all-events/" + ls_organisationId + "/" + evntType
    );
    // console.log('lo_response',lo_response);
    if (lo_response?.status === "success") {
      setEventsList(lo_response?.events);
    }
  };

  const getAttendeeList = async () => {
    setLoading(true);
    const la_attendee = await getRequestApi(
      `org-bookings/${ls_organisationId}`
    );
    setLoading(false);
    console.log("la_attendee", la_attendee);
    if (la_attendee?.status === "success") {
      setAttendeeList(la_attendee?.reports);
    }else{
      setAttendeeList([])
    }
  };

  const filterAttendeeList = async () => {
    setLoading(true);
    let filteredList = [...la_attendeeList];
    if (li_selectedEventId) {
      filteredList = la_attendeeList?.filter(
        (attendee) => attendee.event_name === li_selectedEventId
      );
    }
    setFilteredAttendeeList(filteredList);
    setLoading(false);
  };

  useEffect(() => {
    if (li_selectedEventId) {
      filterAttendeeList();
    }else{
      setFilteredAttendeeList(la_attendeeList);
    }
  }, [JSON.stringify(la_attendeeList), li_selectedEventId]);

  useEffect(() => {
    if (ls_organisationId) {
      getUpcomingEventsList();
      getAttendeeList();
    }
  }, [ls_organisationId]);

  return (
    <>
      {/* <Content className="content-layout thumbnail">
          <Row justify={"space-between"} gutter={[14]}>
            <Col xs={24} sm={12} md={16} lg={16}>
              <Typography.Title className="title" level={3}>
                Attendee Summary Report
              </Typography.Title>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}  >
              <Button
                icon={<ArrowLeftOutlined />}
                className="add-btn"
                onClick={() => navigate("/report")}
              >
                Back
              </Button>
            </Col>
          </Row>
      </Content> */}
      <Content className="content-layout thumbnail">
        <PageHeader
          title="Attendee Summary Report"
          className="content-layout content-text thumbnail page-header-content"
          extra={[
            <Row justify={"space-between"} gutter={[14]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="add-btn"
                  onClick={() => navigate("/report")}
                >
                  Back
                </Button>
              </Col>
            </Row>
          ]}
        ></PageHeader>
      </Content>
      <Content className="content-layout">
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="select-event">
            <Space wrap direction="horizontal">
              <Select
                placeholder="All Events"
                style={{ width: '100%' }}
                onChange={(value) => setSlectedEventId(value)}
                allowClear
              >
                {la_events &&
                  la_events.map((event) => {
                    return (
                      <Select.Option value={event.name} key={event.id}>
                        {event.name}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Button icon={<UploadOutlined />} type="primary">
                <CSVLink
                  filename={"Attendee_Report.csv"}
                  data={la_filteredAttendeeList}
                  onClick={() => {
                    message.success("The file is downloading");
                  }}
                >
                  Export to CSV
                </CSVLink> 
              </Button>
            </Space>
          </div>
        </Col>
        <div className="table-container">
          <div>
              <Table
                  pagination={true}
                  scroll={{ x: 900, y:400 }}
                  columns={la_columns}
                  loading={lb_loading}
                  dataSource={la_filteredAttendeeList}
                  className="table-inner-content"
                  rowClassName="row-table"
                  size="middle"
                  rowKey="id"

              />
          </div>
        </div>
      </Content>
    </>
  );
};

export default AttendeeReport;
