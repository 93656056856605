import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
// import Col from "antd/lib/col";
import Table from "antd/lib/table";
// import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Select from "antd/lib/select";
import message from "antd/lib/message";
// import MessageOutlined from "@ant-design/icons/MessageOutlined";
import LineChartOutlined from "@ant-design/icons/LineChartOutlined";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import Button from "antd/lib/button";
import { PageHeader } from "@ant-design/pro-layout";
import { getRequestApi } from "../../../../api/commonRequest";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Col, Row } from "antd/lib";

const TicketSales = () => {
  // const ls_organisationId = localStorage.getItem("organisation_id");
  const ls_organisationId = useSelector( (state) => state.common.organisation_id );
  const navigate = useNavigate();
  const [lb_loading, setLoading] = useState(false);
  const [la_ticketSalesList, setTicketSalesList] = useState([]);
  const [la_events, setEventsList] = useState([]);
  const [li_selectedEventId, setSlectedEventId] = useState('');
  const [la_filteredList, setFilteredList] = useState([]);
  
  useEffect(() => {
    getUpcomingEventsList();
   
  }, [ls_organisationId]);

  // useEffect(() => {
  //   if (la_events.length > 0) {
  //     getTicketSalesListByOrg();
  //   } else {
  //     setTicketSalesList([])
  //   }
  // }, [la_events])
  

  const la_columns = [
    {
      title: "Ticket Type",
      dataIndex: "ticket_name",
      render: (text) => (
        <div style={{ textTransform: "capitalize" }}>{text}</div>
      ),
    },
    {
      title: "Price",
      dataIndex: "cost_to_buyer",
      render: (text) => `£ ${text}`,
    },
    {
      title: "Quantity",
      dataIndex: "total_quantity",
      render: (text) => <div style={{ color: "#24248f" }}>{text}</div>,
    },
    {
      title: "Sold",
      dataIndex: "sold_out",
      render: (text) => <div style={{ color: "#24248f" }}>{text}</div>,
    },
  ];

  const getUpcomingEventsList = async () => {
    // let evntType = "upcomming";
    let evntType = "all"
    const lo_response = await getRequestApi(
      "all-events/" + ls_organisationId + "/" + evntType
    );
    console.log('event_list_response',lo_response);
    if (lo_response?.status === "success") {
      setEventsList(lo_response?.events);
      setSlectedEventId(lo_response?.events?.[0]?.name || '')
    }
  };

  const getTicketSalesListByOrg = async (name = "") => {
    // let event_id = id ? id : "";
    let la_ticketList = []
    setLoading(true);
    // const la_ticketType = await getRequestApi(
    //   `org-ticket-sales?organisation_id=${ls_organisationId}&event_id=${event_id}`
    // );
    const la_ticketType = await getRequestApi(
      `org-ticket-sales/${ls_organisationId}`
    );
    setLoading(false);
    console.log("la_ticketType", la_ticketType);
    if (la_ticketType?.status === "success") {
      la_ticketList = la_ticketType?.reports || [];
      // if (name != "") {
      //   la_ticketList.filter((ticket) => ticket.event_name == name)
  
      // }
    }
    setTicketSalesList(la_ticketList);
  };

  const onChangeEvent = async (event_name) => {
    console.log('event_name',event_name);
    setSlectedEventId(event_name)
  };

  const filterList = async () => {
    setLoading(true);
    let filteredList = [...la_ticketSalesList];
    if (li_selectedEventId) {
      filteredList = la_ticketSalesList?.filter(
        (rowValue) => rowValue.event_name === li_selectedEventId
      );
    }
    setFilteredList(filteredList);
    setLoading(false);
  };

  useEffect(() => {
    if (li_selectedEventId) {
      filterList();
    }
  }, [JSON.stringify(la_ticketSalesList), li_selectedEventId]);

  useEffect(() => {
    getTicketSalesListByOrg();
  }, [ls_organisationId])
  
  useEffect(() => {
   console.log('----la_filteredList',la_filteredList);
  }, [la_filteredList])
  

  return (
    <>
      <Content className="content-layout thumbnail">
        <PageHeader
          title="Sales By Ticket Type"
          className="content-layout content-text thumbnail page-header-content"
          extra={[
            <Row justify={"space-between"} gutter={[14]}>
              <Col xs={24} sm={12} md={16} lg={16}>
                <Link to={"/attendee-report"}>
                  <Button icon={<LineChartOutlined />} type="primary">
                    Attendee summary report
                  </Button>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="add-btn"
                  onClick={() => navigate("/report")}
                >
                  Back
                </Button>
              </Col>
            </Row>
          ]}
        ></PageHeader>
      </Content>
      <Content className="content-layout">
        <Space wrap className="select-event">
          
          { la_events?.length > 0 &&<Select
            placeholder="Select Event"
            onChange={onChangeEvent}
            style={{ width: '100%' }}
            value={li_selectedEventId}
            // allowClear
            // defaultValue={la_events?.[0]?.name || null}
          >
            {la_events &&
              la_events.map((event) => {
                return (
                  <Select.Option value={event.name} key={event.id}>
                    {event.name}
                  </Select.Option>
                );
              })}
          </Select>}
          <Button icon={<UploadOutlined />} type="primary">
            <CSVLink
              filename={"Ticket_Sales.csv"}
              data={la_filteredList}
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to CSV
            </CSVLink>
          </Button>
        </Space>
        <Table
          rowKey="id"
          columns={la_columns}
          className="table-inner-content"
          pagination={true}
          scroll={{ y:400 }}
          loading={lb_loading}
          dataSource={la_filteredList}
          rowClassName="row-table"
          size="middle"
        />
        {/* <Row gutter={[32, 8]}>
          <Col xs={24} sm={24} md={16} lg={16}> 
            
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Row className="attendee-actions-wrapper">
              <Col xs={24} sm={24} md={24} lg={24} className="attendee-actions">
                <div className="action-icon-wrapper">
                  <span className="action-icon">
                    
                  </span>
                 
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={20} className="attendee-actions">
                <div className="action-icon-wrapper">
                  <span className="action-icon">
                    <MessageOutlined />
                  </span>
                  Responses to custom questions
                </div>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </Content>
    </>
  );
};

export default TicketSales;
