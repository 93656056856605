import React from 'react'
// import { ReactSVG } from 'react-svg'

const User = () => {
  return (
  //  <div class="eds-structure__body eds-structure__body--overflow-set">
  //  <section class="eds-structure__drawer eds-structure__right-drawer" data-spec="eds-structure-drawer-right"><div class="eds-structure__drawer-content"></div></section><div class="eds-structure__main-mask eds-structure__main-mask--overflow-set" data-spec="eds-structure-main-mask"><div class="eds-structure__fixed-bottom-bar-layout-wrapper"><div class="eds-fixed-bottom-bar-layout" data-spec="fixed-bottom-bar-layout"><div class="eds-fixed-bottom-bar-layout__content"><div class="eds-structure__main-container"><main class="eds-structure__main" data-spec="eds-structure-main">
  //  <div class="user-profile__profile">
  //  <section class="eds-layout eds-layout--has-large-max-width" data-spec="eds-layout"><div class="eds-layout__body" data-spec="eds-layout__body"><div class="user-profile__profile--main eds-l-pad-bot-20 eds-l-pad-hor-4 eds-l-mn-pad-hor-6 eds-l-md-pad-hor-6 eds-l-mw-pad-hor-6 eds-l-ln-pad-hor-12 eds-l-lg-pad-hor-12 eds-l-lw-pad-hor-12"><div style="height: 100%;"><header class="user-profile-header eds-l-pad-top-20 eds-l-sn-pad-top-12 eds-l-sm-pad-top-12 eds-l-sw-pad-top-12 eds-l-pad-bot-16"><div class="user-profile-header__background">
  //  <div class="user-profile-header__background-image__wrapper">
  //  </div>
  //  </div>
  //  <div class="user-profile-header__content eds-align--center-vertical eds-fx--fade-in-up"><div class="user-profile-header__picture eds-align--center"><i class="eds-vector-image eds-icon--small eds-vector-image--grey-500" data-spec="icon" data-testid="icon" aria-hidden="true">
  //  {/* <ReactSVG id="user_svg__eds-icon--list-view_svg" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path id="user_svg__eds-icon--user_base" fill-rule="evenodd" clip-rule="evenodd" d="M5.2 19.1c1-2.8 3.7-4.7 6.7-4.7s5.7 1.9 6.7 4.7c-4.1 2.5-9.3 2.5-13.4 0zm16.1-1.9c-.5.5-1.1 1-1.7 1.5a8.15 8.15 0 00-7.6-5.2c-3.3 0-6.3 2.1-7.6 5.1-.6-.4-1.1-.9-1.6-1.4l-.8.7C4.8 20.6 8.4 22 12 22s7.2-1.4 10-4.1l-.7-.7z"></path><path id="user_svg__eds-icon--user_head" fill-rule="evenodd" clip-rule="evenodd" d="M12 2C9.2 2 7 4.2 7 7s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"></path></ReactSVG> */}
  //  </i>
  //  </div>
  //  <div class="user-profile-header__info eds-l-pad-left-5">
  //  <div class="user-profile-header__name eds-l-pad-bot-3">
  //  <h1 class="user-profile-header__name-text eds-text-hm">Kritittwa Roy</h1>
  //  <span class="user-profile-header__name-icon eds-l-pad-left-3">
  //  <span class="eds-icon-button eds-icon-button--neutral" data-spec="icon-button">
  //  <button class="eds-btn--button eds-btn--none eds-btn--icon-only profile-edit-button" type="button"><i class="eds-vector-image eds-icon--small eds-vector-image--block" title="" data-spec="icon" data-testid="icon">
  //  {/* <svg id="pencil-chunky_svg__eds-icon--pencil-chunky_svg" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path id="pencil-chunky_svg__eds-icon--pencil-chunky_base" fill-rule="evenodd" clip-rule="evenodd" d="M6 17l8-8 1 1-8 8-1-1zm-2-.3V20h3.3l9.8-9.8-3.3-3.3L4 16.7z"></path><path id="pencil-chunky_svg__eds-icon--pencil-chunky_eraser" fill-rule="evenodd" clip-rule="evenodd" d="M19.7 7.6c.3-.3.3-.9 0-1.3l-2.1-2.1c-.3-.3-.9-.3-1.3 0l-1.6 1.6L18 9.1l1.7-1.5z"></path></svg> */}
  //  <span class="eds-is-hidden-accessible">Edit profile</span></i></button></span></span></div><div class="user-profile-header__stats eds-align--center-vertical">
  //  <a class="user-profile-header__stats-link" data-spec="user-profile-tickets-stats" href="#profile-tickets">0 orders</a><span class="user-profile-header__stats-divisor"></span><a class="user-profile-header__stats-link" href="/u/1645463596933/favorites" data-spec="user-profile-likes-stats">0 likes</a><span class="user-profile-header__stats-divisor"></span>
  //  <a class="user-profile-header__stats-link" data-spec="user-profile-following-stats" href="#profile-following">0 following</a>
  //  </div>
  //  </div>
  //  </div>
  //  </header>
  //  <span style="font-size:0"></span>
  //  </div>
  //  <section class="eds-g-cell eds-g-cell-1-1 eds-g-cell-sw-7-12 eds-g-offset-sw-4-12" id="profile-tickets"><div class="eds-l-pad-bot-8 eds-align-center--vertical eds-align--space-between">
  //  <h2 class="eds-text-bl">Orders</h2>
  //  </div><div class="eds-l-pad-bot-6">
  //  <div class="eds-l-pad-bot-2 eds-text-bl eds-align--center-horizontal">Looking for your tickets?</div><div class="eds-l-pad-bot-2 eds-align--center-horizontal">You need to verify your email to view transfers and gifts.</div><form method="post" id="request_email_verification_form" name="request_email_verification" action="/users/verify-email/request/" class="eds-align--center-horizontal" data-airgap-id="37"><button class="eds-btn eds-btn--submit eds-btn--neutral" type="submit">Verify your email</button>
  //  <input type="hidden" name="csrfmiddlewaretoken" value="116fe5ac184911ee94576f98ea9bb77e"/>
  //  </form>
  //  </div>
  //  <div class="eds-l-mar-bot-3">
  //  <div class="eds-empty-state eds-align--center" data-spec="empty-state"><div>
  //  <div class="eds-empty-state__graphic eds-align--center">
  //  <span class="eds-graphic--halo eds-graphic-halo--xsmall">
  //  <i class="eds-vector-image eds-graphic--small eds-vector-image-size--reset eds-align--center eds-vector-image-halo-size-override" data-spec="icon" data-testid="icon" aria-hidden="true">
  //  {/* <svg viewBox="0 0 144 144">
  //  <g fill="none" fill-rule="evenodd">
  //  <g><path d="M0 0h144v144H0z">
  //  </path>
  //  <path d="M27 84v-.6c0-4.5 4.05-8.25 8.85-8.25 4.95 0 9.15 4.2 9.15 8.55v.3h3V42h42c.6 4.35 4.2 7.5 8.85 7.5 4.65 0 8.25-3.15 8.85-7.5h7.8v51h-7.8c-.6-4.35-4.2-7.5-8.85-7.5-4.65 0-8.25 3.15-8.85 7.5H35.85c-4.95 0-8.7-3-8.85-9z" fill="#D2D6DF" fill-rule="nonzero"></path>
  //  <path fill="#363A43" fill-rule="nonzero" d="M81 103h6v3h-6zM99 103h6v3h-6zM107 103h6v3h-6zM72 103h6v3h-6zM90 103h6v3h-6zM63 103h6v3h-6zM54 102.9h6v3h-6zM45 102.9h6v3h-6zM36 102.9h6v3h-6zM36 94h3v6h-3zM110 94h3v6h-3z"></path><path d="M24 84.45c0 6.6 5.25 11.7 11.85 11.7H93v-1.5c0-3.45 2.55-6 6-6s6 2.55 6 6v1.5h13.5v-57H105v1.5c0 3.45-2.55 6-6 6s-6-2.55-6-6v-1.5H47.7c-1.05-5.7-5.85-9.9-11.7-9.9-6.75 0-12 5.4-12 12.15v43.05zM45 41.4v35.4c-3-2.7-5.55-4.35-9.15-4.35-3.6 0-7.35 1.65-8.85 4.05V41.25c0-5.1 3.9-9.15 9-9.15s9 4.2 9 9.3zM27 84v-.6c0-4.5 4.05-8.25 8.85-8.25 4.95 0 9.15 4.2 9.15 8.55v.3h3V42h42c.6 4.35 4.2 7.5 8.85 7.5 4.65 0 8.25-3.15 8.85-7.5h7.8v51h-7.8c-.6-4.35-4.2-7.5-8.85-7.5-4.65 0-8.25 3.15-8.85 7.5H35.85c-4.95 0-8.7-3-8.85-9z" fill="#363A43" fill-rule="nonzero"></path><path d="M45 41.4v35.4c-3-2.7-5.55-4.35-9.15-4.35-3.6 0-7.35 1.65-8.85 4.05V41.25c0-5.1 3.9-9.15 9-9.15s9 4.2 9 9.3z" fill="#FFF" fill-rule="nonzero"></path><path fill="#363A43" fill-rule="nonzero" d="M97.35 70.8h3v3h-3zM97.35 64.8h3v3h-3zM97.35 76.8h3v3h-3zM97.35 58.8h3v3h-3zM97.35 52.8h3v3h-3z"></path>
  //  <path fill="#3A3A3A" fill-rule="nonzero" d="M54 58.8h37.5v3H54zM54 64.8h15v3H54z"></path></g></g></svg> */}
  //  </i></span></div><div class="eds-align--center"><div class="eds-empty-state__description eds-text--center eds-text-bl eds-text-color--grey-600 eds-l-sn-mar-top-6 eds-l-sm-mar-top-6 eds-l-sw-mar-top-6 eds-l-mn-mar-top-6 eds-l-md-mar-top-6 eds-l-md-mar-top-8 eds-l-mw-mar-top-8 eds-l-ln-mar-top-8 eds-l-lg-mar-top-8 eds-l-lw-mar-top-8">No upcoming orders</div></div></div></div></div><div class="past-orders"><div class="eds-align--center-horizontal eds-l-pad-top-3" data-spec="see-more">
  //  <button class="eds-btn eds-btn--button eds-btn--link profile-see-more-button" type="button">See past orders</button></div></div><div class="eds-l-pad-vert-8"><hr class="eds-divider__hr eds-bg-color--grey-300 eds-divider--horizontal" data-spec="divider-hr" aria-hidden="true"/>
  //  </div>
  //  </section>
  //  <section class="eds-g-cell eds-g-cell-1-1 eds-g-cell-sw-7-12 eds-g-offset-sw-4-12" id="profile-interests"><div class="eds-l-pad-bot-8 eds-align-center--vertical eds-align--space-between"><h2 class="eds-text-bl"><a data-spec="eds-link" href="/u/interests/" class="eds-link eds-link--neutral"><span>Interests</span><i class="eds-vector-image eds-icon--small" data-spec="icon" data-testid="icon" aria-hidden="true">
  //  {/* <svg id="chevron-right-chunky_svg__eds-icon--chevron-right-chunky_svg" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path id="chevron-right-chunky_svg__eds-icon--chevron-right-chunky_base" fill-rule="evenodd" clip-rule="evenodd" d="M10.2 17l5-5-5-5-1.4 1.4 3.6 3.6-3.6 3.6z"></path></svg> */}
  //  </i></a></h2></div><div class="eds-l-pad-vert-8"><hr class="eds-divider__hr eds-bg-color--grey-300 eds-divider--horizontal" data-spec="divider-hr" aria-hidden="true"/>
  //  </div>
  //  </section>
  //  <section class="eds-g-cell eds-g-cell-1-1 eds-g-cell-sw-7-12 eds-g-offset-sw-4-12" id="profile-collections"><div class="eds-l-pad-bot-8 eds-align-center--vertical eds-align--space-between"><h2 class="eds-text-bl"><a data-spec="eds-link" href="/u/1645463596933/favorites/collections" class="eds-link eds-link--neutral"><span>Collections</span><i class="eds-vector-image eds-icon--small" data-spec="icon" data-testid="icon" aria-hidden="true">
  //  {/* <svg id="chevron-right-chunky_svg__eds-icon--chevron-right-chunky_svg" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path id="chevron-right-chunky_svg__eds-icon--chevron-right-chunky_base" fill-rule="evenodd" clip-rule="evenodd" d="M10.2 17l5-5-5-5-1.4 1.4 3.6 3.6-3.6 3.6z"></path></svg> */}
  //  </i></a></h2></div><div class="eds-l-pad-vert-8"><hr class="eds-divider__hr eds-bg-color--grey-300 eds-divider--horizontal" data-spec="divider-hr" aria-hidden="true"/></div></section></div></div></section></div></main><footer class="eds-global-footer eds-g-grid l-mar-top-2" aria-label="">
  //  <h2 class="eds-is-hidden-accessible">Site Navigation</h2><div class="eds-global-footer__base eds-g-grid eds-l-pad-hor-6"><div class="eds-global-footer__copyright eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-2-12 eds-l-pad-vert-4">© 
  //  2023 Ticketkart</div>
  //  <div class="eds-global-footer__general-links eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-8-12 eds-text--center" data-spec="global-footer-general-links"><ul class="eds-l-mar-all-0 eds-l-pad-hor-0"><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/how-it-works" class="eds-global-footer__link">How It Works</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/organizer/pricing/" class="eds-global-footer__link">Pricing</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/support/contact-us" class="eds-global-footer__link">Contact Support</a>
  //  </li>
  //  <li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item eds-hide-md eds-hide-mw eds-hide-ln eds-hide-lg eds-hide-lw" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/l/contact-sales/" class="eds-global-footer__link">Contact Sales</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/about" class="eds-global-footer__link">About</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/blog/" class="eds-global-footer__link">Blog</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/support" class="eds-global-footer__link">Help</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/careers/" class="eds-global-footer__link">Careers</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item">
  //  <a href="https://www.ticketkart.com/blog/press/" class="eds-global-footer__link">Press</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a href="https://investor.ticketkart.com" class="eds-global-footer__link">Investors</a>
  //  </li>
  //  <li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a rel="nofollow" href="https://www.ticketkart.com/security/" class="eds-global-footer__link" data-airgap-id="38">Security</a>
  //  </li>
  //  <li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/platform/" class="eds-global-footer__link">Developers</a></li>
  //  <li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a rel="nofollow" href="https://www.ticketkart.com/l/LegalTerms/" class="eds-global-footer__link" data-airgap-id="39">Terms</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a rel="nofollow" href="https://www.ticketkart.com/privacypolicy/" class="eds-global-footer__link" data-airgap-id="40">Privacy</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/support/articles/en_US/Troubleshooting/supplemental-privacy-notice-for-california-residents?lg=en_US" class="eds-global-footer__link">CA Privacy Notice</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a href="https://www.ticketkart.com/l/accessibility/" class="eds-global-footer__link">Accessibility</a></li><li class="eds-global-footer__link-bullet eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><a rel="nofollow" href="https://www.ticketkart.com/cookies/" class="eds-global-footer__link" data-airgap-id="41">Cookies</a>
  //  </li>
  //  <li class="eds-global-footer__horizontal-link-item" data-spec="global-footer-link-item"><span class="evidon-notice-link"></span></li></ul></div><div class="eds-global-footer__region eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-2-12 eds-l-pad-top-4 eds-l-pad-bot-3"><div class="eds-global-footer__region-selector"><div style="margin-bottom:0px"><div class=""><div class="eds-field-styled eds-field-styled--basic" style="margin-bottom:0px" data-automation="select-field-wrapper" data-spec="select-field" data-heap-redact-attributes="data-val"><div class="eds-field-styled__border-simulation"><div class="eds-field-styled__internal"><div class="eds-field-styled__input-container">
  //  <div class="eds-field-styled__label-wrapper">
  //  <label class="eds-field-styled__label eds-is-hidden-accessible" id="global-footer-select-label" for="global-footer-select" data-spec="label-label"><span class="eds-label__content">Locale</span></label></div><div class="eds-field-styled__select-wrapper"><span class="eds-field-styled__select-value" aria-hidden="true"><span class="eds-field-styled__select-value-text">United States</span><span class="eds-field-styled__select-icon"><i class="eds-vector-image eds-icon--small" data-spec="icon" data-testid="icon" aria-hidden="true">
  //  {/* <svg id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_svg-global-footer-select" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve"><path id="chevron-down-chunky_svg__eds-icon--chevron-down-chunky_base" fill-rule="evenodd" clip-rule="evenodd" d="M7 10.2l5 5 5-5-1.4-1.4-3.6 3.6-3.6-3.6z"></path></svg> */}
  //  </i></span></span><select aria-labelledby="global-footer-select-label" class="eds-field-styled__input eds-field-styled__select" role="listbox" id="global-footer-select" name="global-footer-select"><option value="es_AR" data-spec="select-option">Argentina</option><option value="en_AU" data-spec="select-option">Australia</option><option value="nl_BE" data-spec="select-option">België</option><option value="fr_BE" data-spec="select-option">Belgique</option>
  //  <option value="pt_BR" data-spec="select-option">Brasil</option><option value="en_CA" data-spec="select-option">Canada (EN)</option><option value="fr_CA" data-spec="select-option">Canada (FR)</option><option value="es_CL" data-spec="select-option">Chile</option><option value="es_CO" data-spec="select-option">Colombia</option><option value="en_DK" data-spec="select-option">Denmark</option><option value="de_DE" data-spec="select-option">Deutschland</option><option value="es_ES" data-spec="select-option">España</option><option value="en_FI" data-spec="select-option">Finland</option><option value="fr_FR" data-spec="select-option">France</option><option value="en_HK" data-spec="select-option">Hong Kong</option><option value="en_IE" data-spec="select-option">Ireland</option><option value="it_IT" data-spec="select-option">Italia</option><option value="es_MX" data-spec="select-option">México</option><option value="nl_NL" data-spec="select-option">Nederland</option><option value="en_NZ" data-spec="select-option">New Zealand</option><option value="de_AT" data-spec="select-option">Österreich</option><option value="es_PE" data-spec="select-option">Perú</option><option value="pt_PT" data-spec="select-option">Portugal</option><option value="de_CH" data-spec="select-option">Schweiz</option><option value="en_SG" data-spec="select-option">Singapore</option><option value="fr_CH" data-spec="select-option">Suisse</option><option value="sv" data-spec="select-option">Sverige</option><option value="en_GB" data-spec="select-option">United Kingdom</option><option selected="" value="en_US" data-spec="select-option">United States</option></select></div></div></div></div></div></div></div></div></div></div></footer></div></div><div class="eds-fixed-bottom-bar-layout__bar"></div></div></div></div>
  //  </div>
  <div>
    User form starting
  </div>
  )
}

export default User
