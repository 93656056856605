import React, { useState } from "react";
import "./style.css";
import logo from "../../../image/logo.png"
import message from "antd/lib/message";
import { Api_base_url, PasswordRules } from "../../../../config";
import { Link, useNavigate } from "react-router-dom";
import { isExistProfanity } from "../../../utility";
import { Button, Tooltip } from 'antd';
import InfoCircleOutlined  from "@ant-design/icons/InfoCircleOutlined";

const SignUp = () => {
  let navigate = useNavigate();
  const [isShown, setIsSHown] = useState(false);
  const [lo_responseMessage, setResponseMessage] = useState({})
  const [lb_diclaration, setDiclaration] = useState(true)
  const [lb_checkValue, setcheckValue] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const ls_Msgkey = 'updatable';

  const handleDiclaration = (e) => {
    if (lb_diclaration) {
      setDiclaration(false)
    }else{
      setDiclaration(true)
    }
  }

  const handleCheckValue = (e) => {
    if (lb_checkValue) {
      setcheckValue(false)
    }else{
      setcheckValue(true)
    }
  }
  
  const handleSubmitUser = async (e) => {
    e.preventDefault();
    messageApi.open({
      ls_Msgkey,
      type: 'loading',
      content: 'Loading...',
    });

    if (!lb_diclaration){
      messageApi.destroy()
      message.error('You have to check Terms and Conditions before submit!');
      setResponseMessage({"term_condition":["You have to check Terms and Conditions before submit!"]})
      return;
    }
    const formdata = new FormData(e.target);

    const jsonData = Object.fromEntries(formdata.entries());
    if (!jsonData?.emails_for_marketting) {
      formdata.append("emails_for_marketting", "0");
    }

    const la_allInputText = Object.values(jsonData)
    const li_profinityCount =  await isExistProfanity(la_allInputText)
    if (li_profinityCount && li_profinityCount > 0) {
      messageApi.destroy()
      message.error('You can not use any slang / bad language!');
      return;
    }

    setResponseMessage({})

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(Api_base_url+"organiser-reg", requestOptions)
      .then(response => response.text())
      .then(result => {
        const lo_response = JSON.parse(result)
        console.log('lo_response',lo_response);
        if (lo_response.status == 'success') {
          setDiclaration(false)
          messageApi.open({
            ls_Msgkey,
            type: 'success',
            content: 'Success!',
            duration: 2,
          });
          setResponseMessage({})
          navigate('/organiser/signup-success')
        }else {
          setResponseMessage(lo_response.message)
          messageApi.destroy()
          // message.error('Validation Error!!')
        }
      })
      .catch(error => {
        console.log('error', error)
        messageApi.destroy()
        message.error('Something Wrong!!')
      });
  }

  return (
    <>
      {contextHolder}
      <form onSubmit={handleSubmitUser} method="post" className="sign-up-form">
        <div className="login-header-block">
          <Link to={"/home"}><img src={logo} alt="logo" /></Link>
          <h2 className="title">Join event creators using our platform!</h2>
        </div>
        
        <div className={`input-field ${lo_responseMessage['name'] ? 'input-err-border' : ''}`}>
          <i className="fas fa-user"></i>
          <input type="text" name="name" placeholder="Enter Full Name" />
          {lo_responseMessage['name'] ? <p className="comn-input-err">{lo_responseMessage['name'][0]}</p> : <></>}
        </div>
        <div className={`input-field ${lo_responseMessage['email'] ? 'input-err-border' : ''}`}>
          <i className="fas fa-envelope"></i>
          <input type="email" name="email" placeholder="Enter Email Address" />
          {lo_responseMessage['email'] ? <p className="comn-input-err">{lo_responseMessage['email'][0]}</p> : <></>}
        </div>
        <div className={`input-field ${lo_responseMessage['password'] ? 'input-err-border' : ''}`}
          style={(lo_responseMessage['password']&&lo_responseMessage['password'].length) > 0 ? { marginBottom : (12*lo_responseMessage['password'].length)+'px'} : {} }>
          <i className="fas fa-lock"></i>
          <input type={isShown ? "text" : "password"} name="password" placeholder="Create a password (Must be at least 8 characters)" />
          <button type="button" className="button signup-show-hide-button" onClick={() => setIsSHown((isShown) => !isShown)}>
          {isShown ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-sharp fa-solid fa-eye"></i>}
          </button>

          <Tooltip placement="top" title={PasswordRules}>
            <Button style={{position:"absolute", top:"12px", left:"379px", color:"#836666"}} type="text"><InfoCircleOutlined style={{fontSize:"18px"}} /></Button>
          </Tooltip>
          
          {lo_responseMessage['password'] ? 

          (lo_responseMessage['password'] && lo_responseMessage['password'].map((rowMsg, inx) => <p key={inx} 
            className="comn-input-err"
            style={{ top : (55+(15*inx))+'px'}}
          >
            {rowMsg}
          </p>))
          : <></>}
        </div>
        <div className={`input-field ${lo_responseMessage['password_confirmation'] ? 'input-err-border' : ''}`}>
          <i className="fas fa-lock"></i>
          <input type="password" name="password_confirmation" placeholder="Confirm a password (Must be at least 8 characters)" />
          {lo_responseMessage['password_confirmation'] ? <p className="comn-input-err">{lo_responseMessage['password_confirmation'][0]}</p> : <></>}
        </div>
        <div className="form-group">
          <input name="emails_for_marketting" value={lb_checkValue? 1 : 0} type="checkbox" id="emails_for_marketting" checked={lb_checkValue} onClick={handleCheckValue} />
          <label htmlFor="emails_for_marketting">Opt out of emails about latest product updates, offers, set up advice, and Ticket Kart news</label>
        </div>
        <div className={`form-group ${lo_responseMessage['term_condition'] ? 'checkbox-err-border' : ''}`}>
          <input name="term_condition" value={lb_diclaration? 1 : 0} type="checkbox" id="termCondition" checked={lb_diclaration} onClick={handleDiclaration} />
          <p className="signup__button" style={{fontSize: 14,color: '#303030',display:'flex',flexDirection:'row'}}>
          By clicking "SIGN UP" <p className="checkbox-lbl-trm" style={{marginInlineStart:10}}>I agree to the <a href="/termsandcondition">Terms and Conditions </a> and <a href="/privacypolicy">Privacy Policy</a>.</p>
          </p>
          {lo_responseMessage['term_condition'] ? <p  style={{position:"fixed",left:202, color:"#b97d7d", marginTop:15}}>{lo_responseMessage['term_condition'][0]}</p> : <></>}
        </div>
        <input type="submit" className="btn" value="Sign up" />
      
      </form>
    </>
  );
};

export default SignUp;
