import React, { useEffect, useState } from "react";
import "./style.css";
import Tabs from "antd/lib/tabs";
import Table from "antd/lib/table";
import MailOutlined from "@ant-design/icons/MailOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import FileAddOutlined from "@ant-design/icons/FileAddOutlined";
import OrderedListOutlined from "@ant-design/icons/OrderedListOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import MoreOutlined from "@ant-design/icons/MoreOutlined";
import { Content } from "antd/lib/layout/layout";
import { Typography, Input, Button, Modal, message, Badge } from "antd";
// import debounce from "lodash/debounce";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Image, Menu, Spin } from "antd/lib";
import { Api_base_url } from "../../../config";
import { getPublicRequestApi, getRequestApi } from "../../../api/commonRequest";
import { CopyOutlined, DeleteOutlined, FundViewOutlined } from "@ant-design/icons";
// import { capitalizeFirstLetter } from "../../utility";
// import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-layout'
import { useSelector } from "react-redux";
const { Search } = Input;
const { Title } = Typography;

const { TabPane } = Tabs;

const Event = () => {

    const ls_userType = useSelector( (state) => state.common.userType );
    const ls_token = useSelector( (state) => state.common.token );
    const ls_organisationId = useSelector( (state) => state.common.organisation_id );
    
    const [lb_loading, setLoading] = useState(false);
    const [la_EventsList, setEventsList] = useState();
    const [ls_searchText, setSearchText] = useState("");
    const [li_page, setPage] = useState(1);
    const [total, setToatal] = useState(0);
    const [li_pageSize, setPageSize] = useState(10);
    const [li_totalPage, setTotalPage] = useState(0);
    const [ls_tabIndex, setTabIndex] = useState("upcomming");
    const [lb_spin, setSping] = useState(true);

    const [lb_paymentAccountStatus, setPaymentAccountStatus] = useState(0)

    const navigate = useNavigate();
    
    const onStatusChange = (value) => {
        setTabIndex(value);
        getEventssList(value);
    };
    
    const getSubjectAndClassComponent = (record) => {
        const la_classSubjectDetails = record.class_subject_details;
        const subjectAndClasses = la_classSubjectDetails.map((rowValue) => <Typography.Text key={rowValue.class_id} code>{rowValue.subject_name} ({rowValue.class_name})</Typography.Text>)
        return subjectAndClasses;
    }
    const la_columns = [
        {
            title: "Event Date",
            dataIndex: 'starts_date',
            width: '10%',
            render: (value) => (
                // <div>{value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ''}</div>
                <div className="event-date-content">
                        <p style={{ marginBottom: 0, marginTop: 5, color: 'red', textTransform: "capitalize" }}>
                        {dayjs(value, "YYYY-MM-DD").format("MMM")}
                        </p>
                        <h3 style={{ marginBottom: 0 }}>{dayjs(value, "YYYY-MM-DD").format("D")}</h3>
                </div>
            ),
        },
        {
            title: "Image",
            dataIndex: 'images',
            width: '10%',
            render: (text,record) => (
                <div><Image
                width={80}
                src={record?.images[0]?.public_url}
              /></div>
            ),
        },
        {
            title: "Event Name",
            dataIndex: 'name',
            width: '45%',
            // render: (record) => (
            //     <div>{record.name.first + " " + record.name.last}</div>
            // ),
        },
        
        {
            title: "Sold",
            dataIndex: "minimumTicketPrice",
            width: '7%',
            // group: 'primary_email'
            render: (text) => {
                if (text === "0.00") {
                    return "Free"
                } else {
                    return `£ ${text}`
                }
            }
        },
        {
            title: "Gross",
            dataIndex: "gross",
            width: '5%',
            // render: (record) => (
            //     <div>{record.job_role}</div>
            // ),
        },
        {
            title: "Status",
            dataIndex: "status",
            // width: 130,
            width: '20%',
            render: (text, record) => {
            var isBetween = require('dayjs/plugin/isBetween')
            dayjs.extend(isBetween)
            if (record.is_publish == 0) {
                return <Badge color='red' text={<span style={{ color: 'darkred'}}>Draft</span>} />
            } else if (dayjs().isBetween(record.starts_date, dayjs(record.ends_date), 'day', '[]')) {
                return <Badge color='green' text={<span style={{ color: 'darkgreen'}}>Live</span>} />
            } else if (dayjs().isAfter(dayjs(record.ends_date))) {
                return <Badge color='#808080' text={<span style={{ color: 'darkgrey'}}>Event Ended</span>} />
            } else if (dayjs().isBefore(dayjs(record.starts_date))) {
                return <Badge color='blue' text={<span style={{ color: 'darkblue'}}>On Sale</span>} />
            }
            }
        },
        {
            title: "Manage",
            dataIndex: "manage",
            render: (text, record, inx) => manageButton(record),
            width: '5%'
        },
    ];
    const handleArchiveAndRestoreUser = (record, value) => {
        // Modal.confirm({
        //     title: "Confirm",
        //     icon: <ExclamationCircleOutlined />,
        //     content: `Do you want to ${
        //         value == "Ar" ? "archive" : "restore"
        //     } this Events?`,
        //     async onOk() {
        //         try {
        //             await meteorAsyncCall(
        //                 "student.archiveAndRestorUser",
        //                 organization_id,
        //                 record._id,
        //                 record.email,
        //                 'user',
        //                 value
        //             );
        //             getEventssList(ls_tabIndex);
        //             message.success(
        //                 `Succesfully ${
        //                     value == "Ar" ? "archived" : "restored"
        //                 }!`
        //             );
        //         } catch (error) {
        //             message.error(error.error);
        //         }
        //     },
        // });
    };

    const getEventssList = async (evntType) => {
        setSping(true)
        const lo_response = await getRequestApi("all-events/"+ls_organisationId+"/"+evntType);
        setSping(false)
        if (lo_response?.events) {
            setEventsList(lo_response.events)
        }else{
            setEventsList([])
        }
    };

    const deleteEvent = (record) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ls_token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(Api_base_url+"event-status-update/"+record.slug, requestOptions)
        .then(response => response.text())
        .then(result => {
            const lo_response = JSON.parse(result)
            if (lo_response.status == 'success') {
                getEventssList(ls_tabIndex);
                message.success(`Succesfully Deleted!`)
            }else {
            // setResponseMessage(lo_response.message)
                message.error(lo_response.message[0])
            }
        })
        .catch(error => {
            console.log('error', error)
            message.error('Something Wrong!!')
        });
    }
    const handleRemoveSpecificEventById = (record) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Typography.Text>Do you want to delete the Event ({record.name})?</Typography.Text>
            </>,
            onOk() {
                deleteEvent(record);
            },
            //// cancelText: (setDelete(false)),
            // onCancel() {
            //     handleSetDataAction()
            // },
        });
    }
    
    const getMenu = (record) => {
        return [{
            key: '1',
            label: (
                <Link 
                to={"/event-details/"+record.slug}
                // to={"/preview-event/"+record.slug} 
                target="_blank" 
                className="underLineNone"
                rel="noopener noreferrer">
                    <Typography.Text><FundViewOutlined style={{marginInlineEnd:15}}/> View</Typography.Text>
                </Link>
            ),
          },
          {
            key: '2',
            label: ls_tabIndex !== "past" && (
              <Link
              to={"/events/update/"+record.slug+"/basic-info"}
              className="underLineNone"
                //   onClick={() => navigate("/events/update/"+record.slug+"/basic-info")}
              >
              <EditOutlined style={{marginInlineEnd:15}}/>
                Edit
              </Link>
            )
          },
          {
            key: '4',
            label: ls_tabIndex !== "past" && (
              <Link
              to={"/events/copy/"+record.slug+""}
              className="underLineNone"
                //   onClick={() => navigate("/events/update/"+record.slug+"/basic-info")}
              >
              <CopyOutlined style={{marginInlineEnd:15}}/>
                Copy
              </Link>
            )
          },
          {
            key: '3',
            label: (
              
                <Link onClick={() => handleRemoveSpecificEventById(record)} 
                  className="underLineNone">
                <DeleteOutlined style={{marginInlineEnd:15}}/>
                Delete
              </Link>
            ),
          }]
        // return (
        // <Menu>
        //     <Menu.Item key="1">Edit Agency</Menu.Item>
        // </Menu>)
    }
    const manageButton = (record, index) => {
        return (
            <div style={{ display: 'flex' }}>
                {/* <Button onClick={() => handleSetDataAction('archive', record, index)} className="delete-button" type="text" icon={<DeleteFilled />} /> */}
                <Dropdown 
                    menu={{
                        items:getMenu(record),
                        // onClick : onClickDropDownMenu
                      }}
                    trigger={['click']}> 
                    <Button size="middle" type="primary" shape="circle" ghost icon={<MoreOutlined />} className='button menu-dropdown-btn' />
                </Dropdown>
                {/* <Button onClick={() => { handleSetDataAction('edit_agency', record, index) }} className="edit-button" type="text" icon={<EditOutlined />} /> */}
            </div>
        )
    }

    const getPaymentAccountStatus = async () => {
        const lo_res = await getPublicRequestApi("connect-account/"+ls_organisationId);
        console.log('>>>>>>>>>>>>>>>>>>>>>>',lo_res);
        setPaymentAccountStatus(lo_res?.account?.status || 0)
    }

    const createEvent = () => {
        navigate('/events/add');
        // if (lb_paymentAccountStatus == 1) {
        //     navigate('/events/add')
        // }else{
        //     message.error('You have to create stripe account before create event!!');
        // }
    };

    // useEffect(() => {
    //     getPaymentAccountStatus();
    // }, [ls_organisationId]);

    useEffect(() => {
        getEventssList(ls_tabIndex);
    }, [li_page, ls_searchText]);
    useEffect(() => {
        let totalPage = Math.ceil(total / 10);
        setTotalPage(totalPage);
    }, [total]);
    
    return (
        <>
          <PageHeader
                title="Manage Events"
                className="content-layout content-text thumbnail page-header-content"
                // breadcrumb={{ routes }}
                extra={[
                        <Button onClick={createEvent}
                            className="add-btn"
                            type="default"
                            icon={<FileAddOutlined />}
                            style={{float:"right"}}
                        >
                            Add Events
                        </Button>
                ]}
            >
                 <Tabs
                    className="thumbnail mt10-lg"
                    onChange={(index) => onStatusChange(index)}
                    // tabBarExtraContent={
                    //     <Search
                    //         placeholder="Search here..."
                    //         // onChange={debounce((e) => {
                    //         //     setSearchText(e.target.value);
                    //         // }, 500)}
                    //         allowClear
                    //     ></Search>
                    // }
                >

                   <TabPane
                        className="content-text"
                        key="upcomming"
                        tab={
                            <span>
                                <MailOutlined /> Upcoming Events
                            </span>
                        }
                    ></TabPane>

                    <TabPane
                        className="content-text"
                        key="all"
                        tab={
                            <span>
                                <MailOutlined /> All Events
                            </span>
                        }
                    ></TabPane>

                    

                    <TabPane
                        className="content-text"
                        key="past"
                        tab={
                            <span>
                                <OrderedListOutlined />
                                Past Events
                            </span>
                        }
                    />

                    <TabPane
                        className="content-text"
                        key="draft"
                        tab={
                            <span>
                                <OrderedListOutlined />
                                Drafts
                            </span>
                        }
                    />
                </Tabs>
            </PageHeader>

        <Spin spinning={lb_spin}>
            
            <Content className="content-layout thumbnail">
                <Table
                    rowKey="id"
                    columns={la_columns}
                    className="table-inner-content"
                    pagination={true}
                    scroll={{ x: 600 }}
                    // pagination={{
                    //     current: li_page,
                    //     total: li_totalPage * 10,
                    //     pageSize: li_pageSize,
                    //     onChange: (li_page, li_pageSize) => {
                    //         setPage(li_page);
                    //         setPageSize(li_pageSize);
                    //     },
                    // }}
                    // rowSelection={rowSelection}
                    loading={lb_loading}
                    dataSource={la_EventsList}
                    rowClassName="row-table"
                    size="middle"
                />
            </Content>
        </Spin>
        </>
    );
};

export default Event;
