import React, { useEffect, useState } from "react";
import './style.css'
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import Space from "antd/lib/space";
import message from "antd/lib/message";
import { Select, Tag } from "antd/lib";
import { useSelector } from "react-redux";
import { getPublicRequestApi, postRequestByTokenParamApi } from "../../../../../../api/commonRequest";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import Typography from "antd/lib/typography/Typography";
import { Link } from "react-router-dom";
import { Descriptions } from "antd";
import { getDeviceType } from "../../../../../utility";

const StripeAccountForm = () => {
    const ls_deviceType = getDeviceType();
    const [form] = Form.useForm();
    const ls_token = useSelector( (state) => state.common.token );
    const ls_userId = useSelector( (state) => state.common.user_id );
    const ls_organisationId = useSelector( (state) => state.common.organisation_id );

    const [lo_paymentAccountStatus, setPaymentAccountStatus] = useState({})
    const [lb_loading, setLoading] = useState(false)
    // const [ls_updateId, setUpdateId] = useState('')
    const [la_countryList, setCountryList] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    const onSubmitForm = async (lo_formValue) => {
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...', });
        setLoading(true)
        const lo_formData = { ...lo_formValue };
        lo_formData['type'] = "standard";
        lo_formData['country'] = "GB";
        lo_formData['organisation_id'] = ls_organisationId;
        const lo_response = await postRequestByTokenParamApi("stripe_create_account", lo_formData, ls_token);
        setLoading(false)
        if (lo_response.status == 'success') {
            if (lo_response?.url) {
                window.location.href = lo_response.url;
            }
        }
        if (lo_response?.message) message.success(lo_response.message)

    }

    const getPaymentAccountStatus = async () => {
        const lo_res = await getPublicRequestApi("connect-account/"+ls_organisationId);
        const lo_accountData = lo_res?.account || {};
        setPaymentAccountStatus(lo_accountData);
        form.setFieldsValue({"email": lo_accountData?.email || ''});
    }

    useEffect(() => {
        if(ls_organisationId) getPaymentAccountStatus();
    }, [ls_organisationId]);

    return (
        <div>
            {
                lo_paymentAccountStatus?.status == 1 ?
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Content style={{paddingTop:10}}>
                        <Tag
                            icon={<CheckCircleOutlined />}
                            color="success"
                            style={{
                            textAlign: "center",
                            padding: ls_deviceType == 'xs' ? "5px 10px 5px 10px" : "10px 20px 10px 20px",
                            marginBottom: 6,
                            width: "100%",
                            }}
                            >
                            <Typography.Title
                            level={3}
                            type="success"
                            style={{ fontSize: ls_deviceType == 'xs' ? "15px" : "24px" }}
                            >
                            Your are successfully connected to stripe.
                            </Typography.Title>
                            <Typography.Title
                            level={4}
                            type="success"
                            style={{ fontSize: ls_deviceType == 'xs' ? "13px" : "18px" }}
                            >
                            You can now publish all your paid <Link to={"/events"}>events</Link>.
                            </Typography.Title>
                        </Tag>
                            <Descriptions
                                // title="Responsive Descriptions"
                                bordered
                                column={{
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 3,
                                xl: 4,
                                xxl: 4,
                                }}
                                items={[{
                                    key: '1',
                                    label: 'Your stripe account Id',
                                    children: lo_paymentAccountStatus?.email,
                                    }]}
                            />
                        </Content>
                    </Col>
                </Row>
                    
                    
                :
                    <Form
                        className="content-form"
                        form={form}
                        labelCol={{
                            span: 7
                        }}
                        onFinish={onSubmitForm}
                    >
                        <Row>

                            <Col span={24}>
                                <Form.Item
                                    labelAlign="right"
                                    label="Email"
                                    name='email'
                                    rules={[{ type: "email", required: true }]}
                                >
                                    <Input disabled={lo_paymentAccountStatus?.email} placeholder="Enter Your Valid Email Id" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Divider className="mt-0" orientation="left" plain></Divider>
                            </Col>
                        </Row>
                        <div style={{ float: "right" }}>
                            <Button loading={lb_loading} type="primary" htmlType="submit" icon={<CheckOutlined />}>Create Account</Button>
                        </div>
                    </Form>  
            }
            
            {/* <Divider style={{ marginTop: 58 }} /> */}


        </div>
    )
}
export default StripeAccountForm