import React from 'react'
import PaymentForm from './PaymentForm'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Typography from 'antd/lib/typography/Typography';
import { Stripe_pk } from '../../../../../config';

const stripePromise = loadStripe(Stripe_pk); // Replace with your actual publishable key

const StripePayment = (props) => {
    const {amount} = props;
  return (
    <div>
      <Typography.Title level={4} style={{ justifyContent: 'center', textAlign: 'center' }}>Complete Payment</Typography.Title>
      <Elements stripe={stripePromise}><PaymentForm {...props}/></Elements>
    </div>
  )
}

export default StripePayment