import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import Result from "antd/lib/result";
import { getRequestApi } from "../../../../api/commonRequest";
const OrderList = () => {
  const navigate = useNavigate();
  const ls_organisationId = localStorage.getItem('organisation_id')
  const [la_OrdersList, setOrdersList] = useState([]);
  const [lb_loading, setLoading] = useState(false);
  const la_columns = [
    {
      title: "Order#",
      dataIndex: "id",
    },
    {
      title: "Event Name",
      dataIndex: "event",
      render: (text, record) => record?.event?.name
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      render: (text, record) => `${record.billing_first_name} ${record.billing_last_name}`
    },
    
    {
      title: "Quantity",
      dataIndex: "total_quantity",
    },
    {
      title: "Price",
      dataIndex: "total_amount",
      render: (text) => `£ ${text}`,
    },
    {
      title: "Date",
      dataIndex: "booking_date",
      render: (value) => (
        <div>
          {value ? dayjs(value, "YYYY-MM-DD").format("MMMM D, YYYY") : ""}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getOrganiserOrderListByOrganisation()
  }, [ls_organisationId])

  const getOrganiserOrderListByOrganisation = async () => {
    setLoading(true);
    let la_orders = await getRequestApi(`org-all-bookings/${ls_organisationId}`);
    setLoading(false);
    console.log('la_orders', la_orders);
    if (la_orders?.status === "success") {
      setOrdersList(la_orders?.bookings);
    }
  }
  const onRowClick = (record) => {
    navigate(`/order-details/${record?.unique_code}`, { state: record?.event_id});
  };

  const locale = {
    emptyText: (
      <Result
        title=""
        subTitle="No orders for this event yet"
        icon={<FileTextOutlined />}
      />
    ),
  };
  return (
    <>
      <Content className="content-layout thumbnail">
        <Col xs={24} sm={24} md={24} lg={16}>
          <Typography.Title className="title" level={3}>
            Order List
          </Typography.Title>
        </Col>
      </Content>
      <Content className="content-layout">
        <Table
          rowKey="id"
          columns={la_columns}
          className="table-inner-content"
          pagination={true}
          scroll={{ x: 600 }}
          loading={lb_loading}
          dataSource={la_OrdersList}
          locale={locale}
          rowClassName="row-table"
          size="middle"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onRowClick(record),
            };
          }}
        />
      </Content>
    </>
  );
};

export default OrderList;
