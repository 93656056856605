import React, { useEffect } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card/Card";
import Typography from "antd/lib/typography";
import Divider from "antd/lib/divider";
import Meta from "antd/lib/card/Meta";
import Avatar from "antd/lib/avatar/avatar";
import Space from "antd/lib/space";
import ContainerOutlined from "@ant-design/icons/ContainerOutlined";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import TeamOutlined from "@ant-design/icons/TeamOutlined";
import DashboardCard from "./DashboardCard";
import { useSelector } from "react-redux";
import { FloatButton, Tooltip } from "antd/lib";
import { Link } from "react-router-dom";
import { getRequestApi } from "../../../api/commonRequest";

const { Title, Text } = Typography;

const Dashboard = () => {
  const ls_userType = useSelector((state) => state.common.userType);
  const lo_userDetails = useSelector((state) => state.common.user);

  const getUpdateData = async () => {
    const lo_userDetails = await getRequestApi("loggedin-user");
    console.log('----lo_userDetails----',lo_userDetails);
  }

  const la_organiser_items = [
    {
      header: "Events",
      description: "Create New/View All",
      icon: (
        <CalendarOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "events",
    },
    {
      header: "My Tickets",
      description: "View or Download Tickets",
      icon: (
        <ContainerOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "orders",
    },
    {
      header: "Reports",
      description: "Ticket Sales/Attendee List",
      icon: (
        <BarChartOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "report",
    },
    {
      header: "Org. Settings",
      description: "Edit Organization Details",
      icon: (
        <SettingOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "overview",
    },
    {
      header: "Referrals",
      description: "Refer and earn lifetime commission",
      icon: (
        <TeamOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "referrals",
    },
    {
      header: "Need Help?",
      description: "Help & FAQ",
      icon: (
        <QuestionCircleOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      // url: "contactform",
      url: "faqs",
    },
  ];

  const la_user_items = [
    {
      header: "Browse Events",
      description: "View All Events",
      icon: (
        <CalendarOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "event-list",
    },
    {
      header: "My Tickets",
      description: "View or Download Tickets",
      icon: (
        <ContainerOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "user-orders",
    },
    {
      header: "Referrals",
      description: "Refer and earn lifetime commission",
      icon: (
        <TeamOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      url: "referrals",
    },
    {
      header: "Need Help?",
      description: "Help & FAQ",
      icon: (
        <QuestionCircleOutlined
          style={{
            fontSize: "22px",
            display: "inline-block",
            borderRadius: "60px",
            backgroundColor: "#0000000f",
            padding: "0.7em 0.8em",
          }}
        />
      ),
      // url: "contactform",
      url: "faqs",
    },
  ];

  const organiser_welcome_message = "Welcome to TicketKart, your premier destination for event success. As you log in, you're entering a world where every detail matters, every idea is a possibility, and every event is an opportunity to create something remarkable. Our user-friendly tools, instant payouts, and data-driven insights are here to elevate your event planning. We're dedicated to your success, ready to support you at every step, and excited to see the amazing events you'll bring to life with us."
  const attendee_welcom_message = "Welcome back to TicketKart, where every ticket opens a world of experiences. Your event journey continues here. Explore upcoming events, manage your bookings, and make every moment unforgettable.";

  useEffect(() => {
    getUpdateData()
  }, [])
  

  return (
    <div>
      <Row gutter={16} style={{ padding: 5 }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            headStyle={{ backgroundColor: "#04142c", alignItems: "center" }}
            title={
              <Title level={3} style={{ padding: 10, color: "white" }}>
                Welcome to TicketKart, {lo_userDetails?.name}
              </Title>
            }
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <p
                  style={{
                    backgroundColor: "#fff",
                    fontWeight: 600,
                    fontSize: "12px",
                    lineHeight: 2,
                  }}
                >
                  {ls_userType == "organizer" ? organiser_welcome_message : attendee_welcom_message}
                  {/* // "Currently you are showing: I'm sure you can't wait to get your ticket sales off to a flying start so we've prepared an easy checklist. If you don't like lists just ignore this and feel free to click around the dashboard to find your own way. If you can't find something or need help just click the chat icon in the bottom right corner and ask us anything. P.S. Your free trial allows you to sell up to 5 tickets. After this you will automatically move on to Pay as you go. Find out about cheaper ways to pay, like buying Pre pay credits, here." */}

                </p>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Meta
                    avatar={
                      <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />
                    }
                    title="TicketKart team"
                    description="Wishing you success,"
                  />
                </div>

              </Col>
            </Row>
            <Divider />
            <Row>
              <Space wrap direction="vertical">
                <Title level={3}>How can we help ?</Title>
                <Text type="secondary">
                  Click on the links below to start your journey
                </Text>
              </Space>
            </Row>

            <div style={{ marginTop: 35 }}>
              <Row gutter={[8, 16]}>
                {ls_userType == "organizer"
                  ? la_organiser_items.map((item) => {
                    return (
                      <DashboardCard cardDetails={item} key={item?.header} />
                    );
                  })
                  : la_user_items.map((item) => {
                    return (
                      <DashboardCard cardDetails={item} key={item?.header} />
                    );
                  })}
              </Row>
            </div>
          </Card>
        </Col>
      </Row>

      {ls_userType == "organizer" ?
        <Link to={"/events/add"}>
          <Tooltip title='Create Event' color="#ff7733">
            <FloatButton
              icon={<CalendarOutlined />}
              type="primary"
              style={{
                right: 80,
              }}
            />
          </Tooltip>
        </Link>
        : <></>}

    </div>
  );
};

export default Dashboard;
