import React, { useEffect, useState } from "react";
import './style.css'
import { Link, useNavigate } from "react-router-dom";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import Radio from "antd/lib/radio";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import Space from "antd/lib/space";
import InputNumber from "antd/lib/input-number";
import { Spin } from "antd/lib";
import message from "antd/lib/message";
import { useParams } from "react-router";
import { objectToFormData } from "../../../../utility";
import { Api_base_url } from "../../../../../config";
import { Typography } from "antd";
import { getPublicRequestApi, getRequestApi, postRequestApi } from "../../../../../api/commonRequest";
import CkEditors from "../../../../components/CkEditor";
import UploadAndCropImage from "../../../../components/UploadAndCropImage";
import CreateAgenda from "./CreateAgenda";
import CreateFaq from "./CreateFaq";
import { useSelector } from "react-redux";

const Details = () => {
    const [form] = Form.useForm();
    const formimages = Form.useWatch('images', form);
    const ls_token = useSelector( (state) => state.common.token );
    const ls_organisationId = useSelector( (state) => state.common.organisation_id );
    const lo_params = useParams()
    const { slug } = lo_params
    const [lb_loading, setLoading] = useState(false)
    const [ls_slug, setSlug] = useState(slug)
    const [ls_eventId, setEventId] = useState('')
    const [lb_spin, setSping] = useState(true);
    const [ls_paymentAccountStatus, setPaymentAccountStatus] = useState()
    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    const onSubmitForm = (lo_formValue) => {
        console.log('---lo_formValue---',lo_formValue);
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...',});
        setLoading(true)
        const lo_formData = { ...lo_formValue };
        if (!lo_formData?.refund_policy) lo_formData['refund_policy'] = '';
        if (!lo_formData?.video_url) lo_formData['video_url'] = '';
        

        if (ls_slug) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer "+ls_token);
            const formdata = objectToFormData(lo_formData);
            formdata.append("organisation_id", ls_organisationId);
            console.log('lo_formData',lo_formData);
            let requestOptions = {
                // mode: 'no-cors',
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              };
            
            fetch(Api_base_url+"event-details-update/"+ls_slug, requestOptions)
            .then(response => response.text())
            .then(result => {
                messageApi.destroy()
                setLoading(false)
                console.log('result',JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    getSpecificEventDetails();
                    message.success("Event Updated Succesfully!")
                    navigate("/events/update/"+ls_slug+"/ticket")
                    setEventId(null)
                }else {
                    message.error('Validation Error!!')
                }
            })
            .catch(error => {
                messageApi.destroy()
                setLoading(false)
                console.log('error', error)
                message.error('Something Wrong!!')
            });
        } else {
            messageApi.destroy()
            message.error('Something Wrong!!')
        }
    }

    const getSpecificEventDetails = async () => {
        setSping(true)
        const lo_eventDetails = await getUpdateData();
        console.log('-----------lo_eventDetails',lo_eventDetails);
        setEventId(lo_eventDetails.id)
        const lo_formData = {};
        lo_formData['video_url'] = lo_eventDetails.video_url;
        lo_formData['summery'] = lo_eventDetails.summery;
        lo_formData['decription'] = lo_eventDetails.decription;
        lo_formData['refund_policy'] = lo_eventDetails.refund_policy;
        
        form.setFieldsValue(lo_formData)
        getAllEventImageListAndSet()
        setSping(false)
    }
    const getUpdateData = async () => {
        if(!ls_slug) return;
        const lo_eventDetails = await getRequestApi("events/"+ls_slug);
        return lo_eventDetails.event;
    }

    const handleSubmitImageToUpload = async (lo_fileDetails) => {
        console.log('lo_fileDetails---',lo_fileDetails);
        const lo_formData = {
            "event_id" : ls_eventId,
            "img" : lo_fileDetails.data_url,
            "extension" : lo_fileDetails.type.split('/')[1]
        }
        const lo_response = await postRequestApi("event-img", lo_formData);
        if (lo_response.status == 'success') {
            getAllEventImageListAndSet()
            console.log('uploaded lo_response',lo_response);
        }else {
            message.error('Validation Error!!')
        }
    }

    const getAllEventImageListAndSet = async () => {
        const lo_response = await getRequestApi("all-event-images/"+ls_eventId);
        console.log('lo_response --- get',lo_response);
        
        if (lo_response.status == 'success') {
            if(lo_response?.images) {
                const la_fileListData = [...lo_response.images];
                console.log('value',la_fileListData);
                for (let j = 0; j < la_fileListData.length; j++) {
                    const element = la_fileListData[j];
                    console.log('................element',element);
                    if (element.public_url) {
                    console.log('element??? ????',element);
                        la_fileListData[j]['uid'] = element.id;
                        la_fileListData[j]['name'] = element.img;
                        la_fileListData[j]['status'] = 'done';
                        la_fileListData[j]['url'] = element.public_url;
                        // la_fileListData.push(lo_files)
                    }
                }
                console.log('la_fileListData ????',la_fileListData);
                form.setFieldsValue({"images":la_fileListData})
            }
            
        }else {
            
        }
    }

    const deleteImage = async (delId) => {
        const lo_response = await postRequestApi("delete-event-img/"+delId, {});
        console.log('lo_responselo_response delete',delId, lo_response);
        // getSpecificEventDetails()
        if (lo_response.status == 'success') {
            message.success(`Succesfully Deleted!`)
        }else {
        // setResponseMessage(lo_response.message)
            message.error(lo_response.message[0])
        }
    }

    const getPaymentAccountStatus = async () => {
        const lo_res = await getPublicRequestApi("connect-account/"+ls_organisationId);
        setPaymentAccountStatus(lo_res?.account?.status == 1 ? 'verified' : 'unverified');
    }

    // useEffect(() => {
    //     if(ls_organisationId) getPaymentAccountStatus();
    // }, [ls_organisationId]);
    
    // useEffect(() => {
    //   if (ls_paymentAccountStatus && ls_paymentAccountStatus == 'unverified') {
    //     message.error('You have to create stripe account before create event!!');
    //     navigate('/events');
    //   }
    // }, [ls_paymentAccountStatus])
    

    useEffect(() => {
        getSpecificEventDetails()
    }, [ls_slug, ls_eventId])

    return (
        <div>
            <Spin spinning={lb_spin}>
                <Form
                    className="content-form"
                    form={form}
                    labelCol={{
                        span: 24
                    }}
                    onFinish={onSubmitForm}
                >
                    <Row style={{paddingTop:20}}>
                        <Col span={24}>
                            <Typography.Title level={5}>Images</Typography.Title>
                        </Col>
                        {/* <Col span={24} className="form-input-radio-group">
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                label="Image"
                            >
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                // maxCount={1}
                                >
                                    <Button className="text-button" type="text" icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </Form.Item>

                        </Col> */}
                       
                        <Col span={24} className="imgupld-prnt">
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                label={<div style={{paddingBottom:20}}>
                                    {/* <Typography.Title level={5}>Images</Typography.Title> */}
                                    <Typography.Text type="secondary">Add photos to show what your event will be about.</Typography.Text>
                                </div>}
                                placeholder="Choose Image"
                                name='images'
                                // rules={[
                                //     {
                                //         required: true,
                                //         // message: "Please input!",
                                //     },
                                // ]}
                            >
                        <UploadAndCropImage 
                                    handleSubmitImageToUpload={handleSubmitImageToUpload}
                                    deleteImage={deleteImage}
                                />
                       
                               
                            </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                // label="Video"
                                label={
                                <div style={{paddingBottom:20}}>
                                    <div><Typography.Title level={5}>Video</Typography.Title></div>
                                    <div style={{display:"flex", flexWrap:"nowrap", marginBottom:40}}><Typography.Text type="secondary">Add a video embedded code from Youtube or Vimeo to show your event's vibe. The video will appear with your event images.</Typography.Text></div>
                                </div>}
                                placeholder="Enter URL"
                                name='video_url'
                                // rules={[
                                //     {
                                //         required: true,
                                //         // message: "Please input!",
                                //     },
                                // ]}
                            >
                                {/* <Input/> */}
                                <Input.TextArea rows={5}/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            {/* <Divider orientation="center" plain> */}
                            <Typography.Title level={5}>Summary</Typography.Title>
                            {/* </Divider> */}
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                // label="Short description (140 characters max)"
                                label={<div >
                                <div style={{display:"flex", flexWrap:"nowrap", marginBottom:62, marginTop:40}}>
                                    {/* <Typography.Title level={5}>Short description</Typography.Title> */}
                                    <Typography.Text type="secondary">Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max)</Typography.Text>
                                </div>
                                </div>}
                                placeholder="Enter Summary"
                                name='summery'
                                rules={[
                                    {
                                        required: true,
                                        // message: "Please input!",
                                    },
                                ]}
                            >
                                <Input.TextArea showCount maxLength={140}/>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            {/* <Divider orientation="center" plain> */}
                            <Typography.Title level={5}>Description</Typography.Title>
                            {/* </Divider> */}
                        </Col>

                        <Col span={24} className="col-custom-labl">
                            <Form.Item
                                style={{minHeight:78}}
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                // label="Description"
                                label={<div>
                                    {/* <Typography.Title level={5}>Description</Typography.Title> */}
                                    <Typography.Text type="secondary">Add more details to your event like your schedule, sponsors, or featured guests.</Typography.Text>
                                </div>}
                                placeholder="Enter Description"
                                name='decription'
                                rules={[
                                    {
                                        required: true,
                                        // message: "Please input!",
                                    },
                                ]}
                            >
                                <CkEditors height="calc(60vh - 250px)"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={24}>
                            {/* <Divider className="mt-0" orientation="left" plain> */}
                            {/* <Typography.Title type="secondary" level={5}>Agenda</Typography.Title> */}
                            {/* </Divider> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <CreateAgenda ls_eventId={ls_eventId}/>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={24} style={{marginTop:15}}>
                            {/* <Divider className="mt-0" orientation="left" plain />
                            <Typography.Title type="secondary" level={5}>Faq</Typography.Title> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <CreateFaq ls_eventId={ls_eventId}/>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={24} style={{marginTop:15}}>
                            {/* <Divider orientation="center" plain/> */}
                            <Typography.Title level={5}>Refund & Cancellation Policy</Typography.Title>
                        </Col>
                        <Col span={24} className="col-custom-labl">
                            <Form.Item
                                style={{minHeight:78}}
                                labelAlign="right"
                                // labelCol={{ span: 8 }}
                                // label="Description"
                                // label={<div>
                                //     {/* <Typography.Title level={5}>Description</Typography.Title> */}
                                //     <Typography.Text>Refund & Cancelation Policy</Typography.Text>
                                // </div>}
                                placeholder="Enter Description"
                                name='refund_policy'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Refund & Cancellation Policy!",
                                    },
                                ]}
                            >
                                <CkEditors height="calc(60vh - 250px)"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    
                    <Row>
                        <Col span={24}>
                            <Divider className="mt-0" orientation="left" plain></Divider>
                        </Col>
                    </Row>
                    <div style={{ textAlign:"center" }}>
                        <Button loading={lb_loading} type="primary" htmlType="submit" size="large" style={{width:"30%"}}>Update</Button>
                    </div>
                </Form>
                <Divider style={{marginTop:58}} />
            </Spin>
        </div>
    )
}
export default Details