import React, { useState } from "react";
import "./style.css";
import logo from "../../../../image/logo.png"
import message from "antd/lib/message";
import { Link, useNavigate } from "react-router-dom";
import { Api_base_url } from "../../../../../config";
import { storeDataAfterLogin, storeOrganisationData } from "../../../../utility";
import { Modal } from "antd";
import Login from "../../../PublicAuth/Login";
const LoginModal = (lo_props) => {
  const {lb_modalVisible, setModalVisible, lb_modalLoginVisible, setModalLoginVisible} = lo_props;
  // let navigate = useNavigate();
  // const [isShown, setIsSHown] = useState(false);
  // const [lo_responseMessage, setResponseMessage] = useState({})
  // const [rememberMe, setRememberMe] = useState(true);

  const actionAfterCompleteModalLogin = (e) => {
    setModalLoginVisible(false)
    if(!lb_modalVisible) setModalVisible(true)
  };
  
  return (
    <>
      <Modal
            footer={false}
            // title={title}
            open={lb_modalLoginVisible}
            // onOk={onCancel}
            onCancel={() => setModalLoginVisible(false)}
            // confirmLoading={lb_loading}
            className='modal'
            width={500}
            style={{
                maxHeight: '92%',
                overflow: 'hidden',
                marginTop: -70, 
                marginBottom: 20
            }}

        >
          <Login lb_isModal={true} actionAfterCompleteModalLogin={actionAfterCompleteModalLogin} />
      </Modal>
    </>
  );
};

export default LoginModal;