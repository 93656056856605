import React from 'react';
import helpCenter from '../../../../assets/img/faq/help-desk.png';
import faqs from '../../../../assets/img/faq/faq.png';
import demo from '../../../../assets/img/faq/wand.png';
import "../../../../assets/css/custom-style.css";
import { Link } from 'react-router-dom';

const Faqs = () => {
  return (
    <div className="container-s98cus">
      <div className="container-s98cus">
        <div className="row-s98cus" style={{display:'flex',
        flexDirection:'row',justifyContent:'space-between'}}>
          <Link to={'/faqs'} className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus smmall-faqs">
            <div className="helpFaqDemo-div">
              <img src={helpCenter} alt="" />
              <h3 className="mt-3-s98cus" style={{color:'black'}}>Visit Help Center</h3>
          </div>
          </Link>
          <Link to={'/faqs' } className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus smmall-faqs">
            <div className="helpFaqDemo-div">
              <img src={faqs} alt="" />
              <h3 className="mt-3-s98cus" style={{color:'black'}}>See our FAQs</h3>
            </div>
          </Link>
          <Link to={'/contactform'} className="col-lg-4-s98cus col-md-4-s98cus col-sm-12-s98cus smmall-faqs">
            <div className="helpFaqDemo-div">
              <img src={demo} alt="" />
              <h3 className="mt-3-s98cus" style={{color:'black'}}>Book a Demo</h3>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
