import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const PricingFooter = () => {
  return (
    <div className="pricing_footer_conatiner">
    <center>
      <h2>Sign up and get started</h2>
      <p>Make an impact, one event at a time.</p>
      <button className="host-btn"><Link to={"/events/add"} className='color'>Create an Event</Link></button>
    </center>
  </div>
  )
}

export default PricingFooter
