
import React, { useState, useEffect } from 'react';
import FilterFeatures from './FilterFeatures';
import EventCards from './EventCards';
import FilterSection from './FilterSection';
import eventbanner from '../../../../assets/img/events/event-list-banner.jpg'
import { getPublicRequestApi } from '../../../../api/commonRequest';
import "./style.css";
import { useLocation, useParams } from 'react-router-dom';


const EventsPage = () => {
  const li_count = 8;
  const [isMobile, setIsMobile] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [la_data, setData] = useState([]);
  const [la_events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([])

  const lo_params = useParams()
  const location = useLocation()
  const { slug } = lo_params

  useEffect(() => {
    getEventsList()
    // const handleResize = () => {
    //   // setIsMobile(window.innerWidth < 800);
    // };

    // handleResize(); // Check on component mount
    // window.addEventListener('resize', handleResize);
    // return () => window.removeEventListener('resize', handleResize);
  }, [slug]);

  const getEventsList = async () => {
    let lo_res;
    // let event_url = location.pathname.split("/")[1];
    
    // console.log('event_url', slug);
    if (slug !== undefined) {
      lo_res = await getPublicRequestApi("events-search/"+slug);
    } else {
      lo_res = await getPublicRequestApi("events");
    }
    // console.log('lo_res', lo_res);
    setData(lo_res?.events ? lo_res.events : [])
    if (lo_res?.events?.length > 0 ){
      var newArr = lo_res.events.slice(0, li_count);
      setEvents(newArr)
      setAllEvents([...lo_res.events])
    }else{
      setEvents([])
      setAllEvents([])
    }
    setInitLoading(false);
    console.log('lo_event',lo_res?.events);
  }

  const lo_event_props = { initLoading, la_data, la_events, li_count, setEvents, getEventsList }
  const lo_filter_props= { setEvents, allEvents }

  return (
    <>
      {/* <img src={eventbanner} className="aboutus__banner"/>
      <div className="container-s98cus event-header">
        <div className="event-header-content">
          <h2 className="text-center-s98cus" style={{marginTop:110}}>UPCOMING EVENTS</h2>
        </div>
      </div> */}

    <div className="container_price_main">
       <div className="container-s98cus pricing-conatiner">
      <h2 className="heading_price">
        <span style={{ color: "#ff8d1d" }}>Browse Events</span>  
      </h2>
      <div className="s98cus-img">
        <img src={eventbanner} alt="" />
      </div>
      </div>
       </div>
      <div className="container-s98cus">
        {/* Content section */}
        <div className="event-section container-s98cus">
          <div className="row-s98cus">
            {isMobile ? (
              <>
                <div className="col-lg-12-s98cus">
                  <FilterFeatures {...lo_filter_props}/>
                  <EventCards {...lo_filter_props}/>
                </div>
                <div className="col-lg-12-s98cus d-flex-s98cus justify-content-center-s98cus">
                  {/* Render FilterSection here */}
                  {/* <FilterSection /> */}
                </div>

              </>
            ) : (
              <>
                {/* <div className="col-lg-3-s98cus filter-div">
                  <FilterSection />
                </div> */}
                <div className="col-lg-12-s98cus col-md-12-s98cus d-flex-s98cus align-items-center-s98cus flex-column-s98cus all-content-div" style={{paddingBottom:10 ,}}>
                  {/* Render FilterFeatures and EventCards here */}
                  <FilterFeatures {...lo_filter_props}/>
                  <EventCards {...lo_event_props}/>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsPage;