import { message } from 'antd/lib';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Api_base_url } from '../../../../config';
import { getUserInformationData, storeDataAfterLogin, storeOrganisationData } from '../../../utility';
import { getRequestApi, getRequestByTokenParamApi, postRequestApi, postRequestByTokenParamApi } from '../../../../api/commonRequest';

const EmailVerification = () => {

    const lo_params = useParams();
    const ls_encriptionId = lo_params?.id;
    let navigate = useNavigate();

    // should make it comon
    const getAndsetAllOrganizationList = async (ls_token) => {
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer "+ls_token);
        // var requestOptions = {
        //     method: 'GET',
        //     headers: myHeaders,
        //     redirect: 'follow'
        //   };

        // fetch(Api_base_url+"all-organisations", requestOptions)
        // .then(response => response.text())
        // .then(result => {
        //     const lo_response = JSON.parse(result)
        //     console.log('lo_response',lo_response);
        //     if (lo_response?.organisations) {
        //       storeDataAfterLogin(null, lo_response.organisations)
        //     }
        //     navigate('/dashboard')
        // })
        // .catch(error => console.log('error', error));

        

        const lo_response = await getRequestByTokenParamApi("all-organisations", ls_token);
        if (lo_response?.organisations && lo_response.organisations.length > 0) {
            console.log('lo_response--- ogr created',lo_response);
            await storeDataAfterLogin(null, lo_response.organisations)
            if(lo_response.organisations.length == 1) await storeOrganisationData(lo_response.organisations[0])
        }else{
            const userDetails = await getUserInformationData();
            const lo_userDetails = {...userDetails.user};
            const lo_insertData = { 
                "name" : lo_userDetails.name,
                "country" : 1,
                // "address" : lo_userDetails.address,
                "contact_person" : lo_userDetails.name,
                "contact_email" : lo_userDetails.email,
                "contact_phone_prefix" : "+44",
                // "contact_phone" : lo_userDetails.phone_no,
            }
            const lo_response = await postRequestByTokenParamApi("organisation-create", lo_insertData, ls_token)
            console.log('-- create request lo_response',lo_response, userDetails);
            if (lo_response.status == 'success') {
                getAndsetAllOrganizationList(ls_token);
            }
        }
        navigate('/dashboard')
    }

    const checkForEmailVerification = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("encryption_key", ls_encriptionId);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(Api_base_url+"verify-email", requestOptions)
        .then(response => response.text())
        .then(async (result) => {
            const lo_response = JSON.parse(result)
            console.log('lo_response---',lo_response);
            if (lo_response.status == 'success' && lo_response.token) {
                
                await storeDataAfterLogin(lo_response)
                getAndsetAllOrganizationList(lo_response.token)
                message.success("Verified successfull!");

            }else {
                message.error(lo_response.message)
            }
        })
        .catch(error => {
            console.log('error', error)
            message.error('Something Wrong!!')
        });
    }

    useEffect(() => {
        console.log('useEffect',ls_encriptionId);
        if(ls_encriptionId) checkForEmailVerification()
    }, [lo_params?.id])
    

//   return (
//     <div>index</div>
//   )
}

export default EmailVerification