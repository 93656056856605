import React, { useState , useEffect } from "react";
import './style.css'
import Content from "antd/lib/layout/layout";
import Tabs from "antd/lib/tabs";
import { Outlet, useNavigate } from "react-router";
import { Button, Typography } from "antd/lib";
// import AppstoreAddOutlined from "@ant-design/icons/AppstoreAddOutlined";
// import UndoOutlined from "@ant-design/icons/UndoOutlined";
// import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
// import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
// import SolutionOutlined from "@ant-design/icons/SolutionOutlined";
import StripeAccountForm from "./StripeAccountForm";

const CreateStripeAccount = () => {
  return (
    <div>
            <div className="form-header">
                <Typography.Title level={4}>Stripe Account</Typography.Title>
                {/* <Link to="/dashboard" key="1">
                    <Button key="2" className='back-button' icon={<ArrowLeftOutlined />}>
                        Back
                    </Button>
                </Link> */}
            </div>
            <Content className="acc-stripe-sec">
                <StripeAccountForm/>
            </Content>
        </div >
  )
}

export default CreateStripeAccount