import React from "react";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";
import Col from "antd/lib/col";
import { faTag, faListUl, faUser } from "@fortawesome/free-solid-svg-icons";
import OrganiserReportCard from "./ReportCard";

const { Title, Text } = Typography;
const Reports = () => {
  const la_items = [
    {
      header: "Sales Summary",
      description: "An overview of all ticket sales, including fees and taxes",
      icon: faTag,
      url: "sales-summary",
    },
    {
      header: "Sales by Ticket Type",
      description: "Ticket type wise overall sales for each event",
      icon: faListUl,
      url: "ticket-sales",
    },
    {
      header: "Attendee Summary",
      description: "View attendee details like name, email and tickets",
      icon: faUser,
      url: "attendee-report",
    },
  ];
  return (
    <>
      <Row gutter={16} style={{ padding: 5 }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Title level={3}>Reports</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Text>Data for your events sales & attendee</Text>
        </Col>
      </Row>
      <div style={{ marginTop: "45px" }}>
        <Row gutter={[32, 16]}>
          {la_items.map((item) => {
            return (
              <OrganiserReportCard cardDetails={item} key={item?.header} />
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default Reports;
