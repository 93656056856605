import React, { useEffect, useState } from "react";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import Space from "antd/lib/space";
import message from "antd/lib/message";
import { getCountryIdByCountryName, objectToFormData } from "../../../utility";
import { getRequestApi } from "../../../../api/commonRequest";
import { Api_base_url } from "../../../../config";
import { getCountryListCommonApi } from "../../../../api/masterDataRequest";
import { Select } from "antd/lib";
import './style.css'

const AccountInfo = () => {
    const [form] = Form.useForm();

    const ls_token = localStorage.getItem('token')
    const ls_userId = localStorage.getItem('user_id')

    const [lb_loading, setLoading] = useState(false)
    // const [ls_updateId, setUpdateId] = useState('')
    const [la_countryList, setCountryList] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    const onSubmitForm = (lo_formValue) => {
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...', });
        lo_formValue.address2 = lo_formValue.address2 ? lo_formValue.address2 : ''
        lo_formValue.state = lo_formValue.state ? lo_formValue.state : ''
        setLoading(true)
        const lo_formData = { ...lo_formValue };

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + ls_token);
        const formdata = objectToFormData(lo_formData);
        console.log('lo_formData', lo_formData);
        let requestOptions = {
            // mode: 'no-cors',
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(Api_base_url + "update-user-account", requestOptions)
            .then(response => response.text())
            .then(result => {
                messageApi.destroy()
                setLoading(false)
                console.log('result', JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    getSetSpecificUserDetails();
                    message.success("Account Updated Succesfully!")
                } else {
                    message.error('Validation Error!!')
                }
            })
            .catch(error => {
                messageApi.destroy()
                setLoading(false)
                console.log('error', error)
                message.error('Something Wrong!!')
            });
    }

    const getSetSpecificUserDetails = async () => {
        const lo_userDetails = await getUpdateData();
        console.log('-----------lo_userDetails', lo_userDetails);
        if (lo_userDetails) {
            let ls_country = lo_userDetails?.country_id;
            if (!lo_userDetails?.country_id || lo_userDetails.country_id == 0) {
                ls_country = await getCountryIdByCountryName('United Kingdom', la_countryList);
            }
            const lo_formData = {};
            lo_formData['name'] = lo_userDetails.name;
            lo_formData['country_id'] = ls_country;
            lo_formData['phone_no_prefix'] = lo_userDetails.phone_no_prefix ? lo_userDetails.phone_no_prefix : '+44';
            lo_formData['phone_no'] = lo_userDetails.phone_no;
            lo_formData['address'] = lo_userDetails.address;
            lo_formData['city'] = lo_userDetails.city;
            lo_formData['state'] = lo_userDetails.state;
            lo_formData['post_code'] = lo_userDetails.post_code;
            form.setFieldsValue(lo_formData)
        }
    }
    const getUpdateData = async () => {
        const lo_userDetails = await getRequestApi("loggedin-user");
        console.log('lo_userDetails', lo_userDetails);
        return lo_userDetails.user;
    }

    const getCountryList = async () => {
        const result = await getCountryListCommonApi();
        setCountryList(result)
    }
    const handleChangeCountry = (ls_value, e) => {
        // console.log('country',ls_value, e.key);
        if (e?.key) {
            const ls_countryPhCode = la_countryList[e?.key]?.phone_no_prefix;
            form.setFieldsValue({ 'phone_no_prefix': ls_countryPhCode });
        } else {
            form.setFieldsValue({ 'phone_no_prefix': null });
        }

    }

    useEffect(() => {
        getSetSpecificUserDetails();
    }, [ls_userId, JSON.stringify(la_countryList)])

    useEffect(() => {
        getCountryList()
    }, [])
    

    return (
        <div>
            <Form
                className="content-form"
                form={form}
                labelCol={{
                    span: 7
                }}
                onFinish={onSubmitForm}
            >
                <Row>

                    {/* <Col span={24} className="form-input-radio-group">
                        <Form.Item
                            labelAlign="right"
                            // labelCol={{ span: 8 }}
                            label="Image"
                        >
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                            // maxCount={1}
                            >
                                <Button className="text-button" type="text" icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>

                    </Col> */}

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Name"
                            name='name'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Address Line 1"
                            name='address'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Address Line 2 (Optional)"
                            name='address2'
                            rules={[{ required: false }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="City"
                            name='city'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="County"
                            name='state'
                            rules={[{ required: false }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Post Code"
                            name='post_code'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} >
                        <Form.Item
                            labelAlign="right"
                            label="Country"
                            name='country_id'
                            rules={[{ required: true }]}
                        >
                            <Select
                                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                placeholder="Country" className="country__select"
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={handleChangeCountry}
                            >
                                {
                                    la_countryList && la_countryList.map((countryItem, countryIndex) => {
                                        return (
                                            <Select.Option key={countryIndex} value={countryItem.id}>{countryItem.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Col span={24} className="phoneView">
                        
                        <Space.Compact style={{width:"100%"}}>
                            <Form.Item
                                labelAlign="right"
                                label="Phone"
                                labelCol={18}
                                name='contact_phone_prefix'
                                rules={[{ required: true }]}
                                style={{ width: '29%',}}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>

                            <Form.Item
                                // labelAlign="right"
                                // label="Phone"
                                name='contact_phone'
                                rules={[{ required: true }]}
                                style={{ width: '71%',}}
                                className='contact_phone'
                            >
                                <Input />
                            </Form.Item> 
                        </Space.Compact>
                        
                </Col> */}
                    <Col span={24}>
                        <Row>
                        <Col  xs={0} sm={0} md={5} lg={5}></Col>
                        <Col   xs={24} sm={24} md={19} lg={19}>
                            <Space.Compact style={{ width: "100%" }}>
                                <Form.Item
                                    labelAlign="right"
                                    label="Phone"
                                    labelCol={14}
                                    name='phone_no_prefix'
                                    rules={[{ required: true }]}
                                    style={{ width: '23%', }}
                                >
                                    <Input readOnly={true} />
                                </Form.Item>

                                <Form.Item
                                    // labelAlign="right"
                                    // label="Phone"
                                    name='phone_no'
                                    rules={[{ required: true }]}
                                    style={{ width: '77%', }}
                                    className='contact_phone'
                                >
                                    <Input />
                                </Form.Item>
                            </Space.Compact>
                        </Col>
                        </Row>
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <Divider className="mt-0" orientation="left" plain></Divider>
                    </Col>
                </Row>
                <div style={{ float: "right" }}>
                    <Button loading={lb_loading} type="primary" htmlType="submit" icon={<CheckOutlined />}>Update</Button>
                </div>
            </Form>

            <Divider style={{ marginTop: 58 }} />


        </div>
    )
}
export default AccountInfo