import React from "react";
import Col from "antd/lib/col";
import Card from "antd/lib/card/Card";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const DashboardCard = ({ cardDetails }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(`/${path}`);
  };
  return (
    <>
      <Col xs={12} sm={12} md={6} lg={4}>
        <Card
          title={""}
          bordered
          hoverable
          onClick={() => handleClick(cardDetails?.url)}
        >
          <Row gutter={[0, 0]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              {cardDetails?.icon}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Title level={5}>{cardDetails?.header}</Title>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Text type="secondary" style={{ fontSize: "10px" }}>
                {cardDetails?.description}
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default DashboardCard;
