import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from 'react';
import InboxOutlined from "@ant-design/icons/InboxOutlined";

const UploadAndCropImage = ({value = [], onChange = () => { }, handleSubmitImageToUpload, deleteImage}) => {
  const [fileList, setFileList] = useState(value);

  const [lo_newUpload, setNewUpload] = useState()
  const onImageChange = async ({ fileList: newFileList }) => {
    
    const newUpload = await setImagepathAndDetails(newFileList)
    
    // if(lo_newUpload) handleSubmitImageToUpload(lo_newUpload);
    if(newUpload >= 0) setNewUpload(newUpload+1);
  };

  const setImagepathAndDetails = async (newFileList) => {
    const la_fileList = [...newFileList];
    let newUpload;
    for (let j = 0; j < la_fileList.length; j++) {
        const element = la_fileList[j];
        if (!element.data_url) {
            const ls_fileUrl = await generateSingleFileUpload(element)
            la_fileList[j]['data_url'] = ls_fileUrl;
            newUpload = j;
        }
    }
    // console.log('la_fileList',la_fileList);
    setFileList(la_fileList);
    onChange(la_fileList)
    return newUpload;
  }

  useEffect(() => {
    // console.log('lo_newUpload >>>>',fileList, lo_newUpload, fileList[lo_newUpload-1]);
    if(lo_newUpload > 0 && fileList[lo_newUpload-1]?.data_url) {
      // console.log('upload');
      handleSubmitImageToUpload(fileList[lo_newUpload-1])
    }
    // setNewUpload(null)
    // return () => {
    //   setNewUpload(null)
    // }
  }, [lo_newUpload])
  

    const generateSingleFileUpload = async (file) => {
        let src = file.url;
        if (!src) {
        src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
        }
        return src;
    }
  
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onRemove = (file) => {
    if(file?.id) deleteImage(file.id)
    const files = (fileList || []).filter((v) => v.url !== file.url);
    if (onChange) {
      onChange(files);
    }
  };

  // img
  const setPathExistData = () => {
    setFileList(value);
    // onChange(la_fileList)
  }
  useEffect(() => {
    if(value.length > 0)  setPathExistData();
  }, [value])
  
  return (
    <ImgCrop rotationSlider cropShape={"rect"} aspect={2/1}>
      <Upload.Dragger
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onImageChange}
        onPreview={onPreview}
        crossOrigin
        onRemove={onRemove}
        maxCount={5}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Supported image files: JPEG or PNG. Strictly prohibited from uploading company data or other
          banned files.
        </p>
        {/* {fileList.length < 5 && '+ Upload / Darg & Drop'} */}
      </Upload.Dragger>
    </ImgCrop>
  );
};
export default UploadAndCropImage;