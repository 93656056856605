import "./style.css";
import React from "react";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";
import Space from "antd/lib/space";
const { Title, Text } = Typography;

const ContactDetails = ({ lo_details }) => {
  return (
    <>
      {/* <div className="contact-header-container "> */}
      {/* <Col xs={24} sm={24} md={24} lg={24} style={{ width: '100%'}}> */}
      <Title level={3} className="title">Contact Information</Title>
      {/* </Col> */}
        
      {/* </div> */}

      <div className="contact-body-container">
        <Row gutter={[16, 16]}>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Space direction="vertical">
              <Text>
                First Name<Text type="danger"> *</Text>
              </Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={16} lg={16}>
            <Space direction="vertical">
              <Text type="secondary"> {lo_details?.billing_first_name}</Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Space direction="vertical">
              <Text>
                Last Name<Text type="danger"> *</Text>
              </Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={16} lg={16}>
            <Space direction="vertical">
              <Text type="secondary">{lo_details?.billing_last_name}</Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Space direction="vertical">
              <Text>
                Email<Text type="danger"> *</Text>
              </Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={16} lg={16}>
            <Text type="secondary">{lo_details?.billing_email}</Text>
          </Col>
          {/* <Col xs={12} sm={12} md={8} lg={8}>
            <Space direction="vertical">
              <Text>Payment Method</Text>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={16} lg={16}>
            <Text type="secondary">
              {lo_details?.payment_method ? lo_details?.payment_method : "NA"}
            </Text>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default ContactDetails;
