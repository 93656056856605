import React from 'react'
import Routers from '../ui/routes'
import "../ui/style/index.css";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from 'antd/lib';
import { Provider } from 'react-redux';
import store, { persistor } from '../ui/store';
import AcceptCookie from '../ui/components/AcceptCookie';

const App = () => {
  return (
    <>
      <ConfigProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routers />
          </PersistGate>
        </Provider>
      </ConfigProvider>
      <AcceptCookie/>
    </>
  )
}

export default App
