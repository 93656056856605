import React, { useState, useEffect } from "react";
import Content from "antd/lib/layout/layout";
import Tabs from "antd/lib/tabs";
import message from "antd/lib/message";
import { Outlet, useNavigate } from "react-router";
import { Button, Typography } from "antd/lib";
import AppstoreAddOutlined from "@ant-design/icons/AppstoreAddOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import SolutionOutlined from "@ant-design/icons/SolutionOutlined";

import "./style.css";
import { Link } from "react-router-dom";
import { getDeviceType } from "../../../utility";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { getPublicRequestApi } from "../../../../api/commonRequest";
const { TabPane } = Tabs;


const CreateEvent = () => {
    const navigate = useNavigate()
    const ls_organisationId = useSelector( (state) => state.common.organisation_id );
    const [li_navIndex, setNavIndex] = useState(0);
    const [ls_paymentAccountStatus, setPaymentAccountStatus] = useState()

    const getPaymentAccountStatus = async () => {
        const lo_res = await getPublicRequestApi("connect-account/"+ls_organisationId);
        setPaymentAccountStatus(lo_res?.account?.status == 1 ? 'verified' : 'unverified')
    }

    const la_navList = [
        {
            icon: AppstoreAddOutlined,
            name: 'Basic Info',
            path: 'basic-info'
        },
        {
            icon: FileTextOutlined,
            name: 'Details',
            path: 'details'
        },
        {
            icon: SolutionOutlined,
            name: 'Ticket',
            path: 'ticket'
        },
        {
            icon: UndoOutlined,
            name: 'Publish',
            path: 'publish'
        },
    ]

    const onTabsChange = (index) => {
        setNavIndex(index);
        navigate(la_navList[index].path);
    }
    useEffect(() => {
        if (window.location.pathname) {
            const la_urlPath = window.location.pathname.split('/');
            const ls_currentPath = la_urlPath.pop();
            const li_indx = la_navList.findIndex(x => x.path == ls_currentPath);
            setNavIndex(li_indx);
        }
    }, [window.location.pathname]);

    // useEffect(() => {
    //     if(ls_organisationId) getPaymentAccountStatus();
    // }, [ls_organisationId])
    
    // useEffect(() => {
    //   if (ls_paymentAccountStatus && ls_paymentAccountStatus == 'unverified') {
    //     message.error('You have to create stripe account before create event!!');
    //     navigate('/events');
    //   }
    // }, [ls_paymentAccountStatus])
    

    // Tab Menu change on mobile screen

    const ls_deviceType = getDeviceType();

    return (
        <div>
            <Col xs={24} sm={24} md={24} lg={24} className="header">
                <Row className="form-header">
                    <Col  xs={16} sm={18} md={16} lg={16}>
                        <Typography.Title level={4}>Update Events</Typography.Title>
                    </Col>
                    <Col  xs={3} sm={2} md={5} lg={6}></Col>
                    <Col xs={5} sm={4} md={3} lg={2}>
                        <Link to="/events" key="1">
                            <Button key="2" className='add-btn button__padding' icon={<ArrowLeftOutlined />}>
                                Back
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Col>

            <Content className="form-tab">
                <Tabs size="large"
                    type="card"
                    onChange={onTabsChange}
                    activeKey={'' + li_navIndex}
                    className="content-tab"
                    // tabBarStyle={{width:'20%'}}
                    tabPosition={ls_deviceType == 'xs' ? 'top' : 'left'}
                >
                    {
                        la_navList.map((item, inx) => {
                            return (
                                <TabPane
                                    tab={
                                        <span>
                                            <item.icon /> {item.name}
                                        </span>
                                    }
                                    key={inx}
                                >
                                    <Outlet />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </Content>
        </div >
    );
};

export default CreateEvent;