import React, { useEffect, useState } from "react";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import "./style.less";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Divider from "antd/lib/divider";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import Radio from "antd/lib/radio";
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import Space from "antd/lib/space";
import InputNumber from "antd/lib/input-number";
import message from "antd/lib/message";
import { useParams } from "react-router";
import { Typography } from "antd";
// import moment from "moment";
import { objectToFormData } from "../../../utility";
import { getRequestApi } from "../../../../api/commonRequest";
import { Api_base_url } from "../../../../config";
import { getCountryListCommonApi } from "../../../../api/masterDataRequest";
import { Alert, Select } from "antd/lib";

const CreatePassword = () => {
    const [form] = Form.useForm();

    const ls_token = localStorage.getItem('token')
    const ls_userId = localStorage.getItem('user_id')
    const ls_organisationId = localStorage.getItem('organisation_id')

    const [lb_loading, setLoading] = useState(false)
    // const [ls_updateId, setUpdateId] = useState('')
    const [la_countryList, setCountryList] = useState([]);
    const [la_responseMessage, setResponseMessage] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const ls_Msgkey = 'msgLoader';

    const onSubmitForm = (lo_formValue) => {
        messageApi.open({ ls_Msgkey, type: 'loading', content: 'Loading...',});
        setLoading(true)
        const lo_formData = { ...lo_formValue };

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer "+ls_token);
        const formdata = objectToFormData(lo_formData);
        console.log('lo_formData',lo_formData);
        let requestOptions = {
            // mode: 'no-cors',
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
            };
        
        fetch(Api_base_url+"update-user-password", requestOptions)
        .then(response => response.text())
        .then(result => {
            messageApi.destroy()
            setLoading(false)
            console.log('result',JSON.parse(result))
            const lo_response = JSON.parse(result)
            if (lo_response.status == 'success') {
                message.success("Password Changed Succesfully!")
                setResponseMessage([])
            }else {
                message.error('Validation Error!!')
                if (lo_response?.message?.password) {
                    setResponseMessage(lo_response.message.password)
                }else if (lo_response.message) {
                    setResponseMessage(lo_response.message)
                }
                
            }
        })
        .catch(error => {
            messageApi.destroy()
            setLoading(false)
            console.log('error', error)
            message.error('Something Wrong!!')
        });
    }
     
    // useEffect(() => {
    // }, [ls_userId])

    return (
        <div>
            <Form
                className="content-form"
                form={form}
                labelCol={{
                    span: 7
                }}
                onFinish={onSubmitForm}
            >
                <Row>

                    <Col span={24}>
                        {la_responseMessage.length > 0 ? <div className="login-header-block">
                        <Alert
                            message="Error!!"
                            description={
                                la_responseMessage.map((msg) => <><Divider/><Typography.Text>{msg}</Typography.Text></>)
                            }
                            type="error"
                            showIcon
                            closable
                        />
                        </div> : <></>}
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Old Password"
                            name='old_password'
                            rules={[{ required: true }]}
                        >
                            <Input.Password placeholder="Enter old password" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="New Password"
                            name='password'
                            rules={[{ required: true }]}
                        >
                            <Input.Password placeholder="Enter new password" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Confirm Password"
                            name='password_confirmation'
                            rules={[{ required: true }]}
                        >
                            <Input.Password placeholder="Confirm new password" />
                        </Form.Item>
                    </Col>
                </Row>
               
                <Row>
                    <Col span={24}>
                        <Divider className="mt-0" orientation="left" plain></Divider>
                    </Col>
                </Row>
                <div style={{ float: "right" }}>
                    <Button loading={lb_loading} type="primary" htmlType="submit" icon={<CheckOutlined />}>Update</Button>
                </div>
            </Form>

            <Divider style={{marginTop:58}} />
            

        </div>
    )
}
export default CreatePassword