import React, { useState, useEffect } from "react";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Typography from "antd/lib/typography"
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import Tag from "antd/lib/tag";
import "./style.less";
import Divider from "antd/lib/divider";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import DeleteFilled from "@ant-design/icons/DeleteFilled";
import EditOutlined from "@ant-design/icons/EditOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
// import moment from "moment";
import message from "antd/lib/message";
import Search from 'antd/lib/input/Search';
// import debounce from 'lodash/debounce';
import Avatar from "antd/lib/avatar";
import InputNumber from "antd/lib/input-number";
import { Space } from "antd/lib";
import { Api_base_url } from "../../../../config";
import { getAndsetAllOrganizationList } from "../../../utility";
import './style.css'
import { useDispatch } from "react-redux";
import { setSelectOrganization } from "../../../store/common";

const OverviewForm = ({ ls_actionFromPage = "main", ls_organizationId = null, getDataList, ls_actionType, lo_actionRecord, handleSetDataAction, updateFrom='general' }) => {
    const dispatch = useDispatch();
    const ls_token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const dateFormat = "DD/MM/YYYY";
    const [lb_loading, setLoading] = useState(false)
    const [lb_visibleModal, setVisibleModal] = useState(false)
    const [ls_updateId, setUpdateId] = useState('')
    const [la_countryList, setCountryList] = useState([]);
    const [lo_responseMessage, setResponseMessage] = useState({})

    const handleShowModal = () => {
        setUpdateId('')
        form.resetFields();
        form.setFieldsValue({'contact_phone_prefix':'+44'})
        setVisibleModal(true)
    }
    const handleShowUpdateModal = (record, index) => {
        console.log('handleShowUpdateModal', record);
        setVisibleModal(true)
        const lo_updateDate = {}
        lo_updateDate['name'] = record.name;
        lo_updateDate['country'] = record.country_id;
        lo_updateDate['postcode'] = record.postcode;
        lo_updateDate['city'] = record.city;
        lo_updateDate['address'] = record.address;
        lo_updateDate['address2'] = record.address2;
        lo_updateDate['contact_person'] = record.contact_person;
        lo_updateDate['contact_email'] = record.contact_email;
        lo_updateDate['contact_phone_prefix'] = record.contact_phone_prefix;
        lo_updateDate['contact_phone'] = record.contact_phone;
        // lo_updateDate['organization'] = {...record.organization};
        setUpdateId(record.id)
        form.resetFields()
        form.setFieldsValue(lo_updateDate)
    }
    const handleCancel = () => {
        form.resetFields()
        setUpdateId('')
        setVisibleModal(false)
        handleSetDataAction()
    };

    function objectToFormData(obj) {
        const formData = new FormData();

        Object.entries(obj).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return formData;
    }

    const handleChangeCountry = (ls_value, e) => {
        if (e?.key) {
            const ls_countryPhCode = la_countryList[e?.key]?.phone_no_prefix;
            form.setFieldsValue({ 'contact_phone_prefix': ls_countryPhCode });
        } else {
            form.setFieldsValue({ 'contact_phone_prefix': null });
        }

    }

    const handleSubmitFormDetails = (lo_formDetails) => {
        console.log('lo_formDetails',lo_formDetails);
        setLoading(true)
        const lo_formData = { ...lo_formDetails };
        if(!lo_formDetails?.city) lo_formData['city'] = '';
        if(!lo_formDetails?.postcode) lo_formData['postcode'] = '';

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + ls_token);
        console.log('ls_token', ls_token, lo_formData);
        const formdata = objectToFormData(lo_formData);
        // setResponseMessage({})
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        if (ls_updateId) {
            fetch(Api_base_url + "organisation-update/" + ls_updateId, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false)
                    console.log('result', JSON.parse(result))

                    const lo_response = JSON.parse(result)
                    if (lo_response.status == 'success') {
                        setVisibleModal(false)
                        form.resetFields()
                        message.success("Organization Updated Succesfully!")
                        getDataList()
                        handleSetDataAction()

                        //this is for get org list on selction part
                        getAndsetAllOrganizationList(ls_token)

                        setResponseMessage({})
                        if (updateFrom == 'other') {
                            dispatch(setSelectOrganization({...lo_actionRecord, ...lo_formData }));
                        }
                    } else {
                        // setResponseMessage(lo_response.message)
                        setResponseMessage(lo_response.message)
                        message.error('Validation Error!!')
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log('error', error)
                    message.error('Something Wrong!!')
                });
        } else {
            fetch(Api_base_url + "organisation-create", requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false)
                    console.log('result', JSON.parse(result))

                    const lo_response = JSON.parse(result)
                    if (lo_response.status == 'success') {
                        setVisibleModal(false)
                        form.resetFields()
                        message.success("Organization Added Succesfully!")
                        getDataList()
                        handleSetDataAction()

                        //this is for get org list on selction part
                        getAndsetAllOrganizationList(ls_token)

                        // setResponseMessage({})
                    } else {
                        // setResponseMessage(lo_response.message)
                        message.error('Validation Error!!')
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log('error', error)
                    message.error('Something Wrong!!')
                });
        }
    }

    const deleteBoxOffice = (record) => {
        console.log('record', record);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + ls_token);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(Api_base_url + "update-status/" + record.id, requestOptions)
            .then(response => response.text())
            .then(result => {
                handleSetDataAction()
                console.log('result', JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response.status == 'success') {
                    getDataList()
                    //this is for get org list on selction part
                    getAndsetAllOrganizationList(ls_token)
                    message.success(`Succesfully Deleted!`)
                } else {
                    // setResponseMessage(lo_response.message)
                    message.error(lo_response.message[0])
                }
            })
            .catch(error => {
                handleSetDataAction()
                console.log('error', error)
                message.error('Something Wrong!!')
            });
    }
    const handleRemoveSpecificOrganizationById = (record) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Typography.Text>Do you want to delete the Organization ({record.name})?</Typography.Text>
                {/* <Typography.Text>Do you want to delete ${record.name}?</Typography.Text> */}
            </>,
            onOk() {
                deleteBoxOffice(record);
            },
            //// cancelText: (setDelete(false)),
            onCancel() {
                handleSetDataAction()
            },
        });
    }

    const getCountryList = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(Api_base_url + "all-countries", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(JSON.parse(result))
                const lo_response = JSON.parse(result)
                if (lo_response?.countries) {
                    setCountryList(lo_response.countries)
                } else {
                    setCountryList([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const validatePhoneNumber = (rule, value, callback) => {
        const phoneNumber = String(value).replace(/\s/g, ''); // Remove spaces
        const isNumber = /^\d+$/.test(phoneNumber); // Check if it's a number
        const isValidLength = phoneNumber.length === 10;
    
        if (isNumber && isValidLength) {
            callback(); // Validation passed
        } else {
            callback('Please enter a valid 10-digit phone number without spaces');
        }
    };


    useEffect(() => {
        if (ls_actionType == "add_organization") {
            getCountryList()
            handleShowModal()
        } else if (ls_actionType == "edit_organization") {
            handleShowUpdateModal(lo_actionRecord)
            getCountryList()
        } else if (ls_actionType == 'remove_organization') {
            handleRemoveSpecificOrganizationById(lo_actionRecord)
        }
    }, [ls_actionType])

    return (
        <Modal
            title={ls_updateId ? 'Update Organization' : 'Add Organization'}
            open={lb_visibleModal}
            onOk={() => {
                form.validateFields().then((values) => {
                    // form.resetFields();
                    handleSubmitFormDetails(values);
                })
                    .catch((info) => {
                        // console.log('Validate Failed:', info);
                    });
            }}
            onCancel={handleCancel}
            confirmLoading={lb_loading}
            className='modal'
            width={700}
        >
            <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
                style={{
                    maxWidth: "600px",
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Organization Name"
                            name='name'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Organization Email"
                            name='contact_email'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Contact Person"
                            name='contact_person'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Full Address"
                            name='address'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Address Line 2 (Optional)"
                            name='address2'
                            rules={[{ required: false }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Post Code"
                            name='postcode'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="City"
                            name='city'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col> */}
                    <Col span={24} >

                        <Form.Item
                            labelAlign="right"
                            label="State/Country"
                            name='country'
                            rules={[{ required: true }]}
                        >
                            <Select
                                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                placeholder="Country" className="country__select"
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={handleChangeCountry}
                            >
                                {
                                    la_countryList && la_countryList.map((countryItem, countryIndex) => {
                                        return (
                                            <Select.Option key={countryIndex} value={countryItem.id}>{countryItem.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col xs={0} sm={0} md={4} lg={4}></Col>
                            <Col xs={24} sm={24} md={20} lg={20}>
                                <Space.Compact 
                                  style={{ width: "97%" ,float:'right'}}>
                                    <Form.Item
                                        labelAlign="right"
                                        label="Phone"
                                        labelCol={18}
                                        name='contact_phone_prefix'
                                        rules={[{ required: true }]}
                                        style={{ width: '29%', }}
                                    >
                                        <Input readOnly={true} />
                                    </Form.Item>

                                    <Form.Item
                                        // labelAlign="right"
                                        // label="Phone"
                                        name='contact_phone'
                                        rules={[
                                            { validator: validatePhoneNumber, message: 'Please enter a 10-digit phone number without spaces' }
                                        ]}
                                        style={{ width: '71%', }}
                                        className='contact_phone'
                                        validateStatus={lo_responseMessage['contact_phone'] ? 'error' : ''}
                                        help={lo_responseMessage?.contact_phone && lo_responseMessage?.contact_phone[0]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Space.Compact>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Form>

        </Modal>
    )
}

export default OverviewForm