import React, { useState , useEffect } from "react";
import Content from "antd/lib/layout/layout";
import Tabs from "antd/lib/tabs";
import { Outlet, useNavigate } from "react-router";
import { Button, Typography } from "antd/lib";
import AppstoreAddOutlined from "@ant-design/icons/AppstoreAddOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import SolutionOutlined from "@ant-design/icons/SolutionOutlined";

import "./style.css";
import { Link } from "react-router-dom";
import { getDeviceType } from "../../utility";
const { TabPane } = Tabs;

const ls_deviceType = getDeviceType();
const AccountSettings = () => {
    const navigate = useNavigate()
    const [li_navIndex, setNavIndex] = useState(0);

    const la_navList = [
        {
            icon: AppstoreAddOutlined,
            name: 'Account Information',
            path: 'account-info'
        },
        {
            icon: FileTextOutlined,
            name: 'Change Password',
            path: 'create-password'
        },
    ]

    const onTabsChange = (index) => {
        setNavIndex(index);
        navigate(la_navList[index].path);
    }
    useEffect(() => {
        const la_urlPath = window.location.pathname.split('/');
        const ls_currentPath = la_urlPath.pop();
        const li_indx = la_navList.findIndex(x => x.path == ls_currentPath);
        setNavIndex(li_indx);
    }, []);

    // Tab Menu change on mobile screen

    return (
        <div>
            <div className="form-header">
                <Typography.Title level={4}>Account Settings</Typography.Title>
                {/* <Link to="/dashboard" key="1">
                    <Button key="2" className='back-button' icon={<ArrowLeftOutlined />}>
                        Back
                    </Button>
                </Link> */}
            </div>
            <Content className="acc-form-tab">
                <Tabs size="large"
                    type="card"
                    onChange={onTabsChange} 
                    activeKey={'' + li_navIndex} 
                    className="content-tab"
                    tabPosition={ ls_deviceType == 'xs' ? 'top' : 'left' }
                >
                    {
                        la_navList.map((item, inx) => {
                            return (
                                <TabPane
                                    tab={
                                        <span>
                                            <item.icon /> {item.name}
                                        </span>
                                    }
                                    key={inx}
                                >
                                    <Outlet />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </Content>
        </div >
    );
};

export default AccountSettings;