
import BellOutlined from "@ant-design/icons/BellOutlined";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import FieldTimeOutlined from "@ant-design/icons/FieldTimeOutlined";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import BookOutlined from "@ant-design/icons/BookOutlined";
import TeamOutlined from "@ant-design/icons/TeamOutlined";
import ExceptionOutlined from "@ant-design/icons/ExceptionOutlined";

import AppstoreOutlined from "@ant-design/icons/AppstoreOutlined";
import ContainerOutlined from "@ant-design/icons/ContainerOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import DesktopOutlined from "@ant-design/icons/DesktopOutlined";
import NotificationOutlined from "@ant-design/icons/NotificationOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import PieChartOutlined from "@ant-design/icons/PieChartOutlined";
import UserSwitchOutlined from "@ant-design/icons/UserSwitchOutlined";
import BankOutlined from "@ant-design/icons/BankOutlined";
import RetweetOutlined from "@ant-design/icons/RetweetOutlined";
import FileSearchOutlined from "@ant-design/icons/FileSearchOutlined"; 
import Tooltip  from "antd/lib/tooltip";
// import { PayCircleOutlined } from "@ant-design/icons";

export const getMenuList = () => {
 
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  return [
    getItem(<div className="menu-font">Overview</div>, 'dashboard', <Tooltip title={'Overview'} color="#ff7733"><HomeOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Events</div>, 'events', <Tooltip title='Events' color="#ff7733"><CalendarOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Orders</div>, 'orders', <Tooltip title='Orders' color="#ff7733"><ContainerOutlined className="iconstyle" /></Tooltip>),
    // getItem('Promote', 'promote', <NotificationOutlined className="iconstyle" />),
    getItem(<div className="menu-font">Reports</div>, 'report', <Tooltip title='Reports' color="#ff7733"><BarChartOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Referrals</div>, 'referrals', <Tooltip title='Referrals' color="#ff7733"><TeamOutlined className="iconstyle" /></Tooltip>),
    // getItem(<div className="menu-font">Team Access</div>, 'team-access', <Tooltip title='Team Access' color="#ff7733"><UserSwitchOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Checkin User</div>, 'checkin-user', <Tooltip title='Checkin User' color="#ff7733"><UserSwitchOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Org. Settings</div>, 'overview', <Tooltip title='Org. Settings' color="#ff7733"><SettingOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Stripe Account</div>, 'create-stripe-account', <Tooltip title='Stripe Account' color="#ff7733"><BankOutlined  className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Switch to Attendee</div>, 'switch-to-attendee', <Tooltip title='Switch to Attendee' color="#ff7733"><RetweetOutlined  className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Browse Events</div>, 'event-list', <Tooltip title='Browse Events' color="#ff7733"><FileSearchOutlined  className="iconstyle" /></Tooltip>),
    // getItem('Option 3', '3', <ContainerOutlined />),
    // getItem('Navigation One', 'sub1', <MailOutlined />, [
    //   getItem('Option 5', '5'),
    //   getItem('Option 6', '6'),
    //   getItem('Option 7', '7'),
    //   getItem('Option 8', '8'),
    // ]),
    // getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    //   getItem('Option 9', '9'),
    //   getItem('Option 10', '10'),
    //   getItem('Submenu', 'sub3', null, [getItem('Option 11', '11'), getItem('Option 12', '12')]),
    // ]),
  ];
}

export const getMenuListForUser = () => {
 
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  return [
    getItem(<div className="menu-font">Overview</div>, 'dashboard', <Tooltip title={'Overview'} color="#ff7733"><HomeOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">My Orders</div>, 'user-orders', <Tooltip title='Orders' color="#ff7733"><ContainerOutlined className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Switch to Organizer</div>, 'switch-to-organizer', <Tooltip title='Switch to Organizer' color="#ff7733"><RetweetOutlined  className="iconstyle" /></Tooltip>),
    getItem(<div className="menu-font">Browse Events</div>, 'event-list', <Tooltip title='Browse Events' color="#ff7733"><FileSearchOutlined  className="iconstyle" /></Tooltip>),
  ];
}