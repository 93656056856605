import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Typography from "antd/lib/typography";
import List from "antd/lib/list";
import Space from "antd/lib/space";
import eventImg from "../../../../ui/image/empty-event.png";
// import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import "./style.css";
import { getRequestApi } from "../../../../api/commonRequest";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
// import Avatar from "antd/lib/avatar/avatar";
import { useSelector } from "react-redux";
import Card from "antd/lib/card";
const { Text } = Typography;

const UserOrders = () => {
  const ls_token = useSelector((state) => state.common.token);
  const [la_orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (ls_token) {
      getOrderList();
    }
  }, [ls_token]);

  const getOrderList = async () => {
    setLoading(true);
    let la_orders = await getRequestApi("booking-list");
    setLoading(false);
    // console.log("la_orders", la_orders);
    if (la_orders?.status === "success") {
      setOrderList(la_orders?.bookings);
    }
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
  // const loadMore =
  //   !initLoading && !loading && la_orderList.length > 0? (
  //     <div
  //       style={{
  //         textAlign: "center",
  //         marginTop: 12,
  //         height: 32,
  //         lineHeight: "32px",
  //       }}
  //     >
  //       <Button
  //       // onClick={onLoadMore}
  //       >
  //         loading more
  //       </Button>
  //     </div>
  //   ) : null;

  const viewSpecificBookingDetails = async (record) => {
    if (record?.unique_code) {
      navigate(`/booking-details/${record?.unique_code}`, {
        state: record?.event_id,
      });
    }
  };

  const responsiveColumnCount = {
    xs: 1, // On extra-small screens, show 1 column
    sm: 2, // On small screens, show 2 columns
    md: 2, // On medium screens, show 3 columns
    lg: 2, // On large screens, show 4 columns
    xl: 3, // On extra-large screens, show 5 columns
    xxl: 3,
  };

  return (
    <>
      <Content className="content-layout thumbnail">
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Typography.Title className="title" level={3}>
                Booking List
              </Typography.Title>
            </Col>
            <Col xs={0} sm={0} md={0} lg={3}></Col>
            <Col xs={24} sm={24} md={24} lg={5} className="mb-5"></Col>
          </Row>
        </Col>
      </Content>
      <Content className="content-layout">
        <Col xs={24} sm={24} md={24} lg={24}>

        </Col>
        <List
          itemLayout="vertical"
          dataSource={la_orderList}
          size="small"
          // loadMore={loadMore}
          loading={loading}
          grid={{
            gutter: 16,
            ...responsiveColumnCount,
          }}
          pagination={{ position: "bottom", align: "center", pageSize: 5 }}
          renderItem={(item) => (
            <>
              <div
                onClick={() => viewSpecificBookingDetails(item)}
                className="card-item-wrapper"
              >
                <List.Item key={item?.id}>
                  <Card title={item.unique_code}
                    cover={
                      <img
                        alt="example"
                        src={ item?.event?.images && item?.event?.images[0]?.public_url ? item?.event?.images[0]?.public_url : eventImg}
                      />
                    }

                    actions={[
                      <Space direction="vertical">
                        <Text className="card-footer-header">Booking Date</Text>
                        <Text className="card-footer-content">{
                          item?.created_at
                            ? `${dayjs(
                              item?.booking_date,
                              "YYYY-MM-DD"
                            ).format("MMMM D, YYYY")}`
                            : ""
                        }</Text></Space>,
                      <Space direction="vertical">
                        <Text className="card-footer-header">Total Amount</Text>
                        <Text className="card-footer-content">{`£ ${item?.total_amount}`}</Text>
                      </Space>,
                      <Space direction="vertical">
                        <Text className="card-footer-header">Total Qty</Text>
                        <Text className="card-footer-content">{`${item?.total_quantity}`}</Text>
                      </Space>
                    ]}>
                    <Card.Meta
                      title={<Typography.Text>{item?.event?.name}</Typography.Text>}
                      description={<Typography.Text type="secondary">
                        {`${item?.billing_first_name} ${item?.billing_last_name} (${item?.billing_email})`}
                      </Typography.Text>}
                    />

                  </Card>

                </List.Item>
              </div>
              {/* <div className="order-divider"></div> */}
            </>
          )}
        />
      </Content>
    </>
  );
};

export default UserOrders;
