import { postRequestPublicApi } from "../../../../../api/commonRequest"
import { Api_base_url } from "../../../../../config";
import store from "../../../../store";
import { setAllOrganizationList, setLoggingIn, setOrganizationId, setSelectOrganization, setToken, setUser, setUserId, setUserType } from "../../../../store/common";
import { storeDataAfterLogin, storeOrganisationData } from "../../../../utility";

const getAndsetAllOrganizationList = (ls_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+ls_token);
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

    fetch(Api_base_url+"all-organisations", requestOptions)
    .then(response => response.text())
    .then(async (result) => {
        const lo_response = JSON.parse(result)
        if (lo_response?.organisations) {
          storeDataAfterLogin(null, lo_response.organisations);
          store.dispatch(setAllOrganizationList(lo_response.organisations));
          if(lo_response.organisations.length == 1){
            const lo_orgDetails = {...lo_response.organisations[0]};
            store.dispatch(setOrganizationId(lo_orgDetails.id));
            store.dispatch(setSelectOrganization(lo_orgDetails));
            await storeOrganisationData(lo_response.organisations[0])
          }
        }
    })
    .catch(error => console.log('error', error));
}
export const submitGuestRegistrationData = async (lo_formData) => {
    const lo_response = await postRequestPublicApi('guest-reg', lo_formData);
    console.log('lo_response===lo_response',lo_response);

    if (lo_response?.status == 'success') {
        store.dispatch(setLoggingIn(true));
        store.dispatch(setUserType('user'));
        store.dispatch(setUserId(lo_response.user.id));
        store.dispatch(setUser(lo_response.user));
        store.dispatch(setToken(lo_response.token));

        storeDataAfterLogin(lo_response)
        getAndsetAllOrganizationList(lo_response.token)
    }
    
    
    return lo_response;
}