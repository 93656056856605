import React, { useEffect } from 'react';
import "./style.css";
// import logo from "../../image/logo.png"
import logImg from "../../image/log.png"
import registerImg from "../../image/register.png"


import { Outlet, useLocation } from 'react-router-dom';

const AuthLayout = ({setUserDetailsOfUser}) => {
  const location = useLocation();
  // const container = document.querySelector(".login-container");
  const handleOnClickSignUpBtn = () => {
    const container = document.querySelector(".login-container");
    container.classList.add("sign-up-mode");
  }

  const handleOnClickSignInBtn = () => {
    const container = document.querySelector(".login-container");
    container.classList.remove("sign-up-mode");
  }

  useEffect(() => {
    setUserDetailsOfUser()
  }, [location])

  return (

    <div className="login-container">
      <div className="forms-container">
        <Outlet />
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Don’t have an account? Set one up.</h3>
            <p>Join over 80,000 event creators using us!</p>
            <button className="btn transparent" id="sign-up-btn" onClick={handleOnClickSignUpBtn}>
              Sign up
            </button>
          </div>
          <img src={logImg} className="image" alt="image" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>Already have an account?</h3>
            <p>It’s free to create an account and get your event live.</p>
            <button className="btn transparent" id="sign-in-btn"  onClick={handleOnClickSignInBtn}>
              Sign in
            </button>
          </div>
          <img src={registerImg} className="image" alt="image" />
        </div>
      </div>
    </div>

  );
}

export default AuthLayout;