import React, { useEffect, useState } from 'react'
// import {CKEditor} from 'ckeditor4-react';
import "./style.less"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CkEditors = ({ value = null, onChange = () => { }, height="100%" }) => {
    return (
            <CKEditor
                // style={{height: '500px'}}
                editor={ ClassicEditor }
                data={value}
                config={ {
                    // plugins: [ Paragraph, Bold, Italic, Essentials ],
                    // toolbar: [ 'bold', 'italic' ]
                    removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']

                } }
                onReady={ editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    console.log( { event, editor, data } );
                    onChange(data);
                } }
                onBlur={ ( event, editor ) => {
                    // console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    // console.log( 'Focus.', editor );
                } }
            />
         
    )
}

export default CkEditors;