import React, { useEffect } from 'react';
import { useState } from "react";
import { Input, List } from "antd";

import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import "./style.less"
import { ls_apiKey } from '../../../config';

const AutoCompleteLocation = ({ setmapRes, setFullAddress }) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: ls_apiKey,
        options: { componentRestrictions: { country: ["in", "uk"] }, fields: ["address_components", "geometry", "icon", "name"] },
        // options: { fields: ["address_components", "geometry", "icon", "name"] }
    });
    const [ls_lookupvalue, setLookupValue] = useState("");
    const [hide, setHide] = useState(true);

    const handleChooseAddress = (lo_addr) => {
        setTimeout(() => setHide(true), 500)

        setLookupValue(lo_addr.description);
        setFullAddress(lo_addr.description);
        if (placePredictions.length)
            placesService?.getDetails(
                {
                    placeId: placePredictions[0].place_id,
                    fields: ["address_components"]
                },
                (placeDetails) => autoFillAddress(placeDetails)
            );
    }

    useEffect(() => {
        if (ls_lookupvalue != "")
            setHide(false);

    }, [ls_lookupvalue])


    const autoFillAddress = (place) => {
        console.log("place: ", place);
        let ls_postalCode = '';
        let ls_city = '';
        let ls_country = '';
        for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
                case "postal_code":
                    ls_postalCode = component.long_name;
                    break;
                case "locality":
                    ls_city = component.long_name;
                    break;
                case "postal_town":
                    ls_city = ls_city != '' ? ls_city : component.long_name;
                    break;
                case "country":
                    ls_country = component.long_name;
                    break;
            }
        }
        const lo_mapRes = { "postalCode": ls_postalCode, "city": ls_city, "country": ls_country }
        return setmapRes(lo_mapRes);
    }

    return (
        <div className="auto-cnt">
            <Input.Search
                // style={{ color: "black" }}
                className="mb8-xs"
                value={ls_lookupvalue}
                placeholder="Lookup Address"
                onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    setLookupValue(evt.target.value);
                }}
                loading={isPlacePredictionsLoading}
            />
            {!isPlacePredictionsLoading && !hide && placePredictions.length > 0 && (
                <div className='atocomplete-options'>

                    <List
                        dataSource={placePredictions}
                        renderItem={(item) => (
                            <List.Item onClick={() => handleChooseAddress(item)}>
                                <List.Item.Meta title={item.description} />
                            </List.Item>
                        )}
                    />

                </div>
            )
            }
        </div>
    );
};

export default AutoCompleteLocation;